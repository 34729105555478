import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useFocusEffect,
  useIsFocused,
  useRoute,
  useNavigation,
} from '@react-navigation/native';
import { OptionProps } from 'react-select';
import shallow from 'zustand/shallow';
import { TextField } from '../../../../../packages/assets/components/text-field';
import { useForm } from '../../../../../packages/assets/form';
import { Form } from '../../../../../packages/assets/layout';
import { ArrowLeftIcon, PlusIcon, SlashIcon, TrashIcon } from 'assets/icons';
import {
  FieldError,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';
import {
  createAppointmentType,
  getAppointmentGroupCategories,
  getAppointmentGroups,
  getAppointmentType,
  getForms,
  getSchedules,
  resetAppointmentGroups,
  resetAppointmentType,
  resetSchedules,
  setSubmit,
  updateAppointmentType,
} from './service-form-actions';
import { useServiceFormState } from './service-form-store';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import {
  AppointmentGroupDto,
  AppointmentTypeScheduleDto,
  CreateAppointmentTypeDto,
  AppointmentTypePeriodType,
  FormDto,
  AppointmentGroupCategoryDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { View } from 'react-native';
import { makeStyles, useTheme } from '../../../../../packages/assets/theme';
import { Text } from '../../../../../packages/assets/components/text';
import { Icon } from '../../../../../packages/assets/components/icon';
import {
  CheckIcon,
  ClockIcon,
  EditIcon,
} from '../../../../../packages/assets/icons';
import { useToast } from '../../common/hooks/useToast';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  ComboInput,
  ComboInputLayout,
  ComboInputMode,
} from '../combo-input/ComboInput';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Button } from 'assets/components/button';
import { IconButton } from 'assets/components/icon-button';
import { FormSectionSeparator } from './FormSectionSeparator';
import { PreviewAvailability } from './PreviewAvailability';
import { getText } from 'assets/localization/localization';
import RadioButton, {
  RadioButtonInputMode,
} from '../../../../../packages/assets/components/radio-button-group/RadioButton';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { AccumulativeInput } from '../accumulative-input/AccumulativeInput';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';

interface SelectOptions {
  title: string;
  id: any;
}

const FREQUENCY_OPTIONS = [
  { title: getText('year'), id: 0 },
  { title: getText('month'), id: 1 },
  { title: getText('week'), id: 2 },
];

export const DropdownOptionTemplate = (
  props: OptionProps<
    | AppointmentTypeScheduleDto
    | AppointmentGroupDto
    | AppointmentGroupCategoryDto
    | MockAppointmentLocationDto
    | SelectOptions
    | BufferOptionDto
  >,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    props.selectProps.value !== null
      ? (
          props.selectProps.value as
            | AppointmentTypeScheduleDto
            | AppointmentGroupDto
            | MockAppointmentLocationDto
            | BufferOptionDto
        ).id === props.data.id
      : false;

  const isDisabled = props.isDisabled;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={[styles.title, isDisabled ? styles.disabledOptionText : null]}
      >
        {props.data.title}
      </Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

export const DropdownOptionTemplateForm = (props: OptionProps<FormDto>) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    props.selectProps.value !== null
      ? (props.selectProps.value as FormDto).form_id === props.data.form_id
      : false;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text style={styles.title}>{props.data.form_name}</Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

export const DropdownOptionTemplateNumeric = (
  props: OptionProps<BufferOptionDto>,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    props.selectProps.value !== null
      ? (props.selectProps.value as BufferOptionDto).id === props.data.id
      : false;

  const isDisabled = props.isDisabled;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={[styles.title, isDisabled ? styles.disabledOptionText : null]}
      >
        {props.data.title}
      </Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

// TODO: This will be replaced with actual back-end data and interfaces
// for now we're just mocking the location selector for visual purposes
interface MockAppointmentLocationDto {
  id: string;
  title: string;
  isDisabled?: boolean;
}

interface BufferOptionDto {
  id: string;
  title: string;
}

interface BookingLimitsOption {
  id: string;
  title: string;
}

interface BookingLimitsRecordDto {
  type: BookingLimitsOption;
  value: string;
}

const mockLocationValues = [
  {
    title: getText('venue-in-person'),
    id: 'in-person',
  },
  {
    title: getText('venue-virtual'),
    id: 'virtual',
    isDisabled: true,
  },
  {
    title: getText('venue-over-the-phone'),
    id: 'over-the-phone',
    isDisabled: true,
  },
];

const bufferOptions = [
  {
    title: getText('no-buffer-time'),
    id: '0',
  },
  ...['5', '10', '15', '20', '30', '45', '60', '90', '120'].map((minutes) => ({
    title: minutes + ` ${getText('minutes').toLowerCase()}`,
    id: minutes,
  })),
];

const bookingLimitsOptions = [
  {
    title: getText('per-day'),
    id: 'PER_DAY',
  },
  {
    title: getText('per-week'),
    id: 'PER_WEEK',
  },
  {
    title: getText('per-month'),
    id: 'PER_MONTH',
  },
];

const daysTypeOptions = [
  {
    title: getText('calendar-days'),
    id: 'calendar',
  },
  { title: getText('business-days'), id: 'business' },
];

const initialFormValue = {
  title: '',
  description: '',
  notes: '',
  length: 15,
  appointmentGroupCategory: null,
  appointmentGroup: null,
  schedule: null,
  interval: 1,
  count: 12,
  freq: FREQUENCY_OPTIONS[2],
  location: mockLocationValues[0],
  daysType: daysTypeOptions[0],
  enabled: true,
  forms: [{}],
  beforeEvent: null,
  afterEvent: null,
};

export const ServiceForm: FunctionComponent<ServiceFormProps> = (props) => {
  const theme = useTheme();

  const methods = useForm<ServiceFormData>({
    defaultValues: initialFormValue,
    mode: 'onChange',
  });
  const isFocused = useIsFocused();
  const {
    appointmentGroups,
    appointmentGroupCategories,
    schedules,
    submit,
    status,
    appointmentType,
    forms,
    formsStatus,
  } = useServiceFormState(
    (state) => ({
      ...state,
      forms: state.forms?.map((form) => {
        return {
          form_id: form.id,
          form_name: form.title,
          form_status: form.status,
        };
      }),
    }),
    shallow,
  );
  const scheduleWatch = methods.watch('schedule');
  const [availableBookingFrequencyLimits, setAvailableBookingFrequencyLimits] =
    useState(bookingLimitsOptions);
  const [
    availableBookingTotalDurationLimits,
    setAvailableBookingTotalDurationLimits,
  ] = useState(bookingLimitsOptions);
  const [selectedPeriodType, setSelectedPeriodType] =
    useState<AppointmentTypePeriodType>(AppointmentTypePeriodType.Unlimited);

  const [appointmentGroupsByCategory, setAppointmentGroupsByCategory] =
    useState<AppointmentGroupDto[]>([]);

  const styles = useStyles();
  const route = useRoute<any>();
  const serviceId = route.params?.serviceId;
  const edit = !!serviceId;
  const isLoading =
    status === 'loading' ||
    !schedules ||
    !appointmentGroups ||
    (edit && !appointmentType);
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const periodStartDateWatch = methods.watch('periodStartDate');
  const periodEndDateWatch = methods.watch('periodEndDate');

  const bookingFrequencyLimitsArray: UseFieldArrayReturn<
    ServiceFormData,
    'bookingFrequencyLimits',
    string
  > = useFieldArray({
    control: methods.control,
    name: 'bookingFrequencyLimits',
  });

  const bookingTotalDurationLimitsArray: UseFieldArrayReturn<
    ServiceFormData,
    'bookingTotalDurationLimits',
    string
  > = useFieldArray({
    control: methods.control,
    name: 'bookingTotalDurationLimits',
  });

  const getAvailableLimitOptions = (
    fieldName: 'bookingTotalDurationLimits' | 'bookingFrequencyLimits',
  ) => {
    const currentValues = methods.getValues(fieldName);

    const newOptions = bookingLimitsOptions.filter(
      (o) => !currentValues.map((c) => c.type.id).includes(o.id),
    );

    return newOptions;
  };

  useFocusEffect(
    useCallback(() => {
      getAppointmentGroups();
      getAppointmentGroupCategories();
      getSchedules();
      getForms();

      if (edit) {
        getAppointmentType(serviceId);
      }

      return () => {
        methods.reset(initialFormValue);
        resetAppointmentGroups();
        resetSchedules();
        resetAppointmentType();
        setAvailableBookingFrequencyLimits(bookingLimitsOptions);
        setAvailableBookingTotalDurationLimits(bookingLimitsOptions);
      };
    }, [isFocused]),
  );

  useEffect(() => {
    if (schedules && !edit) {
      const schedule = schedules.find((schedule) => schedule.isDefault);
      schedule && methods.setValue('schedule', schedule);
    }
  }, [schedules]);

  useEffect(() => {
    if (submit) {
      methods.handleSubmit(handleSubmit)();
      setSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    if (periodStartDateWatch && periodEndDateWatch) {
      setSelectedPeriodType(AppointmentTypePeriodType.Range);
    }
  }, [periodStartDateWatch, periodEndDateWatch]);

  useEffect(() => {
    if (
      edit &&
      appointmentType &&
      schedules &&
      appointmentGroups &&
      appointmentGroupCategories
    ) {
      const schedule = schedules.find(
        (schedule) =>
          schedule.id === appointmentType.appointment_type_schedule_id,
      );
      const appointmentGroup = appointmentGroups.find(
        (group) => group.id === appointmentType.appointment_group_id,
      );
      const appointmentGroupCategory = appointmentGroupCategories.find(
        (groupCategory) =>
          groupCategory.id === appointmentGroup?.appointment_group_category_id,
      );
      if (appointmentGroupCategory) {
        populateAppointmentGroups(appointmentGroupCategory);
      }

      const formData: ServiceFormData = {
        enabled: appointmentType.enabled,
        title: appointmentType.title,
        notes: appointmentType.notes,
        description: appointmentType.description,
        length: appointmentType.length,
        appointment_type_schedule_id: schedule ? schedule.id : '',
        appointment_group_id: appointmentGroup ? appointmentGroup.id : '',
        appointmentGroup,
        appointmentGroupCategory,
        schedule,
        beforeEvent: bufferOptions.find(
          (o) => o.id === String(appointmentType.beforeEventBuffer),
        ),
        afterEvent: bufferOptions.find(
          (o) => o.id === String(appointmentType.afterEventBuffer),
        ),
        forms_optional: appointmentType.forms_optional ?? false,
        limitBookingFrequenciesEnabled:
          !!appointmentType.bookingLimits &&
          Object.entries(appointmentType.bookingLimits).length > 0,
        'condition-limitBookingFrequenciesEnabled':
          !!appointmentType.bookingLimits &&
          Object.entries(appointmentType.bookingLimits).length > 0,
        bookingFrequencyLimits: Object.entries(
          appointmentType.bookingLimits || {},
        ).map(([key, value]) => {
          return {
            type:
              bookingLimitsOptions.find((r) => r.id == key) ||
              bookingLimitsOptions[0],
            value: String(value),
          };
        }),
        limitTotalBookingDurationEnabled:
          !!appointmentType.bookingLimits &&
          Object.entries(appointmentType.bookingLimits).length > 0,
        bookingTotalDurationLimits: Object.entries(
          appointmentType.durationLimits || {},
        ).map(([key, value]) => {
          return {
            type:
              bookingLimitsOptions.find((r) => r.id == key) ||
              bookingLimitsOptions[0],
            value: String(value),
          };
        }),
        'condition-limitTotalBookingDurationEnabled':
          !!appointmentType.durationLimits &&
          Object.entries(appointmentType.durationLimits).length > 0,
        'condition-limitFutureEnabled':
          appointmentType.periodType !== AppointmentTypePeriodType.Unlimited,
        periodDays: appointmentType.periodDays,
        daysType: appointmentType.periodCountCalendarDays
          ? daysTypeOptions[0]
          : daysTypeOptions[1],
        periodStartDate: appointmentType.periodStartDate,
        periodEndDate: appointmentType.periodEndDate,
        forms: appointmentType.forms.length
          ? getFormsWithNames(appointmentType.forms)
          : [{ form_id: '', form_name: '' }],
      };
      appointmentType.periodType &&
        setSelectedPeriodType(appointmentType.periodType);

      methods.reset(formData);
    }
  }, [
    appointmentType,
    schedules,
    appointmentGroups,
    appointmentGroupCategories,
  ]);

  const handleSubmit = () => {
    const data = methods.getValues();

    const appointmentTypeData: CreateAppointmentTypeDto = {
      title: data.title,
      notes: data.notes,
      enabled: data.enabled,
      description: data.description,
      appointment_group_id: data.appointmentGroup!.id,
      appointment_type_schedule_id: data.schedule!.id,
      length: data.length,
      beforeEventBuffer: parseInt(data.beforeEvent?.id || '0'),
      afterEventBuffer: parseInt(data.afterEvent?.id || '0'),
      bookingLimits:
        data['condition-limitBookingFrequenciesEnabled'] &&
        data.bookingFrequencyLimits &&
        data.bookingFrequencyLimits.length != 0
          ? parseLimits(data.bookingFrequencyLimits)
          : {},
      durationLimits:
        data['condition-limitTotalBookingDurationEnabled'] &&
        data.bookingTotalDurationLimits &&
        data.bookingTotalDurationLimits.length != 0
          ? parseLimits(data.bookingTotalDurationLimits)
          : {},
      periodType: data['condition-limitFutureEnabled']
        ? selectedPeriodType
        : AppointmentTypePeriodType.Unlimited,
      periodCountCalendarDays: data.daysType?.id === 'calendar',
      periodDays:
        selectedPeriodType === AppointmentTypePeriodType.Rolling
          ? data.periodDays
          : undefined,
      periodStartDate:
        selectedPeriodType === AppointmentTypePeriodType.Range
          ? data.periodStartDate
          : undefined,
      periodEndDate:
        selectedPeriodType === AppointmentTypePeriodType.Range
          ? data.periodEndDate
          : undefined,
      forms: data.forms.filter(
        (form) => Object.keys(form).length !== 0 && form.form_id,
      ),
      forms_optional: data.forms_optional,
    };

    if (edit && appointmentType) {
      updateAppointmentType(appointmentType.id, appointmentTypeData).then(() =>
        navigation.navigate('services'),
      );
    } else {
      createAppointmentType(appointmentTypeData).then(() =>
        navigation.navigate('services'),
      );
    }
  };

  const parseLimits = (limits: BookingLimitsRecordDto[]) => {
    return Object.fromEntries(
      limits.map((r) => [r.type.id, parseInt(r.value)]),
    );
  };

  const getFieldErrorMessage = (
    fieldName: string,
  ): ReactElement | undefined => {
    const nameArray = fieldName.split('.');
    const error: FieldError = nameArray.reduce((field: any, name) => {
      if (field) {
        return field[name];
      }
    }, methods.formState.errors);

    if (error) {
      return <Text style={styles.error}>{error.message}</Text>;
    }
  };

  const getAvailableForms = (value: FormDto) => {
    const selectedForms = methods.getValues('forms');
    const availableForms = forms?.filter((form) => {
      return !selectedForms.find(
        (selectedForm) =>
          selectedForm.form_id === form.form_id &&
          selectedForm.form_id !== value.form_id,
      );
    });

    return availableForms;
  };

  const getFormsWithNames = (formsToProcess: FormDto[]) => {
    return formsToProcess.map((form) => {
      const formFullData = forms?.find((item) => form.form_id === item.form_id);

      if (formFullData) {
        return formFullData;
      }

      return form;
    });
  };

  const renderOptions = (props: {
    fieldName: string;
    fieldArray: UseFieldArrayReturn<ServiceFormData, any, string>;
    options: BookingLimitsOption[];
    label?: string;
    onChange: () => void;
  }) => {
    return (
      <View>
        {props.fieldArray.fields.map((field, index) => (
          <View style={styles.limitOptionRow} key={field.id}>
            <View style={styles.limitOptionRow}>
              <View style={styles.limitOptionRowItem}>
                <TextField
                  key={field.id}
                  name={`${props.fieldName}.${index}.value`}
                  style={{ height: 36 }}
                  height={36}
                  rules={{
                    pattern: {
                      value: /^[0-9]*$/,
                      message: getText('error-field-number'),
                    },
                    required: getText('field-required'),
                  }}
                />
                {getFieldErrorMessage(`${props.fieldName}.${index}.value`)}
              </View>

              {props.label && (
                <Text style={styles.limitOptionRowItem}>{props.label}</Text>
              )}
              <View style={styles.limitOptionRowItem}>
                <AdvancedDropDownField
                  name={`${props.fieldName}.${index}.type`}
                  isMulti={false}
                  options={props.options}
                  isClearable={false}
                  onChange={() => {
                    props.onChange();
                  }}
                  menuPortalTarget={document.body}
                  getOptionValue={(optionValue) => optionValue.id}
                  getOptionLabel={(optionValue) => optionValue.title}
                  optionTemplate={DropdownOptionTemplate}
                  rules={{ required: getText('field-required') }}
                />
                {getFieldErrorMessage(`${props.fieldName}.${index}.type`)}
              </View>
            </View>

            <View nativeID={`delete-option-icon-tooltip-${field.id}`}>
              <IconButton
                icon={TrashIcon}
                onPress={() => {
                  props.fieldArray.remove(index);
                  props.onChange();
                }}
                size={24}
                logger={{ id: 'delete-option-button' }}
              />
            </View>
          </View>
        ))}

        {props.fieldArray.fields.length < bookingLimitsOptions.length && (
          <View style={styles.limitOptionRow}>
            <Button
              hierarchy="tertiary-gray"
              size="small"
              icon={PlusIcon}
              onPress={() => {
                props.fieldArray.append({ type: '', value: '' });
              }}
              logger={{ id: 'add-new-option-button' }}
            >
              {getText('add-limit')}
            </Button>
          </View>
        )}
      </View>
    );
  };

  const formsWatcher = methods.watch('forms');

  const checkIfFormsExist = () => {
    const data = methods.getValues('forms');
    if (data.length <= 0 || data[0].form_id === '' || !data[0].form_id) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!checkIfFormsExist()) {
      methods.setValue('forms_optional', false);
    }
  }, [formsWatcher]);

  const populateAppointmentGroups = (
    appointmentGroupCategory: AppointmentGroupCategoryDto,
  ) => {
    if (appointmentGroups) {
      const appointmentGroupsByCat = appointmentGroups
        .filter(
          (appointmentGroup) =>
            appointmentGroup.appointment_group_category_id ===
            appointmentGroupCategory.id,
        )
        .sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        });

      setAppointmentGroupsByCategory(appointmentGroupsByCat);
      if (
        !methods.getValues('appointmentGroup') &&
        appointmentGroupsByCat.length > 0
      ) {
        methods.setValue('appointmentGroup', appointmentGroupsByCat[0]);
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <View style={{ maxWidth: 600 }}>
        <Form methods={methods}>
          <FormSectionSeparator label="General" icon={EditIcon} />
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="appointmentGroupCategory"
                label={getText('category')}
                isMulti={false}
                options={appointmentGroupCategories}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={DropdownOptionTemplate}
                rules={{ required: getText('field-required') }}
                onChange={(appointmentGroupCategory) => {
                  if (appointmentGroupCategory?.id && appointmentGroups) {
                    methods.setValue('appointmentGroup', undefined);
                    populateAppointmentGroups(
                      appointmentGroupCategory as AppointmentGroupCategoryDto,
                    );
                  }
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="appointmentGroup"
                label={getText('service-type')}
                isMulti={false}
                options={appointmentGroupsByCategory}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={DropdownOptionTemplate}
                rules={{ required: getText('field-required') }}
                isDisabled={
                  appointmentGroupsByCategory.length === 0 ||
                  !methods.getValues('appointmentGroupCategory')
                }
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="title"
                label={getText('display-name')}
                rules={{ required: getText('name-is-required') }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText('description')}
                name="description"
                type="text"
                multiline={true}
                numberOfLines={3}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText('instructions')}
                name="notes"
                type="text"
                multiline={true}
                numberOfLines={3}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={{ maxWidth: 275 }}>
            <Form.Column>
              <TextField
                label={`${getText('length')} (${getText('minutes')})`}
                name="length"
                type="text"
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: getText('error-field-number'),
                  },
                  min: {
                    value: 5,
                    message: getText('service-min-length-error-message'),
                  },
                  required: getText('field-required'),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="location"
                label={getText('venue')}
                isMulti={false}
                options={mockLocationValues}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={DropdownOptionTemplate}
                rules={{ required: getText('field-required') }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <Text style={styles.label}>{getText('forms')}</Text>
              {forms?.length === 0 && formsStatus === 'success' ? (
                <Text>{getText('create-form-first')}</Text>
              ) : (
                <AccumulativeInput
                  name="forms"
                  limit={10}
                  input={(props) => (
                    <AdvancedDropDownField
                      {...props}
                      isMulti={false}
                      options={getAvailableForms(props.value)}
                      isClearable={false}
                      menuPortalTarget={document.body}
                      getOptionValue={(optionValue) =>
                        (optionValue as FormDto).form_id
                      }
                      getOptionLabel={(optionValue) =>
                        (optionValue as FormDto).form_name
                      }
                      optionTemplate={DropdownOptionTemplateForm}
                      rules={{ required: getText('field-required') }}
                      containerStyle={{ width: '100%' }}
                    />
                  )}
                />
              )}

              <View style={styles.limitOptionCheckbox}>
                <PharmacyTooltipWrapper tooltipId="forms-optional-tooltip">
                  <CheckboxField
                    disabled={!checkIfFormsExist()}
                    name="forms_optional"
                    label={getText('forms-are-optional')}
                    mode={CheckboxInputMode.FLAT}
                  />
                </PharmacyTooltipWrapper>
              </View>

              <Tooltip
                place="top"
                text={getText('forms-are-optional-checkbox-tooltip')}
                id="forms-optional-tooltip"
              />
            </Form.Column>
          </Form.Row>
          <FormSectionSeparator label="Availability" icon={ClockIcon} />
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="schedule"
                label={getText('availability')}
                isMulti={false}
                options={schedules}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={DropdownOptionTemplate}
                rules={{ required: 'Availability is required' }}
              />

              {!!scheduleWatch && (
                <PreviewAvailability
                  schedule={scheduleWatch}
                ></PreviewAvailability>
              )}
            </Form.Column>
          </Form.Row>
          <FormSectionSeparator
            label={getText('restriction')}
            icon={SlashIcon}
          />
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="beforeEvent"
                label={getText('before-event')}
                isMulti={false}
                options={bufferOptions}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={DropdownOptionTemplate}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="afterEvent"
                label={getText('after-event')}
                isMulti={false}
                options={bufferOptions}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={DropdownOptionTemplate}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <ComboInput
                conditionInput={(props) => (
                  <View style={styles.limitOptionCheckbox}>
                    <CheckboxField
                      {...props}
                      label={getText('limit-booking-frequency')}
                      mode={CheckboxInputMode.FLAT}
                    />
                  </View>
                )}
                input={() => (
                  <>
                    {renderOptions({
                      fieldName: 'bookingFrequencyLimits',
                      fieldArray: bookingFrequencyLimitsArray,
                      options: availableBookingFrequencyLimits,
                      onChange: () => {
                        setAvailableBookingFrequencyLimits(
                          getAvailableLimitOptions('bookingFrequencyLimits'),
                        );
                      },
                    })}
                  </>
                )}
                name="limitBookingFrequenciesEnabled"
                mode={ComboInputMode.Hide}
                layout={ComboInputLayout.Vertical}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <ComboInput
                conditionInput={(props) => (
                  <View style={styles.limitOptionCheckbox}>
                    <CheckboxField
                      {...props}
                      label={getText('limit-booking-duration')}
                      mode={CheckboxInputMode.FLAT}
                    />
                  </View>
                )}
                input={() => (
                  <>
                    {renderOptions({
                      fieldName: 'bookingTotalDurationLimits',
                      fieldArray: bookingTotalDurationLimitsArray,
                      options: availableBookingTotalDurationLimits,
                      label: getText('minutes'),
                      onChange: () => {
                        setAvailableBookingTotalDurationLimits(
                          getAvailableLimitOptions(
                            'bookingTotalDurationLimits',
                          ),
                        );
                      },
                    })}
                  </>
                )}
                name="limitTotalBookingDurationEnabled"
                mode={ComboInputMode.Hide}
                layout={ComboInputLayout.Vertical}
              />
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <ComboInput
                conditionInput={(props) => (
                  <View style={styles.limitOptionCheckbox}>
                    <CheckboxField
                      {...props}
                      label={getText('limit-future-bookings')}
                      mode={CheckboxInputMode.FLAT}
                      onPress={(checked) => {
                        if (checked) {
                          setSelectedPeriodType(
                            AppointmentTypePeriodType.Rolling,
                          );
                        }
                      }}
                    />
                  </View>
                )}
                input={() => (
                  <>
                    <View style={styles.limitOptionRow}>
                      <RadioButton
                        value={AppointmentTypePeriodType.Rolling}
                        text=""
                        onValueChange={() => {
                          setSelectedPeriodType(
                            AppointmentTypePeriodType.Rolling,
                          );
                        }}
                        mode={RadioButtonInputMode.FLAT}
                        selected={
                          selectedPeriodType ===
                          AppointmentTypePeriodType.Rolling
                        }
                      />
                      <View style={styles.limitOptionRowItem}>
                        <TextField
                          style={{ height: 36 }}
                          height={36}
                          name="periodDays"
                          rules={{
                            validate: {
                              required: (value) => {
                                if (
                                  !value &&
                                  selectedPeriodType ===
                                    AppointmentTypePeriodType.Rolling
                                )
                                  return getText('field-required');
                                return true;
                              },
                              pattern: (value) => {
                                const regex = /^[0-9]*$/;

                                if (
                                  value &&
                                  !regex.test(value.toString()) &&
                                  selectedPeriodType ===
                                    AppointmentTypePeriodType.Rolling
                                ) {
                                  return getText('error-field-number');
                                }

                                return true;
                              },
                            },
                          }}
                        />
                      </View>
                      <View style={styles.limitOptionRowItem}>
                        <AdvancedDropDownField
                          name="daysType"
                          isMulti={false}
                          options={daysTypeOptions}
                          isClearable={false}
                          menuPortalTarget={document.body}
                          getOptionValue={(optionValue) => optionValue.id}
                          getOptionLabel={(optionValue) => optionValue.title}
                          optionTemplate={DropdownOptionTemplate}
                          onChange={() =>
                            setSelectedPeriodType(
                              AppointmentTypePeriodType.Rolling,
                            )
                          }
                        />
                      </View>
                      <View>
                        <Text>{getText('into-future')}</Text>
                      </View>
                    </View>
                    <View style={styles.limitOptionRow}>
                      <RadioButton
                        value={AppointmentTypePeriodType.Range}
                        text=""
                        onValueChange={() => {
                          setSelectedPeriodType(
                            AppointmentTypePeriodType.Range,
                          );
                        }}
                        selected={
                          selectedPeriodType === AppointmentTypePeriodType.Range
                        }
                        mode={RadioButtonInputMode.FLAT}
                      />
                      <View style={styles.limitOptionRowItem}>
                        <Text>{getText('within-date-range')}</Text>
                      </View>
                      <View style={styles.limitOptionRowItem}>
                        <DateTimePickerField
                          minDate={new Date().toISOString()}
                          type="date"
                          {...methods.register('periodStartDate', {
                            validate: {
                              required: (value) => {
                                if (
                                  !value &&
                                  selectedPeriodType ===
                                    AppointmentTypePeriodType.Range
                                )
                                  return getText('field-required');
                                return true;
                              },
                            },
                          })}
                        />
                        {getFieldErrorMessage('periodStartDate')}
                      </View>
                      <View
                        style={[
                          styles.limitOptionRowItem,
                          { transform: [{ rotate: '180deg' }] },
                        ]}
                      >
                        <ArrowLeftIcon
                          color={theme.palette.gray[700]}
                          size={18}
                        />
                      </View>
                      <View style={styles.limitOptionRowItem}>
                        <DateTimePickerField
                          minDate={
                            periodStartDateWatch
                              ? (periodStartDateWatch as any as string) // casting it to string, because backend returns a string, but in the generated client it's incorrectly typed as object
                              : new Date().toISOString()
                          }
                          type="date"
                          {...methods.register('periodEndDate', {
                            validate: {
                              required: (value) => {
                                if (
                                  !value &&
                                  selectedPeriodType ===
                                    AppointmentTypePeriodType.Range
                                )
                                  return getText('field-required');
                                return true;
                              },
                            },
                          })}
                        />
                        {getFieldErrorMessage('periodEndDate')}
                      </View>
                    </View>
                  </>
                )}
                name="limitFutureEnabled"
                mode={ComboInputMode.Hide}
                layout={ComboInputLayout.Vertical}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </>
  );
};

interface ServiceFormData extends CreateAppointmentTypeDto {
  appointmentGroup?: AppointmentGroupDto | null;
  appointmentGroupCategory?: AppointmentGroupCategoryDto | null;
  schedule?: AppointmentTypeScheduleDto | null;
  location?: MockAppointmentLocationDto | null;
  beforeEvent?: BufferOptionDto | null;
  afterEvent?: BufferOptionDto | null;
  limitBookingFrequenciesEnabled: boolean;
  'condition-limitBookingFrequenciesEnabled': boolean;
  'condition-limitFutureEnabled': boolean;
  bookingFrequencyLimits: BookingLimitsRecordDto[];
  limitTotalBookingDurationEnabled: boolean;
  'condition-limitTotalBookingDurationEnabled': boolean;
  bookingTotalDurationLimits: BookingLimitsRecordDto[];
  daysType?: SelectOptions;
}

export interface ServiceFormProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: 'auto',
  },
  dropdownOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(0.5),
    margin: 0,
  },
  disabledOptionText: {
    color: theme.palette.gray[400],
  },
  copyButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(2),
    marginTop: -theme.getSpacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 12,
    marginBottom: theme.getSpacing(2),
  },
  sectionTitle: {
    color: theme.palette.gray[700],
    fontSize: 16,
    marginBottom: theme.getSpacing(1),
  },
  sectionSubtitle: {
    color: theme.palette.gray[500],
    fontSize: 14,
  },
  copyButtonText: {
    color: theme.palette.primary[600],
    marginRight: theme.getSpacing(1),
  },
  formRowPosition: { alignItems: 'center', gap: 8 },
  limitOptionRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  limitOptionRowItem: {
    marginRight: theme.getSpacing(2),
  },
  limitOptionCheckbox: {
    width: 200,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  futureLimitRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  futureLimitItem: {
    marginHorizontal: theme.getSpacing(0.5),
  },
  error: {
    color: theme.palette.error[600],
  },
  label: {
    marginBottom: theme.getSpacing(1),
    color: theme.palette.gray[700],
    fontSize: 14,
  },
}));
