import React, { FunctionComponent } from 'react';
import { Dimensions, View } from 'react-native';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { PatientAgreementTemplateModalProps } from '../../types';
import { useChecklistStore } from '../../../../../store/checklist-store';
import { PatientAgreementPDFViewer } from './PatientAgreementPDFViewer';

export const PatientAgreementTemplateModal: FunctionComponent<
  PatientAgreementTemplateModalProps
> = ({ show, onClose, onDismiss, type }) => {
  const styles = useStyles();

  const privacyBlobPart = useChecklistStore(
    (x) => x.patientAgreementsDocs?.privacy,
  );
  const termsBlobPart = useChecklistStore(
    (x) => x.patientAgreementsDocs?.terms,
  );

  return (
    <Modal
      title={
        type === 'privacy'
          ? getText('patient-agreements-privacy-template')
          : getText('patient-agreements-terms-template')
      }
      show={show}
      handleDismissModal={onDismiss}
      size="sm"
      buttons={[
        {
          text: getText('close'),
          onPress: onClose,
          hierarchy: 'tertiary-gray',
          logger: { id: 'close-checklist-patient-agreement-modal' },
        },
      ]}
      showDismissButton
      isScrollable
    >
      <View style={styles.container}>
        {/* TODO:  */}
        {/* uncomment this part once we have the copy */}
        {/* <Text style={styles.description}>
          {getText('patient-agreements-legal-notice')}
        </Text> */}

        {type === 'privacy' && privacyBlobPart && (
          <PatientAgreementPDFViewer src={privacyBlobPart} />
        )}
        {type === 'terms' && termsBlobPart && (
          <PatientAgreementPDFViewer src={termsBlobPart} />
        )}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    gap: theme.getSpacing(2),
    minHeight: Dimensions.get('window').height - 250,
  },
  description: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.black,
  },
}));
