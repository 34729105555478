import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { setOpenedModal } from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';

export interface ServiceDetail {
  id: string;
  order: number;
  name: string;
  enabled: boolean;
}

// to do
const initialServiceDetails: ServiceDetail[] = [
  {
    id: '1',
    order: 0,
    name: 'Pet Care',
    enabled: true,
  },
  {
    id: '2',
    order: 1,
    name: 'Gift Shop',
    enabled: false,
  },
  {
    id: '3',
    order: 4,
    name: 'Vitamins and Supplements',
    enabled: false,
  },
  {
    id: '4',
    order: 2,
    name: 'Durable Medical Equipment ‘(DME)',
    enabled: false,
  },
  {
    id: '5',
    order: 3,
    name: 'Hormone Testing',
    enabled: true,
  },
];

export const PatientWebsiteServiceDetails: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const styles = useStyles();
  const serviceDetails = initialServiceDetails.sort(
    (a, b) => a.order - b.order,
  );

  const methods = useForm<{ serviceDetails: ServiceDetail[] }>({
    //to do
    defaultValues: {
      serviceDetails,
    },
  });
  const serviceDetailsFields = methods.watch('serviceDetails');

  return (
    <Modal
      title={getText('pharmacy-service-details')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal(undefined)}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => console.log('back'),
          logger: { id: 'next-website-service-details' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal(undefined),
          logger: { id: 'back-website-service-details' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('indicate-type-of-compounding')}
            </Text>
          </Form.Column>
        </Form.Row>
        {serviceDetailsFields.map((detail, index) => (
          <Form.Row key={index}>
            <Form.Column key={detail.id}>
              <CheckboxField
                name={`serviceDetails.${index}.enabled`}
                label={detail.name}
                mode={CheckboxInputMode.FLAT}
              />
            </Form.Column>
          </Form.Row>
        ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
