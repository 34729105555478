import { getText } from 'assets/localization/localization';
import { convertTimeRangeToISOString } from '../../common/datetime-utils';
import {
  DropdownOptions,
  HourType,
  LocationHolidayType,
} from '../../store/onboarding-store';

export const FORM_NOT_VALID = 'Form not valid';
export const NOT_ALLOWED = 'NOT_ALLOWED';

export const getDayAsStr = (day: number) => {
  switch (day) {
    case 1:
      return getText('sunday');
    case 2:
      return getText('monday');
    case 3:
      return getText('tuesday');
    case 4:
      return getText('wednesday');
    case 5:
      return getText('thursday');
    case 6:
      return getText('friday');
    case 7:
      return getText('saturday');
    default:
      return '';
  }
};

export const closedDefaultTimeRange = convertTimeRangeToISOString({
  start_hour: 9,
  start_minute: 0,
  end_hour: 18,
  end_minute: 0,
});

export const newBreakDefaultData = {
  time: {
    start: '',
    end: '',
  },
  type: HourType.BREAK,
  text: getText('remove'),
};

export const closedDefaultData = {
  time: {
    start: closedDefaultTimeRange.start,
    end: closedDefaultTimeRange.end,
  },
  type: HourType.CLOSED,
  text: getText('closed'),
};

export const selectAllData: LocationHolidayType = {
  id: Math.random().toString(),
  name: getText('select-all'),
  enabled: false,
  isSelect: true,
  time_range_id: '',
};

export const createOptions = <T extends object>(
  enumObj: T,
): DropdownOptions[] => {
  return Object.values(enumObj).map((type) => ({
    label: type,
    value: type,
  }));
};

export const findDropdownOption = (
  options: DropdownOptions[],
  value?: string,
): DropdownOptions | null => {
  const label = options.find((x) => x.value === value)?.label;
  return label && value
    ? {
        label,
        value,
      }
    : null;
};

export const OBSingleLocationSteps = [
  getText('account'),
  getText('addresses'),
  getText('equipment'),
  getText('employees'),
  getText('hours'),
  getText('review'),
];

export const OBMultiLocationSteps = [
  getText('account'),
  getText('locations'),
  getText('review'),
];

export const mapOBError = (e: any) => {
  const match = typeof e === 'string' && e.match(/locations\.0\.(.*)/);
  return match ? match[1] : getText('error-occured-ob-save');
};
