import React, { FunctionComponent, PropsWithChildren } from 'react';
import { getText } from 'assets/localization/localization';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { View } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { makeStyles } from 'assets/theme';
import { Button } from 'assets/components/button';
import { notImplementedAlert } from 'assets/utils/alert';
import { MessageCircleIcon } from 'assets/icons';
import { Divider } from 'react-native-paper';
import { formatPhoneNumber } from 'assets/utils/phoneNumber';
import { formatDate } from '../../common/datetime-utils';
import { AutoFillInfo } from './AutoFillInfo';
import { getRxStatusText } from '../../../utils';

export const PatientMedsCard: FunctionComponent<
  PropsWithChildren<PatientMedsCardProps>
> = ({ patientPrescriptions }) => {
  const styles = useStyles();
  const doctorFullName =
    (patientPrescriptions.doctor_first_name ||
      patientPrescriptions.doctor_last_name) &&
    `Dr. ${patientPrescriptions.doctor_first_name || ''} ${
      patientPrescriptions.doctor_last_name || ''
    }`.trim();

  const doctorPhone = patientPrescriptions.doctor_phone
    ? formatPhoneNumber(patientPrescriptions.doctor_phone.replace(/-/g, '')) ||
      patientPrescriptions.doctor_phone
    : '';

  return (
    <View>
      <View style={styles.rowSpaceBetween}>
        <View style={styles.rowView}>
          <Text style={styles.textTitle}>{patientPrescriptions.drug_name}</Text>
        </View>
        <View style={styles.rowView}>
          <Button
            hierarchy={'tertiary'}
            logger={{
              id: 'send-video-button',
            }}
            icon={MessageCircleIcon}
            onPress={() => notImplementedAlert()}
          >
            <Text style={styles.buttonText}>{getText('send-video')}</Text>
          </Button>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('rx')} </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            {patientPrescriptions.rx_number}
          </Text>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('status')}: </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            {patientPrescriptions.rx_status
              ? getRxStatusText(patientPrescriptions.rx_status)
              : getText('prescriber-info-not-available')}
          </Text>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('date-written')}: </Text>
        </View>
        <View>
          {patientPrescriptions.written_date ? (
            <Text style={styles.textDetails}>
              {formatDate(patientPrescriptions.written_date)}
            </Text>
          ) : (
            <Text style={styles.textDetails}>
              {getText('prescriber-info-not-available')}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>
            {getText('last-filled-date')}:{' '}
          </Text>
        </View>
        <View>
          {patientPrescriptions.last_refill_date ? (
            <Text style={styles.textDetails}>
              {formatDate(patientPrescriptions.last_refill_date)}
            </Text>
          ) : (
            <Text style={styles.textDetails}>
              {getText('prescriber-info-not-available')}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>
            {getText('remaining-refills')}:{' '}
          </Text>
        </View>
        <View>
          {patientPrescriptions.remaining_refills ? (
            <Text style={styles.textDetails}>
              {patientPrescriptions.remaining_refills}
            </Text>
          ) : (
            <Text style={styles.textDetails}>
              {getText('prescriber-info-not-available')}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('auto-refill')}: </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            <AutoFillInfo prescription={patientPrescriptions} />
          </Text>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('prescriber')}: </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            {!doctorFullName && !doctorPhone ? (
              <Text style={styles.textDetails}>
                {getText('prescriber-info-not-available')}
              </Text>
            ) : (
              <>
                <Text style={styles.textDetails}>
                  {doctorFullName || getText('prescriber-name-not-available')}
                  {', '}
                </Text>
                <Text style={styles.textDetails}>
                  {doctorPhone || getText('prescriber-phone-not-available')}
                </Text>
              </>
            )}
          </Text>
        </View>
      </View>
      <Divider style={styles.divider} />
    </View>
  );
};

export interface PatientMedsCardProps {
  patientPrescriptions: PrescriptionDto;
}

export default PatientMedsCard;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.lumistryFonts.label.xSmall.bold,
    color: theme.palette.gray[900],
    textTransform: 'capitalize',
  },
  textDetails: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  buttonText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.getSpacing(1),
  },
}));
