import React, { useEffect, useMemo, useCallback, useState } from 'react';
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
} from 'react-native';
import {
  useIsFocused,
  useNavigation,
  ParamListBase,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { v4 } from 'uuid';
import { DocumentPickerAsset } from 'expo-document-picker';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import UnifiedCommsService from '../../api/UnifiedCommsService';
import FileStorageService from '../../api/FileStorageService';
import { Avatar } from 'assets/components/avatar';
import { useCurrentUrl } from 'assets/hooks';
import { useAppStateStore } from '../../store/app-store';
import {
  DirectMessagePatientDto,
  AuthorType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { User } from 'react-native-gifted-chat';
import {
  getFullName,
  compare,
  groupConversationsByPatient,
  getDynamicHeightForMessages,
  getMessages,
  getPatientsWithoutConversationsSet,
  populatePillText,
  DIRECT_MESSAGE_TEMPLATE_VARIABLES,
  separateImages,
} from 'assets/utils/messageUtils';
import PatientService from '../../api/PatientService';
import {
  BIG_MESSAGE_LIMIT,
  CONVERSATIONS_TABS_WIDTH,
  ERROR_NO_MESSAGE,
  ERROR_MESSAGE_LIMIT_FIVE_THOUSAND,
  ERROR_FILES_SENDING,
  ERROR_FILES_SENDING_MODAL,
  LOADING_CONVERSATION_CREATING,
  EXCLUDED_ERRORS_CHAT,
  LOADING_MESSAGES,
  TAKE,
  CONVERSATION_PATIENTS_PAGINATION_STEP,
  LOADING_CONVERSATIONS,
} from './data';
import {
  NewChatModal,
  CreateConversationData,
} from './components/NewChatModal';
import {
  Order,
  IUploadFilesResult,
  TypedMessage,
  LoadMoreOptions,
  ITemplate,
  IMessageExtended,
  MessageStatus,
} from 'assets/types/messageTypes';
import { useUserState } from '../../store/user-store';
import { useChatState } from './stores/chat-store/chat-store';
import {
  setConversationsGroupedByPatient,
  setSelectedPatient,
  setSelectedConversation,
  addViewedConversationsToSet,
  removeViewedConversationsFromSet,
  setSelectedMessages,
  setRawConversations,
  removeSelectedPatient,
  removeSelectedConversation,
  removeSelectedMessages,
  openNewChatModal,
  closeNewChatModal,
  setMessagesPagination,
  setShowEditModal,
  setShowAddPatientModal,
  setRawConversationsCount,
  setPatientsWithoutConversationsSet,
  setShowTemplatesModal,
  setSelectedTemplate,
  resetChatState,
  closeSidebarNewChatModal,
  removeSelectedPatientConversations,
  setSelectedPatientConversations,
  setChatBoxText,
  addFailedMessage,
  removeFailedMessage,
  setStatusInConversation,
} from './stores/chat-store/chat-actions';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useErrorState } from './stores/error-store/error-store';
import { ErrorsContainer } from './components/ErrorsContainer';
import { setLoading } from './stores/loading-store/loading-actions';
import { useLoadingState } from './stores/loading-store/loading-store';
import { ErrorStatus } from './stores/error-store/error-store';
import { setError } from './stores/error-store/error-actions';
import AddPatientModal from '../../components/AddPatientModal/AddPatientModal';
import EditPatientModal from '../../components/EditPatientModal/EditPatientModal';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { isDateOfBirth } from '@digitalpharmacist/validation-dp';
import { PatientWithoutConversationsView } from './components/PatientWithoutConversationsView';
import { PatientConversationWrapper } from './components/PatientConversationWrapper';
import { ActionsBar } from './components/ActionsBar';
import { PatientInfo } from './components/PatientInfo';
import { ConversationsHeader } from './components/ConversationsHeader';
import { PharmacyWithoutConversationsView } from './components/PharmacyWithoutConversationsView';
import { PharmacyWithoutPatientsView } from './components/PharmacyWithoutPatientsView';
import { PatientWithConversationsView } from './components/PatientWithConversationsView';
import { useConversationsManipulationState } from './stores/data-manipulation-store/data-manipulation-store';
import {
  setTextFilters,
  setPagination,
  setConversationsSorting,
  resetConversationsManipulationState,
} from './stores/data-manipulation-store/data-manipulation-actions';
import { useFilterCriteria } from './hooks/useFilterCriteria';
import { ChatScreenProps } from '../../layout/MessagesDrawer';
import { TemplatesModal } from './components/TemplatesModal';
import { PharmacyScreenContainer } from 'assets/layout';
import { useMessagingState } from './stores/messaging-store/messaging-store';
import { setPharmacyLocationHasPatientRecords } from './stores/messaging-store/messaging-actions';
import { loadBillingData } from '../settings/billing/billing-settings-actions';
import { useBillingOptionsSettingsState } from '../settings/billing/billing-settings-store';
import { Alert } from 'assets/components/alert';
import { useTypingInfo } from './hooks/useTypingInfo';
import { ChatUserDetailSidebar } from './components/ChatUserDetailSidebar/ChatUserDetailSidebar';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import PharmacyService from '../../api/PharmacyService';
import { ampli } from '../../src/ampli';

export const Chat: React.FC<ChatScreenProps> = ({ route }) => {
  const newMessageModal = route.params?.newMessageModal;
  const locationId = useAppStateStore((state) => state.locationId);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const conversationsGroupedByPatient = useChatState(
    (state) => state.conversationsGroupedByPatient,
  );
  const selectedPatient = useChatState((state) => state.selectedPatient);
  const selectedConversation = useChatState(
    (state) => state.selectedConversation,
  );
  const selectedPatientConversations = useChatState(
    (state) => state.selectedPatientConversations,
  );
  const viewedConversationsSet = useChatState(
    (state) => state.viewedConversationsSet,
  );
  const selectedMessages = useChatState((state) => state.selectedMessages);
  const rawConversations = useChatState((state) => state.rawConversations);
  const rawConversationsCount = useChatState(
    (state) => state.rawConversationsCount,
  );
  const isOpenNewChatModal = useChatState((state) => state.isOpenNewChatModal);
  const isOpenSidebarNewChatModal = useChatState(
    (state) => state.isOpenSidebarNewChatModal,
  );
  const messagesPagination = useChatState((state) => state.messagesPagination);
  const patientsWithoutConversationsSet = useChatState(
    (state) => state.patientsWithoutConversationsSet,
  );
  const showTemplatesModal = useChatState((state) => state.showTemplatesModal);
  const selectedTemplate = useChatState((state) => state.selectedTemplate);
  const showAddPatientModal = useChatState(
    (state) => state.showAddPatientModal,
  );
  const showEditModal = useChatState((state) => state.showEditModal);
  const baseFilter = useConversationsManipulationState(
    (state) => state.baseFilter,
  );
  const multiFilters = useConversationsManipulationState(
    (state) => state.multiFilters,
  );
  const textFilters = useConversationsManipulationState(
    (state) => state.textFilters,
  );
  const pagination = useConversationsManipulationState(
    (state) => state.pagination,
  );
  const pharmacyLocationHasPatientRecords = useMessagingState(
    (state) => state.pharmacyLocationHasPatientRecords,
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const sorting = useConversationsManipulationState((state) => state.sorting);
  const user = useUserState((x) => x.data);
  const userId = useUserState((x) => x.data?.id);
  const { errorObject } = useErrorState();
  const isFocused = useIsFocused();
  const { url: currentUrl } = useCurrentUrl();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const { smsUsageData, emailUsageData } = useBillingOptionsSettingsState();
  const theme = useTheme();
  const styles = useStyles();

  const { typingMember = null, onType } = useTypingInfo();
  const filterCriteria = useFilterCriteria();

  const isLoadingConversations = useLoadingState((state) =>
    LOADING_CONVERSATIONS in state.loadingObject
      ? state.loadingObject[LOADING_CONVERSATIONS].isLoading
      : false,
  );
  const isLoadingConversationCreating = useLoadingState((state) =>
    LOADING_CONVERSATION_CREATING in state.loadingObject
      ? state.loadingObject[LOADING_CONVERSATION_CREATING].isLoading
      : false,
  );

  const chatUser: User = useMemo(() => {
    return {
      _id: userId || 'NO_USER_ID',
      name: getFullName(user),
      avatar: function () {
        return (
          <Avatar
            size={32}
            firstName={user?.firstName}
            lastName={user?.lastName}
          />
        );
      },
    };
  }, [userId]);

  useEffect(() => {
    void (async () => {
      if (locationId) {
        loadBillingData(locationId);
        removeSelectedPatient();
        removeSelectedConversation();
        removeSelectedPatientConversations();
        const records = await PatientService.findAllLocationsRecordsById(
          locationId,
          {
            offset: 0,
            limit: 1, // just to take a count
          },
        );

        const isPharmacyLocationHasPatientRecords = Boolean(records.count);
        setPharmacyLocationHasPatientRecords(
          isPharmacyLocationHasPatientRecords,
        );

        if (isPharmacyLocationHasPatientRecords) {
          return;
        }

        // If location does not have patients yet then pages messages/bulk and messages/auto are not available for a pharmacy user
        if (
          currentUrl.includes('messages/bulk') ||
          currentUrl.includes('messages/auto')
        ) {
          // Bring a pharmacy user to chat page
          navigation.navigate('chat');
        }
      }
    })();
  }, [locationId]);

  useEffect(() => {
    // sometimes the previous requests are completed after the last one
    // what this code is supposed to do is to cancel previous request
    // so the output in search will be accurate
    const controller = new AbortController();

    const loadData = async () => {
      try {
        setLoading(LOADING_CONVERSATIONS, true);

        const filteredConversations =
          await UnifiedCommsService.getAllFilteredConversations(
            locationId,
            filterCriteria,
            {
              signal: controller.signal,
            },
          );

        const groupedConversations = groupConversationsByPatient(
          filteredConversations,
        ).sort((currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            sorting.field,
            sorting.order,
            sorting.isDate,
          ),
        );

        const viewedConversations = filteredConversations
          .filter((conversations) => conversations.pharmacy_viewed_all_messages)
          .map((conversations) => conversations.conversation_id);
        const initialPatientsWithoutConversationsSet =
          getPatientsWithoutConversationsSet(groupedConversations);

        setInitialConversationCount(filteredConversations.length);
        setRawConversations(filteredConversations);
        setConversationsGroupedByPatient(groupedConversations);
        addViewedConversationsToSet(viewedConversations);
        setPatientsWithoutConversationsSet(
          initialPatientsWithoutConversationsSet,
        );

        setLoading(LOADING_CONVERSATIONS, false);

        if (!isInitialized) {
          await onSelectPatient(groupedConversations[0], filteredConversations);
          setIsInitialized(true);
        }
      } catch (err: any) {
        if (err.message !== 'canceled') {
          throw err;
        }
      }
    };

    if (isFocused) {
      void loadData();
    } else {
      resetChatState();
      resetConversationsManipulationState();
      setIsInitialized(false);
    }

    return () => {
      controller.abort();
    };
  }, [
    baseFilter,
    multiFilters,
    textFilters,
    pagination,
    locationId,
    isFocused,
  ]);

  useEffect(() => {
    if (conversationsGroupedByPatient.length) {
      (() => {
        const groupedConversations = [...conversationsGroupedByPatient].sort(
          (currentConversation, nextConversation) =>
            compare(
              currentConversation,
              nextConversation,
              sorting.field,
              sorting.order,
              sorting.isDate,
            ),
        );
        setConversationsGroupedByPatient(groupedConversations);
      })();
    }
  }, [sorting]);

  useEffect(() => {
    if (newMessageModal === 'open') openNewChatModal();
  }, [newMessageModal]);

  function increasePatientsCount() {
    setPagination(pagination.limit + CONVERSATION_PATIENTS_PAGINATION_STEP);
  }

  function setInitialConversationCount(count: number) {
    if (rawConversationsCount === 0 && count !== 0) {
      setRawConversationsCount(count);
    }
  }

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: DocumentPickerAsset[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      if (!file.file) {
        console.error(`The file ${file.name} does not have 'file' property`);
        continue;
      }

      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        const responseWriteUrl = await FileStorageService.writeUrl(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
        );

        await FileStorageService.uploadFile(
          file.file,
          responseWriteUrl.data.url,
        );

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        //TODO: review console.error
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  function validateMessage(message: TypedMessage) {
    let errorsCount = 0;

    if (!message.text && !message.attachments.length) {
      setError(
        ERROR_NO_MESSAGE,
        ErrorStatus.ERROR,
        `${getText('message-or-attachment-is-required')}.`,
      );
      errorsCount += 1;
    }
    if (message.text && message.text.length > BIG_MESSAGE_LIMIT) {
      setError(
        ERROR_MESSAGE_LIMIT_FIVE_THOUSAND,
        ErrorStatus.ERROR,
        `${getText('message-characters-limit-five-thousand')}.`,
      );
      errorsCount += 1;
    }

    return errorsCount;
  }

  async function updateConversationStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    pharmacyViewedAllMessages: boolean,
  ) {
    await UnifiedCommsService.updateUserViewedStatus(
      locationId,
      locationPatientId,
      conversationId,
      {
        patient_viewed_all_messages: patientViewedAllMessages,
        pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
      },
    );

    const isViewedConversation = (id: string) => viewedConversationsSet.has(id);

    if (pharmacyViewedAllMessages && !isViewedConversation(conversationId)) {
      addViewedConversationsToSet([conversationId]);
    } else if (
      !pharmacyViewedAllMessages &&
      isViewedConversation(conversationId)
    ) {
      removeViewedConversationsFromSet([conversationId]);
    }

    const changedRawList = rawConversations.map((rawConversation) => {
      if (rawConversation.conversation_id === conversationId) {
        rawConversation.pharmacy_viewed_all_messages =
          pharmacyViewedAllMessages;
      }
      return rawConversation;
    });

    const groupedConversations = groupConversationsByPatient(
      changedRawList,
    ).sort((currentConversation, nextConversation) =>
      compare(
        currentConversation,
        nextConversation,
        sorting.field,
        sorting.order,
        sorting.isDate,
      ),
    );
    setRawConversations(changedRawList);
    setConversationsGroupedByPatient(groupedConversations);
  }

  async function onSelectPatient(
    patientConversationMeta: DirectMessagePatientDto,
    rawConversations: DirectMessagePatientDto[],
  ) {
    const newSelectedPatient =
      selectedPatient?.id === patientConversationMeta.location_patient_id
        ? undefined
        : patientConversationMeta;

    removeSelectedMessages();

    if (!newSelectedPatient) {
      removeSelectedPatient();
      removeSelectedPatientConversations();
      removeSelectedConversation();
      removeSelectedMessages();
      return;
    }

    const selectedPatientLocationRecord =
      await PatientService.getPatientLocationRecord(
        locationId,
        newSelectedPatient.location_patient_id,
      );

    const newSelectedPatientConversations = rawConversations
      .filter(
        (conversation) =>
          conversation.location_id === locationId &&
          conversation.location_patient_id === selectedPatientLocationRecord.id,
      )
      .sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          'most_recent_qualifying_message_date',
          Order.DESC,
          true,
        ),
      );

    setSelectedPatient(selectedPatientLocationRecord);
    setSelectedPatientConversations(newSelectedPatientConversations);
  }

  const onSend = async (typedMessage: TypedMessage) => {
    if (!selectedPatient || !chatUser || !selectedConversation) {
      return;
    }

    const errorsCount = validateMessage(typedMessage);
    if (errorsCount) {
      return;
    }

    let pharmacy: PharmacyLocationDto | undefined;

    if (
      typedMessage.text.includes(
        DIRECT_MESSAGE_TEMPLATE_VARIABLES.PharmacyPhoneNumber,
      ) ||
      typedMessage.text.includes(DIRECT_MESSAGE_TEMPLATE_VARIABLES.PharmacyName)
    ) {
      pharmacy = await PharmacyService.pharmacyLocationFindOne(locationId);
    }

    const [images, files] = await separateImages<DocumentPickerAsset>(
      typedMessage.attachments,
      FileStorageService,
    );

    const { failedMessagesInConversation } = useChatState.getState();

    const newMessage: IMessageExtended = {
      _id: typedMessage.retryMessageId || v4(),
      text: populatePillText(typedMessage.text, selectedPatient, pharmacy),
      user: chatUser,
      createdAt: new Date(),
      author_type: AuthorType.Pharmacy,
      attachments: { files: files, images: images },
      pending: true,
    };

    if (
      failedMessagesInConversation &&
      Object.keys(failedMessagesInConversation).length &&
      failedMessagesInConversation[selectedConversation.conversation_id] &&
      typedMessage.retryMessageId
    ) {
      removeFailedMessage(
        selectedConversation.conversation_id,
        typedMessage.retryMessageId,
      );
    }

    let updatedSelectedMessages = selectedMessages;
    if (!typedMessage.retryMessageId) {
      updatedSelectedMessages = [newMessage, ...selectedMessages];
      setSelectedMessages(updatedSelectedMessages);
    }

    try {
      const attachmentsPresent = images.length > 0 || files.length > 0;
      // TODO: This is needed only if message contains attachments.
      // Re-updating text for message in ChatBox only for re-rendering that component and children.
      // This the only way to force re-rendering that component to show sending message with attachment in UI.
      // I did not find another way to fix this.
      if (attachmentsPresent) {
        setTimeout(() => {
          setChatBoxText('');
        }, 0);
        setChatBoxText('.');
      }

      const filesUploadResult = await uploadFiles(
        locationId,
        pharmacyId,
        typedMessage.attachments,
      );

      if (filesUploadResult.isError) {
        setError(
          ERROR_FILES_SENDING,
          ErrorStatus.ERROR,
          getText('files-sending-error-try-again'),
        );
        throw new Error();
      }

      setStatusInConversation({
        [selectedConversation.conversation_id]: MessageStatus.Sending,
      });

      await UnifiedCommsService.addMessage(
        pharmacyId,
        locationId,
        selectedPatient.id,
        selectedConversation.conversation_id,
        {
          author_id: chatUser._id.toString(),
          author_type: AuthorType.Pharmacy,
          content: typedMessage.text,
          patient_viewed_all_messages: false,
          pharmacy_viewed_all_messages: true,
          attachments: filesUploadResult.filesData.length
            ? filesUploadResult.filesData
            : [],
        },
      );

      ampli.messageSent({
        conversationID: selectedConversation.conversation_id,
        messageID:
          selectedMessages[selectedMessages.length - 1]._id.toString() ??
          typedMessage.retryMessageId,
        messageSentTime: new Date().toISOString(),
        messageSubject: selectedConversation.subject,
        messageTemplateSelected: '',
      });

      const messageFromServer = await getMessages({
        pharmacyId,
        locationId,
        locationPatientId: selectedPatient.id,
        conversationId: selectedConversation.conversation_id,
        UnifiedCommsService: UnifiedCommsService,
        FileStorageService: FileStorageService,
        failedMessagesInConversation,
        skip: 0,
        take: TAKE,
      });

      updatedSelectedMessages = messageFromServer.messages;

      if (Object.keys(messageFromServer).length > 0) {
        setMessagesPagination({
          ...messagesPagination,
          count: messagesPagination.count + 1,
        });
      }
      setStatusInConversation({
        [selectedConversation.conversation_id]: MessageStatus.Delivered,
      });
    } catch (error) {
      console.error('An error occurred while sending a message: ', error);

      const [lastMessage] = selectedMessages;
      if (chatUser) {
        const secondsOfLastMessage = new Date(
          lastMessage.createdAt,
        ).getSeconds();
        const createdAt = new Date(lastMessage.createdAt).setSeconds(
          secondsOfLastMessage + 1,
        );

        const failedMessage: IMessageExtended = {
          _id: newMessage._id,
          text: newMessage.text,
          user: chatUser,
          createdAt,
          attachments: { files, images },
          author_type: AuthorType.Pharmacy,
          sent: false,
          pending: false,
        };
        const indexOfFailedMessage = updatedSelectedMessages.findIndex(
          (message) => message._id === failedMessage._id,
        );

        if (indexOfFailedMessage >= 0) {
          updatedSelectedMessages[indexOfFailedMessage] = failedMessage;
        } else {
          updatedSelectedMessages.unshift(failedMessage);
        }
        addFailedMessage(selectedConversation.conversation_id, failedMessage);
      }

      setStatusInConversation({
        [selectedConversation.conversation_id]: MessageStatus.Error,
      });
    } finally {
      setSelectedMessages(updatedSelectedMessages);
    }
  };

  const lastLeftMessageId = useMemo(() => {
    const messages = selectedMessages ?? [];

    return messages.find(
      (message) => message.author_type === AuthorType.Patient,
    )?._id;
  }, [selectedMessages]);

  const onSelectConversation = async (
    conversation: DirectMessagePatientDto,
  ) => {
    const isSameConversation =
      conversation.conversation_id === selectedConversation?.conversation_id;
    setLoading(LOADING_MESSAGES, true);

    removeSelectedMessages();

    if (selectedTemplate) {
      setSelectedTemplate(null);
    }

    if (isSameConversation) {
      removeSelectedConversation();
      return;
    }

    setSelectedConversation(conversation);
    const { failedMessagesInConversation } = useChatState.getState();
    const messagesResult = await getMessages({
      pharmacyId: pharmacyId,
      locationId: locationId,
      locationPatientId: conversation.location_patient_id,
      conversationId: conversation.conversation_id,
      UnifiedCommsService: UnifiedCommsService,
      FileStorageService: FileStorageService,
      skip: 0,
      take: TAKE,
      failedMessagesInConversation: failedMessagesInConversation,
    });
    setSelectedMessages(messagesResult.messages);
    setMessagesPagination({
      skip: 0,
      take: TAKE,
      count: messagesResult.count,
    });

    await updateConversationStatus(
      locationId,
      conversation.location_patient_id,
      conversation.conversation_id,
      conversation.patient_viewed_all_messages,
      true,
    );
    setLoading(LOADING_MESSAGES, false);
    if (!selectedConversation?.pharmacy_viewed_all_messages) {
      ampli.messageRead({
        conversationID: selectedConversation?.conversation_id ?? '',
        messageID: lastLeftMessageId?.toLocaleString() ?? '',
        messageReadTime: new Date().toISOString(),
        messageSubject: selectedConversation?.subject ?? '',
      });
    }
  };

  const onMarkChat = async (
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    isChatRead: boolean,
  ) => {
    await updateConversationStatus(
      locationId,
      locationPatientId,
      conversationId,
      patientViewedAllMessages,
      isChatRead,
    );
    removeSelectedConversation();
  };

  const onConversationCreate = async (
    data: CreateConversationData,
    onComplete: () => void,
  ) => {
    setLoading(LOADING_CONVERSATION_CREATING, true);
    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      data.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING_MODAL,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      setLoading(LOADING_CONVERSATION_CREATING, false);
      return;
    }

    const createConversationDto = {
      author_id: String(chatUser._id),
      author_type: AuthorType.Pharmacy,
      subject: data.subject,
      patient_viewed_all_messages: false,
      pharmacy_viewed_all_messages: true,
      content: data.message,
      attachments: filesUploadResult.filesData.length
        ? filesUploadResult.filesData
        : [],
    };

    await UnifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      data.patient,
      createConversationDto,
    );

    setLoading(LOADING_CONVERSATION_CREATING, false);
    onComplete();
  };

  const onConversationCreateForSelectedPatient = async (
    data: CreateConversationData,
    onComplete: () => void,
  ) => {
    await onConversationCreate(data, onComplete);
    closeNewChatModal();
  };

  const onConversationCreateForSidebar = async (
    data: CreateConversationData,
    onComplete: () => void,
  ) => {
    await onConversationCreate(data, onComplete);
    closeSidebarNewChatModal();
  };

  const onPatientFilter = (text: string) => {
    if (isDateOfBirth(text)) {
      setTextFilters({
        ...textFilters,
        full_name: '',
        date_of_birth: text,
      });
    } else {
      setTextFilters({
        ...textFilters,
        full_name: text,
        date_of_birth: '',
      });
    }
  };

  const onEditSuccess = async (
    locationPatientRecord: LocationPatientRecordDto | null,
  ) => {
    if (!locationPatientRecord) {
      return;
    }
    const { first_name, last_name, date_of_birth } = locationPatientRecord;
    if (
      selectedPatient?.first_name !== first_name ||
      selectedPatient.last_name === last_name ||
      selectedPatient.date_of_birth === date_of_birth
    ) {
      setLoading(LOADING_CONVERSATIONS, true);

      const filteredConversations =
        await UnifiedCommsService.getAllFilteredConversations(
          locationId,
          filterCriteria,
        );
      const groupedConversations = groupConversationsByPatient(
        filteredConversations,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          sorting.field,
          sorting.order,
          sorting.isDate,
        ),
      );
      setRawConversations(filteredConversations);
      setConversationsGroupedByPatient(groupedConversations);

      setLoading(LOADING_CONVERSATIONS, false);
    }

    const selectedPatientLocationRecord =
      await PatientService.getPatientLocationRecord(
        locationId,
        locationPatientRecord.id,
      );
    setSelectedPatient(selectedPatientLocationRecord);
  };

  async function verifyPatient() {
    try {
      if (!selectedPatient) return;

      const verifiedPatient: LocationPatientRecordDto =
        await PatientService.updateLocationPatientRecord(
          locationId,
          selectedPatient.id,
          {
            is_verified: true,
          },
        );

      setSelectedPatient({
        ...selectedPatient,
        is_verified: verifiedPatient.is_verified,
      });
    } catch (error) {
      console.error('Error verifying a patient: ', error);
    }
  }

  const loadMore = async (args: LoadMoreOptions) => {
    const conversation = selectedPatientConversations.find(
      (group) =>
        group.conversation_id === selectedConversation?.conversation_id,
    );
    const { failedMessagesInConversation } = useChatState.getState();
    if (conversation && selectedConversation) {
      const messagesResult = await getMessages({
        pharmacyId: pharmacyId,
        locationId: locationId,
        locationPatientId: conversation.location_patient_id,
        conversationId: selectedConversation.conversation_id,
        UnifiedCommsService: UnifiedCommsService,
        FileStorageService: FileStorageService,
        skip: args.skip,
        take: args.take,
        failedMessagesInConversation,
      });

      setSelectedMessages(messagesResult.messages);
      setMessagesPagination({
        skip: args.skip,
        take: args.take,
        count: messagesResult.count,
      });
    }
  };

  const onAddPatientClick = useCallback(() => {
    setShowAddPatientModal(true);
  }, [setShowAddPatientModal]);

  const createPatientCB = useCallback(
    async (patientRecord: PatientRecordDto) => {
      if (patientRecord.location_patient_records.length) {
        const location_patient_id =
          patientRecord.location_patient_records[0].id;
        const patientLocationRecord =
          await PatientService.getPatientLocationRecord(
            locationId,
            location_patient_id,
          );

        setSelectedPatient(patientLocationRecord);
        openNewChatModal();
      } else {
        console.error('Location Patient Record was not created');
      }
    },
    [locationId],
  );

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    // space to end of the list, which scrolling through we want to trigger loading of a new set of conversations (pixels)
    const spaceToListEnd = 100;
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const isNearEnd =
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - spaceToListEnd;
    const areAllConversationsLoaded =
      conversationsGroupedByPatient.length < pagination.limit;
    // we need isConversationsLimitOverloaded for case when all patients with conversations are loaded already,
    // but its count did not reach digit CONVERSATION_PATIENTS_PAGINATION_STEP
    const isConversationsLimitOverloaded =
      conversationsGroupedByPatient.length +
        CONVERSATION_PATIENTS_PAGINATION_STEP <
      pagination.limit;

    if (
      isNearEnd &&
      !areAllConversationsLoaded &&
      !isConversationsLimitOverloaded
    ) {
      // increase patients count to load more conversations
      increasePatientsCount();
    }
  }

  function onSelectTemplate(template: ITemplate) {
    setSelectedTemplate(template);
    setShowTemplatesModal(false);
  }

  function onCloseTemplatesModal() {
    setShowTemplatesModal(false);
    if (selectedTemplate) {
      setSelectedTemplate(null);
    }
  }

  const renderLoadMore = useCallback(() => {
    if (messagesPagination.skip + TAKE > messagesPagination.count) {
      return <></>;
    }
    return (
      <View style={{ height: 50, marginTop: 10 }}>
        <LoadingIndicator color={theme.palette.primary[700]} />
      </View>
    );
  }, [messagesPagination.skip, messagesPagination.count]);

  const hasReachedCap = (usageData: any) =>
    usageData?.quantity >= usageData?.quantity_cap;

  const emailMessageError = emailUsageData && hasReachedCap(emailUsageData);
  const smsMessageError = smsUsageData && hasReachedCap(smsUsageData);

  function getContent() {
    if (rawConversationsCount) {
      return (
        <>
          {emailMessageError && smsMessageError ? (
            <Alert
              intent="warning"
              title={getText('sms-email-cap-reached')}
              linkText={getText('billing-settings')}
              onLinkPress={() => navigation.navigate('settings')}
              style={{ marginBottom: theme.getSpacing(2) }}
            />
          ) : emailMessageError ? (
            <Alert
              intent="warning"
              title={getText('email-cap-reached')}
              linkText={getText('billing-settings')}
              onLinkPress={() =>
                navigation.navigate('settings', { screen: 'billing' })
              }
              style={{ marginBottom: theme.getSpacing(2) }}
            />
          ) : smsMessageError ? (
            <Alert
              intent="warning"
              title={getText('sms-cap-reached')}
              linkText={getText('billing-settings')}
              onLinkPress={() =>
                navigation.navigate('settings', { screen: 'billing' })
              }
              style={{ marginBottom: theme.getSpacing(2) }}
            />
          ) : null}
          <ConversationsHeader />
          <View style={styles.body}>
            <View
              style={[
                styles.conversationsContainer,
                Boolean(selectedPatient) && {
                  width: CONVERSATIONS_TABS_WIDTH,
                },
              ]}
            >
              <ActionsBar
                sorting={sorting}
                textFilters={textFilters}
                isPatientSelected={Boolean(selectedPatient)}
                setConversationsSorting={setConversationsSorting}
                onAddPatientClick={onAddPatientClick}
                onPatientFilter={onPatientFilter}
              />
              <View
                style={{
                  height: getDynamicHeightForMessages(575),
                }}
              >
                <FlatList
                  data={conversationsGroupedByPatient}
                  keyExtractor={(patient) => patient.location_patient_id}
                  onScroll={onScroll}
                  renderItem={(item) => (
                    <PatientConversationWrapper
                      patient={item.item}
                      selectedPatient={selectedPatient}
                      index={item.index}
                      patientsCount={conversationsGroupedByPatient.length}
                      avatarColor={theme.palette.gray[100]}
                      onSelectPatient={() =>
                        onSelectPatient(item.item, rawConversations)
                      }
                    />
                  )}
                />
                {isLoadingConversations ? <LoadingIndicator /> : <></>}
              </View>
            </View>
            {selectedPatient && (
              <View style={{ flex: 1 }}>
                <PatientInfo
                  selectedPatient={selectedPatient}
                  openNewChatModal={openNewChatModal}
                  setShowEditModal={setShowEditModal}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
                {patientsWithoutConversationsSet.has(selectedPatient.id) ? (
                  <View style={styles.patientNoConversationsWrapper}>
                    <PatientWithoutConversationsView
                      onClick={openNewChatModal}
                    />
                  </View>
                ) : (
                  <PatientWithConversationsView
                    selectedPatient={selectedPatient}
                    selectedPatientConversations={selectedPatientConversations}
                    user={chatUser}
                    selectedConversationId={
                      selectedConversation?.conversation_id
                    }
                    selectedMessages={selectedMessages}
                    typingMember={typingMember}
                    viewedConversationsSet={viewedConversationsSet}
                    messagesCount={messagesPagination.count}
                    verifyPatient={verifyPatient}
                    onType={onType}
                    onSend={onSend}
                    onSelectConversation={onSelectConversation}
                    onMarkChat={onMarkChat}
                    loadMore={loadMore}
                    renderLoadMore={renderLoadMore}
                  />
                )}
              </View>
            )}
          </View>
        </>
      );
    } else if (isLoadingConversations && rawConversationsCount === 0) {
      return (
        <View style={{ marginTop: 100 }}>
          <LoadingIndicator />
        </View>
      );
    } else if (!pharmacyLocationHasPatientRecords) {
      return <PharmacyWithoutPatientsView />;
    }

    return <PharmacyWithoutConversationsView />;
  }

  return (
    <PharmacyScreenContainer>
      <View style={{ flex: 1 }}>
        <ErrorsContainer
          errorObject={errorObject}
          excludedErrors={EXCLUDED_ERRORS_CHAT}
        />
        <NewChatModal
          show={isOpenNewChatModal}
          onConversationCreate={onConversationCreateForSelectedPatient}
          onCancel={closeNewChatModal}
          recipient={selectedPatient}
          isConversationCreating={isLoadingConversationCreating}
          verifyPatient={verifyPatient}
        />
        {/** This NewChatModal is called from sidebar and should not have a pre-selected recipient */}
        <NewChatModal
          show={isOpenSidebarNewChatModal}
          onConversationCreate={onConversationCreateForSidebar}
          onCancel={closeSidebarNewChatModal}
          isConversationCreating={isLoadingConversationCreating}
          verifyPatient={verifyPatient}
        />
        <AddPatientModal
          show={showAddPatientModal}
          setShowAddPatientModal={setShowAddPatientModal}
          locationId={locationId}
          pharmacyId={pharmacyId}
          successCB={createPatientCB}
        />
        <TemplatesModal
          title={getText('select-chat-template')}
          show={showTemplatesModal}
          onSelectTemplate={onSelectTemplate}
          onCancel={onCloseTemplatesModal}
        />
        {selectedPatient ? (
          <EditPatientModal
            show={showEditModal}
            locationPatientRecord={selectedPatient}
            setShowEditModal={setShowEditModal}
            patientDetails={selectedPatient}
            successCB={onEditSuccess}
          />
        ) : (
          <></>
        )}
        {getContent()}
      </View>
      {selectedPatient && isSidebarOpen ? (
        <ChatUserDetailSidebar
          locationId={locationId}
          locationPatientRecordDto={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      ) : null}
    </PharmacyScreenContainer>
  );
};

export default Chat;

const useStyles = makeStyles((theme) => ({
  headerSearch: {
    backgroundColor: '#EAF1F4', // blue-gray
    height: 44,
    width: '100%',
  },
  body: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(1.5),
    width: '100%',
  },
  conversationsContainer: {
    width: '100%',
    flexDirection: 'column',
    marginRight: theme.getSpacing(1.5),
  },
  patientNoConversationsWrapper: {
    marginTop: 70,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));
