import { Modal } from 'assets/components/modal';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import PatientMedsCard from './PatientMedsCard';
import { Avatar } from 'assets/components/avatar';
import { Text } from 'assets/components/text/Text';
import { makeStyles } from 'assets/theme';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';
import { formatDate } from '../../common/datetime-utils';
import { cl } from '@fullcalendar/core/internal-common';

export const PatientMedsModal: FunctionComponent<
  PropsWithChildren<PatientMedsModalProps>
> = ({ show, close, locationPatientRecord, patientPrescriptions }) => {
  const styles = useStyles();
  const fullName = `${locationPatientRecord.first_name} ${locationPatientRecord.last_name}`;

  return (
    <Modal
      title={getText('medications')}
      show={show}
      size="sm"
      height={500}
      isScrollable={true}
      showDismissButton={true}
      handleDismissModal={close}
    >
      <View style={styles.rowView}>
        <Avatar
          firstName={locationPatientRecord.first_name}
          lastName={locationPatientRecord.last_name}
          size={40}
        />
        <Text style={styles.patientName}>{fullName}</Text>
        <Text style={styles.patientDOB}>
          {formatDate(locationPatientRecord.date_of_birth)}
        </Text>
      </View>
      <Divider style={styles.divider} />
      {patientPrescriptions.map((item) => (
        <PatientMedsCard
          key={item.prescription_id}
          patientPrescriptions={item}
        />
      ))}
    </Modal>
  );
};

export interface PatientMedsModalProps {
  show: boolean;
  close: () => void;
  locationPatientRecord: LocationPatientRecordDto;
  patientPrescriptions: PrescriptionDto[];
}

export default PatientMedsModal;

const useStyles = makeStyles((theme) => ({
  patientName: {
    marginLeft: theme.getSpacing(1),
    fontWeight: '600',
    fontSize: 20,
    height: 22,
    color: theme.palette.gray[900],
  },
  patientDOB: {
    marginLeft: theme.getSpacing(1),
    fontWeight: '400',
    fontSize: 16,
    height: 18,
    color: theme.palette.gray[600],
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
}));
