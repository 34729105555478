import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import {
  UseFormReturn,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  DeepPartial,
} from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import theme, { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { DynamicInputValue } from '../../common/types/MessageForm';
import { getText } from 'assets/localization/localization';

export const getDynamicFieldName = (index: number) => `input-${index}`;
export const INPUT_MOCK = {}; // needed to show the input before selecting the value and adding second one

const DynamicInputs = <T extends FieldValues>(
  {
    options: initialOptions,
    inputs,
    methods,
    setInputs,
    getValueField,
    isDisabled,
  }: BulkFilterInputProps<T>,
  ref: React.Ref<BulkFilterInputRef>,
) => {
  const styles = useStyles();

  // useImperativeHandle is a hook that allows to set values for ref of the component.
  // With the defined clearInputs field in the returned object in the hook we can call this function in the parent component like:
  // const ref = useRef(null);
  // ref.current?.clearInputs();
  // So, in general, with this we can call internal functions outside of the component
  useImperativeHandle(ref, () => ({
    clearInputs: () => {
      let counter = 0;
      let isRunning = true;
      while (isRunning) {
        const inputValue = methods.watch(
          getDynamicFieldName(counter) as Path<T>,
        );
        methods.resetField(getDynamicFieldName(counter) as Path<T>);
        methods.resetField(inputValue as Path<T>);

        counter++;
        if (counter === inputs.length) {
          isRunning = false;
        }
      }

      setInputs([INPUT_MOCK]);
    },
  }));

  const booleanOptionsSet = useMemo(() => {
    return new Set(
      initialOptions
        .filter((option) => option.isBooleanValue)
        .map((option) => option.value),
    );
  }, [initialOptions]);
  const dynamicFieldNames: (keyof T)[] = initialOptions.map((option, index) =>
    getDynamicFieldName(index),
  );
  const dynamicFieldNamesSet = new Set(dynamicFieldNames);
  const previousValues = methods.getValues();

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      // this is needed to prevent double updating when we call onRemoveInput() or onAddInput() functions which do the same
      const isUpdateNotPossible = localStorage.getItem('isUpdateNotPossible');

      // check if the name if related to the dynamic input and if updating available
      if (
        name &&
        dynamicFieldNamesSet.has(name) &&
        !isUpdateNotPossible &&
        isUpdateNotPossible !== 'true'
      ) {
        const prevValue = previousValues[name];

        if (prevValue) {
          // not sure why "methods.resetField(prevValue);" does not work here
          methods.setValue(
            prevValue,
            undefined as UnpackNestedValue<
              PathValue<T, UnpackNestedValue<DeepPartial<T>>[Path<T>]>
            >,
          );
        }
        const newValue = value[name];

        if (newValue) {
          if (booleanOptionsSet.has(newValue)) {
            methods.setValue(
              newValue,
              true as UnpackNestedValue<
                PathValue<T, UnpackNestedValue<DeepPartial<T>>[Path<T>]>
              >,
            );
          } else {
            methods.setValue(
              newValue,
              '' as UnpackNestedValue<
                PathValue<T, UnpackNestedValue<DeepPartial<T>>[Path<T>]>
              >,
            );
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [methods, dynamicFieldNames, previousValues]);

  const selectedOptions = useMemo(() => {
    const selectedInputOptions: string[] = [];
    let counter = 0;
    let isRunning = true;
    while (isRunning) {
      const inputValue = methods.watch(getDynamicFieldName(counter) as Path<T>);
      selectedInputOptions.push(inputValue);

      counter++;
      if (counter === inputs.length) {
        isRunning = false;
      }
    }

    return selectedInputOptions;
  }, [inputs, previousValues]);

  const optionsByInputIndex = useMemo(() => {
    const selectedOptionsSet = new Set(selectedOptions);
    const optionsMapByValue = initialOptions.reduce(
      (accumulator, currentOption, index) => {
        return {
          ...accumulator,
          [currentOption.value]: currentOption,
        };
      },
      {},
    );

    const optionsMap: Record<string, DynamicInputsOption[]> = {};
    inputs.forEach((_input, index) => {
      const filterOptions = initialOptions.filter(
        (option) => !selectedOptionsSet.has(option.value),
      );
      const inputOption = methods.watch(getDynamicFieldName(index) as Path<T>);
      if (inputOption) {
        filterOptions.unshift(optionsMapByValue[inputOption]);
      }
      optionsMap[index] = filterOptions;
    });

    return optionsMap;
  }, [inputs, initialOptions, selectedOptions]);

  const onRemoveInput = (index: number) => {
    localStorage.setItem('isUpdateNotPossible', 'true');
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    const inputName = methods.watch(getDynamicFieldName(index) as Path<T>);
    // not sure why "methods.resetField(...);" does not work here
    methods.setValue(
      getDynamicFieldName(index) as Path<T>,
      undefined as UnpackNestedValue<PathValue<T, Path<T>>>,
    );
    methods.setValue(
      inputName as Path<T>,
      undefined as UnpackNestedValue<PathValue<T, Path<T>>>,
    );

    if (newInputs.length === 0) {
      newInputs.push(INPUT_MOCK);
    } else {
      // move selected inputs in methods closer to the start by 1 step, since one of them is going to be removed
      inputs.forEach((_inputMock, loopIndex: number) => {
        if (loopIndex >= index) {
          const nextInputRow = methods.watch(
            getDynamicFieldName(loopIndex + 1) as Path<T>,
          );
          if (nextInputRow) {
            methods.setValue(
              getDynamicFieldName(loopIndex) as Path<T>,
              nextInputRow,
            );
            methods.resetField(getDynamicFieldName(loopIndex + 1) as Path<T>);
          }
        }
      });
    }

    setInputs(newInputs);
    localStorage.removeItem('isUpdateNotPossible');
  };

  const onAddInput = (input: {}, index: number) => {
    localStorage.setItem('isUpdateNotPossible', 'true');
    const inputsCopy = [...inputs];
    inputsCopy.splice(index, 0, input);

    // move selected inputs in methods further by 1 step
    let counter = inputs.length - 1;
    while (counter > -1) {
      if (counter === index) {
        const currentInputRow = methods.watch(
          getDynamicFieldName(counter) as Path<T>,
        );
        methods.setValue(
          getDynamicFieldName(counter + 1) as Path<T>,
          currentInputRow as UnpackNestedValue<PathValue<T, Path<T>>>,
        );
        methods.resetField(getDynamicFieldName(counter) as Path<T>);
      } else if (counter > index) {
        const currentInputRow = methods.watch(
          getDynamicFieldName(counter) as Path<T>,
        );
        methods.setValue(
          getDynamicFieldName(counter + 1) as Path<T>,
          currentInputRow as UnpackNestedValue<PathValue<T, Path<T>>>,
        );
      }

      counter--;
    }

    setInputs(inputsCopy);
    localStorage.removeItem('isUpdateNotPossible');
  };

  const checkIsCurrentFilled = (index: number) => {
    let lastFilledIndex = 0;
    let counter = 0;
    let isRunning = true;
    while (isRunning) {
      const inputValue = methods.watch(getDynamicFieldName(counter) as Path<T>);

      if (inputValue !== undefined) {
        lastFilledIndex = counter;
      }

      counter++;
      if (counter === inputs.length) {
        isRunning = false;
      }
    }

    return lastFilledIndex === index;
  };

  return (
    <View>
      {inputs.map((input, index) => {
        const currentInput = getDynamicFieldName(index);
        const { valueInput, isReturningValueInput, marginBottom } =
          getValueField(methods.watch(currentInput as Path<T>));
        const isInputSelected = Boolean(methods.watch(currentInput as Path<T>));
        const isCurrentFilled = checkIsCurrentFilled(index);
        const isPreLast = index === inputs.length - 2;
        const isNextLastAndNotFilled = isPreLast && isCurrentFilled;

        return (
          <View
            key={index}
            style={[styles.wrapper, { marginBottom: marginBottom }]}
          >
            <View style={styles.dropdownSelectWrapper}>
              <DropdownSelectField
                testID="forwarded-dynamic-input"
                name={currentInput}
                disabled={isDisabled}
                options={
                  index in optionsByInputIndex
                    ? optionsByInputIndex[index]
                    : initialOptions
                }
                labelInlined={false}
                placeholder={`${getText('select-filter')}...`}
                rules={{
                  required: getText('filter-is-required-field'),
                }}
              />
            </View>
            {isInputSelected ? (
              <>
                {isReturningValueInput ? valueInput : <></>}
                {isCurrentFilled ? (
                  <>
                    <View style={styles.removeWrapper}>
                      <TouchableOpacity onPress={() => onRemoveInput(index)}>
                        <View style={styles.removeBox}>
                          <MinusCircleIcon
                            size={20}
                            color={theme.palette.primary[600]}
                          />
                          <Text style={styles.textWrapper}>
                            {getText('remove')}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.addWrapper}>
                      <TouchableOpacity
                        disabled={
                          inputs.length >= initialOptions.length ||
                          isNextLastAndNotFilled
                        }
                        onPress={() => onAddInput(input, index + 1)}
                      >
                        <View style={styles.addBox}>
                          <PlusCircleIcon
                            size={20}
                            color={theme.palette.primary[600]}
                          />
                          <Text style={styles.textWrapper}>
                            {getText('add-another')}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </View>
        );
      })}
    </View>
  );
};

export interface DynamicInputsOption {
  label: string;
  value: string;
  isBooleanValue: boolean;
}

interface BulkFilterInputProps<T extends FieldValues> {
  options: DynamicInputsOption[];
  inputs: {}[];
  setInputs: (inputs: {}[]) => void;
  methods: UseFormReturn<T, any>;
  getValueField: (input: DynamicInputValue) => {
    valueInput: React.JSX.Element;
    isReturningValueInput: boolean;
    marginBottom: number;
  };
  isDisabled?: boolean;
}

export interface BulkFilterInputRef {
  clearInputs: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  dropdownSelectWrapper: {
    width: '35%',
  },
  removeWrapper: {
    width: '12%',
    alignItems: 'flex-start',
    marginLeft: 16,
    paddingTop: 14,
  },
  removeBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textWrapper: {
    marginLeft: 4,
    color: theme.palette.primary[600],
  },
  addWrapper: {
    width: '15%',
    alignItems: 'flex-start',
    paddingTop: 14,
  },
  addBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const BulkFilterInput = forwardRef(DynamicInputs) as <
  T extends FieldValues,
>(
  props: BulkFilterInputProps<T> & { ref?: React.Ref<BulkFilterInputRef> },
) => ReturnType<typeof DynamicInputs>;
