import { PharmacyWebsiteDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { isNumber, isURL } from '@digitalpharmacist/validation-dp';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { View } from 'react-native';
import { canFactory } from '../../../../common/guards/permission-guard';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { useAppStateStore } from '../../../../store/app-store';
import {
  setShowModalWebsite,
  updatePharmacyWebsite,
} from '../app-settings-actions';
import { useAppSettingsState } from '../app-settings-store';

const DEFAULT_FORM_VALUE = {
  url: '',
  company: '',
  space_craft_id: '',
  pid: '',
  default_location_id: '',
  ga_key: '',
  account_url: '',
  refill_url: '',
  transfer_url: '',
};

const convertToFormValues = (
  websiteValue?: PharmacyWebsiteDto,
): WebsiteFormProps => {
  return websiteValue
    ? {
        url: websiteValue.url,
        company: websiteValue.company,
        space_craft_id: websiteValue.space_craft_id,
        pid: websiteValue.pid,
        default_location_id: websiteValue.default_location_id,
        ga_key: websiteValue.ga_key,
        account_url: websiteValue.account_url,
        refill_url: websiteValue.refill_url,
        transfer_url: websiteValue.transfer_url,
      }
    : DEFAULT_FORM_VALUE;
};

export const WebsiteModal: FunctionComponent<PropsWithChildren<any>> = () => {
  const { showModalWebsite, pharmacyWebsite } = useAppSettingsState();
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );

  const styles = useStyles();
  const { pharmacyId } = useAppStateStore();

  useEffect(() => {
    methods.reset(convertToFormValues(pharmacyWebsite));
  }, [pharmacyWebsite, showModalWebsite]);

  const methods = useForm<WebsiteFormProps>({
    defaultValues: convertToFormValues(pharmacyWebsite),
    mode: 'onChange',
  });

  const handleSubmit = () => {
    const formValue = methods.getValues();
    if (
      !_isEqual(formValue, convertToFormValues(pharmacyWebsite)) &&
      pharmacyId
    ) {
      updatePharmacyWebsite(pharmacyId, formValue);
    }
    setShowModalWebsite(false);
  };

  const closeModal = () => {
    methods.reset(convertToFormValues(pharmacyWebsite));
    setShowModalWebsite(false);
  };

  const validateUrl = (value: string) => {
    return isURL(value) ? true : getText('url-is-not-valid');
  };

  const validateNumber = (value: string) => {
    return isNumber(Number(value)) ? true : getText('number-is-not-valid');
  };

  return (
    <Modal
      title={getText('edit-website-details')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-general-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalWebsite}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('url')}</Text>
              {!canLumistryUserUpdateSettings && (
                <SettingsTooltipsComponents tooltipId="website-settings-url-modal" />
              )}
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="url"
              type="text"
              rules={{
                validate: validateUrl,
                required: getText('field-required'),
              }}
              disabled={!canLumistryUserUpdateSettings}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('company')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="company"
              type="text"
              rules={{
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('space-craft-id')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="space_craft_id"
              type="numeric"
              rules={{
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pid')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="pid"
              type="text"
              rules={{
                validate: validateNumber,
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('default-location-id')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="default_location_id"
              type="text"
              rules={{
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('g-analytics-key')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="ga_key"
              type="text"
              rules={{
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('account-url')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="account_url"
              type="text"
              rules={{
                validate: validateUrl,
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('refill-url')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="refill_url"
              type="text"
              rules={{
                validate: validateUrl,
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('transfer-url')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="transfer_url"
              type="text"
              rules={{
                validate: validateUrl,
                required: getText('field-required'),
              }}
              disabled={!canFactory('update')('settingsLumistryUser')}
            />
          </Form.Column>
        </Form.Row>
      </Form>
      {!canLumistryUserUpdateSettings && (
        <Tooltip
          anchorId="website-settings-url-modal"
          text={getText('website-settings-url-tooltip')}
        />
      )}
    </Modal>
  );
};

interface WebsiteFormProps {
  url: string;
  company: string;
  space_craft_id: string;
  pid: string;
  default_location_id: string;
  ga_key: string;
  account_url: string;
  refill_url: string;
  transfer_url: string;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.getSpacing(1),
    flexDirection: 'row',
    alignContent: 'center',
  },
  textFormat: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));
