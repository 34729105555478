import { FC } from 'react';
import { View } from 'react-native';
import theme, { makeStyles, useTheme } from 'assets/theme';
import { IRendererProps } from '../types';
import { useBulkTableStyles } from '../commonStyles';
import { PharmacyTooltipWrapper } from '../../../../common/PharmacyTooltipWrapper';
import { ChatIcon } from 'assets/icons';

export const SubjectRenderer: FC<IRendererProps> = ({ data }) => {
  const theme = useTheme();
  const bulkTableStyles = useBulkTableStyles();
  const styles = useStyles();

  return (
    <>
      <div style={bulkTableStyles.iconAndTextContainer}>
        {/** the tooltip itself lives in <Bulk /> component */}
        <PharmacyTooltipWrapper tooltipId="secure-chat-subject-renderer">
          <View style={styles.tooltipIcon}>
            <ChatIcon size={20} color={theme.palette.gray[700]} />
          </View>
        </PharmacyTooltipWrapper>

        <div style={styles.text}>{data.subject}</div>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  text: {
    marginTop: -6,
    color: theme.palette.gray[700],
  },
  tooltipIcon: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
