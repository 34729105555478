import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';
import { StyleProp, ViewStyle } from 'react-native';

export const LinkIcon: FunctionComponent<
  IconProps & { style?: StyleProp<ViewStyle> }
> = ({ color, size, testID, style }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 -7 24 24"
      fill="none"
      stroke="currentColor"
      color={color}
      testID={testID}
    >
      <Path
        d="M2.4 5C2.4 3.29 3.79 1.9 5.5 1.9H9.5V0H5.5C2.74 0 0.5 2.24 0.5 5C0.5 7.76 2.74 10 5.5 10H9.5V8.1H5.5C3.79 8.1 2.4 6.71 2.4 5ZM6.5 6H14.5V4H6.5V6ZM15.5 0H11.5V1.9H15.5C17.21 1.9 18.6 3.29 18.6 5C18.6 6.71 17.21 8.1 15.5 8.1H11.5V10H15.5C18.26 10 20.5 7.76 20.5 5C20.5 2.24 18.26 0 15.5 0Z"
        fill="#748995"
      />
    </Svg>
  );
};
