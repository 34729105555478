import React, { PropsWithChildren, useEffect } from 'react';
import { TooltipProvider } from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import { AppLoader } from 'assets/common/AppLoader';
import { NavigationContainer } from './modules/common/NavigationContainer';
import {
  AppNavigation,
  PublicNavigationParamList,
} from './modules/navigation/RootNavigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLoginState } from './modules/screens/login/login-store';
import { StorageKeys } from './enums/storage-keys';
import {
  ThemeProvider,
  createBrandedColors,
  useThemeContext,
} from 'assets/theme';
import { ApiConfig } from 'assets/core/api';
import { logout } from './modules/screens/login/login-actions';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';
import 'photoswipe/dist/photoswipe.css';

import { ProSidebarProvider } from 'react-pro-sidebar';

import { isUserAuthorized, useUserState } from './modules/store/user-store';
import { AppRedirectionProvider } from './modules/providers/app-redirection';
import './modules/common/react-toastify-overrides.css';
import './modules/common/photoswipe-overrides.css';
import './global-style-overrides.css';
import UsersService from './modules/api/UsersService';
import { useDetectPresence } from './modules/common/sessions/presence-tracking';

import {
  AG_GRID_LICENSE_KEY,
  INTERCOM_ENABLED,
  USERWAY_ACCOUNT_ID,
} from './modules/common/constants';
import {
  tryGetUser,
  persistUserInfo,
  setAvailableRoles,
} from './modules/actions/user-actions';

import './modules/common/data-grid-overrides.css';
import { initIntercom } from './utils';
import { ChecklistModalProvider } from './modules/screens/checklist/ChecklistModalProvider';
import { useSocketConnectionManager } from './modules/screens/messages/hooks/useConnectionManager';
import { useSocketsForChat } from './modules/screens/messages/hooks/useSocketsForChat';
import { getUsersMostRecentLocations } from './modules/actions/app-actions';
import { initUserway } from 'assets/utils/common';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

ApiConfig.setBaseApiConfig({
  getAccessToken: async () =>
    await AsyncStorage.getItem(StorageKeys.AccessToken),
  setAccessToken: async (token: string) =>
    await AsyncStorage.setItem(StorageKeys.AccessToken, token),
  signOut: async () => {
    await logout();
  },
  onError: async (error: any) => {
    // TODO
    console.error('error from ApiConfig.onError', error);
  },
  retryRefreshToken: async () => {
    const refreshToken = await AsyncStorage.getItem(StorageKeys.RefreshToken);
    const { access_token } = await UsersService.refreshToken({
      refresh_token: refreshToken ?? '',
    });
    return access_token;
  },
});

const initialize = async () => {
  try {
    const user = await tryGetUser();
    if (user) {
      await persistUserInfo(user);
      await getUsersMostRecentLocations();
      if (user.id) {
        await setAvailableRoles(user.id);
      } else {
        console.error('Could not get userId to initialize role guards');
      }
      useLoginState.setState({ status: 'success' });
    }
  } catch (e) {}

  if (USERWAY_ACCOUNT_ID) {
    initUserway(USERWAY_ACCOUNT_ID);
  }

  if (INTERCOM_ENABLED) {
    initIntercom();
  }
};

export default function App() {
  useDetectPresence();
  useSocketConnectionManager();
  useSocketsForChat();

  return (
    <SafeAreaProvider>
      <AppLoader onBeforeLoad={initialize}>
        <NavigationContainer>
          <ThemeProvider>
            <ProSidebarProvider>
              <TooltipProvider>
                <IntercomLoader>
                  <AppRedirectionContainer>
                    <AppNavigation />
                    <ChecklistModalProvider />
                  </AppRedirectionContainer>
                </IntercomLoader>
                <ToastContainer />
              </TooltipProvider>
            </ProSidebarProvider>
          </ThemeProvider>
        </NavigationContainer>
      </AppLoader>
    </SafeAreaProvider>
  );
}

const AppRedirectionContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.data));
  const { setTheme } = useThemeContext();

  const publicRoutesKeys: Array<keyof PublicNavigationParamList> = [
    'create-password',
    'expiring-soon',
    'login',
    'reset-link',
    'reset-link-sent',
    'reset-password',
    'update-password',
  ];
  useEffect(() => {
    const brandingColors = createBrandedColors({
      brandedText: '#0063B2',
      brandedPrimary: '#0093E8',
      brandedSecondary: '#0063B2',
    });

    setTheme({ colors: brandingColors });
  }, []);

  return (
    <AppRedirectionProvider
      appRedirectionConfig={{
        handleAutomaticRedirection: true,
      }}
      isAuthorized={isAuthorized}
      publicRoutes={publicRoutesKeys}
    >
      {children}
    </AppRedirectionProvider>
  );
};

const IntercomLoader: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.data));

  useEffect(() => {
    if (INTERCOM_ENABLED) {
      if (isAuthorized) {
        // @ts-expect-error we know window.Intercom exists because we have a script that puts it there
        window.Intercom('update', {
          hide_default_launcher: false,
        });
        // @ts-expect-error we know window.Intercom exists because we have a script that puts it there
        window.Intercom('show'); // include this code to immediately expand the messenger widget
      } else {
        // @ts-expect-error we know window.Intercom exists because we have a script that puts it there
        window.Intercom('update', {
          hide_default_launcher: true,
        });
      }
    }
  }, [isAuthorized]);

  return <>{children}</>;
};
