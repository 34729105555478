import { Modal } from 'assets/components/modal';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { setOpenedModal } from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { InfoTooltip } from '../../../onboarding/components/common/InfoTooltip';
import { ChecklistModalProps } from '../../types';

export const WebsiteAccessSetupModal: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const styles = useStyles();

  const methods = useForm<any>({
    //to do
    defaultValues: {},
  });

  return (
    <Modal
      title={getText('website-access')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal(undefined)}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => console.log('next'),
          logger: { id: 'next-website-access-setup' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal(undefined),
          logger: { id: 'back-website-access-setup' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('website-you-like-to-use') + '*'}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="website-you-like-to-use"
                text={getText('website-domain-first-tooltip')}
              />
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={styles.radioButton}>
              <RadioButtonGroupField
                name="test" // to do
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                values={[
                  {
                    text: getText('yes'),
                    value: 'yes',
                  },
                  {
                    text: getText('no'),
                    value: 'no',
                  },
                ]}
                rules={{
                  required: getText('field-required'),
                }}
              />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('website-how-to-access-domain') + '*'}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="website-how-to-access-domain"
                text={getText('website-domain-second-tooltip')}
              />
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={styles.radioButton}>
              <RadioButtonGroupField
                name="test" // to do
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                values={[
                  {
                    text: getText('yes'),
                    value: 'yes',
                  },
                  {
                    text: getText('no'),
                    value: 'no',
                  },
                ]}
                rules={{
                  required: getText('field-required'),
                }}
              />
            </View>
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  tootlip: {
    left: theme.getSpacing(1),
    top: theme.getSpacing(0.5),
  },
  radioButton: {
    marginLeft: -16,
    marginBottom: -24,
    marginTop: -24,
    display: 'flex',
    flexDirection: 'row',
  },
}));
