import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { ColDef, ColGroupDef, GridReadyEvent } from '@ag-grid-community/core';
import {
  getSchedules,
  resetAvailabilitiesList,
  setDefaultSchedule,
  setReset,
} from './availabilities-list-actions';
import { useAvailabilitiesListState } from './availabilities-list-store';
import { DataGrid } from 'assets/components/data-grid';
import { makeStyles, useTheme } from 'assets/theme';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { AppointmentTypeScheduleDto } from '@digitalpharmacist/appointment-service-client-axios';
import { scheduleAvailabilityToString } from '../../common/datetime-utils';
import { View } from 'react-native';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { IconButton } from 'assets/components/icon-button';
import { ClockIcon, PencilIcon, TrashIcon } from 'assets/icons';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import { Text } from 'assets/components/text';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { getText } from '../../../../../packages/assets/localization/localization';
import { deleteSchedule } from '../availability-form/availability-form-actions';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import './availabilities-list-grid.css';

const MENU_ID = 'availabilities-row-options';

const AvailabilityHoursRenderer = (props: {
  data: AppointmentTypeScheduleDto;
}) => {
  const availabilities = props.data.availability;
  const styles = useStyles();
  const baseAvailabilities = availabilities
    .filter((availability) => !availability.date)
    .sort((a, b) => a.days[0] - b.days[0]);

  return (
    <View style={styles.cellContainer}>
      <Text>
        {baseAvailabilities.map((availability, i) => (
          <span key={i}>
            {scheduleAvailabilityToString(availability)}
            {i + 1 !== baseAvailabilities.length && ', '}
          </span>
        ))}
      </Text>
    </View>
  );
};

export const AvailabilitiesList: FunctionComponent<AvailabilitiesListProps> = (
  props,
) => {
  const { schedules, reset, gridApi } = useAvailabilitiesListState();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const theme = useTheme();
  const [hideTooltip, setHideTooltip] = useState(false);

  useFocusEffect(
    useCallback(() => {
      getSchedules();
    }, []),
  );

  useEffect(() => {
    if (reset) {
      getSchedules();
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (gridApi) {
      const rowCount = gridApi.getDisplayedRowCount();
      if (rowCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, schedules]);

  // When you change a tooltip that should be shown while the previous one is visible
  // it glitches the previous tooltip and it can't be hidden (two tooltips are visible then).
  // This is a workaround that resets the tooltips.
  const resetTooltip = () => {
    setHideTooltip(true);
    setTimeout(() => setHideTooltip(false), 50);
  };

  const setGridApi = (event: GridReadyEvent) => {
    const { api } = event;
    event.api.setDomLayout('autoHeight');
    useAvailabilitiesListState.setState({ gridApi: api });
  };

  const ActionButtonsRenderer = (props: {
    data: AppointmentTypeScheduleDto;
  }) => {
    const rowData = props.data;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const navigation = useNavigation<ScheduleDrawerNavigationProp>();
    const styles = useStyles();
    const { status } = useAvailabilitiesListState();

    const handleDeleteClick = async () => {
      setShowDeleteConfirmation(false);
      await deleteSchedule(rowData.id);
      resetAvailabilitiesList();
    };

    return (
      <View style={styles.cellContainer}>
        <PharmacyTooltipWrapper
          tooltipId={
            rowData.isDefault ? 'default-tooltip' : 'make-default-tooltip'
          }
          isContentIcon={true}
        >
          <ToggleSwitch
            logger={{ id: `change-status-availability--${rowData.id}` }}
            value={rowData.isDefault}
            onPress={() => {
              setDefaultSchedule(rowData);
              resetTooltip();
            }}
            disabled={status === 'loading'}
          />
        </PharmacyTooltipWrapper>

        <PharmacyTooltipWrapper
          tooltipId="edit-availability-record-tooltip"
          isContentIcon={true}
        >
          <IconButton
            icon={PencilIcon}
            logger={{ id: `edit-availability--${rowData.id}` }}
            onPress={() =>
              navigation.navigate('edit-availability', {
                availabilityId: rowData.id,
              })
            }
          />
        </PharmacyTooltipWrapper>

        <PharmacyTooltipWrapper
          tooltipId={
            rowData.in_use
              ? 'delete-availability-in-use-tooltip'
              : 'delete-availability-record-tooltip'
          }
          isContentIcon={true}
        >
          <IconButton
            icon={TrashIcon}
            logger={{ id: `delete-availability--${rowData.id}` }}
            onPress={() => setShowDeleteConfirmation(true)}
            disabled={rowData.in_use}
          />
        </PharmacyTooltipWrapper>
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => handleDeleteClick()}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={getText('delete-confirmation-modal', {
            itemType: getText('availability').toLowerCase(),
            itemName: `"${rowData.title}"`,
          })}
        />
      </View>
    );
  };

  const [columnDefs] = useState([
    {
      width: 300,
      maxWidth: 300,
      field: 'title',
      headerName: getText('service'),
    },
    {
      field: 'availability',
      headerName: getText('hours'),
      cellRenderer: AvailabilityHoursRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      },
    },
    {
      width: 200,
      maxWidth: 200,
      field: 'actions',
      headerName: getText('actions'),
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  return (
    <>
      <DataGrid
        gridOptions={{
          onGridReady: setGridApi,
          rowData: schedules,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: false,
          loadingOverlayComponent: 'loadingIndicator',
          loadingOverlayComponentParams: {
            color: theme.colors.brandedPrimary,
          },
          components: {
            loadingIndicator: LoadingIndicator,
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title={getText('no-availability')}
              subtitle={getText('check-later')}
              icon={<ClockIcon size={100} color={theme.palette.gray[300]} />}
              layout="vertical"
              containerCustomStyles={{ marginTop: -120 }}
            />
          ),
        }}
        gridToolbarProps={{
          titleProps: {
            title: getText('availability'),
          },
          actionButtonsProps: {
            maxActionToShow: 2,
            actionButtons: [
              {
                text: getText('new-availability'),
                hierarchy: 'primary',
                logger: { id: 'new-availability-button' },
                onPress: () => navigation.navigate('new-availability'),
              },
            ],
          },
        }}
        className="availabilities-list-grid"
      />
      {!hideTooltip && (
        <Tooltip text={getText('make-default')} id="make-default-tooltip" />
      )}
      {!hideTooltip && (
        <Tooltip text={getText('default-availability')} id="default-tooltip" />
      )}
      <Tooltip
        text={getText('edit-availability-record-tooltip')}
        id="edit-availability-record-tooltip"
      />
      <Tooltip
        text={getText('delete-availability-record-tooltip')}
        id="delete-availability-record-tooltip"
      />
      <Tooltip
        text={getText('delete-availability-in-use-tooltip')}
        id="delete-availability-in-use-tooltip"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  loadingContainer: {
    marginTop: theme.getSpacing(4),
  },
}));

export interface AvailabilitiesListProps {}
