import { HiddenText } from 'assets/components/hidden-text';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

export const RowItemRender: FunctionComponent<
  PropsWithChildren<RowItemRenderProps>
> = ({ title, value, isSecretValue, style, children, icon, isHeader }) => {
  const styles = useStyles();
  return (
    <View style={isHeader ? styles.containerHeader : styles.container}>
      <View style={styles.firstColumnContent}>
        <Text style={styles.title}>{title}</Text>
        {icon}
      </View>

      <View style={[{ flex: 4 }, style]}>
        {isSecretValue ? (
          <HiddenText style={styles.value}>{value}</HiddenText>
        ) : (
          <View style={{ flex: 4 }}>
            {value && (
              <Text ellipsizeMode="tail" numberOfLines={1} style={styles.value}>
                {value}
              </Text>
            )}
          </View>
        )}
        {children}
      </View>
    </View>
  );
};

interface RowItemRenderProps {
  title: string;
  value?: string;
  icon?: ReactElement;
  style?: StyleProp<ViewStyle>;
  isSecretValue?: boolean;
  isHeader?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  containerHeader: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    paddingHorizontal: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: 1,
  },
  title: {
    ...theme.lumistryFonts.label.small.semiBold,
    color: theme.palette.gray[900],
    alignItems: 'center',
  },
  value: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
