import React, { useCallback, useEffect, useMemo, useState } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { AutomationConfig } from './utils';
import AutomationItem from './auto/components/AutomationItem';
import HappyBirthdayImage from './stores/auto-messages-store/HappyBirthdayImage';
import InsuranceCardImage from './stores/auto-messages-store/InsuranceCardImage';
import FluShotImage from './stores/auto-messages-store/FlushotImage';
import ReminderGenericImage from './stores/auto-messages-store/ReminderGenericImage';
import { useAppStateStore } from '../../store/app-store';
import { getAutoMessage } from './stores/auto-messages-store/auto-messages-actions';
import { useAutoMessageState } from './stores/auto-messages-store/auto-messages-store';
import {
  AutoMessageConfigDto,
  MessageConfigType,
  UpdateAutoMessageConfigDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import UnifiedCommsService from '../../api/UnifiedCommsService';
import { EditMessageConfigModal } from './auto/components/EditAutoMessageConfigModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { loadBillingData } from '../settings/billing/billing-settings-actions';
import { useBillingOptionsSettingsState } from '../settings/billing/billing-settings-store';
import { Alert } from 'assets/components/alert';
import { useNavigation, ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

export default function Auto() {
  const styles = useStyles();
  const locationId = useAppStateStore((state) => state.locationId);
  const { automations, status } = useAutoMessageState();
  const automationsArray: AutoMessageConfigDto[] = Object.values(automations);
  const [automationItem, setAutomationItem] = useState<AutoMessageConfigDto>();
  const { smsUsageData, emailUsageData } = useBillingOptionsSettingsState();

  const sheetRef = React.useRef<BaseModalHandler>(null);

  useEffect(() => {
    void getAutoMessage(locationId);
    loadBillingData(locationId);
  }, [locationId]);

  const togglePressHandler = useCallback(
    async (value: boolean, automationId: string) => {
      value = true;
      //This will be added later in this https://rxwiki.atlassian.net/browse/BLUES-4339 ticket
    },
    [],
  );

  function createArrayOfKeyPairs(
    arr: AutoMessageConfigDto[],
  ): { title: string; items: AutoMessageConfigDto[] }[] {
    const resultMap = new Map<string, AutoMessageConfigDto[]>();

    arr.forEach((obj) => {
      const section = AutomationConfig[obj.message_config_type].section;
      if (!resultMap.has(section)) {
        resultMap.set(section, []);
      }
      resultMap.get(section)?.push(obj);
    });

    return Array.from(resultMap).map(([title, items]) => ({ title, items }));
  }

  const getCardImage = (type: string): JSX.Element => {
    let jsxElement = <></>;
    switch (type) {
      case MessageConfigType.HappyBirthday:
        jsxElement = <HappyBirthdayImage />;
        break;

      case MessageConfigType.InsuranceCardUpdate:
        jsxElement = <InsuranceCardImage />;
        break;

      case MessageConfigType.FluShotReminder:
        jsxElement = <FluShotImage />;
        break;

      default:
        jsxElement = <ReminderGenericImage />;
        break;
    }
    return jsxElement;
  };
  const onEditCopyMessage = async (
    updateAutoMessageConfigDto: UpdateAutoMessageConfigDto,
    onComplete: () => void,
  ): Promise<void> => {
    if (automationItem === undefined || automationItem === null) {
      throw new Error('automationItem is null');
    }
    const mergeUpdateAutoMessageConfigDto: UpdateAutoMessageConfigDto = {
      ...updateAutoMessageConfigDto,
      message_config_type: automationItem.message_config_type,
    };

    await UnifiedCommsService.autoMessageConfigCreateOrUpdateAutoMessageConfig(
      updateAutoMessageConfigDto.location_id,
      mergeUpdateAutoMessageConfigDto,
    );

    onComplete();

    // Once the modal is closed in the background, we have all configuration messages.
    // Therefore, we need to call getAutoMessage to update the auto configurations with the latest changes.
    void getAutoMessage(locationId);

    hideEditPersonalModal();
  };

  const hideEditPersonalModal = () => {
    sheetRef.current?.hide();
  };

  const showEditModal = (automation: AutoMessageConfigDto) => {
    setAutomationItem(automation);
    sheetRef.current?.show();
  };
  const hasReachedCap = (usageData: any) =>
    usageData?.quantity >= usageData?.quantity_cap;

  const emailMessageError = emailUsageData && hasReachedCap(emailUsageData);
  const smsMessageError = smsUsageData && hasReachedCap(smsUsageData);
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  return (
    <View style={styles.container}>
      {emailMessageError && smsMessageError ? (
        <Alert
          intent="warning"
          title={getText('sms-email-cap-reached')}
          linkText={getText('billing-settings')}
          onLinkPress={() =>
            navigation.navigate('settings', { screen: 'billing' })
          }
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      ) : emailMessageError ? (
        <Alert
          intent="warning"
          title={getText('email-cap-reached')}
          linkText={getText('billing-settings')}
          onLinkPress={() =>
            navigation.navigate('settings', { screen: 'billing' })
          }
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      ) : smsMessageError ? (
        <Alert
          intent="warning"
          title={getText('sms-cap-reached')}
          linkText={getText('billing-settings')}
          onLinkPress={() =>
            navigation.navigate('settings', { screen: 'billing' })
          }
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      ) : null}
      {status === 'loading' && <LoadingOverlay />}
      <View style={styles.tabHeader}>
        <Text style={styles.headerText}>{getText('automated-messages')}</Text>
      </View>
      {createArrayOfKeyPairs(
        // TODO remove this filter when Vitamin implements appointment reminders
        automationsArray.filter(
          (automation) => automation.message_config_type !== 'appointment',
        ),
      ).map((group, groupIndex) => (
        <View key={group.title} style={{ marginBottom: theme.getSpacing(2) }}>
          <View key={groupIndex} style={styles.titleContainer}>
            <Text style={styles.title}>{group.title}</Text>
          </View>

          <View style={styles.automationsContainer}>
            {group.items.map((automation, index) => (
              <AutomationItem
                key={automation.message_config_type}
                automation={automation}
                isLoading={false}
                title={AutomationConfig[automation.message_config_type].title}
                description={automation.message ? automation.message : ''}
                id={automation.message_config_type}
                isEditable={true}
                onPressHandler={() => showEditModal(automation)}
                image={getCardImage(automation.message_config_type)}
                togglePressHandler={() =>
                  togglePressHandler(false, 'some id or whatever tbd')
                }
                showEditPen={true}
                showToggle={false}
              />
            ))}
          </View>
        </View>
      ))}
      {automationItem && automationItem.message && (
        <EditMessageConfigModal
          ref={sheetRef}
          defaultMessage={automationItem.message}
          defaultSubject={
            AutomationConfig[automationItem.message_config_type].subject
          }
          editableColumns={automationItem.editable_columns}
          hasPreSelectedItems={true}
          title={getText('configure-label-secure', {
            label: AutomationConfig[automationItem.message_config_type].title,
          })}
          onSubmit={onEditCopyMessage}
        />
      )}
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(4),
    paddingRight: theme.getSpacing(2),
  },
  titleContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(4),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    marginRight: theme.getSpacing(2),
  },
  title: {
    ...theme.lumistryFonts.text.xLarge.bold,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  automationsContainer: {
    // display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tabHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    marginBottom: theme.getSpacing(4),
  },
  headerText: {
    ...theme.fonts.medium,
    fontSize: 26,
    marginRight: theme.getSpacing(4),
  },
}));
