import { ClosureHoursDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { convertClosuresDateToString } from '../../../../common/datetime-utils';

export const AdditionalClosuresItemRenderer: FunctionComponent<
  PropsWithChildren<ClosureHoursDto>
> = ({ name, timeRange }) => {
  const styles = useStyles();

  return (
    <>
      <View style={styles.viewStyle}>
        <View style={styles.textView}>
          <Text style={styles.textStyle}>{name}</Text>
        </View>
        <View style={styles.weekViewStyle}>
          <Text style={styles.value}>{`${convertClosuresDateToString(
            timeRange,
          )} `}</Text>
        </View>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  textView: {
    flex: 1,
    flexDirection: 'row',
  },
  buttonView: {
    width: 60,
    flexDirection: 'row',
  },
  textStyle: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  weekViewStyle: {
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 4,
  },
  dayTextStyle: {
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  dayViewStyle: {
    flexDirection: 'row',
    width: '100%',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
