import { View, TouchableOpacity } from 'react-native';
import { FunctionComponent, useMemo } from 'react';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { EmailIcon, EmailOpenedIcon } from 'assets/icons';
import { ChatBox, ChatBoxProps } from './ChatBox';
import theme, { makeStyles } from 'assets/theme';
import { User } from 'react-native-gifted-chat';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  formatDate,
} from '../../../common/datetime-utils';
import { IMessageExtended } from 'assets/types/messageTypes';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

const SPECIAL_CHARACTERS_REG_EXP =
  /\s|'|"|`|~|{|}|\[|\]|\\|,|:|\$|&|\+|;|=|\?|@|#|<|>|\.|\^|\*|\(|\)|%|!|-|\|/gi;

export const ConversationBox: FunctionComponent<ConversationBoxProps> = ({
  messages,
  user,
  isOpen,
  subject,
  isViewed,
  lastMessageTime,
  onHeaderClick,
  onMarkChat,
  ...restProps
}) => {
  const styles = useStyles();

  const sortedMessages = useMemo(() => {
    return messages.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });
  }, [messages]);

  const lastMessageTimeFormat = formatDate(
    lastMessageTime,
    DEFAULT_TIME_FORMAT,
  );

  const lastMessageDateFormat = formatDate(
    lastMessageTime,
    DEFAULT_DATE_FORMAT,
  );

  const markReadKey = `mark-read-${subject.replaceAll(
    SPECIAL_CHARACTERS_REG_EXP,
    '-',
  )}-${isViewed}-${isOpen}`;
  const markUnreadKey = `mark-unread-${subject.replaceAll(
    SPECIAL_CHARACTERS_REG_EXP,
    '-',
  )}-${isViewed}-${isOpen}`;

  const runTooltips = () => {
    return (
      <>
        <View key={markReadKey}>
          <Tooltip text={getText('mark-as-unread')} anchorId={markReadKey} />
        </View>
        <View key={markUnreadKey}>
          <Tooltip text={getText('mark-as-read')} anchorId={markUnreadKey} />
        </View>
      </>
    );
  };

  const header = (
    <TouchableOpacity onPress={onHeaderClick}>
      <View style={styles.header}>
        <View style={styles.flexRow}>
          {!isViewed ? <View style={styles.unreadCircle}></View> : <></>}
          <Text selectable style={!isViewed && styles.unread}>
            {subject}
          </Text>
        </View>
        <View style={styles.statusWrapper}>
          {isOpen ? (
            <TouchableOpacity
              onPress={() => onMarkChat(false)}
              style={styles.middleStatus}
            >
              <View style={[styles.flexRow, styles.markAsUnreadWrapper]}>
                <EmailIcon color={theme.palette.primary[600]} size={14} />
                <Text
                  style={[styles.unreadMessage, !isViewed && styles.unread]}
                >
                  {getText('mark-as-unread')}
                </Text>
              </View>
            </TouchableOpacity>
          ) : null}

          {isOpen ? (
            <Text style={!isViewed && styles.unread}>
              {lastMessageTimeFormat}
            </Text>
          ) : (
            <View style={styles.flexRow}>
              <Text style={[styles.statusTime, !isViewed && styles.unread]}>
                {lastMessageTimeFormat} &nbsp; {lastMessageDateFormat}
              </Text>
              {isViewed ? (
                <TouchableOpacity onPress={() => onMarkChat(false)}>
                  <View nativeID={markReadKey}>
                    <EmailIcon color={theme.palette.gray[500]} size={14} />
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={() => onMarkChat(true)}>
                  <View nativeID={markUnreadKey}>
                    <EmailOpenedIcon
                      color={theme.palette.gray[500]}
                      size={14}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );

  if (isOpen) {
    return (
      <View style={styles.wrapper}>
        {runTooltips()}
        {header}
        <ChatBox messages={sortedMessages} user={user} {...restProps} />
      </View>
    );
  } else {
    return (
      <View style={styles.headerWrapper}>
        {runTooltips()}
        {header}
      </View>
    );
  }
};

interface ConversationBoxProps extends ChatBoxProps {
  messages: IMessageExtended[];
  user: User;
  isOpen: boolean;
  subject: string;
  isViewed: boolean;
  lastMessageTime: string;
  conversationId: string;
  onHeaderClick: () => void;
  onMarkChat: (isChatRead: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 4,
    shadowOffsetWidth: 1,
    shadowOffsetHeight: 1,
    minHeight: 535,
    shadowRadius: 10,
    shadowOpacity: 0.2,
  },
  headerWrapper: {
    borderWidth: 1,
    borderColor: theme.palette.gray[200],
    borderRadius: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(1),
  },
  header: {
    ...theme.fonts.regular,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: theme.getSpacing(2),
    height: 40,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    color: theme.palette.gray[900],
  },
  statusWrapper: {
    flexDirection: 'row',
  },
  statusTime: {
    marginHorizontal: theme.getSpacing(2),
    color: theme.palette.gray[700],
  },
  unread: {
    fontWeight: 'bold',
  },
  middleStatus: {
    marginRight: theme.getSpacing(1),
  },
  markAsUnreadWrapper: {
    marginRight: theme.getSpacing(1),
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  unreadCircle: {
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    backgroundColor: theme.palette.primary[600],
    marginRight: theme.getSpacing(1),
  },
  unreadMessage: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.primary[600],
    fontFamily: 'Lato_400Regular',
  },
}));
