import { AutoMessageConfigDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import UnifiedCommsService from '../../../../api/UnifiedCommsService';
import { useAutoMessageState } from './auto-messages-store';

export const getAutoMessage = async (locationId: string) => {
  useAutoMessageState.setState({
    status: 'loading',
  });

  const response = await UnifiedCommsService.autoMessageConfigGet(locationId);
  const automationsData: AutoMessageConfigDto[] = response;
  const automations: Record<string, AutoMessageConfigDto> = {};
  automationsData.forEach((automation: AutoMessageConfigDto) => {
    automations[automation.message_config_type] = automation;
  });

  useAutoMessageState.setState({
    status: 'success',
    automations: automations,
  });
};
