import { MultiColorIconProps } from 'assets/icons/types';
import { useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export const UnreadMessagesEmptyStateIcon: FunctionComponent<
  MultiColorIconProps
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 61 63"
      fill="none"
      testID={testID}
    >
      <Path
        fill={color ?? theme.palette.primary[600]}
        d="M18.66 17.704a1 1 0 1 0 0 2h23.684a1 1 0 0 0 0-2H18.66ZM17.66 24.69a1 1 0 0 1 1-1h23.684a1 1 0 0 1 0 2H18.66a1 1 0 0 1-1-1ZM18.66 29.675a1 1 0 1 0 0 2h23.684a1 1 0 0 0 0-2H18.66Z"
      />
      <Path
        fill={colorSecondary ?? theme.palette.gray[700]}
        fillRule="evenodd"
        d="M28.681.855a1.5 1.5 0 0 1 1.838.014l11.825 9.308h7.027a1.5 1.5 0 0 1 1.5 1.5v6.258l8.299 7.598a1.497 1.497 0 0 1 1.46 1.5v33.963a1.5 1.5 0 0 1-1.5 1.5H2.264a1.5 1.5 0 0 1-1.5-1.5V27.033A1.5 1.5 0 0 1 1.816 25.6l8.187-8.424v-5.5a1.5 1.5 0 0 1 1.5-1.5h4.958L28.681.855Zm.896 3.09 7.917 6.232H21.407l8.17-6.232ZM50.87 22.003l5.59 5.118-5.59 4.072v-9.19Zm-40.868 8.81-5.31-3.867 5.31-5.465v9.332Zm3 2.186 12.262 8.93H36.13l11.74-8.551v-20.2H13.004v19.82Zm23.1 11.93h-10.81L6.625 59.496H54.77L36.102 44.93ZM57.63 57.923 39.11 43.47l18.52-13.49v27.943Zm-53.866 0V29.981l18.521 13.49-18.52 14.452Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
