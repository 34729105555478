import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { AlertTriangleIcon, CalendarIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { getNestedObjectValue } from 'assets/utils/common';
import moment from 'moment';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import {
  formatDateTime,
  formatUTCToRelative,
  isDateInCurrentYear,
} from '../common/datetime-utils';
import { zIndexAuto } from '../common/theme';
import { CustomTimeInput } from './CustomTimeInput';
import './react-datepicker-overrides.css';
import { timePickerDefaultValue } from '../../utils';

const CustomDateInput = ({
  value,
  onClick,
  disabled,
  showTimeInput,
  type = 'date-and-time',
}: {
  value: string;
  onClick?(): void;
  disabled: boolean;
  showTimeInput?: boolean;
  type?: DateTimePickerFieldType;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  /* the `value` here is a string in the format of "Feb 2, 2031 9:49 AM" in local time, so we need to convert it to iso string */
  const dateString = moment(value).local().toISOString();
  return (
    <TouchableOpacity onPress={onClick} disabled={disabled}>
      <View
        style={[
          styles.datePickerComboContainer,
          disabled
            ? type === 'date-and-time-combo'
              ? styles.dateTimePickerComboDisabledContainer
              : styles.datePickerComboDisabledContainer
            : null,
        ]}
      >
        {isDateInCurrentYear(dateString) && (
          <Icon
            icon={CalendarIcon}
            size={16}
            color={theme.palette.gray[700]}
          ></Icon>
        )}
        <Text
          style={[
            styles.datePickerComboText,
            disabled ? styles.datePickerComboDisabledText : null,
          ]}
          selectable
        >
          {showTimeInput
            ? formatDateTime(dateString, 'MMM DD, YYYY h:mm a')
            : formatUTCToRelative(dateString, undefined, true)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

/*
Relatively basic controller wrapper for the date time picker ("react-datepicker" library)
Allows us to use this in within form context for the pharmacy app in a reusable manner
 */
export const DateTimePickerField: FunctionComponent<
  PropsWithChildren<DateTimePickerFieldProps>
> = ({
  name,
  placeholder,
  type = 'date-and-time',
  disabled = false,
  rules,
  hideRequiredAsterisk,
  minDate,
  maxDate,
  label,
  timeLabel,
  showTimeInput,
  minTime,
  maxTime,
  open = false,
  isOnDark = false,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Date Time Picker must have a parent form context');
  }

  const { control, formState, getValues, setValue } = formContext;
  const [timePickerKey, setTimePickerKey] = useState<number>(0);
  // getNestedObjectValue is function similar to lodash _get allows to get nested properties by passing
  // a string that looks like 'property.nestedProperty' or get(object, 'path.to.something') it should work
  // even if the name is not of type 'nested.prop' and should handle both cases
  const error = getNestedObjectValue(formState.errors, name);

  useEffect(() => {
    if ((type === 'date-and-time' || type === 'time') && !getValues(name)) {
      setValue(name, timePickerDefaultValue);
    }
  }, [type]);

  const getLabelWithRequired = (label: string): string => {
    const requiredSymbol = rules?.required && !hideRequiredAsterisk ? ' *' : '';
    return label + requiredSymbol;
  };

  const filterTime = (time: Date) => {
    const minTimeLimit = minTime ? new Date(minTime) : new Date(0, 0);
    const maxTimeLimit = new Date(maxTime ? maxTime : new Date().setHours(24));
    const selectedTime = new Date(time);

    return (
      minTimeLimit.getTime() < selectedTime.getTime() &&
      maxTimeLimit.getTime() > selectedTime.getTime()
    );
  };

  return (
    <View style={{ zIndex: zIndexAuto }}>
      <View style={[styles.datePickerContainer, styles.datePickerComboWrapper]}>
        {(type == 'date-and-time' || type == 'date') && (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
              <View style={styles.datePickerFieldContainer}>
                {label && (
                  <Text style={styles.label}>
                    {getLabelWithRequired(label)}
                  </Text>
                )}
                <DatePicker
                  placeholderText={placeholder ? placeholder : 'Date'}
                  selected={field.value ? new Date(field.value) : null}
                  minDate={minDate ? new Date(minDate) : null}
                  maxDate={maxDate ? new Date(maxDate) : null}
                  disabled={disabled}
                  onChange={(selectedDate: Date) => {
                    field.onChange(
                      selectedDate ? selectedDate.toISOString() : null,
                    );
                  }}
                  wrapperClassName={`datepicker--date ${
                    disabled ? 'datepicker--disabled' : ''
                  }`}
                />
              </View>
            )}
          />
        )}

        {(type == 'date-and-time' || type == 'time') && (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
              <View
                style={[
                  styles.datePickerFieldContainer,
                  type === 'date-and-time' &&
                    styles.datePickerFieldContainerSpacing,
                  disabled && styles.datePickerComboDisabledContainer,
                ]}
              >
                {(label || timeLabel) && (
                  <Text style={styles.label}>
                    {getLabelWithRequired(timeLabel ? timeLabel : label!)}
                  </Text>
                )}
                <DatePicker
                  key={timePickerKey}
                  selected={
                    field.value ? new Date(field.value) : timePickerDefaultValue
                  }
                  disabled={disabled}
                  onChange={(selectedDate: Date) => {
                    field.onChange(
                      selectedDate ? selectedDate.toISOString() : null,
                    );
                  }}
                  placeholderText={
                    type == 'time' && placeholder ? placeholder : 'Time'
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  showPopperArrow={false}
                  dateFormat="h:mm aa"
                  customInput={
                    <CustomTimeInput field={field} setKey={setTimePickerKey} />
                  }
                  popperClassName="datepicker--time-popper"
                  wrapperClassName={`datepicker--time ${
                    disabled ? 'datepicker--disabled' : ''
                  }`}
                  filterTime={minTime || maxTime ? filterTime : undefined}
                />
              </View>
            )}
          />
        )}

        {type == 'date-and-time-combo' && (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
              <View style={styles.datePickerFieldContainer}>
                {label && (
                  <Text style={styles.label}>
                    {getLabelWithRequired(label)}
                  </Text>
                )}
                <DatePicker
                  selected={new Date(field.value)}
                  disabled={disabled}
                  customInput={
                    <CustomDateInput
                      value={field.value}
                      disabled={disabled}
                      showTimeInput={showTimeInput}
                      type={type}
                    />
                  }
                  onChange={(selectedDate: Date) => {
                    field.onChange(selectedDate.toISOString());
                  }}
                  minDate={minDate ? new Date(minDate) : null}
                  maxDate={maxDate ? new Date(maxDate) : null}
                  showTimeSelect
                  wrapperClassName="datepicker--date-time-combo"
                  dateFormat="MMM d, yyyy h:mm aa"
                  open={open}
                />
              </View>
            )}
          />
        )}
      </View>

      {rules && error && (
        <>
          <View style={isOnDark ? styles.darkContainer : styles.errorContainer}>
            <View style={styles.icon}>
              <Icon
                icon={AlertTriangleIcon}
                color={theme.palette.error[600]}
                size={16}
              />
            </View>
            <Text
              testID={DateTimePickerFieldTestIDs.error}
              style={styles.errorMessage}
              selectable
            >
              {error.message}
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  errorContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  darkContainer: {
    backgroundColor: theme.palette.error[50],
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(1.5),
    borderRadius: theme.roundness,
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    zIndex: zIndexAuto,
  },
  datePickerComboWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  datePickerComboContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.getSpacing(1),
    justifyContent: 'center',
    gap: theme.getSpacing(1),
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.gray[400],
    borderStyle: 'solid',
    borderRadius: theme.roundness,
  },
  datePickerComboDisabledContainer: {
    borderColor: theme.palette.gray[200],
  },
  dateTimePickerComboDisabledContainer: {
    backgroundColor: theme.palette.gray[50],
    borderColor: theme.palette.gray[400],
  },
  datePickerComboText: {
    display: 'flex',
    flex: 1,
    fontSize: 16,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.gray[700],
  },
  datePickerComboDisabledText: {
    color: theme.colors.disabled,
  },
  datePickerFieldContainer: {
    flex: 1,
    zIndex: zIndexAuto,
  },
  datePickerFieldContainerSpacing: {
    marginLeft: theme.getSpacing(1),
  },
  errorMessage: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.error[600],
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
}));

type DateTimePickerFieldType =
  | 'date'
  | 'time'
  | 'date-and-time'
  | 'date-and-time-combo';

export interface DateTimePickerFieldProps {
  name: string;
  type?: DateTimePickerFieldType;
  disabled?: boolean;
  placeholder?: string;
  rules?: RegisterOptions;
  hideRequiredAsterisk?: boolean;
  relative?: boolean;
  minDate?: string;
  maxDate?: string;
  label?: string;
  timeLabel?: string;
  showTimeInput?: boolean;
  minTime?: string;
  maxTime?: string;
  open?: boolean;
  isOnDark?: boolean;
}

export const DateTimePickerFieldTestIDs = {
  error: 'dateTimePickerField-error-message',
};
