import { getText } from 'assets/localization/localization';
import {
  BulkMessageTime,
  EToALLFilter,
  EToSpecificFilter,
} from '../common/types/MessageForm';
import { BulkMessageType } from '@digitalpharmacist/unified-communications-service-client-axios';

export const timeTextMap = {
  [BulkMessageTime.IMMEDIATE]: getText('immediate'),
  [BulkMessageTime.SCHEDULED]: getText('scheduled'),
};

export const typeTextMap = {
  [BulkMessageType.Secure]: getText('secure-chat'),
  [BulkMessageType.NotSecure]: getText('non-secure'),
};

export const toListRecipientCriteriaTextMap = {
  [EToALLFilter.ALL]: getText('send-to-all'),
};

export const toSpecificRecipientCriteriaTextMap = {
  [EToSpecificFilter.SPECIFIC]: 'dummy text',
};
