import { FC } from 'react';
import { IRendererProps } from '../types';
import { useBulkTableStyles } from '../commonStyles';
import {
  TIME_DATE_FORMAT_WITH_LONGER_SPACE,
  formatDate,
} from '../../../../common/datetime-utils';
import { makeStyles } from 'assets/theme';

export const SendDateRenderer: FC<IRendererProps> = ({ data }) => {
  const bulkTableStyles = useBulkTableStyles();
  const styles = useStyles();
  return (
    <div style={{ ...bulkTableStyles.text, ...styles.sendDateCell }}>
      {formatDate(data.scheduled_time, TIME_DATE_FORMAT_WITH_LONGER_SPACE)}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  sendDateCell: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}));
