import { logError } from 'assets/logging/logger';
import create from 'zustand';
import VideoService, {
  Language,
  VideoModel,
  VideosLocation,
} from '../api/VideoService';

const alphabetChars = Array.from({ length: 26 }, (_, index) =>
  String.fromCharCode(65 + index),
);

export const languageVideoMap: Record<VideosLocation, Language> = {
  'en-US': 'en',
  'es-US': 'es',
};

export interface VideosState {
  search?: string | null | undefined;
  locale: VideosLocation;
  page: number;
  category: number;
  filter?: (typeof alphabetChars)[number] | null;
  totalCount: number;
  videos: VideoModel[];
  bcPlayer?: any;
  showVideoShareModal: boolean;
  videoShortUrl?: string;
  showNewMessageModal: boolean;
  slug: string;
  setSearch: (search: string | null | undefined) => void;
  setLocale: (locale: VideosLocation) => void;
  setPage: (page: number) => void;
  setCategory: (category: number) => void;
  setFilter: (filter: (typeof alphabetChars)[number] | null) => void;
  setTotalCount: (totalCount: number) => void;
  getVideos: () => void;
  setBCPlayer: (bcPlayer: any) => void;
  setShowVideoShareModal: (
    showVideoShareModal: boolean,
    videoShortUrl?: string,
  ) => void;
  setShowNewMessageModal: (showNewMessageModal: boolean) => void;
  setSlug: (slug: string) => void;
}

export const useVideosState = create<VideosState>((set, get) => ({
  locale: 'en-US',
  category: 0,
  page: 1,
  totalCount: 0,
  videos: [],
  showVideoShareModal: false,
  showNewMessageModal: false,
  slug: 'camero',
  setSearch: (search: string | null | undefined) => {
    const { getVideos } = get();
    set((state) => ({ ...state, search, page: 1 }));
    getVideos();
  },
  setLocale: (locale: VideosLocation) => {
    const { getVideos } = get();

    set({ locale });
    getVideos();
  },
  setPage: (page: number) => {
    const { getVideos } = get();
    set({ page });
    getVideos();
  },
  setCategory: (category: number) => {
    const { getVideos } = get();
    set((state) => ({ ...state, category, page: 1 }));
    getVideos();
  },
  setFilter: (filter: (typeof alphabetChars)[number] | null) => {
    const { getVideos } = get();
    set((state) => ({ ...state, filter, page: 1 }));
    getVideos();
  },
  setTotalCount: (totalCount: number) => set({ totalCount }),
  getVideos: () => {
    const { category, locale, page, search, filter, slug } = get();
    void VideoService.getVideosList(
      search,
      locale,
      page,
      category,
      filter,
      slug,
    )
      .then((response) => {
        set({
          videos: response.items
            .map((x) => x[languageVideoMap[locale]])
            .filter((x): x is VideoModel => !!x),
          totalCount: response.total_count,
        });
      })
      .catch((error: Error) => logError(error));
  },
  setBCPlayer: (bcPlayer: any) => set({ bcPlayer }),
  setShowVideoShareModal: (
    showVideoShareModal: boolean,
    videoShortUrl?: string,
  ) => set({ showVideoShareModal, videoShortUrl }),
  setShowNewMessageModal: (showNewMessageModal: boolean) =>
    set({ showNewMessageModal }),
  setSlug: (slug: string) => {
    const { getVideos } = get();
    set((state) => ({ ...state, slug, page: 1 }));
    getVideos();
  },
}));
