import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { Icon } from 'assets/components/icon';
import {
  CheckCircleIcon,
  CreditCardIcon,
  GlobeIcon,
  LockIcon,
  MapIcon,
  MessageSquareIcon,
  PlusIcon,
  RefreshCWIcon,
  SmartphoneIcon,
  UserIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { useTheme } from 'assets/theme';
import { defaultDrawerTheme } from 'assets/theme/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'react-native';
import { canFactory, ResourceGuard } from '../common/guards/permission-guard';
import { EditUserModal } from '../screens/settings/Users/EditUserModal';
import Users from '../screens/settings/Users/Users';
import { AppSettings } from '../screens/settings/app-settings/AppSettings';
import {
  LocationsDetailParamsProp,
  LocationsSettingsDrawer,
} from '../screens/settings/location/LocationsSettingsDrawer';
import MessageSettings from '../screens/settings/messages/MessageSettings';
import { PharmacySettings } from '../screens/settings/pharmacy/PharmacySettings';
import PMSIntegrationSettings from '../screens/settings/pms_integrations/PMSIntegrationSettings';
import { ProfileSettings } from '../screens/settings/profile/ProfileSettings';
import { RefillsSettings } from '../screens/settings/refills/RefillsSettings';
import TaskSettings from '../screens/settings/tasks/TaskSettings';
import { useAppStateStore } from '../store/app-store';
import BillingSettings from '../screens/settings/billing/BillingSettings';
import InternalUse from '../screens/settings/internal-use/InternalUse';

const DrawerNavigator =
  createDrawerNavigator<SettingsDrawerNavigationParamList>();

export const SettingsDrawer: FunctionComponent<
  PropsWithChildren<SettingsDrawerProps>
> = () => {
  const canReadSettings = canFactory('read')('settings');
  const canReadIntegration = canFactory('read')('settingsPmsIntegration');
  const { isMultiLocation, pharmacyName } = useAppStateStore((state) => ({
    isMultiLocation: state.userMostRecentLocations.length > 1,
    pharmacyName: state.pharmacyName,
  }));

  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          borderColor: 'red',
          borderRadius: 10,
        },
        drawerStyle: defaultDrawerTheme,
      }}
      initialRouteName="users-list"
      drawerContent={CustomDrawerContent}
    >
      <DrawerNavigator.Screen
        name="profile"
        options={{
          title: `${getText('settings')} - ${getText('profile-settings')}`,
          drawerLabel: getText('profile-settings'),
          drawerIcon: ({ color }) => (
            <Icon icon={UserIcon} color={color} size={20} />
          ),
        }}
        component={ProfileSettings}
      />
      {canReadSettings && (
        <>
          <DrawerNavigator.Screen
            name="pharmacy"
            options={{
              title: `${getText('settings')} - ${pharmacyName}`,
              drawerLabel: pharmacyName,
              drawerIcon: ({ color }) => (
                <Icon icon={GlobeIcon} color={color} size={20} />
              ),
            }}
            component={PharmacySettings}
          />

          <DrawerNavigator.Screen
            name="app-settings"
            options={{
              title: `${getText('settings')} - ${getText('app-settings')}`,
              drawerLabel: getText('app-settings'),
              drawerIcon: ({ color }) => (
                <Icon icon={SmartphoneIcon} color={color} size={20} />
              ),
            }}
            component={AppSettings}
          />
          <DrawerNavigator.Screen
            name="locations"
            options={{
              drawerLabel: isMultiLocation
                ? getText('locations-all')
                : getText('location'),
              drawerIcon: ({ color }) => (
                <Icon icon={MapIcon} color={color} size={20} />
              ),
            }}
            component={LocationsSettingsDrawer}
          />
          <DrawerNavigator.Screen
            name="users-list"
            options={{
              title: `${getText('settings')} - ${getText('users')}`,
              drawerLabel: getText('users'),
              drawerIcon: ({ color }) => (
                <Icon icon={UserIcon} color={color} size={20} />
              ),
            }}
            component={Users}
          />
          <DrawerNavigator.Screen
            name="billing"
            options={{
              title: `${getText('settings')} - ${getText('billing')}`,
              drawerLabel: getText('billing'),
              drawerIcon: ({ color }) => (
                <Icon icon={CreditCardIcon} color={color} size={20} />
              ),
            }}
            component={BillingSettings}
          />
          <DrawerNavigator.Screen
            name="task-settings"
            options={{
              title: `${getText('settings')} - ${getText('tasks')}`,
              drawerLabel: getText('tasks'),
              drawerIcon: ({ color }) => (
                <Icon icon={CheckCircleIcon} color={color} size={20} />
              ),
            }}
            component={TaskSettings}
          />
          <DrawerNavigator.Screen
            name="refills"
            options={{
              title: `${getText('settings')} - ${getText('refills')}`,
              drawerLabel: getText('refills'),
              drawerIcon: ({ color }) => (
                <Icon icon={RefreshCWIcon} color={color} size={20} />
              ),
            }}
            component={RefillsSettings}
          />
          {canReadIntegration && (
            <DrawerNavigator.Screen
              name="pms-settings"
              options={{
                title: `${getText('settings')} - ${getText('integration')}`,
                drawerLabel: getText('integration'),
                drawerIcon: ({ color }) => (
                  <Icon icon={PlusIcon} color={color} size={20} />
                ),
              }}
              component={PMSIntegrationSettings}
            />
          )}
          <DrawerNavigator.Screen
            name="message-settings"
            options={{
              title: `${getText('settings')} - ${getText('messages')}`,
              drawerLabel: getText('messages'),
              drawerIcon: ({ color }) => (
                <Icon icon={MessageSquareIcon} color={color} size={20} />
              ),
            }}
            component={MessageSettings}
          />
          <DrawerNavigator.Screen
            name="edit-user"
            options={{
              title: getText('edit-user'),
              drawerItemStyle: { display: 'none' },
            }}
            component={EditUserModal}
          />
          {canFactory('read')('settingsLumistryUser') && (
            <DrawerNavigator.Screen
              name="internal-use"
              options={{
                title: `${getText('settings')} - ${getText('internal-use')}`,
                drawerLabel: getText('internal-use'),
                drawerIcon: ({ color, size }) => (
                  <Icon icon={LockIcon} color={color} size={size} />
                ),
              }}
              component={InternalUse}
            />
          )}
        </>
      )}
    </DrawerNavigator.Navigator>
  );
};

interface SettingsDrawerProps {}

export type SettingsDrawerNavigationParamList = {
  profile: undefined;
  'users-list': undefined;
  'edit-user': {
    userId: string;
  };
  'task-settings': undefined;
  'app-settings': undefined;
  'pms-settings': undefined;
  pharmacy: undefined;
  locations: undefined;
  'message-settings': undefined;
  location: LocationsDetailParamsProp;
  refills: undefined;
  billing: undefined;
  'internal-use': undefined;
};

export type SettingsDrawerNavigationProp =
  DrawerNavigationProp<SettingsDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  const theme = useTheme();
  return (
    <>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ paddingTop: 0 }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: '700',
            padding: theme.getSpacing(2),
          }}
        >
          {getText('settings')}
        </Text>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </>
  );
}
