import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useLayoutEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
// Styling overrides
import './form-builder.css';
import EditFormElement, { EditFormElementProps } from './EditFormElement';
import { useFormBuilderState } from './form-builder-state';
import { changeEditModeStatus } from './form-builder-actions';
import { toolbox } from './toolbox';
import { useEditFormStore } from '../../screens/forms/edit-form-store';
import {
  generateFormQuestions,
  setEditedFormBuilder,
  setSaveButtonEnabled,
} from '../../screens/forms/edit-form-actions';
import { generateFormBuilderData } from './form-builder-utils';
import { getText } from 'assets/localization/localization';
import { Alert } from 'assets/components/alert';

export const FormBuilder: FunctionComponent<
  PropsWithChildren<FormBuilderProps>
> = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { editModeStatus } = useFormBuilderState();
  const { form, editedFormBuilder } = useEditFormStore();
  const [editFormElementProps, setEditFormElementProps] =
    useState<EditFormElementProps>();

  // The form builder library has problems with dropping new items into the form
  // (sometimes it removes the last element), so we are disabling the drag and drop
  // functionality for the toolbar items. It's still possible to add them by click
  // and rearrange after that.
  useLayoutEffect(() => {
    const toolbarItems = document.querySelectorAll(
      '.react-form-builder-toolbar li[draggable="true"]',
    );

    toolbarItems.forEach((element) => {
      element.removeAttribute('draggable');
    });
  }, []);

  return (
    <View style={styles.wrapper}>
      {form && form.submissionCount > 0 && (
        <Alert
          intent="warning"
          title={getText('form-edit-warning-title')}
          description={getText('form-edit-warning')}
          style={styles.formEditWarning}
        />
      )}

      {editModeStatus && editFormElementProps ? (
        <EditFormElement {...editFormElementProps}></EditFormElement>
      ) : null}

      {form && (
        <div className="form-builder-wrapper" style={styles.formBuilderWrapper}>
          <ReactFormBuilder
            //@ts-ignore The ReactFormBuilder library has incorrect interface, the data property exists so we need to ignore the linter error
            data={generateFormBuilderData(
              editedFormBuilder
                ? generateFormQuestions(
                    form.questions,
                    editedFormBuilder.task_data,
                  )
                : form.questions,
            )}
            onPost={(data) => {
              setSaveButtonEnabled(true);
              setEditedFormBuilder(data);
            }}
            saveAlways={true}
            toolbarItems={toolbox}
            editMode={false}
            renderEditForm={(props) => {
              if (!editModeStatus) {
                const editProps: EditFormElementProps =
                  props as unknown as EditFormElementProps;
                changeEditModeStatus(true);
                setEditFormElementProps(editProps);
                if (editProps.manualEditModeOff) {
                  editProps.manualEditModeOff();
                }
              }

              return null;
            }}
          />
        </div>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    marginTop: theme.getSpacing(2),
  },
  formBuilderWrapper: {
    height: '100%',
  },
  formEditWarning: {
    marginBottom: theme.getSpacing(2),
  },
}));

export interface FormBuilderProps {}

export default FormBuilder;
