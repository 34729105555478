import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { setOpenedModal } from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { ChecklistModalProps } from '../../types';

export const WebsiteAccessPermissionModal: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const styles = useStyles();

  const methods = useForm<any>({
    //to do
    defaultValues: {},
  });

  return (
    <Modal
      title={getText('website-access')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal(undefined)}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => console.log('next'),
          logger: { id: 'next-website-access-permission' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal(undefined),
          logger: { id: 'back-website-access-permission' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('publish-website-need-permission')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              label={getText('give-lumistry-permission')}
              name="test" // to do
              mode={CheckboxInputMode.FLAT}
              testID="give-lumistry-permission"
              rules={{
                required: getText('field-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={styles.divider} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('please-provide-domain-details')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('domain-name')}
              name="name" // to do
              rules={{
                required: getText('field-required'),
              }}
              testID="domain-name"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('domain-host')}
              name="host" // to do
              rules={{
                required: getText('field-required'),
              }}
              testID="domain-host"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('login-user-name')}
              name="username" // to do
              rules={{
                required: getText('field-required'),
              }}
              testID="login-user-name"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('login-password')}
              name="password" // to do
              rules={{
                required: getText('field-required'),
              }}
              testID="login-password"
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  divider: {
    borderBottomColor: theme.palette.gray[100],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(1),
  },
}));
