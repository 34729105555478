import React, {
  FunctionComponent,
  useCallback,
  PropsWithChildren,
} from 'react';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import {
  useRoute,
  getFocusedRouteNameFromRoute,
  useFocusEffect,
} from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { AutoIcon, GridIcon, ListIcon } from 'assets/icons';
import { useTasksFiltersState } from '../tasks/tasks-filters/tasks-filters-store';
import { resetFilters } from '../tasks/tasks-filters/tasks-filters-actions';
import Tasks from '../screens/tasks/Tasks';
import { Tabs, TabsProps } from '../components/tabs/Tabs';
import NewTaskModal from '../tasks/task-modal/TaskModal';
import { TasksFilters } from '../tasks/tasks-filters/TasksFilters';
import { TasksGrid } from '../tasks/tasks-grid/TasksGrid';
import {
  TASKS_BASE_FILTERS,
  TASKS_MULTI_FILTERS,
  GRID_BASE_FILTERS,
  GRID_MULTI_FILTERS,
} from '../tasks/tasks-filters/task-filters.utils';
import { getText } from 'assets/localization/localization';
import { defaultDrawerTheme } from 'assets/theme/theme';
import Automations from '../screens/automations/Automations';
import { AutomationDrawerButton } from '../tasks/automation/AutomationDrawerButton';
import ChecklistCard from '../components/checklist/checklist-card/checklist-card';
import { setOpenedModal } from '../actions/checklist-actions';

const DrawerNavigator = createDrawerNavigator<TasksDrawerNavigationParamList>();

export const TasksDrawer: FunctionComponent<
  PropsWithChildren<TasksDrawerProps>
> = (props) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
        drawerStyle: defaultDrawerTheme,
      }}
      initialRouteName="list"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen
        name="list"
        component={Tasks}
        options={{ title: getText('tasks-list-tab') }}
      />
      <DrawerNavigator.Screen
        name="grid"
        component={TasksGrid}
        options={{ title: getText('tasks-grid-tab') }}
      />
      <DrawerNavigator.Screen
        name="auto"
        component={Automations}
        options={{ title: getText('tasks-grid-automation') }}
      />
    </DrawerNavigator.Navigator>
  );
};

interface TasksDrawerProps {}

export type TasksDrawerNavigationParamList = {
  list: ListScreenParamsProps | undefined;
  grid: undefined;
  auto: undefined;
};

export type TasksDrawerNavigationProp =
  DrawerNavigationProp<TasksDrawerNavigationParamList>;

export type ListScreenProps = StackScreenProps<
  TasksDrawerNavigationParamList,
  'list'
>;

export type ListScreenParamsProps = {
  newTaskModal: 'open';
  tasksFilter: 'my-tasks';
  taskId: string;
};

const tasksTabs: TabsProps['tabs'] = [
  {
    title: getText('list'),
    id: 'list',
    children: (
      <>
        <NewTaskModal />
        <TasksFilters
          baseFilters={TASKS_BASE_FILTERS}
          multiFilters={TASKS_MULTI_FILTERS}
        />
      </>
    ),
    icon: ListIcon,
  },
  {
    title: getText('grid'),
    id: 'grid',
    children: (
      <>
        <NewTaskModal />
        <TasksFilters
          baseFilters={GRID_BASE_FILTERS}
          multiFilters={GRID_MULTI_FILTERS}
        />
      </>
    ),
    icon: GridIcon,
  },
  {
    title: getText('auto'),
    id: 'auto',
    children: (
      <>
        <NewTaskModal />
        <AutomationDrawerButton active={true} />
      </>
    ),
    icon: AutoIcon,
  },
];

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);

  // Setting the flag only one time in case the application is open directly onto the Task Grid page,
  // keeping the flag updated is handled for tab changes below
  useFocusEffect(
    useCallback(
      () => useTasksFiltersState.setState({ isGrid: activeRoute === 'grid' }),
      [],
    ),
  );

  useFocusEffect(
    useCallback(() => {
      handleTabChange('list');
    }, []),
  );

  const handleTabChange = (activeTab: string) => {
    resetFilters(activeTab);

    props.navigation.navigate(
      activeTab as keyof TasksDrawerNavigationParamList,
    );
  };

  return (
    <>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ paddingTop: 0 }}
      >
        <Tabs
          tabs={tasksTabs}
          onTabChange={handleTabChange}
          defaultTab={activeRoute ? activeRoute : 'list'}
        />
        <ChecklistCard
          hierarchy="secondary"
          onPress={() => setOpenedModal('checklist-setup')}
        />
      </DrawerContentScrollView>
    </>
  );
};
