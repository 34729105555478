import { Fragment, FunctionComponent, ReactNode } from 'react';
import { User } from 'react-native-gifted-chat';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { makeStyles } from 'assets/theme';
import VerificationAlert from './VerificationAlert';
import { ConversationBox } from './ConversationBox';
import { View } from 'react-native';
import {
  IMessageExtended,
  LoadMoreOptions,
  TypedMessage,
  UserTyping,
} from 'assets/types/messageTypes';
import { getDynamicHeightForMessages } from 'assets/utils/messageUtils';

export const PatientWithConversationsView: FunctionComponent<
  PatientWithConversationsViewProps
> = ({
  selectedPatient,
  selectedPatientConversations,
  user,
  selectedConversationId,
  selectedMessages,
  typingMember,
  viewedConversationsSet,
  messagesCount,
  verifyPatient,
  onType,
  onSend,
  onSelectConversation,
  onMarkChat,
  loadMore,
  renderLoadMore,
}) => {
  const styles = useStyles();

  return (
    <>
      <VerificationAlert
        selectedPatient={selectedPatient}
        verifyPatient={verifyPatient}
      />
      <View style={styles.conversationsBlock}>
        {selectedPatientConversations.map((conversation) => (
          <Fragment key={conversation.conversation_id}>
            <ConversationBox
              user={user}
              messages={selectedMessages}
              typingMember={typingMember}
              onTyping={() => onType(conversation.conversation_id)}
              isOpen={conversation.conversation_id === selectedConversationId}
              subject={conversation.subject}
              onSendMessage={onSend}
              isViewed={viewedConversationsSet.has(
                conversation.conversation_id,
              )}
              lastMessageTime={conversation.most_recent_qualifying_message_date}
              onHeaderClick={() => void onSelectConversation(conversation)}
              onMarkChat={(isChatRead: boolean) =>
                void onMarkChat(
                  conversation.location_patient_id,
                  conversation.conversation_id,
                  conversation.patient_viewed_all_messages,
                  isChatRead,
                )
              }
              messagesCount={messagesCount}
              conversationId={conversation.conversation_id}
              loadMore={loadMore}
              renderLoadEarlier={renderLoadMore}
            />
          </Fragment>
        ))}
      </View>
    </>
  );
};

interface PatientWithConversationsViewProps {
  selectedPatient: LocationPatientRecordDto;
  selectedPatientConversations: DirectMessagePatientDto[];
  user: User;
  selectedConversationId?: string;
  selectedMessages: IMessageExtended[];
  typingMember: UserTyping | null;
  viewedConversationsSet: Set<string>;
  messagesCount: number;
  verifyPatient: () => Promise<void>;
  onType: (conversationId: string) => void;
  onSend: (typedMessage: TypedMessage) => Promise<void>;
  onSelectConversation: (
    conversation: DirectMessagePatientDto,
  ) => Promise<void>;
  onMarkChat: (
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    isChatRead: boolean,
  ) => Promise<void>;
  loadMore: (args: LoadMoreOptions) => Promise<void>;
  renderLoadMore: () => ReactNode;
}

const useStyles = makeStyles(() => ({
  conversationsBlock: {
    width: '100%',
    height: getDynamicHeightForMessages(620),
    overflow: 'scroll',
  },
}));
