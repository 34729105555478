import React, { FC, FunctionComponent, PropsWithChildren, useRef } from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { TouchableOpacity, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { uuid } from 'assets/utils/uuid';
import {
  SettingsIcon,
  MenuIcon,
  LumistryLogo,
  ExternalLinkIcon,
  UserIcon,
  LockIcon,
} from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { Avatar } from 'assets/components/avatar';
import { NavigationItems } from '../pharmacy-header/NavigationItems';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { logout } from '../../screens/login/login-actions';
import { MenuOptionType, MenuWrapper } from '../pharmacy-header/MenuWrapper';
import { PharmacyStoreSelector } from '../select-store/PharmacyStoreSelector';
import { openUrl } from '../../actions/app-actions';
import { HELP_JUICE_URL } from '../../common/constants';
import { ResourceGuard } from '../../common/guards/permission-guard';
import * as Linking from 'expo-linking';
import { handleError } from '../../common/errors/commonErrorHandler';
import UsersService from '../../api/UsersService';

export interface OnboardingHeaderProps {
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
  email?: string;
  onSaveAndLogout: () => void;
}

export const OnboardingHeader: FunctionComponent<OnboardingHeaderProps> = ({
  gradientId = uuid(),
  email,
  onSaveAndLogout,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  // TODO: verify with product how to persist this number configuration
  const lumistryPhone = '(888) 699-9803';

  const handleNeedHelpPress = async () => {
    if (!email) {
      // Send them to Helpjuice, but they are not logged in;
      return openUrl(HELP_JUICE_URL);
    }

    let helpjuicePath = (await UsersService.getSignedHelpjuiceLink(email))
      .redirect_url;

    if (helpjuicePath === undefined) {
      handleError({
        showToast: true,
        userMessage: `Could not get a helpjuice link. email used: ${email}`,
      });
      helpjuicePath = HELP_JUICE_URL;
    }

    openUrl(helpjuicePath, '_blank');
  };

  const handleOnPhoneNumberPress = () => {
    Linking.openURL(`tel:${lumistryPhone}`);
  };

  return (
    <GradientView
      contentHeight={72}
      fillGradientStops={[
        { stopColor: theme.colors.brandedPrimary, offset: 0 },
        { stopColor: theme.colors.brandedPrimary, offset: 100 },
      ]}
      gradientId={gradientIdRef.current}
    >
      <View style={styles.headerContainer}>
        <View style={styles.leftContent}>
          <LumistryLogo />
        </View>
        <View style={styles.rightContent}>
          <View style={styles.helpContainer}>
            <TouchableOpacity
              style={styles.pressableContainer}
              onPress={handleNeedHelpPress}
            >
              <Text
                style={{
                  color: theme.palette.white,
                }}
              >
                {getText('need-help')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.pressableContainer}
              onPress={handleOnPhoneNumberPress}
            >
              <Text
                style={{
                  color: theme.palette.white,
                }}
              >
                {lumistryPhone}
              </Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={styles.pressableContainer}
            onPress={onSaveAndLogout}
          >
            <LockIcon size={15} color={theme.palette.white} />
            <Text
              style={{
                color: theme.palette.white,
              }}
            >
              {getText('save-logout')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </GradientView>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    height: '100%',
    flexDirection: 'row',
    boxShadow: 'inset 0px 2px 8px rgba(0, 0, 0, 0.45)',
  },
  leftContent: {
    flex: 2,
    alignSelf: 'center',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(4),
    flexDirection: 'row',
  },
  rightContent: {
    marginLeft: 'auto',
    alignSelf: 'center',
    alignItems: 'flex-end',
    paddingRight: theme.getSpacing(4),
    gap: theme.getSpacing(3),
    flexDirection: 'row',
  },
  helpContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
  },
  pressableContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
  },
}));

export const OnboardingHeaderTestIDs = {};
