import {
  HolidayDto,
  UpdateHolidaysDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { useRoute } from '@react-navigation/native';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { LocationsDrawerRouteProp } from '../LocationsSettingsDrawer';
import {
  setShowModalLocationHolidayClosures,
  updateLocationHolidays,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

export const LocationHolidayClosuresModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const route = useRoute<LocationsDrawerRouteProp>();
  const locationId = route.params?.locationId;

  const { showModalLocationHolidayClosures, locationHolidays } =
    useLocationSettingsState();
  const styles = useStyles();
  const [loadingMethod, setLoadingMethod] = useState(false);

  const methods = useForm<HolidayClosuresFormProps>({
    defaultValues: {
      defaultHolidays: locationHolidays.use_default_holidays,
      holidays: locationHolidays.holidays,
    },
    mode: 'onChange',
  });

  const { control } = methods;
  const { fields } = useFieldArray({
    control,
    name: 'holidays',
  });
  const defaultHolidays = methods.watch('defaultHolidays');

  useEffect(() => {
    methods.reset({
      defaultHolidays: locationHolidays.use_default_holidays,
      holidays: locationHolidays.holidays,
    });
  }, [locationHolidays]);

  const handleSubmit = async () => {
    setLoadingMethod(true);
    const formValue = methods.getValues();
    const toggleHolidays: UpdateHolidaysDto = {
      use_default_holidays: formValue.defaultHolidays,
      holidays: formValue.defaultHolidays
        ? []
        : formValue.holidays.map((holiday) => {
            return {
              id: holiday.id,
              enabled: holiday.enabled,
              next_occurrence: holiday.next_occurrence,
            };
          }),
    };
    await updateLocationHolidays(locationId || '', toggleHolidays);
    setShowModalLocationHolidayClosures(false);
    setLoadingMethod(false);
  };

  const closeModal = () => {
    setShowModalLocationHolidayClosures(false);
  };

  return (
    <Modal
      title={getText('locations-holiday-closures')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'locations-holiday-closures-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'locations-holiday-closures-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'locations-holiday-closures-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
        loading: loadingMethod,
      }}
      show={showModalLocationHolidayClosures}
      isScrollable={true}
      size="lg"
    >
      <Form methods={methods}>
        <Form.Row>
          <CheckboxField
            label={getText('locations-use-default-settings')}
            name="defaultHolidays"
            mode={CheckboxInputMode.FLAT}
          />
        </Form.Row>
        <View style={styles.separator}></View>
        <Form.Row>
          <Form.Column>
            {fields
              .slice(0, Math.ceil(fields.length / 2))
              .map((field, index) => (
                <View style={styles.viewStyle} key={+index}>
                  <CheckboxField
                    key={`${field.id}.enabled`}
                    name={`holidays.${index}.enabled`}
                    label={field.name}
                    mode={CheckboxInputMode.FLAT}
                    disabled={defaultHolidays}
                  />
                </View>
              ))}
          </Form.Column>
          <Form.Column>
            {fields.slice(Math.ceil(fields.length / 2)).map((field, index) => (
              <View style={styles.viewStyle} key={+index}>
                <CheckboxField
                  key={`${field.id}.enabled`}
                  name={`holidays.${
                    index + Math.ceil(fields.length / 2)
                  }.enabled`}
                  label={field.name}
                  mode={CheckboxInputMode.FLAT}
                  disabled={defaultHolidays}
                />
              </View>
            ))}
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface HolidayClosuresFormProps {
  defaultHolidays: boolean;
  holidays: HolidayDto[];
}

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    marginBottom: theme.getSpacing(2),
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
    marginBottom: theme.getSpacing(4),
    paddingTop: theme.getSpacing(2),
  },
}));
