import { RowClickedEvent } from '@ag-grid-community/core';
import { useNavigation } from '@react-navigation/native';
import { DataGrid } from 'assets/components/data-grid/DataGrid';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { Icon } from 'assets/components/icon';
import { ShareIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { VideoModel } from '../../api/VideoService';
import {
  VideosDrawerNavigationParamList,
  VideosDrawerNavigationProp,
} from '../../layout/VideosDrawer';
import { useVideosState } from '../../store/videos-store';

const VideosListCollection: FunctionComponent = () => {
  const { videos, locale } = useVideosState();
  const styles = useStyles();

  const navigation = useNavigation<VideosDrawerNavigationProp>();

  const handleEnRowClickEvent = (event: RowClickedEvent) => {
    const row: VideoModel = event.data as VideoModel;
    navigation.navigate('detail', {
      video_id: row.id,
      collection_page: 'list' as keyof VideosDrawerNavigationParamList,
      locale: locale,
    });
  };

  return (
    <View style={styles.viewStyle}>
      <DataGrid
        gridOptions={{
          rowData: videos,
          animateRows: true,
          columnDefs: [
            {
              field: 'category.label',
              headerName: getText('videos-category'),
              sortable: false,
              maxWidth: 150,
              minWidth: 150,
            },
            {
              field: 'name',
              headerName: getText('videos-title'),
              sortable: false,
              flex: 1,
              onCellClicked: handleEnRowClickEvent,
            },
            {
              field: 'short_url',
              headerName: '',
              cellRenderer: ({ value }: { value: string }) => (
                <ShareButtonRenderer short_url={value} />
              ),
              maxWidth: 125,
              minWidth: 125,
              suppressMenu: true,
              suppressColumnsToolPanel: true,
              suppressFiltersToolPanel: true,
              sortable: false,
            },
          ],

          defaultColDef: {
            sortable: false,
          },
        }}
      />
    </View>
  );
};

const ShareButtonRenderer: FunctionComponent<ShareButtonRendererProps> = ({
  short_url,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { setShowVideoShareModal } = useVideosState();
  return (
    <View>
      <View style={styles.rowView}>
        <TouchableOpacity
          style={styles.rowView}
          onPress={() => setShowVideoShareModal(true, short_url)}
        >
          <Icon icon={ShareIcon} color={theme.palette.gray[500]} size={16} />
          <Text style={styles.shareText}>{getText('videos-share')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

interface ShareButtonRendererProps {
  short_url: string;
}

export default VideosListCollection;

const useStyles = makeStyles((theme) => ({
  rowView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: getSpacing(1),
  },
  shareText: {
    marginLeft: getSpacing(1),
    fontSize: 14,
    color: theme.palette.gray[700],
    fontWeight: '400',
    lineHeight: 16,
  },
  viewStyle: {
    flex: 1,
    paddingLeft: theme.getSpacing(3),
    paddingRight: theme.getSpacing(3),
  },
}));
