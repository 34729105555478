import { getText } from 'assets/localization/localization';
import { PatientWebsiteOption } from './types';

export const ChecklistSetupTitle: Record<string, string> = {
  'basic-setup': getText('basic-setup'),
  'media-settings': getText('media-settings'),
  'patient-agreements': getText('patient-agreements'),
  'patient-website': getText('patient-website'),
  'patient-app': getText('patient-app'),
  'scanner-config': getText('scanner-config'),
  'clinical-services': getText('clinical-services'),
  'refill-settings': getText('refill-settings'),
  'message-settings': getText('message-settings'),
};

export const ChecklistSetupDesc: Record<string, string> = {
  'basic-setup': getText('basic-setup-desc'),
  'media-settings': getText('media-settings-desc'),
  'patient-agreements': getText('patient-agreements-desc'),
  'patient-website': getText('patient-website-desc'),
  'patient-app': getText('patient-app-desc'),
  'scanner-config': getText('scanner-config-desc'),
  'clinical-services': getText('clinical-services-desc'),
  'refill-settings': getText('refill-settings-desc'),
  'message-settings': getText('message-settings-desc'),
};

export const SpecsList: string[] = [
  getText('specs-first-item'),
  getText('specs-second-item'),
  getText('specs-third-item'),
];

export const PatientWebsiteOptions: PatientWebsiteOption[] = [
  {
    label: getText('build-replace-website'),
    type: 'build-replace-website',
  },
  {
    label: getText('build-new-website'),
    type: 'build-new-website',
  },
  {
    label: getText('no-build-existing-website'),
    type: 'no-build-existing-website',
  },
  {
    label: getText('no-build-no-existing-website'),
    type: 'no-build-no-existing-website',
  },
];
