import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button } from '../../../../../../packages/assets/components/button';
import { IconButton } from '../../../../../../packages/assets/components/icon-button';
import { Text } from '../../../../../../packages/assets/components/text';
import {
  ChevronLeftIcon,
  TrashIcon,
} from '../../../../../../packages/assets/icons';
import { makeStyles } from '../../../../../../packages/assets/theme';
import { ScheduleDrawerNavigationProp } from '../../../layout/ScheduleDrawer';
import { ServiceForm } from '../../../schedule/service-form/ServiceForm';
import {
  deleteAppointmentType,
  submitForm,
} from '../../../schedule/service-form/service-form-actions';
import PharmacyConfirmationModal from '../../../components/PharmacyConfirmationModal';
import { useServiceFormState } from '../../../schedule/service-form/service-form-store';
import { getText } from 'assets/localization/localization';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { PharmacyScreenContainer } from 'assets/layout';
import { PharmacyTooltipWrapper } from '../../../common/PharmacyTooltipWrapper';

export const Service: FunctionComponent<ServiceProps> = (props) => {
  const styles = useStyles();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const route = useRoute<any>();
  const serviceId = route.params?.serviceId;
  const edit = !!serviceId;
  const [showModal, setShowModal] = useState(false);
  const { status, appointmentType } = useServiceFormState();

  const handleDeleteClick = async () => {
    setShowModal(false);
    await deleteAppointmentType(serviceId);
    navigation.navigate('services');
  };

  const deleteButton = (
    <Button
      hierarchy="tertiary-gray"
      style={{
        borderColor: 'transparent',
      }}
      mode="outlined"
      size="small"
      logger={{ id: 'delete-service-button' }}
      icon={TrashIcon}
      loading={status === 'loading'}
      disabled={
        status === 'loading' ||
        !appointmentType ||
        appointmentType?.has_bookings
      }
      onPress={() => setShowModal(true)}
    >
      {getText('delete')}
    </Button>
  );

  return (
    <PharmacyScreenContainer style={styles.container}>
      <PharmacyConfirmationModal
        show={showModal}
        onAccepted={() => handleDeleteClick()}
        onDismiss={() => setShowModal(false)}
        message={getText('confirm-delete-service-implicit')}
      />
      <View style={styles.header}>
        <IconButton
          size={25}
          logger={{ id: 'go-back-service-form-button' }}
          icon={ChevronLeftIcon}
          onPress={() => navigation.navigate('services')}
          style={styles.backButton}
        />
        <Text style={styles.title} selectable>
          {getText('services')}
        </Text>
        {edit && (
          <>
            {appointmentType?.has_bookings ? (
              <>
                <PharmacyTooltipWrapper tooltipId="delete-button-disabled">
                  {deleteButton}
                </PharmacyTooltipWrapper>
                <Tooltip
                  text={getText('appointments-on-service')}
                  id="delete-button-disabled"
                />
              </>
            ) : (
              deleteButton
            )}
          </>
        )}
        <Button
          hierarchy="primary"
          size="small"
          logger={{ id: 'save-service-button' }}
          onPress={() => submitForm()}
          loading={status === 'loading'}
          disabled={status === 'loading'}
        >
          {getText('save')}
        </Button>
      </View>
      <View>
        <ServiceForm />
      </View>
    </PharmacyScreenContainer>
  );
};

export interface ServiceProps {}

const useStyles = makeStyles((theme) => ({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(4),
    gap: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(3),
  },
  title: {
    fontSize: 25,
    flexGrow: 1,
  },
  backButton: {
    margin: 0,
    marginLeft: -10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(4),
  },
}));
