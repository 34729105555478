import { useCallback, useEffect } from 'react';
import { Text, View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import AutomationModal from '../../tasks/automation/automation-modal/AutomationModal';
import { useAutomationsState } from '../../tasks/automation/automations-store';
import {
  getAutomations,
  updateAutomation,
} from '../../tasks/automation/automations-actions';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import {
  AutomationDescriptionLocalization,
  AutomationTitleLocalization,
} from '../../tasks/automation/utils';
import AutomationItem from '../messages/auto/components/AutomationItem';
import { AutomationIllustrationType } from '@digitalpharmacist/tasks-service-client-axios';
import PatientRecordUpdateImage from '../../tasks/automation/PatientRecordUpdateImage';
import FailedRefillsImage from '../../tasks/automation/FailedRefillsImage';
import PaperlessPatientImage from '../../tasks/automation/PaperlessPatientImage';
import { updateShowModalState } from '../../tasks/automation/automation-modal/automation-modal-actions';

const Automations = () => {
  const styles = useStyles();
  const { automations, status } = useAutomationsState();

  useEffect(() => {
    void getAutomations();
  }, []);

  const togglePressHandler = useCallback(
    async (value: boolean, automationId: string) => {
      await updateAutomation(automationId, {
        enabled: !value,
      });
    },
    [],
  );

  const onPressHandler = useCallback((automationId: string) => {
    updateShowModalState(true, automationId);
  }, []);

  const displayAutomations = useCallback(() => {
    if (!Object.keys(automations).length) {
      return <></>;
    }

    return Object.keys(automations).map((key) => {
      return (
        <AutomationItem
          key={key}
          automation={automations[key]}
          isLoading={status === 'loading'}
          title={AutomationTitleLocalization[automations[key].type]}
          description={AutomationDescriptionLocalization[automations[key].type]}
          id={automations[key].id}
          isEditable={automations[key].enabled}
          configuration={automations[key].configuration} //unsure how to proceed with these
          onPressHandler={() => onPressHandler(automations[key].id)}
          togglePressHandler={() =>
            togglePressHandler(automations[key].enabled, automations[key].id)
          }
          image={getCardImage(automations[key].illustration_name)}
          showEditPen={false}
          showToggle={true}
        />
      );
    });
  }, [automations]);

  const getCardImage = (illustration_name: string): JSX.Element => {
    let jsxElement = <></>;

    switch (illustration_name) {
      case AutomationIllustrationType.PatientRecordUpdate:
        jsxElement = <PatientRecordUpdateImage />;
        break;

      case AutomationIllustrationType.FailedRefills:
        jsxElement = <FailedRefillsImage />;
        break;

      case AutomationIllustrationType.AllergyUpdates:
        jsxElement = <PatientRecordUpdateImage />;
        break;

      case AutomationIllustrationType.PaperlessPatient:
        jsxElement = <PaperlessPatientImage />;
        break;

      case AutomationIllustrationType.RxTransferRequest:
        jsxElement = <PaperlessPatientImage />;
        break;

      default:
        break;
    }

    return jsxElement;
  };

  return (
    <View style={styles.container}>
      {status === 'loading' && <LoadingOverlay />}
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{getText('automated-tasks')}</Text>
      </View>
      <View style={styles.automationsContainer}>{displayAutomations()}</View>
      <AutomationModal />
      {Object.keys(automations).map((key) => {
        return (
          <Tooltip
            id={`automation-title-${automations[key].id}`}
            text={AutomationTitleLocalization[automations[key].type]}
          />
        );
      })}
      {Object.keys(automations).map((key) => {
        return (
          <Tooltip
            id={`automation-description-${automations[key].id}`}
            text={AutomationDescriptionLocalization[automations[key].type]}
          />
        );
      })}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(4),
    paddingRight: theme.getSpacing(2),
  },
  titleContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(4),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    marginRight: theme.getSpacing(2),
  },
  title: {
    ...theme.lumistryFonts.text.xLarge.bold,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  automationsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export default Automations;
