import {
  InfoCardDto,
  InfoCardIcon,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { useRoute } from '@react-navigation/native';
import { Modal } from 'assets/components/modal';
import BaseRadioButton from 'assets/components/radio-button-group/BaseRadioButton';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import {
  AppHeartIcon,
  BowlIcon,
  BoxIcon,
  CalendarTwoColorsIcon,
  ClipboardTwoColorsIcon,
  CovidIcon,
  CovidShotIcon,
  NurseDeskIcon,
  NurseIcon,
  PillsIcon,
  ShoppingBagWithGreenTagIcon,
  ShotIcon,
  TrashWithStripesIcon,
} from 'assets/icons';
import { MultiColorIconProps } from 'assets/icons/types';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, {
  FC,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { validateOptionalURL } from '../../../../../utils';
import { LocationsDrawerRouteProp } from '../LocationsSettingsDrawer';
import {
  infoCardsCreate,
  infoCardsRemove,
  infoCardsUpdate,
  removeSelectedInfoCard,
  setShowModalLocationInfoCards,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

const CARD_ICON_MAP: {
  [key in InfoCardIcon]: FunctionComponent<
    PropsWithChildren<MultiColorIconProps>
  >;
} = {
  bowl: BowlIcon,
  pills: PillsIcon,
  bag: ShoppingBagWithGreenTagIcon,
  virus: CovidIcon,
  virus_shot: CovidShotIcon,
  shot: ShotIcon,
  nurse: NurseIcon,
  nurse_desk: NurseDeskIcon,
  app_heart: AppHeartIcon,
  clipboard: ClipboardTwoColorsIcon,
  calendar: CalendarTwoColorsIcon,
  box: BoxIcon,
};
const keys = Object.keys(CARD_ICON_MAP) as InfoCardIcon[];
const halfIndex = Math.ceil(keys.length / 2);
const firstHalfKeys = keys.slice(0, halfIndex);
const secondHalfKeys = keys.slice(halfIndex);

const emptyFormValue: InfoCardFormProps = {
  active: true,
  title: '',
  summary: '',
  link_url: '',
  link_label: '',
  icon: keys[0],
};

const convertDtoToFormProp = (infoCard: InfoCardDto): InfoCardFormProps => {
  return {
    active: infoCard.active,
    title: infoCard.title,
    summary: infoCard.summary,
    link_url: infoCard.link_url || '',
    link_label: infoCard.link_label || '',
    icon: infoCard.icon,
  };
};

export const LocationInfoCardModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const route = useRoute<LocationsDrawerRouteProp>();
  const locationId = route.params?.locationId;

  const { showModalLocationInfoCards, infoCard } = useLocationSettingsState();
  const [toggle, setToggle] = useState<boolean>();
  const [selectedIcon, setSelectedIcon] = useState<InfoCardIcon>(
    infoCard?.icon || keys[0],
  );
  const styles = useStyles();
  const theme = useTheme();
  const [loadingMethod, setLoadingMethod] = useState(false);
  const [loadingDeleteMethod, setLoadingDeleteMethod] = useState(false);

  const methods = useForm<InfoCardFormProps>({
    defaultValues: infoCard ? convertDtoToFormProp(infoCard) : emptyFormValue,
    mode: 'onSubmit',
  });
  const isNewCard = !infoCard;

  useEffect(() => {
    methods.reset(infoCard ? convertDtoToFormProp(infoCard) : emptyFormValue);
    setToggle(Boolean(infoCard?.active));
    setSelectedIcon(infoCard?.icon || keys[0]);
  }, [infoCard, showModalLocationInfoCards]);

  const handleSubmit = async () => {
    setLoadingMethod(true);
    const formValue = methods.getValues();
    if (
      locationId &&
      infoCard &&
      !_isEqual(formValue, convertDtoToFormProp(infoCard))
    ) {
      await infoCardsUpdate(locationId, infoCard.id, formValue);
    } else if (locationId) {
      await infoCardsCreate(locationId, formValue);
    }
    setLoadingMethod(false);
    closeModal();
  };

  const closeModal = () => {
    setShowModalLocationInfoCards(false);
    removeSelectedInfoCard();
  };

  const toggleActive = () => {
    const active = methods.getValues().active;
    setToggle(!active);
    methods.setValue('active', !active);
  };

  const handleChange = (newIconSlug: InfoCardIcon) => {
    methods.setValue('icon', newIconSlug);
    setSelectedIcon(newIconSlug);
  };

  const ReturnIcon: FC<{ slug: InfoCardIcon }> = ({ slug }) => {
    const IconComponent = CARD_ICON_MAP[slug];

    return <IconComponent size={70} colorSecondary={theme.palette.gray[500]} />;
  };

  const onDeleteCard = () => {
    if (!locationId || !infoCard) {
      return;
    }
    setLoadingDeleteMethod(true);
    infoCardsRemove(locationId, infoCard.id).finally(() => {
      setLoadingDeleteMethod(false);
      closeModal();
    });
  };

  return (
    <Modal
      isScrollable
      title={infoCard ? getText('edit-info-card') : getText('new-info-card')}
      titleSize="sm"
      size="sm"
      show={showModalLocationInfoCards}
      handleDismissModal={closeModal}
      buttons={[
        {
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'edit-info-card-form-cancel-button-modal' },
          hierarchy: 'primary',
          testID: 'edit-info-card-form-cancel-button-modal',
          text: getText('ok'),
          loading: loadingMethod,
        },
        {
          onPress: closeModal,
          logger: { id: 'edit-info-card-form-ok-button-modal' },
          hierarchy: 'tertiary-gray',
          testID: 'edit-info-card-form-ok-button-modal',
          text: getText('cancel'),
        },
      ]}
      leftAlignedButton={
        !isNewCard
          ? {
              onPress: onDeleteCard,
              logger: { id: 'edit-info-card-form-delete-button-modal' },
              hierarchy: 'tertiary-gray',
              testID: 'edit-info-card-form-delete-button-modal',
              text: getText('delete'),
              icon: TrashWithStripesIcon,
              iconPosition: 'left',
              loading: loadingDeleteMethod,
            }
          : undefined
      }
    >
      <Form methods={methods}>
        <Form.Row>
          <View style={styles.enableViewStyle}>
            <ToggleSwitch
              logger={{
                id: `locations-info-card-${infoCard?.id || 'new'}`,
              }}
              value={toggle}
              onPress={() => toggleActive()}
            />
            <View style={styles.enableTextStyle}>
              <Text>{toggle ? getText('enable') : getText('disable')}</Text>
            </View>
          </View>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              name="title"
              type="text"
              label={getText('title')}
              maxLength={80}
              rules={{
                required: getText('title-is-required'),
              }}
              hintMessage={getText('max-char-allowed-message', { maxChar: 80 })}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              name="summary"
              type="text"
              label={getText('summary')}
              multiline
              numberOfLines={2}
              maxLength={100}
              rules={{
                required: getText('summary-is-required'),
              }}
              hintMessage={getText('max-char-allowed-message', {
                maxChar: 100,
              })}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              name="link_url"
              type="text"
              label={getText('link-url')}
              rules={{
                validate: validateOptionalURL,
                required: methods.watch('link_label')
                  ? getText('label-and-url-both-required')
                  : false,
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              label={getText('link-label')}
              name="link_label"
              type="text"
              rules={{
                required: methods.watch('link_url')
                  ? getText('label-and-url-both-required')
                  : false,
              }}
              hintMessage={getText('one-or-two-words-are-suggested')}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <RadioButton.Group
              onValueChange={(newIconSlug) =>
                handleChange(newIconSlug as InfoCardIcon)
              }
              value={selectedIcon}
            >
              <View style={styles.row}>
                {firstHalfKeys.map((iconSlug) => (
                  <View style={styles.item} key={`first-row-${iconSlug}`}>
                    <Pressable
                      style={styles.pressableStyle}
                      onPress={() => handleChange(iconSlug)}
                    >
                      <BaseRadioButton selected={iconSlug === selectedIcon} />
                      <ReturnIcon slug={iconSlug} />
                    </Pressable>
                  </View>
                ))}
              </View>
              <View style={styles.row}>
                {secondHalfKeys.map((iconSlug) => (
                  <View style={styles.item} key={`second-row-${iconSlug}`}>
                    <Pressable
                      style={styles.pressableStyle}
                      onPress={() => handleChange(iconSlug)}
                    >
                      <BaseRadioButton selected={iconSlug === selectedIcon} />
                      <ReturnIcon slug={iconSlug} />
                    </Pressable>
                  </View>
                ))}
              </View>
            </RadioButton.Group>
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface InfoCardFormProps {
  active: boolean;
  title: string;
  summary: string;
  link_url: string;
  link_label: string;
  icon: InfoCardIcon;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    width: 250,
  },
  enableTextStyle: {
    marginLeft: theme.getSpacing(2),
  },
  enableViewStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.getSpacing(3),
  },
  item: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.getSpacing(3),
  },
  pressableStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
}));
