import { PencilIcon } from 'assets/icons/PencilIcon';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { FunctionComponent, useEffect } from 'react';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useAppStateStore } from '../../../store/app-store';
import { setPharmacyId } from '../pharmacy/pharmacy-settings-actions';
import {
  getBranding,
  setPharmacyApp,
  setPharmacyWebsite,
  setShowModalApp,
  setShowModalBrandingInfo,
  setShowModalWebsite,
} from './app-settings-actions';
import { useAppSettingsState } from './app-settings-store';
import { AppItemRenderer } from './components/AppItemRenderer';
import { AppModal } from './components/AppModal';
import { BrandingInfoItemRenderer } from './components/BrandingInfoItemRenderer';
import { BrandingModal } from './components/BrandingModal';
import { WebsiteItemRenderer } from './components/WebsiteItemRenderer';
import { WebsiteModal } from './components/WebsiteModal';
import { canFactory } from '../../../common/guards/permission-guard';

export const AppSettings: FunctionComponent = () => {
  const { pharmacyId } = useAppStateStore();
  const { branded_colors, pharmacyApp, pharmacyWebsite } =
    useAppSettingsState();
  const canUpdateSettings = canFactory('update')('settings');
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );

  useEffect(() => {
    setPharmacyId(pharmacyId)
      .then(() => getBranding())
      .then(() => setPharmacyApp(pharmacyId))
      .then(() => setPharmacyWebsite(pharmacyId))
      .catch((error: Error) => logError(error));
  }, [pharmacyId]);
  return (
    <SettingsPageLayout title={getText('app-settings')}>
      {pharmacyApp && (
        <SettingsSection
          title={getText('app')}
          contentHeader={{
            title: getText('details'),
            button: canUpdateSettings
              ? {
                  title: getText('edit'),
                  icon: PencilIcon,
                  onPress: () => setShowModalApp(true),
                }
              : undefined,
          }}
          contentItem={AppItemRenderer}
          customTypeModal={AppModal}
          contentData={[pharmacyApp]}
        />
      )}

      {pharmacyWebsite && (
        <SettingsSection
          title={getText('website')}
          contentHeader={{
            title: getText('details'),
            button: canUpdateSettings
              ? {
                  title: getText('edit'),
                  icon: PencilIcon,
                  onPress: () => setShowModalWebsite(true),
                }
              : undefined,
          }}
          contentItem={WebsiteItemRenderer}
          customTypeModal={WebsiteModal}
          contentData={[pharmacyWebsite]}
        />
      )}

      <SettingsSection
        title={getText('branding')}
        contentHeader={{
          title: getText('pharmacy-general-details'),
          button: canLumistryUserUpdateSettings
            ? {
                title: getText('edit'),
                icon: PencilIcon,
                onPress: () => setShowModalBrandingInfo(true),
              }
            : undefined,
        }}
        contentItem={BrandingInfoItemRenderer}
        customTypeModal={BrandingModal}
        contentData={[branded_colors]}
      />
    </SettingsPageLayout>
  );
};
