import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { View } from 'react-native';

import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { Alert } from 'assets/components/alert/Alert';
import {
  CheckboxInput,
  CheckboxInputMode,
} from 'assets/components/checkbox/CheckboxInput';

import { useFormsImportStepperState } from '../forms-import-stepper-store';
import { getUserForms } from '../forms-import-stepper-actions';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { getText } from 'assets/localization/localization';

export const FormsImportStep: FunctionComponent = () => {
  const styles = useStyles();
  const { steps, status, error } = useFormsImportStepperState();
  const defaultValues = steps.formsImport.userForms;
  const selectedForms = defaultValues?.filter((form) => form.checked);

  useEffect(() => {
    getUserForms();
  }, []);

  if (status === 'error' && error) {
    return (
      <View style={styles.minHeight}>
        <Alert
          title={getText('error')}
          intent="error"
          description={error.message}
          key={error.message}
        />
      </View>
    );
  }

  if (status === 'loading') {
    return (
      <View style={styles.minHeight}>
        <LoadingOverlay />
      </View>
    );
  }

  const handleChange = (id: string, checked: boolean) => {
    const updatedValue = defaultValues?.map((form) =>
      form.id === id ? { ...form, checked } : form,
    );

    useFormsImportStepperState.setState((prevState) => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        formsImport: {
          ...prevState.steps.formsImport,
          userForms: updatedValue,
          invalid: !updatedValue?.filter((form) => form.checked).length,
        },
      },
    }));
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{getText('import-forms')}</Text>
      {selectedForms && selectedForms.length > 0 && (
        <Text style={styles.contentTitle}>
          {getText('number-of-forms')} {selectedForms.length}
        </Text>
      )}
      <View style={styles.multiContainer}>
        {defaultValues &&
          defaultValues.length > 0 &&
          defaultValues.map((value) => (
            <CheckboxInput
              label={value.title}
              checked={value.checked}
              key={value.id}
              onPress={(checked) => handleChange(value.id, checked)}
              mode={CheckboxInputMode.OUTLINE}
            />
          ))}
      </View>
    </View>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
  },
  title: {
    ...theme.lumistryFonts.text.xLarge.bold,
    paddingBottom: theme.getSpacing(2),
  },
  contentTitle: {
    ...theme.lumistryFonts.label.small.semiBold,
    paddingBottom: theme.getSpacing(2),
  },
  multiContainer: {
    flexDirection: 'column',
    display: 'flex',
    gap: theme.getSpacing(2),
    maxHeight: 280,
    overflowY: 'scroll',
    padding: theme.getSpacing(2),
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
  },
  minHeight: {
    minHeight: 150,
  },
}));
export default FormsImportStep;
