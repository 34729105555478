import { LoadingIndicator } from 'assets/components/loading-indicator';
import { PencilIcon, PlusInCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useAppStateStore } from '../../../store/app-store';
import { AdditionalClosuresItemRenderer } from './components/AdditionalClosuresItemRenderer';
import { AdditionalClosuresModal } from './components/AdditionalClosuresModal';
import { DepartmentHoursItemRenderer } from './components/DepartmentHoursItemRenderer';
import { DepartmentHoursModal } from './components/DepartmentHoursModal';
import { GeneralInfoItemRenderer } from './components/GeneralInfoItemRenderer';
import { GeneralInfoModal } from './components/GeneralInfoModal';
import { HolidayClosuresItemRenderer } from './components/HolidayClosuresItemRenderer';
import { HolidayClosuresModal } from './components/HolidayClosuresModal';
import {
  getClosures,
  getDepartments,
  getDepartmentsHours,
  getHolidays,
  setParentCompanies,
  setPharmacyId,
  setShowModalAdditionalClosures,
  setShowModalDepartmentHours,
  setShowModalGeneralInfo,
  setShowModalHolidayClosures,
} from './pharmacy-settings-actions';
import { usePharmacySettingsState } from './pharmacy-settings-store';

export const PharmacySettings: FunctionComponent = () => {
  const { pharmacy, departmentsHours, holidays, closures } =
    usePharmacySettingsState();
  const { pharmacyId, pharmacyName } = useAppStateStore((x) => ({
    pharmacyId: x.pharmacyId,
    pharmacyName: x.pharmacyName,
  }));

  useEffect(() => {
    setPharmacyId(pharmacyId)
      .then(() => getDepartments())
      .then(() => {
        getHolidays();
        getClosures();
      })
      .then(() => getDepartmentsHours())
      .then(() => setParentCompanies())
      .catch((error: Error) => logError(error));
  }, [pharmacyId]);

  return pharmacy ? (
    <SettingsPageLayout title={pharmacyName}>
      <SettingsSection
        title={getText('business-info')}
        contentHeader={{
          title: getText('pharmacy-general-details'),
          button: {
            title: getText('edit'),
            icon: PencilIcon,
            onPress: () => setShowModalGeneralInfo(true),
          },
        }}
        contentData={[pharmacy]}
        contentItem={GeneralInfoItemRenderer}
        customTypeModal={GeneralInfoModal}
      />
      <SettingsSection
        title={getText('pharmacy-departments-hours')}
        subtitle={getText('pharmacy-departments-hours-subtitle')}
        contentHeader={{
          title: getText('pharmacy-names-details'),
          button: {
            title: getText('new'),
            icon: PlusInCircleIcon,
            onPress: () => setShowModalDepartmentHours(true),
          },
        }}
        contentData={departmentsHours}
        contentItem={DepartmentHoursItemRenderer}
        customTypeModal={DepartmentHoursModal}
      />
      <SettingsSection
        title={getText('pharmacy-holiday-closures')}
        subtitle={getText('pharmacy-holiday-closures-description')}
        contentHeader={{
          title: getText('pharmacy-names-details'),
          button: {
            title: getText('edit'),
            icon: PlusInCircleIcon,
            onPress: () => setShowModalHolidayClosures(true),
          },
        }}
        contentData={holidays.filter((holiday) => holiday.enabled)}
        contentItem={HolidayClosuresItemRenderer}
        customTypeModal={HolidayClosuresModal}
      />
      <SettingsSection
        title={getText('pharmacy-additional-closures')}
        subtitle={getText('pharmacy-additional-closures-description')}
        contentHeader={{
          title: getText('pharmacy-names-details'),
          button: {
            title: getText('edit'),
            icon: PlusInCircleIcon,
            onPress: () => setShowModalAdditionalClosures(true),
          },
        }}
        contentData={closures.filter((closures) => closures.enabled)}
        contentItem={AdditionalClosuresItemRenderer}
        customTypeModal={AdditionalClosuresModal}
      />
    </SettingsPageLayout>
  ) : (
    <View style={{ marginTop: '25%' }}>
      <LoadingIndicator />
    </View>
  );
};
