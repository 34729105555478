import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { User } from 'react-native-gifted-chat';
import { makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import {
  IDatasource,
  IGetRowsParams,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { zIndexAuto } from '../../../../common/theme';
import { getText } from 'assets/localization/localization';
import { DataGrid } from 'assets/components/data-grid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Avatar } from 'assets/components/avatar';
import { BulkEmptyState } from './BulkEmptyState';
import { useAppStateStore } from '../../../../store/app-store';
import unifiedCommsService from '../../../../api/UnifiedCommsService';
import patientService from '../../../../api/PatientService';
import {
  setBulks,
  setBulksTotalCount,
  setInitialFilters,
  setIsNewBulkChatModalOpen,
} from '../../stores/bulk-messages-store/bulk-messages-actions';
import { useBulkMessagesStore } from '../../stores/bulk-messages-store/bulk-messages-store';
import usersService from '../../../../../modules/api/UsersService';
import { useSocketsForBulkMessages } from '../../hooks/useSocketsForBulkMessages';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../../../common/constants';
import { IBulkMessage, StatusFilter } from 'assets/types/messageTypes';
import { useUserState } from '../../../../store/user-store';
import { NewBulkMessageModal } from './NewBulkMessageModal';
import { convertToBulkMessageType } from 'assets/utils/bulkMessagesUtils';
import { useToast } from '../../../../common/hooks/useToast';
import { ReviewBulkMessageModal } from './ReviewBulkMessageModal';
import {
  convertPillToCurlyBraces,
  stripRichTextEditorElements,
} from 'assets/utils/messageUtils';
import {
  BulkMessageForm,
  BulkMessageTime,
} from '../../common/types/MessageForm';
import { useFocusEffect } from '@react-navigation/native';
import { LoadingOverlay } from '../../../../components/LoadingOverlay';
import {
  AuthorType,
  ScheduleType,
  BulkStatus,
  BulkMessageType,
  BulkDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { getMessageAuthor } from '../../components/get-message-author';
import { SYSTEM_GENERATED_ID } from '../../data';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import {
  buildSort,
  getAgeFilter,
  getGenderFilter,
  getPostalCodeFilter,
} from '../utils';
import { loadBillingData } from '../../../settings/billing/billing-settings-actions';
import { Alert } from 'assets/components/alert';
import { useBillingOptionsSettingsState } from '../../../settings/billing/billing-settings-store';
import { bulksTableColumns } from '../data';
import { NoRowsRenderer } from './NoRowsRenderer';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { useNavigation, ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LocationPatientRecordSearchDto } from '@digitalpharmacist/patient-service-client-axios';
import { BulkRecipientsListModal } from './BulkRecipientsListModal';
import { GridSearch } from '../../../patients/patient-types';
import { GLOBAL_SEARCH_FIELD } from 'assets/components/data-grid/constant';
import {
  DEFAULT_DATE_FORMAT,
  formatDate,
  SHORT_DATE_FORMAT,
} from '../../../../common/datetime-utils';

export default function Bulk() {
  const styles = useStyles();
  const theme = useTheme();
  const { smsUsageData, emailUsageData } = useBillingOptionsSettingsState();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const [isBulkComponentVisible, setIsBulkComponentVisible] = useState(false);
  const locationId = useAppStateStore((state) => state.locationId);
  const bulks = useBulkMessagesStore((state) => state.bulks);
  const bulksTotalCount = useBulkMessagesStore(
    (state) => state.bulksTotalCount,
  );
  const message_type = useBulkMessagesStore(
    (state) => state.filters.message_type,
  );
  const status = useBulkMessagesStore((state) => state.filters.status);
  const isNewBulkMessageModalOpen = useBulkMessagesStore(
    (state) => state.isNewBulkMessageModalOpen,
  );
  const [gridApi, setGridApi] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOpenReviewBulkModal, setIsOpenReviewBulkModal] =
    useState<boolean>(false);
  const [isOpenRecipientsListModal, setIsOpenRecipientsListModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bulkData, setBulkData] = useState<BulkMessageForm | undefined>();
  const [initialRecipientsCount, setInitialRecipientsCount] =
    useState<number>(0);
  const [excludedRecipientIds, setExcludedRecipientIds] = useState<string[]>(
    [],
  );
  const [savedExcludedRecipientIds, setSavedExcludedRecipientIds] = useState<
    string[]
  >([]);
  const [specificRecipientIds, setSpecificRecipientIds] = useState<string[]>(
    [],
  );
  const [recipientsGridApi, setRecipientsGridApi] = useState<GridApi>();
  const [isNoRecipientsAlertShown, setIsNoRecipientsAlertShown] =
    useState<boolean>(false);
  const userId = useUserState((x) => x.data?.id);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const { toast } = useToast();

  useSocketsForBulkMessages();

  const hasReachedCap = (usageData: any) =>
    usageData?.quantity >= usageData?.quantity_cap;

  const emailMessageError = emailUsageData && hasReachedCap(emailUsageData);
  const smsMessageError = smsUsageData && hasReachedCap(smsUsageData);

  const automatedActionAuthorPromise: Promise<User> = useMemo(async () => {
    return await getMessageAuthor(
      SYSTEM_GENERATED_ID,
      AuthorType.SystemGenerated,
    );
  }, []);

  const recipientsDataSource: IDatasource = useMemo(() => {
    if (!bulkData) {
      return {
        getRows: async (params: IGetRowsParams) => {
          const { successCallback } = params;
          successCallback([], 0);
        },
      };
    }

    return {
      getRows: async (params: IGetRowsParams) => {
        try {
          recipientsGridApi?.showLoadingOverlay();
          const { filterModel, sortModel, startRow, endRow, successCallback } =
            params;
          const gridSearch: GridSearch | undefined =
            filterModel && filterModel[GLOBAL_SEARCH_FIELD];

          const { filters: postalCodeFilters } = getPostalCodeFilter(bulkData);
          const recipientsResponse = await patientService.getPatientSearch(
            locationId,
            {
              age_filters: getAgeFilter(bulkData),
              postal_code_filters:
                postalCodeFilters.length > 0 ? postalCodeFilters : undefined,
              gender: getGenderFilter(bulkData),
              query: gridSearch?.filter,
              from: startRow,
              size: endRow,
              excluded_ids: [
                ...excludedRecipientIds,
                ...savedExcludedRecipientIds,
              ],
              specific_ids: specificRecipientIds,
              sort: buildSort(sortModel),
            },
          );

          successCallback(recipientsResponse.items, recipientsResponse.count);
          recipientsGridApi?.hideOverlay();
        } catch (error: any) {
          recipientsGridApi?.hideOverlay();
          console.error('Error recipients rows', error);
        }
      },
    };
  }, [
    locationId,
    bulkData,
    excludedRecipientIds,
    savedExcludedRecipientIds,
    specificRecipientIds,
    recipientsGridApi,
  ]);

  const recipientsCount = useMemo(() => {
    return initialRecipientsCount - savedExcludedRecipientIds.length;
  }, [initialRecipientsCount, savedExcludedRecipientIds]);

  useFocusEffect(
    useCallback(() => {
      setInitialFilters();
      setIsBulkComponentVisible(true);
      return () => {
        setSearchValue('');
        setIsBulkComponentVisible(false);
      };
    }, []),
  );

  useEffect(() => {
    void (async () => {
      const count = await unifiedCommsService.getBulksCount(locationId);
      loadBillingData(locationId);

      setBulksTotalCount(count);
    })();
  }, [locationId]);

  const getBulks: () => Promise<BulkDto[]> = useCallback(async () => {
    let status_query: BulkStatus | undefined = undefined;

    if (status !== StatusFilter.All) {
      switch (status) {
        case StatusFilter.Sent:
          status_query = BulkStatus.Sent;
          break;

        case StatusFilter.Scheduled:
          status_query = BulkStatus.Scheduled;
          break;

        case StatusFilter.Failed:
          status_query = BulkStatus.Failed;
          break;

        case StatusFilter.Canceled:
          status_query = BulkStatus.Canceled;
          break;
      }
    }

    let message_type_query: BulkMessageType | undefined = undefined;

    if (!message_type.secure || !message_type.notSecure) {
      if (message_type.secure) {
        message_type_query = BulkMessageType.Secure;
      } else if (message_type.notSecure) {
        message_type_query = BulkMessageType.NotSecure;
      }
    }

    return unifiedCommsService.getBulks(
      locationId,
      status_query,
      message_type_query,
    );
  }, [locationId, status, message_type]);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const bulks: BulkDto[] = await getBulks();
      const pharmacyUserIdsSet = new Set(
        bulks
          .filter((bulk) => bulk.author_type === AuthorType.Pharmacy)
          .map((bulk) => bulk.author_id),
      );
      const automatedActionAuthor = await automatedActionAuthorPromise;
      const pharmacistUsers = await usersService.getPharmacistUsersByIds([
        ...pharmacyUserIdsSet,
      ]);
      const authorsMapById: Record<string, User> = {
        SYSTEM_GENERATED_ID: automatedActionAuthor,
      };

      pharmacistUsers.forEach((pharmacist) => {
        if (pharmacist.id) {
          authorsMapById[pharmacist.id] = {
            _id: pharmacist.id,
            name: `${pharmacist.firstName} ${pharmacist.lastName}`,
            avatar: () => (
              <Avatar
                size={32}
                firstName={pharmacist.firstName}
                lastName={pharmacist.lastName}
              />
            ),
          };
        }
      });

      const bulksWithAuthor = bulks.map((bulk) => {
        return convertToBulkMessageType(bulk, authorsMapById[bulk.author_id]);
      });

      setBulks(bulksWithAuthor);
      setLoading(false);
    })();
  }, [locationId, getBulks]);

  useEffect(() => {
    return () => {
      gridApi?.destroy();
      recipientsGridApi?.destroy();
      setGridApi(null);
    };
  }, []);

  function onReviewBulkMessageBack() {
    setIsOpenReviewBulkModal(false);
    setIsNewBulkChatModalOpen(true);
    resetSavedExcludedRecipients();
    resetExcludedRecipients();
  }

  function onReviewBulkMessageCancel() {
    setIsOpenReviewBulkModal(false);
    setBulkData(undefined);
    resetSavedExcludedRecipients();
    resetExcludedRecipients();
  }

  async function onBulkMessageDraftCreate(data: BulkMessageForm) {
    let newRecipientsCount = 0;
    const specificIds =
      data.specific_recipients && data.specific_recipients.length > 0
        ? data.specific_recipients &&
          data.specific_recipients.map(
            (specificRecipient: LocationPatientRecordDto) =>
              specificRecipient.id,
          )
        : undefined;

    try {
      const { filters: postalCodeFilters } = getPostalCodeFilter(data);
      const recipients = await patientService.getPatientSearch(locationId, {
        age_filters: getAgeFilter(data),
        postal_code_filters:
          postalCodeFilters.length > 0 ? postalCodeFilters : undefined,
        gender: getGenderFilter(data),
        query: data.search,
        specific_ids: specificIds,
        size: 1, // just to get the count of recipients
      });
      newRecipientsCount = recipients.count;
    } catch (error) {
      console.error('Error getting recipientsResponse', error);
    }

    if (isNoRecipientsAlertShown) {
      setIsNoRecipientsAlertShown(false);
    }

    setSpecificRecipientIds(specificIds ? specificIds : []);
    setInitialRecipientsCount(newRecipientsCount);
    setBulkData(data);
    setIsNewBulkChatModalOpen(false);
    setIsOpenReviewBulkModal(true);
  }

  function onBulkMessageDraftCancel() {
    setIsNewBulkChatModalOpen(false);
    setBulkData(undefined);
  }

  async function onBulkMessageCreate(data: BulkMessageForm) {
    const isNoRecipients = recipientsCount === 0;
    setIsNoRecipientsAlertShown(isNoRecipients);
    if (isNoRecipients) {
      return;
    }

    try {
      setBulkData(data);
      const { filters } = getPostalCodeFilter(data);
      const postalCodeFilters = filters.length > 0 ? filters : undefined;

      if (data) {
        let specific_recipients_ids;
        if (data.specific_recipients) {
          specific_recipients_ids = data.specific_recipients.map(
            (item: LocationPatientRecordDto) => item.id,
          );
        }

        let scheduling;
        if (data.time === BulkMessageTime.SCHEDULED) {
          scheduling = {
            schedule_type: ScheduleType.Delayed,
            scheduled_time: data.scheduled_time,
          };
        } else {
          scheduling = {
            schedule_type: ScheduleType.Immediate,
            scheduled_time: new Date().toISOString(),
          };
        }

        if (data.message_type === BulkMessageType.Secure) {
          await unifiedCommsService.bulkMessagesCreateSecure(
            pharmacyId,
            locationId,
            {
              subject: data.subject,
              content: stripRichTextEditorElements(data.content),
              author_id: userId ?? '',
              ...scheduling,
              message_type: data.message_type,
              author_type: AuthorType.Pharmacy,
              gender: getGenderFilter(data),
              age_filters: getAgeFilter(data),
              postal_code_filters: postalCodeFilters,
              excluded_ids: savedExcludedRecipientIds,
              specific_recipients_ids,
            },
          );
        } else {
          if (data.sms_type) {
            await unifiedCommsService.bulkMessagesCreateNonSecure(
              pharmacyId,
              locationId,
              {
                subject: data.subject,
                content_email: convertPillToCurlyBraces(data.content_email),
                content_sms: stripRichTextEditorElements(data.content_sms),
                author_id: userId ?? '',
                ...scheduling,
                message_type: data.message_type,
                author_type: AuthorType.Pharmacy,
                sms_type: data.sms_type,
                gender: getGenderFilter(data),
                age_filters: getAgeFilter(data),
                postal_code_filters: postalCodeFilters,
                excluded_ids: savedExcludedRecipientIds,
                specific_recipients_ids,
              },
            );
          }
        }
      }

      setIsOpenReviewBulkModal(false);
      setBulkData(undefined);
      resetSavedExcludedRecipients();
      resetExcludedRecipients();
      toast(getText('bulk-message-added'), {
        type: 'success',
      });
      setSearchValue('');
    } catch (error) {
      console.error('Error creating a bulk message: ', error);
      toast('Error', {
        content: getText('error-creating-bulk-message'),
        type: 'error',
      });
    }
  }

  function openRecipients() {
    setIsOpenRecipientsListModal(true);
    setIsOpenReviewBulkModal(false);
  }

  function onSaveRecipientsList() {
    setIsOpenRecipientsListModal(false);
    setIsOpenReviewBulkModal(true);
    const uniqueExcludedRecipientIds = new Set([
      ...excludedRecipientIds,
      ...savedExcludedRecipientIds,
    ]);
    setSavedExcludedRecipientIds([...uniqueExcludedRecipientIds]);
  }

  function resetExcludedRecipients() {
    setExcludedRecipientIds([]);
  }

  function resetSavedExcludedRecipients() {
    setSavedExcludedRecipientIds([]);
  }

  function onBackRecipientsListModal() {
    setIsOpenRecipientsListModal(false);
    setIsOpenReviewBulkModal(true);
    resetExcludedRecipients();
  }

  function onRemoveRecipient(recipient: LocationPatientRecordSearchDto) {
    setExcludedRecipientIds([...excludedRecipientIds, recipient.id]);
  }

  function onGridRecipientsListReady(event: GridReadyEvent) {
    setRecipientsGridApi(event.api);
  }

  function setBulksRowData(bulks: IBulkMessage[]) {
    if (gridApi && gridApi.destroyCalled !== true) {
      gridApi.setRowData(bulks);
    } else {
      console.warn('Bulks grid has been destroyed, skipping setRowData');
    }
  }

  function onFilterTextChanged(text: string) {
    setSearchValue(text);
    const loweredText = text.toLowerCase();

    if (!loweredText) {
      setBulksRowData(bulks);
    } else {
      const filteredData: IBulkMessage[] = bulks.filter((bulk) => {
        const authorName = bulk.author?.name?.toLowerCase() ?? '';
        const subject = bulk.subject.toLowerCase();
        // mm/dd/yyyy
        const scheduledTimeDefaultDateFormat = formatDate(
          bulk.scheduled_time,
          DEFAULT_DATE_FORMAT,
        ).toLowerCase();
        // m/d/yyyy
        const scheduledTimeShortDateFormat = formatDate(
          bulk.scheduled_time,
          SHORT_DATE_FORMAT,
        ).toLowerCase();

        const isRemaining =
          authorName.includes(loweredText) ||
          subject.includes(loweredText) ||
          scheduledTimeDefaultDateFormat.includes(loweredText) ||
          scheduledTimeShortDateFormat.includes(loweredText);

        return isRemaining;
      });

      setBulksRowData(filteredData);
    }
  }

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <View style={styles.container}>
        {emailMessageError && smsMessageError ? (
          <Alert
            intent="warning"
            title={getText('sms-email-cap-reached')}
            linkText={getText('billing-settings')}
            onLinkPress={() =>
              navigation.navigate('settings', { screen: 'billing' })
            }
            style={{ marginBottom: theme.getSpacing(2) }}
          />
        ) : emailMessageError ? (
          <Alert
            intent="warning"
            title={`${getText('email-cap-reached')} `}
            linkText={getText('billing-settings')}
            onLinkPress={() =>
              navigation.navigate('settings', { screen: 'billing' })
            }
            style={{ marginBottom: theme.getSpacing(2) }}
          />
        ) : smsMessageError ? (
          <Alert
            intent="warning"
            title={getText('sms-cap-reached')}
            linkText={getText('billing-settings')}
            onLinkPress={() =>
              navigation.navigate('settings', { screen: 'billing' })
            }
            style={{ marginBottom: theme.getSpacing(2) }}
          />
        ) : null}
        {bulksTotalCount === 0 ? (
          <View style={styles.emptyStateWrapper}>
            <BulkEmptyState onClick={() => setIsNewBulkChatModalOpen(true)} />
          </View>
        ) : (
          <View style={styles.content}>
            <View style={{ flexGrow: 1, zIndex: zIndexAuto }}>
              {/** This is needed to initialize DataGrid only when the page was open with grid.
               * Otherwise the grid api can be destroyed before its usage */}
              {isBulkComponentVisible ? (
                <DataGrid
                  gridOptions={{
                    rowData: bulks,
                    columnDefs: bulksTableColumns,
                    enableCellTextSelection: true,
                    suppressMovableColumns: true,
                    suppressContextMenu: true,
                    defaultColDef: { sortable: true, menuTabs: [] },
                    alwaysMultiSort: true,
                    loadingOverlayComponent: 'loadingIndicator',
                    loadingOverlayComponentParams: {
                      color: theme.colors.brandedPrimary,
                    },
                    components: {
                      loadingIndicator: LoadingIndicator,
                    },
                    rowSelection: 'single',
                    suppressCellFocus: true,
                    colResizeDefault: 'shift',
                    paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
                    pagination: true,
                    rowModelType: 'clientSide',
                    getRowId: (params) => params.data.id,
                    onRowClicked(event) {
                      alert('not implemented yet');
                    },
                    noRowsOverlayComponent: () => (
                      <NoRowsRenderer status={status} />
                    ),
                    onGridReady(event: GridReadyEvent) {
                      if (!gridApi || gridApi.destroyCalled) {
                        setGridApi(event.api);
                        gridApi?.addEventListener('gridDestroyed', () => {
                          setGridApi(null); // Clear the API reference when the grid is destroyed
                        });
                      }
                    },
                  }}
                  gridToolbarProps={{
                    titleProps: {
                      title: getText('bulk-messages'),
                    },
                    inputSearchProps: {
                      size: 'lg',
                      placeholder: getText('sender-subject-date'),
                      inputValue: searchValue,
                      onChange(value: string) {
                        onFilterTextChanged(value);
                      },
                    },
                    actionButtonsProps: {
                      maxActionToShow: 2,
                      actionButtons: [],
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
        )}
      </View>
      <Tooltip
        text={getText('secure-chat')}
        id="secure-chat-subject-renderer" // the trigger lives in <SubjectRenderer />
      />
      <NewBulkMessageModal
        title={getText('new-message')}
        show={isNewBulkMessageModalOpen}
        bulkData={bulkData}
        onSubmit={onBulkMessageDraftCreate}
        onCancel={onBulkMessageDraftCancel}
      />
      {bulkData ? (
        <ReviewBulkMessageModal
          title={getText('review-message')}
          show={isOpenReviewBulkModal}
          bulkData={bulkData}
          recipientsCount={recipientsCount}
          isAlertShown={isNoRecipientsAlertShown}
          alertText={getText('you-have-no-bulk-message-recipients')}
          onSubmit={onBulkMessageCreate}
          onBack={onReviewBulkMessageBack}
          onCancel={onReviewBulkMessageCancel}
          openRecipients={openRecipients}
        />
      ) : (
        <></>
      )}
      <BulkRecipientsListModal
        title={getText('recipients-list')}
        show={isOpenRecipientsListModal}
        dataSource={recipientsDataSource}
        removedRecipients={excludedRecipientIds}
        onSubmit={onSaveRecipientsList}
        onBack={onBackRecipientsListModal}
        onCancel={onBackRecipientsListModal}
        onRemoveRecipient={onRemoveRecipient}
        onGridReady={onGridRecipientsListReady}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  emptyStateWrapper: {
    alignItems: 'center',
    marginTop: '15%',
  },
  content: {
    paddingBottom: theme.getSpacing(4),
    flexDirection: 'row',
    height: '100%',
    width: '90%',
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.getSpacing(4),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  tooltip: {
    left: '10%',
    zIndex: 100,
  },
  timestampLight: {
    color: theme.palette.gray[500],
  },
  timestampDark: {
    color: theme.palette.gray[700],
  },
}));
