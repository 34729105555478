import { BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { VUCA_API_BASE_URL } from '../common/constants';

export interface VideoDetailsModel {
  video_id: string;
  player_id: string;
  account_id: string;
  transcript: string;
}

export declare const Language: {
  readonly En: 'en';
  readonly Es: 'es';
  readonly Fr: 'fr';
};
export declare type Language = (typeof Language)[keyof typeof Language];

export interface VideoCategoryModel {
  id: number;
  label: string;
}

export interface VideoModel {
  id: string;
  name: string;
  description: string;
  value: VideoDetailsModel;
  category: VideoCategoryModel;
  short_url: string;
}

export type LanguageVideoModel = {
  [K in (typeof Language)[keyof typeof Language]]?: VideoModel;
};

export type VideosLocation = 'en-US' | 'es-US';
const alphabetChars = Array.from({ length: 26 }, (_, index) =>
  String.fromCharCode(65 + index),
);

export interface IVideoService {
  getVideoInfo(
    videoId: string,
    locale: string,
    slug: string,
  ): Promise<VideoResponseModel>;
  getVideosList(
    search?: string | null,
    locale?: VideosLocation | null,
    page?: number | null,
    category?: number | null,
    filter?: (typeof alphabetChars)[number] | null,
    slug?: string,
  ): Promise<VideosResponseModelList>;
}

export interface VideosResponseModelList {
  items: LanguageVideoModel[];
  total_count: number;
}

export interface MedicationGuidesPdfDownloadUri {
  [key: string]: string;
}

export interface VideoResponseModel {
  id: string;
  name: string;
  description: string;
  value: VideoDetailsModel;
  medication_guides: string[];
  medication_guides_title: string;
  medication_guides_pdf_download_uri: MedicationGuidesPdfDownloadUri;
  category: VideoCategoryModel;
  short_url: string;
}

export class VideoService extends BaseApiService implements IVideoService {
  constructor(
    baseUrl: string,
    _config: AxiosRequestConfig = {},
    enableAuth = true,
  ) {
    super(
      baseUrl,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      enableAuth,
    );
  }

  async getVideoInfo(
    videoId: string,
    locale = 'en-US',
    slug: string,
  ): Promise<VideoResponseModel> {
    const params = { locale, slug };

    const { data } = await this.axiosInstance.get(`api/v2/videos/${videoId}`, {
      params,
    });

    return data;
  }

  async getVideosList(
    search: string | null = null,
    locale: VideosLocation | null = 'en-US',
    page: number | null = 1,
    category: number | null = null,
    filter: (typeof alphabetChars)[number] | null = null,
    slug: string,
  ): Promise<VideosResponseModelList> {
    const videosListParams = {
      search,
      locale,
      page,
      category,
      filter,
      slug,
    };

    const { data } = await this.axiosInstance.get('api/v2/videos', {
      params: videosListParams,
    });
    return data;
  }
}

export default new VideoService(
  VUCA_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
