import {
  DetailedUserRole,
  EntityType,
  Role,
} from '@digitalpharmacist/role-service-client-axios';
import { getText } from 'assets/localization/localization';
import { convertRoleToLocalization } from '../../../../utils';

/**
 * Returns a role object RoleOption
 * when given the role value / (name)
 * @param roleValue
 * @returns
 */
export const getRoleObjByRoleValue = (
  roleValue: Role | string | undefined,
): RoleOption | undefined => {
  return allRoles.find(
    (roleOption: RoleOption) => roleOption.value === roleValue,
  );
};

export const getHighestRole = (roles: Role[] | null): Role => {
  if (roles === null) {
    roles = [];
  }

  return roles.reduce((lowestRole, currentRole) => {
    return roleOrder[currentRole] < roleOrder[lowestRole]
      ? currentRole
      : lowestRole;
  }, roles[0]);
};

export const getEntityTypeFromRole = (role: Role): EntityType => {
  switch (role) {
    case Role.NoAccess:
    case Role.Staff:
    case Role.Manager: {
      return EntityType.Location;
    }
    case Role.Corporate:
    case Role.Brand:
    case Role.ItAdmin: {
      return EntityType.Pharmacy;
    }
    case Role.LumistryUser:
    case Role.LumistryAdmin: {
      return EntityType.Global;
    }

    default: {
      throw new Error(`Unexpected role: ${role}`);
    }
  }
};

export const getUserRoles = (roleData: DetailedUserRole[]) => {
  return roleData.reduce((acc: { [key: string]: Role }, data) => {
    acc[data.entity_id] = data.role;
    return acc;
  }, {});
};

export const formatRoles = (v: DetailedUserRole[]) => {
  const roles: { [key: string]: number } = {};
  v.forEach((v) => {
    roles[v.role] === undefined ? (roles[v.role] = 1) : roles[v.role]++;
  });

  let formatted = '';

  for (const _role in roles) {
    const convertedRole = getText(convertRoleToLocalization[_role as Role]);

    formatted =
      formatted.length === 0
        ? `${convertedRole} (${roles[_role]})`
        : [formatted, `${convertedRole} (${roles[_role]})`].join(', ');
  }

  return formatted;
};

export interface RoleOption {
  label: string;
  value: Role;
  manageableByRoles: Array<Role>; // This is a list of roles that can manage this role
  entityType: EntityType;
}

export const roleOptions = {
  // returns a list of roles that are manageable by a given role and optional entity type
  // ignores entity type if not passed
  getAssignableRoleSet(
    usersRole: Role,
    entityType?: EntityType,
  ): Array<RoleOption> {
    return allRoles.filter((roleOption) => {
      const manageable = roleOption.manageableByRoles.includes(usersRole);
      const correctType = !entityType || entityType === roleOption.entityType;
      return manageable && correctType;
    });
  },
};

export const roleOrder: Record<Role, number> = {
  [Role.LumistryAdmin]: 0,
  [Role.LumistryUser]: 1,
  [Role.ItAdmin]: 2,
  [Role.Corporate]: 3,
  [Role.Brand]: 4,
  [Role.Manager]: 5,
  [Role.Staff]: 6,
  [Role.NoAccess]: 7,
};

export const allRoles: Array<RoleOption> = [
  {
    label: 'Lumistry Admin',
    value: Role.LumistryAdmin,
    manageableByRoles: [Role.LumistryAdmin],
    entityType: 'global', // The lumistry user has no entity
  },
  {
    label: 'Lumistry User',
    value: Role.LumistryUser,
    manageableByRoles: [Role.LumistryAdmin],
    entityType: 'global', // The lumistry user has no entity
  },
  {
    label: 'IT Admin',
    value: Role.ItAdmin,
    manageableByRoles: [Role.LumistryAdmin, Role.LumistryUser, Role.ItAdmin],
    entityType: EntityType.Pharmacy,
  },
  {
    label: 'Corporate',
    value: Role.Corporate,
    manageableByRoles: [
      Role.LumistryAdmin,
      Role.LumistryUser,
      Role.ItAdmin,
      Role.Corporate,
    ],
    entityType: EntityType.Pharmacy,
  },
  {
    label: 'Brand',
    value: Role.Brand,
    manageableByRoles: [
      Role.LumistryAdmin,
      Role.LumistryUser,
      Role.ItAdmin,
      Role.Corporate,
      Role.Brand,
    ],
    entityType: EntityType.Pharmacy,
  }, // Todo, update to Role.brand once available
  {
    label: 'Manager',
    value: Role.Manager,
    manageableByRoles: [
      Role.LumistryAdmin,
      Role.LumistryUser,
      Role.ItAdmin,
      Role.Corporate,
      Role.Brand,
      Role.Manager,
    ],
    entityType: EntityType.Location,
  },
  {
    label: 'Staff',
    value: Role.Staff,
    manageableByRoles: [
      Role.LumistryAdmin,
      Role.LumistryUser,
      Role.Brand,
      Role.Corporate,
      Role.ItAdmin,
      Role.Manager,
    ],
    entityType: EntityType.Location,
  },
  {
    label: 'No Access',
    value: Role.NoAccess,
    manageableByRoles: [
      Role.LumistryAdmin,
      Role.LumistryUser,
      Role.Brand,
      Role.Corporate,
      Role.ItAdmin,
      Role.Manager,
    ],
    entityType: EntityType.Location,
  },
];
