import React, { FunctionComponent } from 'react';
import { PatientAgreementModal } from './patient-agreement-modal/PatientAgreementModal';
import { ChecklistAppSettingsModal } from './checklist-app-settings/ChecklistAppSettingsModal';
import { ChecklistSetupModal } from './checklist-setup-modal/ChecklistSetupModal';
import { ColorPickerModal } from './media-settings/ColorPickerModal';
import { MediaSettingsModal } from './media-settings/MediaSettingsModal';
import { PatientWebsiteModal } from './patient-website/PatientWebsiteModal';
import { PatientWebsiteServiceDetails } from './patient-website/PatientWebsiteServiceDetails';
import { PatientWebsiteServices } from './patient-website/PatientWebsiteServices';
import { WebsiteAccessCompletedModal } from './patient-website/access-flow/WebsiteAccessCompletedModal';
import { WebsiteAccessPermissionModal } from './patient-website/access-flow/WebsiteAccessPermissionModal';
import { WebsiteAccessSetupModal } from './patient-website/access-flow/WebsiteAccessSetupModal';
import { useChecklistStore } from '../../store/checklist-store';

export const ChecklistModalProvider: FunctionComponent = () => {
  const { currentOpenedModalId } = useChecklistStore();

  if (!currentOpenedModalId) return null;

  return (
    <>
      <ChecklistSetupModal id="checklist-setup" />
      <PatientAgreementModal id="patient-agreements" />
      <MediaSettingsModal id="media-settings" />
      <ColorPickerModal id="color-picker" />
      <PatientWebsiteModal id="patient-website" />
      <WebsiteAccessSetupModal id="website-access-setup" />
      <WebsiteAccessCompletedModal id="website-access-completed" />
      <WebsiteAccessPermissionModal id="website-access-permission" />
      <ChecklistAppSettingsModal id="patient-app" />
      <PatientWebsiteServices id="patient-website-services" />
      <PatientWebsiteServiceDetails id="patient-website-service-details" />
    </>
  );
};
