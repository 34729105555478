import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { View } from 'react-native';

import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

const SettingsPageLayout: FunctionComponent<
  PropsWithChildren<SettingsPageLayoutProps>
> = ({ title, headerComponent, children }) => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <View style={styles.viewTitle}>
        <Text style={styles.title} selectable>
          {title}
        </Text>
        <View
          style={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'flex-end',
          }}
        >
          {headerComponent}
        </View>
      </View>
      {children}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  title: {
    ...theme.lumistryFonts.label.xxLarge.semiBold,
    color: theme.palette.gray[900],
  },
  viewTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(4),
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
  },
}));

interface SettingsPageLayoutProps {
  title: string;
  headerComponent?: ReactElement;
}

export default SettingsPageLayout;
