import {
  AllHolidaysDto,
  AppSettingsDto,
  AutoSaveObWizardDto,
  BrandingDto,
  ChecklistOptionDto,
  ClosureHoursDto,
  ColorPair,
  CreateAppSettingsDto,
  CreateClosuresRequestDto,
  CreateDepartmentDto,
  CreateInfoCardDto,
  CreateLocationClosuresRequestDto,
  CreateMediaDto,
  CreateNormalHoursDto,
  CreateNormalTimeRangeDto,
  CreateParentCompanyDto,
  CreatePatientAgreementDto,
  CreatePharmacyAppDto,
  CreatePharmacyWebsiteDto,
  DepartmentDto,
  DepartmentTimeRangeDto,
  FinalizeObWizardDto,
  HolidayDto,
  InfoCardDto,
  LocationClosuresResponseDto,
  MediaDto,
  NormalHoursDto,
  NormalTimeRangeDto,
  ObWizardResponseDto,
  PaginatedLocationResponseDto,
  PaginatedPharmacistLocationResponseDto,
  ParentCompanyResponseDto,
  PatientAgreementDto,
  PharmacyAppDto,
  PharmacyDto,
  PharmacyLocationDto,
  DefaultApi as PharmacyServiceClient,
  PharmacyWebsiteDto,
  PickupMethodResponseDto,
  PricingDto,
  ServiceDto,
  TimezoneDto,
  ToggleHolidayDto,
  UpdateBrandingDto,
  UpdateDepartmentDto,
  UpdateHolidaysDto,
  UpdateInfoCardDto,
  UpdateLocationServiceOptionDto,
  UpdatePharmacyAppDto,
  UpdatePharmacyDto,
  UpdatePharmacyLocationDto,
  UpdatePharmacyWebsiteDto,
  UpdatePickupMethodDto,
  UpdatePricingDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { PHARMACY_API_BASE_URL } from '../common/constants';
import { SearchableDatasourceService } from './SearchableDatasourceService';

export interface IPharmacyService {
  pharmacyLocationFindOne(locationId: string): Promise<PharmacyLocationDto>;
  pharmacyLocationFindAll(params: {
    search?: string;
    limit?: number;
    offset?: number;
    units?: string;
    lng?: string;
    lat?: string;
    postalCode?: string;
    orderBy?: string;
  }): Promise<PaginatedLocationResponseDto>;

  pharmacyGetById(corporationId: string): Promise<PharmacyDto>;
  updatePharmacyRecord(
    corporationId: string,
    updatePharmacyDto: UpdatePharmacyDto,
  ): Promise<PharmacyDto>;
  getHoursByDepartment(departmentId: string): Promise<NormalTimeRangeDto[]>;
  createDepartmentHours(
    departmentId: string,
    timeRange: CreateNormalTimeRangeDto[],
  ): Promise<NormalTimeRangeDto[]>;
  departmentGetByPharmacy(pharmacyId: string): Promise<DepartmentDto[]>;
  departmentGetById(
    pharmacyId: string,
    departmentId: string,
  ): Promise<DepartmentDto>;
  createPharmacyDepartment(
    pharmacyId: string,
    department: CreateDepartmentDto,
  ): Promise<DepartmentDto>;
  updatePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
    department: UpdateDepartmentDto,
  ): Promise<DepartmentDto>;
  removePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
  ): Promise<void>;
  pharmacyLocationUpdate(
    locationId: string,
    updatePharmacyLocationDto: UpdatePharmacyLocationDto,
  ): Promise<PharmacyLocationDto>;
  pharmacyLocationHoursCreate(
    locationId: string,
    departmentId: string,
    createNormalHoursDto: CreateNormalHoursDto,
  ): Promise<NormalHoursDto>;
  pharmacyLocationHoursCreateClosures(
    locationId: string,
    createLocationClosuresRequestDto: CreateLocationClosuresRequestDto,
  ): Promise<LocationClosuresResponseDto>;
  pharmacyLocationHoursFindAll(
    locationId: string,
  ): Promise<DepartmentTimeRangeDto[]>;
  pharmacyLocationHoursFindAllClosures(
    locationId: string,
  ): Promise<LocationClosuresResponseDto>;
  pharmacyLocationHoursGetHolidays(locationId: string): Promise<AllHolidaysDto>;
  pharmacyLocationHoursRemoveClosures(
    locationId: string,
    closureId: string,
  ): Promise<void>;
  pharmacyLocationHoursToggleHoliday(
    locationId: string,
    updateHolidaysDto: UpdateHolidaysDto,
  ): Promise<AllHolidaysDto>;
  pharmacyHoursGetHolidays(pharmacyId: string): Promise<HolidayDto[]>;
  pharmacyHoursToggleHoliday(
    pharmacyId: string,
    toggleHolidayDto: ToggleHolidayDto[],
  ): Promise<HolidayDto[]>;
  pharmacyHoursFindAll(pharmacyId: string): Promise<ClosureHoursDto[]>;
  pharmacyHoursCreateClosures(
    pharmacyId: string,
    createClosureDto: CreateClosuresRequestDto,
  ): Promise<ClosureHoursDto[]>;
  pharmacyHoursRemoveClosures(
    pharmacyId: string,
    closureId: string,
  ): Promise<void>;
  pickupMethodsFindAll(locationId: string): Promise<PickupMethodResponseDto[]>;
  pickupMethodToggle(
    locationId: string,
    methodId: string,
    updatePickupMethodDto: UpdatePickupMethodDto,
  ): Promise<PickupMethodResponseDto>;
  serviceOptionsFindAll(locationId: string): Promise<ServiceDto[]>;
  updateServiceOptions(
    locationId: string,
    updateLocationServiceOptionDto: UpdateLocationServiceOptionDto[],
  ): Promise<ServiceDto[]>;
  timezoneFindAll(): Promise<TimezoneDto[]>;
  findPharmacyLocation(locationId: string): Promise<PharmacyLocationDto>;
  parentCompanySearch(search: string): Promise<ParentCompanyResponseDto[]>;
  parentCompanyCreate(
    createParentCompanyDto: CreateParentCompanyDto,
  ): Promise<ParentCompanyResponseDto>;
  pharmacyAppCreate(
    pharmacyId: string,
    createPharmacyAppDto: CreatePharmacyAppDto,
  ): Promise<PharmacyAppDto>;
  pharmacyAppFind(pharmacyId: string): Promise<PharmacyAppDto>;
  pharmacyAppRemove(pharmacyId: string): Promise<void>;
  pharmacyAppUpdate(
    pharmacyId: string,
    updatePharmacyAppDto: UpdatePharmacyAppDto,
  ): Promise<PharmacyAppDto>;
  infoCardsCreate(
    locationId: string,
    createInfoCardDto: CreateInfoCardDto,
  ): Promise<InfoCardDto>;
  infoCardsFindAll(locationId: string): Promise<InfoCardDto[]>;
  infoCardsFindOne(locationId: string, cardId: string): Promise<InfoCardDto>;
  infoCardsRemove(locationId: string, cardId: string): Promise<void>;
  infoCardsUpdate(
    locationId: string,
    cardId: string,
    updateInfoCardDto: UpdateInfoCardDto,
  ): Promise<InfoCardDto>;
  pharmacyWebsiteCreate(
    pharmacyId: string,
    createPharmacyWebsiteDto: CreatePharmacyWebsiteDto,
  ): Promise<PharmacyWebsiteDto>;
  pharmacyWebsiteFind(pharmacyId: string): Promise<PharmacyWebsiteDto>;
  pharmacyWebsiteRemove(pharmacyId: string): Promise<void>;
  pharmacyWebsiteUpdate(
    pharmacyId: string,
    updatePharmacyWebsiteDto: UpdatePharmacyWebsiteDto,
  ): Promise<PharmacyWebsiteDto>;
  pricingFind(locationId: string): Promise<PricingDto>;
  pricingUpdate(
    locationId: string,
    updatePricingDto: UpdatePricingDto,
  ): Promise<PricingDto>;

  pharmacistLocationsByPharmacyIdFind(params: {
    userId: string;
    search?: string;
    orderBy?: string;
    limit?: number;
    offset?: number;
  }): Promise<PaginatedPharmacistLocationResponseDto>;

  createMedia(pharmacyId: string, media: CreateMediaDto): Promise<MediaDto>;

  getAppSettingsDunsList(pharmacyId: string): Promise<string[]>;

  getAppSettings(pharmacyId: string): Promise<AppSettingsDto>;

  createAppSettings(
    pharmacyId: string,
    appSettings: CreateAppSettingsDto,
  ): Promise<AppSettingsDto>;

  createPatientAgreement(
    locationId: string,
    patientAgreement: CreatePatientAgreementDto,
  ): Promise<PatientAgreementDto>;
}

export class PharmacyServiceClass
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;
  private searchableDatasourceService: SearchableDatasourceService;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.searchableDatasourceService = new SearchableDatasourceService(
      baseUrl,
      config,
      enableAuth,
      baseConfig,
    );
  }

  async findOnboardingWizardById(
    pharmacyId: string,
  ): Promise<ObWizardResponseDto> {
    const { data } = await this.pharmacyServiceClient.obFind(pharmacyId);
    return data;
  }

  async updateOnboardingWizardById(
    pharmacyId: string,
    autosaveModel: AutoSaveObWizardDto,
  ): Promise<ObWizardResponseDto> {
    const { data } = await this.pharmacyServiceClient.obSave(
      pharmacyId,
      autosaveModel,
    );
    return data;
  }

  async createOnboardingWizardById(
    pharmacyId: string,
    autosaveModel: FinalizeObWizardDto,
  ): Promise<ObWizardResponseDto> {
    const { data } = await this.pharmacyServiceClient.obFinalize(
      pharmacyId,
      autosaveModel,
      {
        headers: {
          'x-pharmacy-id': pharmacyId,
        },
      },
    );
    return data;
  }

  async pharmacyLocationFindOne(
    locationId: string,
  ): Promise<PharmacyLocationDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationFindOne(locationId);
    return data;
  }

  async pharmacyLocationFindAll(params?: {
    search?: string;
    limit?: number;
    offset?: number;
    units?: string;
    lng?: string;
    lat?: string;
    postalCode?: string;
    orderBy?: string;
  }): Promise<PaginatedLocationResponseDto> {
    const { search, limit, offset, units, lng, lat, postalCode, orderBy } =
      params ?? {};
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind(
      search,
      limit,
      offset,
      units,
      lng,
      lat,
      postalCode,
      orderBy,
    );
    return data;
  }

  async pharmacyGetById(corporationId: string): Promise<PharmacyDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyFindOne(corporationId);
    return data;
  }
  async updatePharmacyRecord(
    corporationId: string,
    updatePharmacyDto: UpdatePharmacyDto,
  ): Promise<PharmacyDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyUpdate(
      corporationId,
      updatePharmacyDto,
    );
    return data;
  }

  async getHoursByDepartment(
    departmentId: string,
  ): Promise<NormalTimeRangeDto[]> {
    const { data } =
      await this.pharmacyServiceClient.departmentHoursFindAll(departmentId);
    return data;
  }
  async createDepartmentHours(
    departmentId: string,
    timeRange: CreateNormalTimeRangeDto[],
  ): Promise<NormalTimeRangeDto[]> {
    const { data } = await this.pharmacyServiceClient.departmentHoursCreate(
      departmentId,
      timeRange,
    );
    return data;
  }
  async departmentGetByPharmacy(pharmacyId: string): Promise<DepartmentDto[]> {
    const { data } =
      await this.pharmacyServiceClient.departmentFindAll(pharmacyId);
    return data;
  }
  async brandingGetByPharmacy(pharmacyId: string): Promise<BrandingDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyBrandingFindOne(pharmacyId);
    return data;
  }
  async departmentGetById(
    pharmacyId: string,
    departmentId: string,
  ): Promise<DepartmentDto> {
    const { data } = await this.pharmacyServiceClient.departmentFindOne(
      pharmacyId,
      departmentId,
    );
    return data;
  }
  async createPharmacyDepartment(
    pharmacyId: string,
    department: CreateDepartmentDto,
  ): Promise<DepartmentDto> {
    const { data } = await this.pharmacyServiceClient.departmentCreate(
      pharmacyId,
      department,
    );
    return data;
  }
  async updatePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
    department: UpdateDepartmentDto,
  ): Promise<DepartmentDto> {
    const { data } = await this.pharmacyServiceClient.departmentUpdate(
      pharmacyId,
      departmentId,
      department,
    );
    return data;
  }
  async updatePharmacyBranding(
    pharmacyId: string,
    branding: UpdateBrandingDto,
  ): Promise<BrandingDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyBrandingUpdatePharmacyBranding(
        pharmacyId,
        branding,
      );
    return data;
  }
  async removePharmacyDepartment(
    pharmacyId: string,
    departmentId: string,
  ): Promise<void> {
    await this.pharmacyServiceClient.departmentRemove(pharmacyId, departmentId);
  }
  async pharmacyHoursGetHolidays(pharmacyId: string): Promise<HolidayDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyHoursGetHolidays(pharmacyId);
    return data;
  }
  async pharmacyHoursRemoveClosures(
    pharmacyId: string,
    closureId: string,
  ): Promise<void> {
    await this.pharmacyServiceClient.pharmacyHoursRemoveClosures(
      pharmacyId,
      closureId,
    );
  }
  async pharmacyHoursToggleHoliday(
    pharmacyId: string,
    toggleHolidayDto: ToggleHolidayDto[],
  ): Promise<HolidayDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyHoursToggleHoliday(
        pharmacyId,
        toggleHolidayDto,
      );
    return data;
  }
  async pharmacyHoursCreateClosures(
    pharmacyId: string,
    createClosureDto: CreateClosuresRequestDto,
  ): Promise<ClosureHoursDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyHoursCreateClosures(
        pharmacyId,
        createClosureDto,
      );
    return data;
  }
  async pharmacyHoursFindAll(pharmacyId: string): Promise<ClosureHoursDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyHoursFindAll(pharmacyId);
    return data;
  }

  async pharmacyLocationUpdate(
    locationId: string,
    updatePharmacyLocationDto: UpdatePharmacyLocationDto,
  ): Promise<PharmacyLocationDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationUpdate(
      locationId,
      updatePharmacyLocationDto,
    );
    return data;
  }
  async pharmacyLocationHoursCreate(
    locationId: string,
    departmentId: string,
    createNormalHoursDto: CreateNormalHoursDto,
  ): Promise<NormalHoursDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursCreate(
        locationId,
        departmentId,
        createNormalHoursDto,
      );
    return data;
  }
  async pharmacyLocationHoursCreateClosures(
    locationId: string,
    createLocationClosuresRequestDto: CreateLocationClosuresRequestDto,
  ): Promise<LocationClosuresResponseDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursCreateClosures(
        locationId,
        createLocationClosuresRequestDto,
      );
    return data;
  }
  async pharmacyLocationHoursFindAll(
    locationId: string,
  ): Promise<DepartmentTimeRangeDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursFindAll(locationId);
    return data;
  }
  async pharmacyLocationHoursFindAllClosures(
    locationId: string,
  ): Promise<LocationClosuresResponseDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursFindAllClosures(
        locationId,
      );
    return data;
  }

  async pharmacyLocationHoursGetHolidays(
    locationId: string,
  ): Promise<AllHolidaysDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursGetHolidays(
        locationId,
      );
    return data;
  }
  async pharmacyLocationHoursRemoveClosures(
    locationId: string,
    closureId: string,
  ): Promise<void> {
    await this.pharmacyServiceClient.pharmacyLocationHoursRemoveClosures(
      locationId,
      closureId,
    );
  }
  async pharmacyLocationHoursToggleHoliday(
    locationId: string,
    updateHolidaysDto: UpdateHolidaysDto,
  ): Promise<AllHolidaysDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursToggleHoliday(
        locationId,
        updateHolidaysDto,
      );
    return data;
  }
  async pickupMethodsFindAll(
    locationId: string,
  ): Promise<PickupMethodResponseDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationPickupMethodGetPickupMethods(
        locationId,
      );
    return data;
  }
  async pickupMethodToggle(
    locationId: string,
    methodId: string,
    updatePickupMethodDto: UpdatePickupMethodDto,
  ): Promise<PickupMethodResponseDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationPickupMethodUpdatePickupMethod(
        locationId,
        methodId,
        updatePickupMethodDto,
      );
    return data;
  }
  async serviceOptionsFindAll(locationId: string): Promise<ServiceDto[]> {
    const { data } =
      await this.pharmacyServiceClient.serviceOptionGetServiceOptions(
        locationId,
      );
    return data;
  }
  async updateServiceOptions(
    locationId: string,
    updateLocationServiceOptionDto: UpdateLocationServiceOptionDto[],
  ): Promise<ServiceDto[]> {
    const { data } =
      await this.pharmacyServiceClient.serviceOptionUpdateServiceOptions(
        locationId,
        updateLocationServiceOptionDto,
      );
    return data;
  }
  async timezoneFindAll(): Promise<TimezoneDto[]> {
    const { data } = await this.pharmacyServiceClient.timezoneFindAll();
    return data;
  }
  async findPharmacyLocation(locationId: string): Promise<PharmacyLocationDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationFindOne(locationId);
    return data;
  }

  async parentCompanySearch(
    search: string,
  ): Promise<ParentCompanyResponseDto[]> {
    const { data } =
      await this.pharmacyServiceClient.parentCompanySearch(search);
    return data;
  }

  async parentCompanyCreate(
    createParentCompanyDto: CreateParentCompanyDto,
  ): Promise<ParentCompanyResponseDto> {
    const { data } = await this.pharmacyServiceClient.parentCompanyCreate(
      createParentCompanyDto,
    );
    return data;
  }

  async pharmacyAppCreate(
    pharmacyId: string,
    createPharmacyAppDto: CreatePharmacyAppDto,
  ): Promise<PharmacyAppDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyAppCreate(
      pharmacyId,
      createPharmacyAppDto,
    );
    return data;
  }

  async infoCardsCreate(
    locationId: string,
    createInfoCardDto: CreateInfoCardDto,
  ): Promise<InfoCardDto> {
    const { data } = await this.pharmacyServiceClient.infoCardsCreate(
      locationId,
      createInfoCardDto,
    );
    return data;
  }

  async pharmacyAppFind(pharmacyId: string): Promise<PharmacyAppDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyAppFind(pharmacyId);
    return data;
  }

  async pharmacyAppRemove(pharmacyId: string): Promise<void> {
    await this.pharmacyServiceClient.pharmacyAppRemove(pharmacyId);
  }

  async pharmacyAppUpdate(
    pharmacyId: string,
    updatePharmacyAppDto: UpdatePharmacyAppDto,
  ): Promise<PharmacyAppDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyAppUpdate(
      pharmacyId,
      updatePharmacyAppDto,
    );
    return data;
  }

  async infoCardsFindAll(locationId: string): Promise<InfoCardDto[]> {
    const { data } =
      await this.pharmacyServiceClient.infoCardsFindAll(locationId);
    return data;
  }

  async infoCardsFindOne(
    locationId: string,
    cardId: string,
  ): Promise<InfoCardDto> {
    const { data } = await this.pharmacyServiceClient.infoCardsFindOne(
      locationId,
      cardId,
    );
    return data;
  }

  async infoCardsRemove(locationId: string, cardId: string): Promise<void> {
    await this.pharmacyServiceClient.infoCardsRemove(locationId, cardId);
  }

  async infoCardsUpdate(
    locationId: string,
    cardId: string,
    updateInfoCardDto: UpdateInfoCardDto,
  ): Promise<InfoCardDto> {
    const { data } = await this.pharmacyServiceClient.infoCardsUpdate(
      locationId,
      cardId,
      updateInfoCardDto,
    );
    return data;
  }

  async pharmacyWebsiteCreate(
    pharmacyId: string,
    createPharmacyWebsiteDto: CreatePharmacyWebsiteDto,
  ): Promise<PharmacyWebsiteDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyWebsiteCreate(
      pharmacyId,
      createPharmacyWebsiteDto,
    );
    return data;
  }

  async pharmacyWebsiteFind(pharmacyId: string): Promise<PharmacyWebsiteDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyWebsiteFind(pharmacyId);
    return data;
  }

  async pharmacyWebsiteRemove(pharmacyId: string): Promise<void> {
    await this.pharmacyServiceClient.pharmacyWebsiteRemove(pharmacyId);
  }

  async pharmacyWebsiteUpdate(
    pharmacyId: string,
    updatePharmacyWebsiteDto: UpdatePharmacyWebsiteDto,
  ): Promise<PharmacyWebsiteDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyWebsiteUpdate(
      pharmacyId,
      updatePharmacyWebsiteDto,
    );
    return data;
  }

  async findChecklistById(locationId: string): Promise<ChecklistOptionDto[]> {
    const { data } = await this.pharmacyServiceClient.checklistFind(locationId);
    return data;
  }

  async fetchMediaColors(): Promise<ColorPair[]> {
    const { data } = await this.pharmacyServiceClient.mediaColors();
    return data;
  }

  async fetchMedia(pharmacyId: string): Promise<MediaDto> {
    const { data } = await this.pharmacyServiceClient.mediaFind(pharmacyId);
    return data;
  }

  async createMedia(
    pharmacyId: string,
    media: CreateMediaDto,
  ): Promise<MediaDto> {
    const { data } = await this.pharmacyServiceClient.mediaCreate(
      pharmacyId,
      media,
    );
    return data;
  }

  async getAppSettingsDunsList(pharmacyId: string): Promise<string[]> {
    const { data } =
      await this.pharmacyServiceClient.appSettingsGetDunsList(pharmacyId);
    return data;
  }

  async getAppSettings(pharmacyId: string): Promise<AppSettingsDto> {
    const { data } =
      await this.pharmacyServiceClient.appSettingsFind(pharmacyId);
    return data;
  }

  async createAppSettings(
    pharmacyId: string,
    appSettings: CreateAppSettingsDto,
  ): Promise<AppSettingsDto> {
    const { data } = await this.pharmacyServiceClient.appSettingsCreate(
      pharmacyId,
      appSettings,
    );
    return data;
  }

  async createPatientAgreement(
    pharmacyId: string,
    patientAgreement: CreatePatientAgreementDto,
  ): Promise<PatientAgreementDto> {
    const { data } = await this.pharmacyServiceClient.patientAgreementCreate(
      pharmacyId,
      patientAgreement,
    );
    return data;
  }

  async pharmacistLocationsByPharmacyIdFind(params: {
    userId: string;
    search?: string;
    orderBy?: string;
    limit?: number;
    offset?: number;
  }): Promise<PaginatedPharmacistLocationResponseDto> {
    const { userId, search, orderBy, limit, offset } = params;
    const { data } = await this.pharmacyServiceClient.pharmacistFind(
      userId,
      search,
      orderBy ?? 'location_name',
      limit,
      offset ?? 0,
    );

    return data;
  }

  async pricingFind(locationId: string): Promise<PricingDto> {
    const { data } = await this.pharmacyServiceClient.pricingFind(locationId);
    return data;
  }

  async pricingUpdate(
    locationId: string,
    updatePricingDto: UpdatePricingDto,
  ): Promise<PricingDto> {
    const { data } = await this.pharmacyServiceClient.pricingUpdate(
      locationId,
      updatePricingDto,
    );
    return data;
  }
}

const PharmacyService = new PharmacyServiceClass(
  PHARMACY_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);

export default PharmacyService;
