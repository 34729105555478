import { makeStyles } from 'assets/theme';

export const useStyles = makeStyles((theme) => ({
  infoRow: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingBottom: theme.getSpacing(1),
  },
  nameBar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(3),
  },
  info: {
    paddingTop: theme.getSpacing(3),
  },
  photoId: {
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  phoneNumber: {
    paddingRight: theme.getSpacing(1),
  },
  editNotes: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  editNotesText: {
    paddingLeft: theme.getSpacing(1),
    color: theme.palette.primary[600],
  },

  infoRowValue: {
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
    paddingLeft: theme.getSpacing(1),
    width: 185,
    flex: 1,
  },
  infoRowLabel: {
    fontSize: 14,
    fontWeight: '500',
    color: theme.palette.gray[900],
    lineHeight: 20,
    width: 120,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  width120: {
    width: 120,
  },
  primaryText: {
    fontSize: 14,
    fontWeight: '400',
    color: theme.palette.primary[600],
    paddingLeft: theme.getSpacing(1),
  },
  patientName: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  sectionHeader: {
    marginTop: theme.getSpacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    borderBottomStyle: 'solid',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  sectionLabelText: {
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.gray[900],
  },
  activityEntryLabel: {
    fontWeight: '500',
    color: theme.palette.gray[900],
    lineHeight: 20,
    paddingBottom: theme.getSpacing(1),
  },
  activityEntryValue: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    lineHeight: 20,
  },
  editText: {
    color: theme.palette.gray[500],
    fontSize: 14,
  },
  internalUseOnlyText: {
    color: theme.palette.gray[500],
    fontSize: 13,
    fontWeight: '400',
  },
  badgeText: {
    fontSize: 12,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
  notesContainer: {
    backgroundColor: theme.palette.gray[50],
    padding: theme.getSpacing(1),
  },
  notesText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[600],
  },
  appointmentButtonContainer: {
    marginTop: theme.getSpacing(3),
  },
  chatButton: {
    marginTop: theme.getSpacing(2),
  },
  moreMedsText: {
    fontWeight: '400',
    fontSize: 13,
    textTransform: 'capitalize',
    textAlign: 'right',
    color: theme.palette.primary[600],
  },
  noneText: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
  sectionHeaderButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  sectionButtonText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
  },
}));
