import { Modal } from 'assets/components/modal';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import {
  CreateClosureDto,
  CreateClosuresRequestDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { PlusCircleIcon, TrashWithStripesIcon } from 'assets/icons';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import moment from 'moment-timezone';
import { useFieldArray, useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import {
  convertTimeRangeToISOString,
  extractHoursAndMinutesFromISOString,
  formatDate,
  isDateBefore,
  isDateValid,
} from '../../../../common/datetime-utils';
import { zIndexAuto } from '../../../../common/theme';
import { TimeRangeInput } from '../../../../schedule/time-range-input/TimeRangeInput';
import { HourType, LocationTime } from '../../../../store/onboarding-store';
import {
  setClosures,
  setShowModalAdditionalClosures,
} from '../pharmacy-settings-actions';
import { usePharmacySettingsState } from '../pharmacy-settings-store';

const emptyClosure: ClosureHours = {
  name: '',
  enabled: false,
  date: '',
  dayHours: {
    start: '',
    end: '',
  },
  type: HourType.OPEN,
};

export const AdditionalClosuresModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalAdditionalClosures, closures } = usePharmacySettingsState();
  const styles = useStyles();
  const theme = useTheme();

  const methods = useForm<DepartmentHoursFormProps>({
    defaultValues: { closures: [emptyClosure] },
    mode: 'all',
  });
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'closures',
  });

  useEffect(() => {
    if (closures.length === 0) {
      methods.reset({ closures: [emptyClosure] });
    } else {
      methods.reset({
        closures: closures.map((closure) => {
          const dateString: string = closure.timeRange.date
            ? formatDate(closure.timeRange.date)
            : '';
          return {
            enabled: closure.enabled || false,
            name: closure.name || '',
            date: dateString,
            dayHours: convertTimeRangeToISOString(closure.timeRange),
          };
        }),
      });
    }
  }, [closures]);

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const createClosures: CreateClosureDto[] = formValue.closures.map(
      (closure) => {
        const startHour = extractHoursAndMinutesFromISOString(
          closure.dayHours.start,
        );
        const endHour = extractHoursAndMinutesFromISOString(
          closure.dayHours.end,
        );
        return {
          name: closure.name,
          enabled: closure.enabled,
          time_range: {
            date: closure.date,
            start_hour: startHour.hours,
            start_minute: startHour.minutes,
            end_hour: endHour.hours,
            end_minute: endHour.minutes,
          },
        };
      },
    );
    const closures: CreateClosuresRequestDto = { closures: createClosures };
    setClosures(closures);
    setShowModalAdditionalClosures(false);
  };

  const closeModal = () => {
    setShowModalAdditionalClosures(false);
  };

  return (
    <Modal
      title={getText('pharmacy-additional-closures')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-additional-closures-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-additional-closures-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-additional-closures-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalAdditionalClosures}
      isScrollable={true}
      size="lg"
      scrollViewStyle={{ minHeight: 480 }}
    >
      <Form methods={methods}>
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Form.Row style={styles.row}>
              <Form.Column>
                <CheckboxField
                  key={`${field.id}.enabled`}
                  name={`closures[${index}].enabled`}
                  label={getText('pharmacy-additional-closures-active')}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
              <Form.Column style={{ alignItems: 'flex-end' }}>
                <TouchableOpacity
                  style={[styles.topBarButton]}
                  onPress={() => remove(index)}
                >
                  <Icon
                    icon={TrashWithStripesIcon}
                    color={theme.palette.gray[700]}
                    size={20}
                  />
                </TouchableOpacity>
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.row}>
              <Form.Column>
                <TextField
                  key={`${field.id}.name`}
                  style={styles.inputStyle}
                  name={`closures.${index}.name`}
                  type="text"
                  label={getText('pharmacy-additional-label')}
                  rules={{
                    required: getText('pharmacy-additional-name-required'),
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={{ ...styles.row }}>
              <Form.Row style={styles.dateStyle}>
                <TextField
                  key={`${field.id}.date`}
                  style={styles.inputStyle}
                  name={`closures.${index}.date`}
                  type="date"
                  rules={{
                    required: getText('pharmacy-additional-date-required'),
                    validate: (value: string) => {
                      return isDateValid(value) &&
                        !isDateBefore(value, moment().format('YYYY-MM-DD'))
                        ? true
                        : getText('wrong-date-format');
                    },
                  }}
                />
                <View style={styles.dateTimePickerStyle}>
                  <TimeRangeInput
                    name={`closures.${index}.dayHours`}
                    startTimeRules={{
                      required: getText('start-time-required'),
                    }}
                    endTimeRules={{
                      required: getText('end-time-required'),
                    }}
                  />
                </View>
              </Form.Row>
            </Form.Row>
          </React.Fragment>
        ))}
        <Form.Row style={styles.row}>
          <TouchableOpacity
            style={[styles.topBarButton]}
            onPress={() => append(emptyClosure)}
          >
            <Icon
              icon={PlusCircleIcon}
              color={theme.palette.primary[600]}
              size={20}
            />
            <Text style={styles.topBarText}>{getText('add-another')}</Text>
          </TouchableOpacity>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface ClosureHours {
  name: string;
  enabled: boolean;
  date: string;
  dayHours: LocationTime;
  type: HourType;
}

interface DepartmentHoursFormProps {
  closures: ClosureHours[];
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 54,
    width: '100%',
  },
  textWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  weekStyle: {
    flexDirection: 'row',
    flex: 1,
    zIndex: zIndexAuto,
  },
  hoursGroupStyle: {
    flex: 4,
    zIndex: zIndexAuto,
  },
  hoursStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    marginLeft: theme.getSpacing(1),
  },
  row: {
    display: 'flex',
    flex: 1,
    zIndex: zIndexAuto,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    paddingBottom: theme.getSpacing(3),
  },
  closedTextStyle: {
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.getSpacing(1),
  },
  separatorStyle: {
    color: theme.palette.gray[400],
    fontWeight: '600',
    margin: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
  },
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topBarText: {
    color: theme.palette.primary[600],
    fontWeight: '500',
    fontSize: 16,
    marginLeft: theme.getSpacing(0.5),
  },
  dateStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    zIndex: zIndexAuto,
  },
  dateTimePickerStyle: {
    flexDirection: 'row',
    zIndex: zIndexAuto,
    marginLeft: theme.getSpacing(2),
  },
  separatorView: {
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
}));
