import React, {
  useMemo,
  Fragment,
  useEffect,
  useState,
  ForwardRefRenderFunction,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';
import { DropdownSelect } from 'assets/components/dropdown-select';
import {
  AutoMessageConfigDtoEditableColumnsEnum,
  MessageConfigType,
  UpdateAutoMessageConfigDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { useAppStateStore } from '../../../../store/app-store';
import { useNewChatModalState } from '../../stores/new-chat-modal-store/new-chat-modal-store';
import { useErrorState } from '../../stores/error-store/error-store';
import { useMessagingStyles } from '../../common/styles';
import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import { MessageForm } from '../../common/types/MessageForm';
import { defaultTemplates } from '../../common/defaultTemplates';
import { MessageFields } from '../../common/types/MessageFields';
import { BIG_MESSAGE_LIMIT, EXCLUDED_ERRORS_MODAL } from '../../data';
import { ITemplate } from 'assets/types/messageTypes';
import { convertVariablesToPills } from '../../Quill/utils';
import { ErrorsContainer } from '../../components/ErrorsContainer';
import { Form } from 'assets/layout';
import { DateTimePickerField } from '../../../../components/DateTimePickerField';
import { AutoConfigMessageBodyEditor } from '../../components/AutoConfigMessageBodyEditor';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { makeStyles, useTheme } from 'assets/theme';
import { TextField } from 'assets/components/text-field';
import { FormProvider } from 'assets/form';
import { WebModal } from 'assets/components/base-modal/WebModal';

export const EditAutoMessageConfigModal: ForwardRefRenderFunction<
  BaseModalHandler,
  EditAutoMessageConfigModalProps
> = (
  props: EditAutoMessageConfigModalProps,
  ref: ForwardedRef<BaseModalHandler>,
) => {
  const [errorFields, setErrorFields] = useState<Record<string, string>>({});
  const locationId = useAppStateStore((state) => state.locationId);
  const templatesFilter = useNewChatModalState(
    (state) => state.templatesFilter,
  );
  const { errorObject } = useErrorState();
  const messagingStyles = useMessagingStyles();
  const theme = useTheme();
  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);
  const [preSelectedContent, setPreSelectedContent] = useState<string>();
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const [messageLength, setMessageLength] = useState<number>(0);

  const styles = useStyles();

  const containsSendDate = props.editableColumns.includes(
    AutoMessageConfigDtoEditableColumnsEnum.SendDate,
  );

  const handleShow = () => {
    sheetRef.current?.show();
  };

  const handleHide = () => {
    sheetRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const methods = useForm<MessageForm>({
    defaultValues: {
      subject: props.defaultSubject,
      content: props.defaultMessage,
    },
  });

  const onContentChange = (content: string, length: number) => {
    setMessageLength(length);
  };

  useEffect(() => {
    if (preSelectedContent) {
      methods.setValue('content', preSelectedContent);
    }
  }, [preSelectedContent]);

  useEffect(() => {
    if (props.defaultMessage === undefined) {
      throw new Error();
    }
    if (props.hasPreSelectedItems && quillRef) {
      const content = convertMessageContent(props.defaultMessage, quillRef);
      if (content) {
        setPreSelectedContent(content);
      }
    }
  }, [quillRef]);

  const filteredTemplates = useMemo(() => {
    const sortedTemplates = defaultTemplates.sort(
      (currentTemplate, nextTemplate) =>
        currentTemplate.name.localeCompare(nextTemplate.name),
    );
    if (!templatesFilter) {
      return sortedTemplates;
    }

    const regExp = new RegExp(templatesFilter, 'i');
    return sortedTemplates.filter((template) => regExp.test(template.name));
  }, [templatesFilter]);

  const onEditCopyMessage = () => {
    const updateAutoMessageConfigDto: UpdateAutoMessageConfigDto = {
      location_id: locationId,
      message: methods.getValues().content,
      is_active: true,
      message_config_type: methods.getValues().subject as MessageConfigType,
    };

    props.onSubmit &&
      props.onSubmit(updateAutoMessageConfigDto, onChatComplete);
  };

  const onChatComplete = () => {
    methods.reset();
  };

  const onSelectTemplate = (template: ITemplate) => {
    if (!quillRef) return;
    const content = convertMessageContent(template.body, quillRef);
    props.hasPreSelectedItems = false;

    if (content) {
      methods.setValue('subject', template.subject);
      methods.setValue('content', content);
    }
  };

  function convertMessageContent(
    text: string,
    quillRef: ReactQuill,
  ): string | undefined {
    quillRef.getEditor().deleteText(0, quillRef.getEditor().getLength());
    quillRef.getEditor().insertText(0, text);

    return convertVariablesToPills(text, quillRef);
  }

  const handleQuillRef = (quillRef: ReactQuill) => {
    setQuillRef(quillRef);
  };

  const getMessageTip = () => {
    if (MessageFields.message in errorFields) {
      return (
        <Text style={styles.errorMessage}>
          {errorFields[MessageFields.message]}
        </Text>
      );
    } else if (messageLength) {
      return (
        <Text style={styles.charactersCounter}>
          {getText('character-count', {
            max: BIG_MESSAGE_LIMIT,
            count: messageLength,
          })}
        </Text>
      );
    } else {
      return <></>;
    }
  };

  return (
    <WebModal
      ref={sheetRef}
      title={props.title}
      isScrollable
      size="lg"
      modalProps={{
        scrollViewStyle: { flex: 1 },
        contentContainerStyle: { flex: 1 },
      }}
      showDismissButton
      buttons={[
        {
          onPress: methods.handleSubmit(onEditCopyMessage),
          logger: { id: 'ok-edit-auto-config' },
          text: getText('ok'),
          hierarchy: 'primary',
        },
        {
          onPress: () => sheetRef.current?.hide(),
          logger: { id: 'cancel-edit-auto-config' },
          text: getText('close'),
          hierarchy: 'secondary',
        },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <View
          style={{
            width: 225,
            marginRight: theme.getSpacing(2),
          }}
        >
          <TextInput
            placeholder={getText('find-template')}
            autoComplete="off"
            autoCapitalize="none"
            style={messagingStyles.findTemplate}
            underlineColor="transparent"
            outlineColor={theme.palette.white}
            value={templatesFilter}
          />
          <View style={messagingStyles.templatesWrapper}>
            {filteredTemplates.length ? (
              filteredTemplates.map((template) => (
                <Fragment key={template.id}>
                  <TouchableOpacity onPress={() => onSelectTemplate(template)}>
                    <Text style={messagingStyles.template}>
                      {template.name}
                    </Text>
                  </TouchableOpacity>
                </Fragment>
              ))
            ) : (
              <Text style={messagingStyles.template}>
                {getText('no-results-found')}
              </Text>
            )}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <ErrorsContainer
            errorObject={errorObject}
            excludedErrors={EXCLUDED_ERRORS_MODAL}
          />
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <FormProvider {...methods}>
              <TextField
                label={getText('subject')}
                style={styles.input}
                value={props.defaultMessage}
                onChange={(e) => {
                  methods.setValue('subject', e.target.value);
                }}
                name={'subject'}
              />
            </FormProvider>
            {MessageFields.subject in errorFields && (
              <Text style={styles.errorMessage}>
                {errorFields[MessageFields.subject]}
              </Text>
            )}
          </View>
          <View style={{ marginBottom: theme.getSpacing(1) }}>
            <AutoConfigMessageBodyEditor
              methods={methods}
              setErrorFields={setErrorFields}
              errorFields={errorFields}
              handleQuillRef={handleQuillRef}
              defaultMessage={props.defaultMessage}
              onContentChange={onContentChange}
            />
            {getMessageTip()}
          </View>
          <Form methods={methods}>
            <View style={{ marginTop: theme.getSpacing(2) }}>
              <Form.Row>
                <Text
                  style={{
                    ...theme.lumistryFonts.text.medium.semiBold,
                  }}
                >
                  {getText('when-to-send-every-year')}
                </Text>
              </Form.Row>
              {containsSendDate ? (
                <Form.Row>
                  <Form.Column>
                    <DropdownSelect
                      placeholder="Month"
                      labelInlined={true}
                      disabled={true}
                      options={[]}
                      testID={'month-dropdown-select-option'}
                    />
                  </Form.Column>
                  <Form.Column>
                    <DropdownSelect
                      placeholder="Day"
                      labelInlined={true}
                      disabled={true}
                      options={[]}
                      testID={'day-dropdown-select-option'}
                    />
                  </Form.Column>
                  <Form.Column>
                    <DateTimePickerField
                      name={'time'}
                      type="time"
                      disabled={true}
                    />
                  </Form.Column>
                </Form.Row>
              ) : (
                <Form.Row style={{ width: '40%' }}>
                  <Form.Column>
                    <DateTimePickerField
                      name={'time'}
                      type="time"
                      disabled={true}
                    />
                  </Form.Column>
                </Form.Row>
              )}
            </View>
          </Form>
        </View>
      </View>
    </WebModal>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    height: theme.getSpacing(4) + theme.getSpacing(3),
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 16,
  },
  message: {
    height: 196,
  },
  note: {
    ...theme.fonts.regular,
    fontSize: 12,
    color: theme.palette.gray[500],
    marginTop: theme.getSpacing(2),
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 32,
    zIndex: 10,
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 12,
  },
  charactersCounter: {
    alignSelf: 'flex-start',
    marginTop: theme.getSpacing(1),
    fontSize: 12,
  },
  typeahead: {
    textAlign: 'left',
    marginBottom: theme.getSpacing(2),
    fontSize: 16,
  },
}));

interface EditAutoMessageConfigModalProps {
  title: string;
  defaultSubject?: string;
  defaultMessage?: string;
  editableColumns: AutoMessageConfigDtoEditableColumnsEnum[];
  hasPreSelectedItems: boolean;
  onSubmit?: (
    data: UpdateAutoMessageConfigDto,
    onComplete: () => void,
  ) => Promise<void>;
}

export const EditMessageConfigModal = React.forwardRef<
  BaseModalHandler,
  EditAutoMessageConfigModalProps
>(EditAutoMessageConfigModal);
