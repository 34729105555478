import { BookingDto } from '@digitalpharmacist/appointment-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { ArrowRightIcon, CalendarAltIcon, CalendarIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import moment from 'moment-timezone';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Pressable, View } from 'react-native';
import AppointmentService from '../../../../api/AppointmentService';
import { formatDateTimeApi } from '../../../../common/datetime-utils';
import { LoadingOverlay } from '../../../../components/LoadingOverlay';
import { useAppStateStore } from '../../../../store/app-store';
import { EmptyState } from '../empty-state/EmptyState';
import { AppointmentList } from '../upcoming-appointments/AppointmentList';
import { UpcomingAppointmentsTestIDs } from '../../DashboardTestIDs';

export const UpcomingAppointmentsWidget: FunctionComponent = () => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const [upcomingApp, setUpcomingApp] = useState<BookingDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { pharmacyId, locationId } = useAppStateStore();

  const styles = useStyles();
  const theme = useTheme();

  useFocusEffect(
    useCallback(() => {
      void fetchUpcomingAppointments();
    }, [locationId]),
  );

  const fetchUpcomingAppointments = () => {
    AppointmentService.getBookings({
      pharmacyId,
      locationId,
      offset: 0,
      limit: 5,
      orderBy: 'ASC',
      minEndDate: formatDateTimeApi(moment()),
    })
      .then(({ results }) => {
        setUpcomingApp(results);
      })
      .catch((error: Error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const handleUpcomingAppointmentClick = () => {
    navigation.navigate('schedule', {
      screen: 'appointments',
    });
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.titleContainer}
        onPress={handleUpcomingAppointmentClick}
        testID={UpcomingAppointmentsTestIDs.title}
      >
        <Icon icon={CalendarIcon} size={24} color={theme.palette.gray[700]} />
        <Text style={styles.titleText}>
          {getText('upcoming')} {getText('appointments')}
        </Text>
        <Icon icon={ArrowRightIcon} size={22} color={theme.palette.gray[700]} />
      </Pressable>
      {isLoading ? (
        <LoadingOverlay size={40} />
      ) : upcomingApp.length === 0 ? (
        <>
          <View style={styles.divider} />
          <EmptyState
            Icon={CalendarAltIcon}
            iconProps={{
              color: theme.palette.gray[700],
              size: 75,
              colorSecondary: theme.palette.primary[600],
            }}
            text={getText('dashboard-no-appointments')}
          />
        </>
      ) : (
        <AppointmentList upcomingApp={upcomingApp} />
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.roundness,
    minHeight: 300,
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(2),
    elevation: 3,
    shadowColor: theme.palette.gray[900],
    shadowOpacity: 0.06,
    shadowRadius: 1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    gap: theme.getSpacing(2),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
  },
  titleText: {
    fontSize: 18,
    lineHeight: 28,
    fontFamily: theme.fonts.medium.fontFamily,
    color: theme.palette.gray[900],
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(2),
  },
}));
