import { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';
import { Text } from 'assets/components/text';
import theme, { getSpacing } from 'assets/theme';

export const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = ({
  text,
  variant = 'dark',
  offset = 15,
  ...props
}) => {
  return (
    <ReactTooltip
      float
      delayShow={500}
      place="top"
      offset={offset}
      style={{
        ...tooltipStyles.base,
        ...tooltipStyles[variant],

        textAlign: 'left',
      }}
      {...props}
    >
      <Text style={[textStyles.base, textStyles[variant]]}>{text}</Text>
    </ReactTooltip>
  );
};
export interface TooltipProps extends ITooltip {
  text: string;
  variant?: 'light' | 'dark';
  offset?: number;
}
const tooltipStyles = {
  base: {
    borderRadius: theme.roundness,
    padding: `${getSpacing(1)} ${getSpacing(2)}`,
    opacity: 1,
    maxWidth: 350,
  },
  dark: {
    backgroundColor: theme.palette.gray[900],
  },
  light: {
    backgroundColor: theme.palette.white,
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  },
};

const textStyles = StyleSheet.create({
  base: {
    fontSize: 12,
  },
  dark: {
    color: theme.palette.white,
  },
  light: {
    color: theme.palette.gray[700],
  },
});
