import React, { useEffect } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import shallow from 'zustand/shallow';
import TasksDataTable from '../../tasks/tasks-data-table/TasksDataTable';
import { useTasksDataTableState } from '../../tasks/tasks-data-table/tasks-data-table-store';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useProSidebar } from 'react-pro-sidebar';
import TaskDetailsSidebar from '../../tasks/tasks-data-table/TaskDetailsSidebar';

import { zIndexAuto } from '../../common/theme';
import { useTasksFiltersState } from '../../tasks/tasks-filters/tasks-filters-store';
import { ListScreenProps } from '../../layout/TasksDrawer';
import { updateShowModalState } from '../../tasks/task-modal/task-modal-actions';
import { setCheckboxFilter } from '../../tasks/tasks-filters/tasks-filters-actions';
import { useUserState } from '../../store/user-store';
import { showTaskDetails } from '../../tasks/tasks-data-table/tasks-data-table-actions';
import EmptyState from '../../components/EmptyState/EmptyState';
import { getText } from 'assets/localization/localization';
import NoTasksImage from './NoTasksImage';
import { BulletPointStyle } from '../../components/EmptyState/BulletPoints/BulletPoint';
import { hasCheckboxFiltersOrSearch } from '../../tasks/tasks-data-table/tasks-data-table.utils';
import { PharmacyScreenContainer } from 'assets/layout';
import usePatientStore from '../patients/patient-store';

export const Tasks: React.FC<ListScreenProps> = ({ route }) => {
  const theme = useTheme();
  const styles = useStyles();
  const userId = useUserState((x) => x.data?.id);
  const { sidebarDetails: patientSidebarDetails } = usePatientStore();
  // temporary code for test purposes - to be deleted
  const { collapseSidebar } = useProSidebar();
  const { count, activeTab } = useTasksFiltersState(
    (state) => ({
      count: state.count,
      activeTab: state.activeTab,
    }),
    shallow,
  );
  const { taskDetails, status } = useTasksDataTableState();

  const noFilterSelected = () =>
    ['all_tasks', 'unresolved'].includes(activeTab) &&
    !hasCheckboxFiltersOrSearch();

  useEffect(() => {
    if (route.params?.newTaskModal === 'open') updateShowModalState(true);
    if (route.params?.tasksFilter === 'my-tasks') {
      setTimeout(() => {
        // temporary work around
        // is not working setting directly the state
        setCheckboxFilter({ assigned_user_id: userId });
      }, 1);

      if (route.params.taskId) {
        void showTaskDetails(route.params.taskId);
        collapseSidebar(false);
      }
    }
  }, [
    route.params?.newTaskModal,
    route.params?.tasksFilter,
    route.params?.taskId,
  ]);

  const handleShowModal = () => {
    updateShowModalState(true);
  };

  return (
    <>
      <PharmacyScreenContainer>
        <View style={styles.content}>
          <View
            style={{
              flexGrow: 1,
              zIndex: zIndexAuto,
            }}
          >
            {status == 'loading' && <LoadingOverlay />}

            {/* Show a different message when there are no tasks at all.
              Currently we don't have a way to tell if there are no tasks
              in general or just no tasks for a certain filter, so as a
              workaround we are checking if any filters are selected - if
              not and we have 0 results, it means that there are no tasks
              in general. */}
            {status !== 'loading' &&
              noFilterSelected() &&
              count.total === 0 && (
                <EmptyState
                  image={<NoTasksImage />}
                  title={getText('welcome-to-tasks')}
                  subtitles={[getText('create-new-task')]}
                  buttonText={getText('new-task')}
                  onButtonPress={handleShowModal}
                  buttonLoggerId="new-task"
                  bulletPoints={[
                    getText('delegate-work-among-employees'),
                    getText('communicate-a-sync-with-coworkers'),
                    getText('track-week-month-quarter-year-tasks'),
                    getText('example-task-check-shelves'),
                  ]}
                  bulletPointStyle={BulletPointStyle.Bullet}
                />
              )}
            <View
              style={
                status == 'loading' || (noFilterSelected() && count.total === 0)
                  ? styles.hideTable
                  : styles.tableContainer
              }
            >
              <TasksDataTable collapseSidebar={collapseSidebar} />
            </View>
          </View>
          {!!taskDetails && !patientSidebarDetails && <TaskDetailsSidebar />}
        </View>
      </PharmacyScreenContainer>
    </>
  );
};

export default Tasks;

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.getSpacing(4),
    flexDirection: 'row',
    height: '100%',
    flex: 1,
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.getSpacing(4),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  hideTable: {
    display: 'none',
  },
  tableContainer: {
    position: 'relative',
    zIndex: 1,
  },
}));
