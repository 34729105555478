import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text/Text';
import { getText } from 'assets/localization/localization';
import { PaymentsAndDeliveryImage } from 'assets/icons';
import { Button } from 'assets/components/button';
import { useUserState } from '../../../store/user-store';
import { subscribeToPaymentAndDelivery } from './billing-settings-actions';
import { useAppStateStore } from '../../../store/app-store';
import { logError } from 'assets/logging/logger';

export const PaymentAndDeliveryCard: FunctionComponent<
  PaymentAndDeliveryCardProps
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const userId = useUserState().data?.id;
  const { locationId, pharmacyId } = useAppStateStore();

  const handleOnPress = () => {
    if (!userId) return logError(new Error(getText('user-not-found')));
    subscribeToPaymentAndDelivery(pharmacyId, locationId, userId);
  };

  return (
    <View style={styles.container}>
      <View style={styles.rowSpaceBetween}>
        <View style={styles.textView}>
          <Text style={styles.title}>
            {getText('payment-and-delivery-title')}
          </Text>
          <Text style={styles.description}>
            {getText('payment-and-delivery-description')}
          </Text>
          <Button
            hierarchy="secondary"
            logger={{
              id: 'notify-me-button',
            }}
            onPress={handleOnPress}
            style={styles.button}
          >
            {getText('notify-me')}
          </Button>
        </View>
        <View style={styles.imageView}>
          <PaymentsAndDeliveryImage color={theme.palette.primary[100]} />
        </View>
      </View>
    </View>
  );
};

export interface PaymentAndDeliveryCardProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    paddingHorizontal: theme.getSpacing(4),
    backgroundColor: theme.palette.primary[50],
    marginBottom: theme.getSpacing(1),
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textView: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: 400,
    marginVertical: theme.getSpacing(1),
  },
  imageView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    maxWidth: 400,
  },
  title: {
    ...theme.lumistryFonts.text.xLarge.bold,
    color: theme.palette.gray[900],
    marginTop: theme.getSpacing(1),
  },
  description: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(1),
  },
  button: {
    marginVertical: theme.getSpacing(2),
  },
}));
