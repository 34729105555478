import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const NoLockIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M4.66676 7.33325V4.66659M11.3334 7.33325V4.66659C11.3334 3.78253 10.9822 2.93468 10.3571 2.30956C9.732 1.68444 8.88415 1.33325 8.0001 1.33325C7.11604 1.33325 6.2682 1.68444 5.64307 2.30956C5.62082 2.33182 5.59891 2.35436 5.57735 2.37717M1.6001 1.59992L7.33343 7.33325M15.2001 15.1999L13.8823 13.8821M10.5334 7.33325H12.6668C13.4031 7.33325 14.0001 7.93021 14.0001 8.66659V10.7999M7.33343 7.33325H3.33343C2.59705 7.33325 2.0001 7.93021 2.0001 8.66659V13.3333C2.0001 14.0696 2.59705 14.6666 3.33343 14.6666H12.6668C13.2075 14.6666 13.6731 14.3447 13.8823 13.8821M7.33343 7.33325L13.8823 13.8821"
        stroke="#748995"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  );
};
