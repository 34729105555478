import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import { GridReadyEvent, GridApi } from '@ag-grid-community/core';
import { useFocusEffect } from '@react-navigation/native';
import {
  BookingPageDto,
  BookingStatus,
} from '@digitalpharmacist/appointment-service-client-axios';
import { makeStyles, useTheme } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { ClockIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

import NoResultsOverlay from '../../components/NoResultsOverlay';
import AppointmentService from '../../api/AppointmentService';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  calculatePatientAge,
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_DATE_TIME_API_FORMAT,
  DEFAULT_PHARMACY_TIMEZONE,
  formatDate,
  formatUTCToRelative,
} from '../../common/datetime-utils';
import {
  AppointmentsFindParams,
  minutesDifference,
} from '../appointments-list/appointments-list.utils';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { useAppointmentFiltersState } from '../appointment-filters/appointment-filters-store';
import { composeAppointmentFiltersMessage } from '../appointment-filters/appointment-filters.utils';
import { AppointmentDetailsSidebar } from '../appointments-list/AppointmentDetailsSidebar';
import { useProSidebar } from 'react-pro-sidebar';
import {
  setAppointmentVisibility,
  showAppointmentDetails,
} from '../appointments-list/appointments-list-actions';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../common/constants';
import usePatientStore from '../../screens/patients/patient-store';

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

export const AppointmentsCancelledList: FunctionComponent<
  AppointmentsCancelledListProps
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const [paginatedDatasource] = useState(
    AppointmentService.getAppointmentsPaginatedDatasource(),
  );
  const gridApiRef = useRef<GridApi>();
  const filters = useAppointmentFiltersState((state) => state.filters);
  const isCustomFilter = useAppointmentFiltersState(
    (state) => state.isCustomFilter,
  );
  const { collapseSidebar } = useProSidebar();

  const [tooltipData, setTooltipData] = useState<TooltipProps>();

  const [columnDefs] = useState([
    {
      field: 'full_name',
      colId: 'patient_record_first_name',
      sortable: true,
      headerName: getText('name'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'patient_record_date_of_birth',
      headerName: getText('birthdate'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={`${formatDate(value)} (${calculatePatientAge(value)})`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'title',
      headerName: getText('service'),
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'appointment_group_type',
      headerName: getText('service-category'),
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 140,
      field: 'duration',
      headerName: getText('duration'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={`${value} min`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 200,
      field: 'startTime',
      colId: 'start_time',
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={formatUTCToRelative(
            convertDateTimeFromUtcToPharmacyTimezone(
              value,
              DEFAULT_PHARMACY_TIMEZONE,
              DEFAULT_DATE_TIME_API_FORMAT,
            ),
            undefined,
            true,
            'US',
            undefined,
            undefined,
            true,
          )}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),

      headerName: getText('time'),
      headerClass: 'data-grid-header-right-aligned',
      cellClass: 'data-grid-cell-right-aligned',
    },
  ]);

  const { setSidebarDetails: setPatientSidebarDetails } = usePatientStore();

  const handleCancel = () => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshServerSideStore();
    }
  };

  useFocusEffect(
    useCallback(() => {
      gridApiRef.current?.dispatchEvent({ type: 'filterChanged' });
    }, [filters]),
  );

  return (
    <>
      <View style={styles.container}>
        <View style={styles.flexOne}>
          <DataGrid
            gridOptions={{
              onGridReady(event: GridReadyEvent) {
                gridApiRef.current = event.api;
              },
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressRowClickSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: true,
              paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
              cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE,
              rowModelType: 'serverSide',
              serverSideStoreType: 'partial',
              serverSideDatasource: paginatedDatasource,
              loadingCellRendererSelector: (params) => {
                // This is a temporary workaround to only show one loading indicator per table row
                // What we want to do in the future is create a RowLoadingPlaceholder which would indicate the loading state for the row
                if (
                  params.rowIndex == 0 ||
                  (params.rowIndex % 5 == 0 && params.rowIndex % 10 !== 0)
                ) {
                  return {
                    component: LoadingOverlay,
                  };
                }

                return {
                  component: NoLoading,
                };
              },
              context: {
                transformResponse(params: BookingPageDto) {
                  const { results, total } = params;

                  const resultsWithUserData = results.map((x) => {
                    return {
                      ...x,
                      full_name: `${x.patient_record_first_name} ${x.patient_record_last_name}`,
                      duration: minutesDifference(x.endTime, x.startTime),
                    };
                  });

                  return {
                    total,
                    results: resultsWithUserData,
                  };
                },
                transformRequest(params: AppointmentsFindParams) {
                  return {
                    ...params,
                    status: BookingStatus.Cancelled,
                    ...filters,
                  };
                },
              },
              noRowsOverlayComponent: () => (
                <NoResultsOverlay
                  title={getText('no-appointments')}
                  subtitle={getText('check-later')}
                  icon={
                    <ClockIcon size={100} color={theme.palette.gray[300]} />
                  }
                  layout="vertical"
                />
              ),
              onCellClicked(event) {
                if (event.column && event.column.getColId() !== 'actions') {
                  setAppointmentVisibility(true);
                  setPatientSidebarDetails(undefined);
                  showAppointmentDetails(event.data.id);
                  collapseSidebar(false);
                }
              },
            }}
            gridToolbarProps={{
              titleProps: {
                title: `${getText('canceled')}  ${getText('appointments')}`,
              },
              toolbarContentBoxProps: {
                isVisible: isCustomFilter,
                title: getText('appointments-filters-info-title'),
                description: composeAppointmentFiltersMessage(),
              },
            }}
          />
        </View>
        {tooltipData && (
          <Tooltip text={tooltipData.value} id={tooltipData.id} />
        )}
        <AppointmentDetailsSidebar onCancel={handleCancel} isReadOnly={true} />
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  flexOne: {
    flex: 1,
  },
}));

export interface AppointmentsCancelledListProps {}

interface CellRendererProps {
  value: string;
  rowIndex: number;
}

interface TooltipProps {
  value: string;
  id: string;
}
