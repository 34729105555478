import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { makeStyles, useTheme } from '../../../theme';
import {
  View,
  Platform,
  StyleProp,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from 'react-native';
import { PlatformType } from '../../types';
import { Text } from '../../text';
import { Button, ButtonProps } from '../../button';
import { CheckboxInput, CheckboxInputMode } from '../../checkbox';
import { IconButton, IconButtonProps } from '../../icon-button';
import {
  TrashWithStripesIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../../../icons';
import { GridApi, ColumnApi, Column } from '@ag-grid-community/core';
import { ToolbarSortingDropDown } from './ToolbarSortingDropDown';
import { ToolbarMenu, ToolbarMenuItemProps } from './ToolbarMenu';
import { Avatar, AvatarProps } from '../../avatar';
import { DataGridOptions, DataGridPaginationInfo } from '../types';
import { ToolbarSelectAllBox } from './ToolbarSelectAllBox';
import { ToolbarContentBox } from './ToolbarContentBox';
import { Tooltip } from '../../../../../apps/pharmacy/modules/components/Tooltip/Tooltip';
import { GLOBAL_SEARCH_FIELD } from '../constant';
import { useDebounceEffect } from '../../../hooks';
import { InputColor, SearchInput } from '../../search-input/SearchInput';
import { getText } from '../../../localization/localization';

export const DataGridToolbar: FunctionComponent<
  PropsWithChildren<DataGridToolbarProps>
> = ({
  titleProps,
  inputSearchProps,
  enableSorting,
  headerChildren,
  actionButtonsProps,
  iconActionButtonsProps,
  toolbarContentBoxProps,
  actionLinkProps,
  platform = Platform.OS,
  gridApi,
  gridColumnApi,
  children,
  onSelectAll,
  paginationTrigger,
  enableSelectAllBox = true,
}) => {
  const theme = useTheme();
  const defaultSearchInputValue = inputSearchProps?.inputValue ?? '';

  const styles = useStyles();
  if (platform !== 'web')
    throw `${Platform.OS} not supported from [DataGridToolbar] component. It support only web!`;

  const columns: Column[] | null | undefined = gridColumnApi
    ?.getAllColumns()
    ?.filter(
      (c) => c.getColDef().field && c.getColDef().field !== GLOBAL_SEARCH_FIELD,
    );

  const [selectedSort, setSelectedSort] = useState<{
    label?: string | undefined;
    value: any;
  }>();
  const [searchInputValue, setSearchInputValue] = useState<string>(
    defaultSearchInputValue,
  );
  const [selectAllValue, setSelectAllValue] = useState<boolean>(false);
  const [selectAllGlobal, setSelectAllGlobal] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] =
    useState<DataGridPaginationInfo>();

  const hasMultiplePages = gridApi && gridApi?.paginationGetTotalPages() > 1;

  const handleSelectAll = (checked: boolean) => {
    if (onSelectAll !== undefined) {
      onSelectAll(checked);
      return;
    }

    if (checked)
      gridApi?.getRenderedNodes().forEach((node) => {
        node.setSelected(checked);
      });
    else gridApi?.deselectAll();
    setSelectAllValue(!checked);
  };

  const onSortingChanged = (option: {
    label?: string | undefined;
    value: any;
  }) => {
    setSelectedSort(option);
    gridColumnApi?.getAllColumns()?.forEach((c) => c.setSort(null));
    gridColumnApi?.getColumn(option.value)?.setSort('asc');
    gridApi?.onSortChanged();
  };

  const updatePaginationInfo = () => {
    if (gridApi?.paginationIsLastPageFound()) {
      const currentLastRowNumber =
        ((gridApi?.paginationGetCurrentPage() || 0) + 1) *
        (gridApi?.paginationGetPageSize() || 0);

      const tempPageInfo = {
        currentFirstRowNumber:
          (gridApi?.paginationGetCurrentPage() || 0) *
            (gridApi?.paginationGetPageSize() || 0) +
          1,
        currentLastRowNumber:
          currentLastRowNumber < gridApi?.paginationGetRowCount()
            ? currentLastRowNumber
            : gridApi?.paginationGetRowCount(),
        totalCount: gridApi?.paginationGetRowCount(),
      } as DataGridPaginationInfo;

      tempPageInfo.isOnFirstPage = tempPageInfo.currentFirstRowNumber === 1;
      tempPageInfo.isOnLastPage =
        currentLastRowNumber >= tempPageInfo.totalCount;

      setPaginationInfo(tempPageInfo);
    }
  };

  const goToPreviousPage = () => {
    gridApi?.paginationGoToPreviousPage();
    updatePaginationInfo();
  };

  const goToNextPage = () => {
    gridApi?.paginationGoToNextPage();
    updatePaginationInfo();
  };

  const onFilterTextChanged = (text: string) => {
    // In case of existing inputSearchProps?.inputValue this will be set in the effect below
    // We don't want to cause additional render
    if (inputSearchProps?.inputValue === undefined) {
      setSearchInputValue(text);
    }

    const gridOptions = (gridApi as any)?.gridOptionsWrapper
      .gridOptions as DataGridOptions;

    if (gridOptions?.rowModelType === 'clientSide') {
      if (inputSearchProps?.onChange) {
        inputSearchProps.onChange(text);
      } else {
        gridApi?.setQuickFilter(text);
      }
      return;
    }
    if (inputSearchProps) {
      inputSearchProps?.onChange?.(text);
    }
  };

  useDebounceEffect(() => {
    const gridOptions = (gridApi as any)?.gridOptionsWrapper
      .gridOptions as DataGridOptions;

    if (
      inputSearchProps &&
      (gridOptions?.rowModelType === 'serverSide' ||
        gridOptions?.rowModelType === 'infinite')
    ) {
      const filterModel = gridApi?.getFilterModel();
      gridApi?.setFilterModel({
        ...filterModel,
        [GLOBAL_SEARCH_FIELD]: {
          filter: searchInputValue,
          filterType: 'text',
          type: 'contains',
        },
      });
    }
  }, [searchInputValue]);

  const handleOnDeselectAll = () => {
    gridApi?.deselectAll();
    gridApi?.redrawRows();

    setSelectAllValue(false);
  };

  const handleOnSelectAllGlobal = () => {
    if (gridApi?.getModel().getType() === 'clientSide') {
      gridApi?.selectAll();
    }

    if (gridApi && selectAllGlobal) {
      // In case of switching between selecting all tasks/items and selecting only the ones on the current page
      // if we had selected all globally we want to unselect the nodes/tasks on the rest of the pages.
      const selectedNodes = gridApi?.getSelectedNodes();
      const renderedNodeIds = gridApi
        ?.getRenderedNodes()
        .map((task) => task.id);

      if (selectedNodes?.length > renderedNodeIds?.length) {
        selectedNodes.forEach((node) => {
          if (!renderedNodeIds?.includes(node.id)) {
            node.setSelected(false);
          }
        });
      }
    }
    setSelectAllGlobal((prevState) => !prevState);
  };

  useEffect(() => {
    if (!gridApi) return;

    const onSelectionChange = () => {
      const selectedNodes = gridApi.getSelectedNodes();

      let headerCheckboxValue = selectedNodes.length !== 0;
      if (headerCheckboxValue) {
        gridApi.getRenderedNodes().forEach((node) => {
          if (!node.isSelected()) {
            headerCheckboxValue = false;
            return;
          }
        });
      }

      if (!headerCheckboxValue) {
        setSelectAllGlobal(headerCheckboxValue);
      }

      setSelectAllValue(headerCheckboxValue);
    };

    const onPaginationChanged = () => {
      if (selectAllGlobal) {
        if (gridApi?.getModel().getType() === 'serverSide') {
          gridApi.getRenderedNodes().forEach((node) => {
            node.setSelected(selectAllGlobal);
          });

          gridApi.redrawRows();
          return;
        }
      }
      onSelectionChange();
    };

    gridApi?.addEventListener('selectionChanged', onSelectionChange);
    gridApi?.addEventListener('paginationChanged', onPaginationChanged);

    return () => {
      gridApi?.removeEventListener('selectionChanged', onSelectionChange);
      gridApi?.removeEventListener('paginationChanged', onPaginationChanged);
    };
  }, [gridApi, selectAllGlobal, searchInputValue]);

  useEffect(() => {
    updatePaginationInfo();
  }, [paginationTrigger]);

  // In case we want to control the input value outside of the DataGrid component, used for resetting the input under some conditions
  // Another use case would be setting the filters along with the search term outside of the search input
  useEffect(() => {
    if (inputSearchProps?.inputValue !== undefined) {
      setSearchInputValue(inputSearchProps?.inputValue);
    }
  }, [inputSearchProps?.inputValue]);

  const PaginationDisplay = () => {
    return (
      <View style={styles.paginationContainer}>
        <Text style={{ marginRight: theme.getSpacing(1) }}>
          {`${paginationInfo?.currentFirstRowNumber} - ${paginationInfo?.currentLastRowNumber} of ${paginationInfo?.totalCount}`}
        </Text>
        <IconButton
          disabled={paginationInfo?.isOnFirstPage}
          icon={ChevronLeftIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-back' }}
          onPress={goToPreviousPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: null,
            width: null,
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
        <IconButton
          disabled={paginationInfo?.isOnLastPage}
          icon={ChevronRightIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-forward' }}
          onPress={goToNextPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: null,
            width: null,
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.toolbarContainer}>
        {titleProps && (
          <View style={styles.titleContainer}>
            {titleProps.avatarProps && (
              <View style={styles.avatar}>
                <Avatar size={24} {...titleProps.avatarProps} />
              </View>
            )}
            {titleProps.title && (
              <Text style={styles.title}>{titleProps.title}</Text>
            )}
            {titleProps.subtitle && (
              <Text style={styles.subtitle}>{titleProps.subtitle}</Text>
            )}
          </View>
        )}
        {inputSearchProps && (
          <View style={styles.searchContainer}>
            <SearchInput
              placeholder={inputSearchProps.placeholder ?? getText('search')}
              onChange={onFilterTextChanged}
              inputValue={searchInputValue}
              inputColor={InputColor.Dark}
            />
          </View>
        )}
        {enableSorting && columns && (
          <View style={styles.sortContainer}>
            <ToolbarSortingDropDown
              value={selectedSort?.value}
              size={inputSearchProps?.size}
              onChange={onSortingChanged}
              options={columns.map((sortingOption) => ({
                label:
                  sortingOption.getColDef().headerName ||
                  sortingOption.getColDef().field ||
                  'Missing header name',
                value:
                  sortingOption.getColDef().field ?? sortingOption.getColId(),
              }))}
            />
          </View>
        )}

        {headerChildren}

        <View style={styles.rightContainer}>
          {!!(paginationInfo?.totalCount && !iconActionButtonsProps) && (
            <PaginationDisplay />
          )}
          {actionButtonsProps &&
            actionButtonsProps.actionButtons
              .slice(0, actionButtonsProps.maxActionToShow)
              .map((actionButton, index) => (
                <View style={styles.actionButtonContainer} key={index}>
                  <Button
                    {...actionButton}
                    onPress={() =>
                      actionButton.onPress({
                        api: gridApi,
                        columnApi: gridColumnApi,
                        isSelectAllGlobal: selectAllGlobal,
                      })
                    }
                  >
                    {actionButton.text}
                  </Button>
                </View>
              ))}
          {/* TODO: add menu with hidden actions */}
          {actionButtonsProps &&
            actionButtonsProps.actionButtons.length >
              actionButtonsProps.maxActionToShow && (
              <View style={styles.actionButtonContainer}>
                <ToolbarMenu
                  anchorPosition={'left'}
                  options={actionButtonsProps.actionButtons
                    .slice(
                      actionButtonsProps.maxActionToShow,
                      actionButtonsProps.actionButtons.length,
                    )
                    .map(
                      (menuOption) =>
                        ({
                          title: menuOption.text,
                          onPress: menuOption.onPress,
                          icon: menuOption.icon,
                        }) as ToolbarMenuItemProps,
                    )}
                />
              </View>
            )}
        </View>
      </View>
      {iconActionButtonsProps && (
        <View style={styles.iconActionButtonContainer}>
          <View style={styles.checkboxContainer}>
            <CheckboxInput
              onPress={handleSelectAll}
              checked={selectAllValue}
              mode={CheckboxInputMode.FLAT}
            />
          </View>
          <View
            style={{
              borderLeftWidth: 1,
              marginRight: theme.getSpacing(2),
              marginLeft: theme.getSpacing(2),
              borderColor: theme.palette.gray[300],
            }}
          />
          {iconActionButtonsProps.actionButtons
            .slice(0, iconActionButtonsProps.maxActionToShow)
            .map((iconActionButton, index) => (
              <Fragment key={index}>
                <View
                  nativeID={iconActionButton?.tooltipText}
                  style={styles.iconActionContainer}
                >
                  <IconButton
                    {...iconActionButton}
                    color={theme.palette.gray[700]}
                    style={styles.actionButtonIcon}
                    onPress={() =>
                      iconActionButton.onPress({
                        api: gridApi,
                        columnApi: gridColumnApi,
                        isSelectAllGlobal: selectAllGlobal,
                      })
                    }
                  />
                </View>
                <Tooltip
                  text={iconActionButton?.tooltipText ?? ''}
                  anchorId={iconActionButton?.tooltipText ?? undefined}
                />
              </Fragment>
            ))}

          {iconActionButtonsProps?.onDeletePress && (
            <Fragment>
              <View
                nativeID={iconActionButtonsProps?.deleteTooltipText}
                style={styles.iconActionContainer}
              >
                <IconButton
                  icon={TrashWithStripesIcon}
                  logger={{ id: 'grid-toolbar-icon-action-delete' }}
                  onPress={() =>
                    iconActionButtonsProps.onDeletePress!({
                      api: gridApi,
                      columnApi: gridColumnApi,
                      isSelectAllGlobal: selectAllGlobal,
                    })
                  }
                  size={24}
                  color={theme.palette.gray[700]}
                  style={styles.actionButtonIcon}
                />
              </View>
              <Tooltip
                text={iconActionButtonsProps?.deleteTooltipText ?? ''}
                anchorId={
                  iconActionButtonsProps?.deleteTooltipText ?? undefined
                }
              />
            </Fragment>
          )}
          <View style={styles.iconActionContainer}>
            {/* TODO: add menu with hidden actions */}
            {iconActionButtonsProps &&
              iconActionButtonsProps.actionButtons.length >
                iconActionButtonsProps.maxActionToShow && (
                <ToolbarMenu
                  options={iconActionButtonsProps.actionButtons
                    .slice(
                      iconActionButtonsProps.maxActionToShow,
                      iconActionButtonsProps.actionButtons.length,
                    )
                    .map(
                      (menuOption) =>
                        ({
                          title: menuOption.text,
                          onPress: () =>
                            menuOption.onPress({
                              api: gridApi,
                              columnApi: gridColumnApi,
                              isSelectAllGlobal: selectAllGlobal,
                            }),
                          icon: menuOption.icon,
                          disabled: menuOption.disabled,
                        }) as ToolbarMenuItemProps,
                    )}
                />
              )}
          </View>
          <View style={styles.rightContainer}>
            {!!paginationInfo?.totalCount && <PaginationDisplay />}
          </View>
        </View>
      )}
      {actionLinkProps && (
        <View style={styles.actionLinkContainer}>
          {actionLinkProps.title && (
            <Text style={styles.linkActionContainer}>
              {actionLinkProps.title}
            </Text>
          )}
          {actionLinkProps.actionLinks
            .slice(0, actionLinkProps.maxActionToShow)
            .map((actionLink, index) => (
              <View style={styles.linkActionContainer} key={index}>
                <Text
                  style={{
                    margin: 0,
                    padding: 0,
                    height: null,
                    width: null,
                    color: theme.palette.primary[600],
                  }}
                  onPress={() =>
                    actionLink.onPress({
                      api: gridApi,
                      columnApi: gridColumnApi,
                      isSelectAllGlobal: selectAllGlobal,
                    })
                  }
                >
                  {actionLink.text}
                </Text>
              </View>
            ))}
        </View>
      )}

      {hasMultiplePages && enableSelectAllBox && selectAllValue && (
        <ToolbarSelectAllBox
          api={gridApi}
          onSelectAll={handleOnSelectAllGlobal}
          onDeselectAll={handleOnDeselectAll}
          isGlobalSelect={selectAllGlobal}
        />
      )}

      {toolbarContentBoxProps && toolbarContentBoxProps.isVisible && (
        <>
          {!toolbarContentBoxProps?.component && (
            <ToolbarContentBox
              title={toolbarContentBoxProps?.title}
              description={toolbarContentBoxProps?.description}
              flat={toolbarContentBoxProps?.flat}
              style={toolbarContentBoxProps?.style}
            />
          )}

          {/* Custom component provided to the Data Grid */}
          {toolbarContentBoxProps?.component}
        </>
      )}

      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    backgroundColor: theme.palette.white,
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  avatar: {
    marginRight: theme.getSpacing(2),
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 26,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  subtitle: {
    ...theme.fonts.regular,
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[600],
    marginRight: theme.getSpacing(2),
    alignSelf: 'flex-end',
  },
  toolbarContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  searchContainer: {
    borderColor: 'white',
  },
  sortContainer: { alignSelf: 'flex-end', marginLeft: theme.getSpacing(2) },
  rightContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  paginationContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionButtonContainer: {
    alignSelf: 'center',
    marginLeft: theme.getSpacing(2),
  },
  iconActionButtonContainer: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(2),
    alignItems: 'center',
    zIndex: 1,
  },
  actionLinkContainer: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(2),
    alignItems: 'center',
  },
  iconActionContainer: {
    marginRight: theme.getSpacing(2),
  },
  linkActionContainer: {
    marginRight: theme.getSpacing(2),
  },
  actionButtonIcon: {
    margin: 0,
    padding: 0,
    height: null,
    width: null,
  },

  checkboxContainer: {},
  divider: { marginTop: theme.getSpacing(2) },
  contentContainer: { marginTop: theme.getSpacing(2), flex: 1 },
}));

export interface DataGridToolbarProps {
  titleProps?: {
    title: string;
    avatarProps?: AvatarProps;
    subtitle?: string;
  };
  inputSearchProps?: {
    size: keyof Sizes;
    placeholder?: string;
    inputValue?: string;
    onChange?: (value: string) => void;
  };
  toolbarContentBoxProps?: {
    title?: string;
    description?: string;
    style?: StyleProp<ViewStyle>;
    testID?: string;
    flat?: boolean;
    isVisible?: boolean;
    component?: React.ReactElement;
  };
  enableSorting?: boolean;
  headerChildren?: JSX.Element;
  actionButtonsProps?: GridActionButtonProps;
  iconActionButtonsProps?: GridIconActionButtonProps;
  actionLinkProps?: GridActionLinkProps;
  platform?: PlatformType;
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onSelectAll?: (checked: boolean) => void;
  paginationTrigger?: boolean;
  enableSelectAllBox?: boolean;
}

export const DataGridToolbarTestIDs = {
  name: 'DataGridToolbar-name',
};

export type GridActionButtonParams = {
  api: GridApi | undefined;
  columnApi: ColumnApi | undefined;
  isSelectAllGlobal: boolean;
};

export type GridButtonProps = Omit<ButtonProps, 'onPress'> & {
  onPress: (params: GridActionButtonParams) => void;
  text: string;
};

export type IconButtonGridProps = Omit<IconButtonProps, 'onPress'> & {
  onPress: (params: GridActionButtonParams) => void;
  text: string;
  tooltipText?: string;
};

export type GridActionButtonProps = {
  maxActionToShow: number;
  actionButtons: Array<GridButtonProps>;
};

export type GridActionLinkProps = {
  maxActionToShow: number;
  title?: string;
  actionLinks: Array<GridButtonProps>;
};

export type GridIconActionButtonProps = {
  maxActionToShow: number;
  actionButtons: Array<IconButtonGridProps>;
  onDeletePress?: (params: GridActionButtonParams) => void;
  deleteTooltipText?: string;
};

export interface Sizes {
  min: number;
  sm: number;
  lg: number;
}
