import { BulkStatus } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  BulkFilters,
  BulkMessagesState,
  IBulkMessage,
  StatusFilter,
} from 'assets/types/messageTypes';
import { useBulkMessagesStore } from './bulk-messages-store';
import unifiedCommsService from '../../../../api/UnifiedCommsService';
import { convertToBulkMessageType } from 'assets/utils/bulkMessagesUtils';
import { getMessageAuthor } from '../../components/get-message-author';

export const setBulks = (bulks: IBulkMessage[]) => {
  useBulkMessagesStore.setState({ bulks });
};

export const addBulkIfItDoesNotExist = (bulk: IBulkMessage) => {
  useBulkMessagesStore.setState((state) => {
    const newState = { ...state };
    const { bulks } = newState;

    const foundBulk = bulks.find((item) => item.id === bulk.id);

    if (!foundBulk) {
      newState.bulks = [bulk, ...newState.bulks];
      newState.bulksTotalCount++;
    }

    return newState;
  });
};

export const changeStatusOfBulk = (bulk: {
  id: string;
  status: BulkStatus;
}) => {
  useBulkMessagesStore.setState((state) => {
    const newState = { ...state };
    const { bulks } = newState;

    const foundBulkIndex = bulks.findIndex((item) => item.id === bulk.id);

    if (foundBulkIndex != -1) {
      const newBulks = [...newState.bulks];

      newBulks[foundBulkIndex] = {
        ...newState.bulks[foundBulkIndex],
        status: bulk.status,
      };

      newState.bulks = newBulks;
    }

    return newState;
  });
};

export const addBulk = async (bulkId: string, locationId: string) => {
  const { bulks } = useBulkMessagesStore.getState();
  const foundBulk = bulks.find((item) => item.id === bulkId);
  let newBulk: IBulkMessage | undefined;
  if (!foundBulk) {
    const dataBulks = await unifiedCommsService.getBulks(locationId);
    const bulk = dataBulks.find((item) => item.id === bulkId);

    if (bulk) {
      const author = await getMessageAuthor(bulk.author_id, bulk.author_type);
      newBulk = convertToBulkMessageType(bulk, author);
    }
  }

  useBulkMessagesStore.setState((state) => {
    const newState = { ...state };

    if (newBulk) {
      const isCorrectFilter =
        newState.filters.status === 'all' ||
        newBulk.status === newState.filters.status;

      if (isCorrectFilter) {
        newState.bulks = [newBulk, ...newState.bulks].sort(
          (a, b) =>
            new Date(b.scheduled_time).getTime() -
            new Date(a.scheduled_time).getTime(),
        );

        newState.bulksTotalCount++;
      }
    }
    return newState;
  });
};

export const setIsNewBulkChatModalOpen = (isOpen: boolean) => {
  useBulkMessagesStore.setState({ isNewBulkMessageModalOpen: isOpen });
};

export const setInitialFilters = () => {
  useBulkMessagesStore.setState({
    filters: {
      status: StatusFilter.All,
      message_type: {
        secure: false,
        notSecure: false,
      },
    },
  });
};

export const setMessageTypeFilters = (messageType: string) => {
  useBulkMessagesStore.setState((state) => {
    const newState: BulkMessagesState = { ...state };

    const newFilters: BulkFilters = { ...newState.filters };

    const newMessageType = { ...newFilters.message_type };

    newMessageType[messageType] = !newMessageType[messageType];

    newFilters.message_type = newMessageType;

    newState.filters = newFilters;

    return newState;
  });
};

export const setStatusTypeFilters = (status: StatusFilter) => {
  useBulkMessagesStore.setState((state) => {
    const newState: BulkMessagesState = { ...state };

    const newFilters: BulkFilters = { ...newState.filters };

    newFilters.status = status;

    newState.filters = newFilters;

    return newState;
  });
};

export const setBulksTotalCount = (count: number) => {
  useBulkMessagesStore.setState({ bulksTotalCount: count });
};
