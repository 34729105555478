import {
  BrandingDto,
  PharmacyWebsiteDto,
  PharmacyAppDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';

export interface BrandingSettingsState {
  showModalBrandingInfo: boolean;
  showModalWebsite: boolean;
  showModalApp: boolean;
  branded_colors: BrandingDto;
  pharmacyWebsite?: PharmacyWebsiteDto;
  pharmacyApp?: PharmacyAppDto;
}

export const useAppSettingsState = create<BrandingSettingsState>(() => ({
  showModalBrandingInfo: false,
  showModalWebsite: false,
  showModalApp: false,
  branded_colors: {} as BrandingDto,
}));
