import {
  PharmacyAppDto,
  UpdatePharmacyAppDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { View } from 'react-native';
import {
  checkCapitalizeAllWords,
  validateOptionalNumber,
  validateOptionalURL,
} from '../../../../../utils';
import { canFactory } from '../../../../common/guards/permission-guard';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { useAppStateStore } from '../../../../store/app-store';
import {
  createPharmacyApp,
  setShowModalApp,
  updatePharmacyApp,
} from '../app-settings-actions';
import { useAppSettingsState } from '../app-settings-store';

const DEFAULT_FORM_VALUE = {
  display_name: '',
  marketplace_name: '',
  android_identifier: '',
  android_store_link: '',
  apple_identifier: '',
  apple_developer_account: '',
  apple_store_link: '',
  ios_app_id: '',
  ios_team_id: '',
  ios_bundle_id: '',
};

const convertToFormValues = (appValue?: PharmacyAppDto): AppFormProps => {
  return appValue
    ? {
        display_name: appValue.display_name,
        marketplace_name: appValue.marketplace_name,
        android_identifier: appValue.android_identifier,
        android_store_link: appValue.android_store_link,
        apple_identifier: appValue.apple_identifier,
        apple_developer_account: appValue.apple_developer_account,
        apple_store_link: appValue.apple_store_link,
        ios_app_id: appValue.ios_app_id,
        ios_team_id: appValue.ios_team_id,
        ios_bundle_id: appValue.ios_bundle_id,
      }
    : DEFAULT_FORM_VALUE;
};

const convertToUpdatePharmacyAppDto = (
  formValue: AppFormProps,
): UpdatePharmacyAppDto => {
  return {
    display_name: formValue.display_name,
    marketplace_name: formValue.marketplace_name,
    android_identifier: formValue.android_identifier || '',
    android_store_link: formValue.android_store_link || '',
    apple_identifier: formValue.apple_identifier || '',
    apple_developer_account: formValue.apple_developer_account,
    apple_store_link: formValue.apple_store_link || '',
    ios_app_id: formValue.ios_app_id || '',
    ios_team_id: formValue.ios_team_id || '',
    ios_bundle_id: formValue.ios_bundle_id || '',
  };
};

export const AppModal: FunctionComponent<PropsWithChildren<any>> = () => {
  const { showModalApp, pharmacyApp } = useAppSettingsState();
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );

  const styles = useStyles();
  const { pharmacyId } = useAppStateStore();
  const canUpdate = canFactory('update')('settingsLumistryUser');

  useEffect(() => {
    methods.reset(convertToFormValues(pharmacyApp));
  }, [pharmacyApp]);

  const methods = useForm<AppFormProps>({
    defaultValues: convertToFormValues(pharmacyApp),
    mode: 'onChange',
  });

  const handleSubmit = () => {
    const formValue = methods.getValues();
    if (!_isEqual(formValue, convertToFormValues(pharmacyApp)) && pharmacyId) {
      if (pharmacyApp?.id) {
        updatePharmacyApp(pharmacyId, convertToUpdatePharmacyAppDto(formValue));
      } else {
        createPharmacyApp(pharmacyId, formValue);
      }
    }
    setShowModalApp(false);
  };

  const closeModal = () => {
    methods.reset(convertToFormValues(pharmacyApp));
    setShowModalApp(false);
  };

  return (
    <Modal
      title={getText('edit-app-details')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-general-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalApp}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('app-name')}</Text>
              {!canLumistryUserUpdateSettings && (
                <SettingsTooltipsComponents tooltipId="app-name-settings-modal" />
              )}
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="display_name"
              type="text"
              rules={{
                maxLength: {
                  value: 36,
                  message: getText('max-char-allowed-message', { maxChar: 36 }),
                },
                required: getText('app-name-required'),
              }}
              disabled={!canLumistryUserUpdateSettings}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('app-marketplace-name')}
              </Text>
              <SettingsTooltipsComponents tooltipId="app-store-name-settings-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="marketplace_name"
              type="text"
              rules={{
                maxLength: {
                  value: 30,
                  message: getText('max-char-allowed-message', { maxChar: 30 }),
                },
                validate: checkCapitalizeAllWords,
                required: getText('app-store-name-required'),
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('android-identifier')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="android_identifier"
              type="text"
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('android-store-link')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="android_store_link"
              type="text"
              rules={{ validate: validateOptionalURL }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('apple-identifier')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="apple_identifier"
              type="text"
              rules={{ validate: validateOptionalNumber }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('apple-developer-account')}
              </Text>
              <SettingsTooltipsComponents tooltipId="apple-developer-account-settings-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="apple_developer_account"
              type="text"
              rules={{ required: getText('apple-developer-account-required') }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('apple-store-link')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="apple_store_link"
              type="text"
              rules={{ validate: validateOptionalURL }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('ios-app-id')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="ios_app_id"
              type="text"
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('ios-team-id')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="ios_team_id"
              type="text"
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('ios-bundle-id')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="ios_bundle_id"
              type="text"
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>
      </Form>
      {!canLumistryUserUpdateSettings && (
        <Tooltip
          anchorId="app-name-settings-modal"
          text={getText('app-name-tooltip-text')}
        />
      )}
      <Tooltip
        anchorId="app-store-name-settings-modal"
        text={getText('app-store-name-tooltip-text')}
      />
      <Tooltip
        anchorId="apple-developer-account-settings-modal"
        text={getText('apple-developer-account-tooltip')}
      />
    </Modal>
  );
};

interface AppFormProps {
  display_name: string;
  marketplace_name: string;
  android_identifier?: string;
  android_store_link?: string;
  apple_identifier?: string;
  apple_developer_account: string;
  apple_store_link?: string;
  ios_app_id?: string;
  ios_team_id?: string;
  ios_bundle_id?: string;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.getSpacing(1),
    flexDirection: 'row',
    alignContent: 'center',
  },
  textFormat: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));
