import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  GestureResponderEvent,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

const SettingsSection: FunctionComponent<
  PropsWithChildren<SettingsSectionProps>
> = ({
  title,
  subtitle,
  contentHeader,
  contentData,
  contentItem: ContentItemComponent,
  customContentHeaderComponent: CustomHeaderComponent,
  customTypeModal: CustomModal,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <>
      <View style={styles.content}>
        <View style={{ flex: 1 }}>
          <Text style={styles.sectionTitle}>{title}</Text>
          {subtitle && <Text style={styles.sectionSubtitle}>{subtitle}</Text>}
        </View>

        <View style={{ flex: 3 }}>
          {CustomHeaderComponent && <CustomHeaderComponent />}
          {!CustomHeaderComponent && contentHeader && (
            <View style={[styles.contentHeader, contentHeader.containerStyle]}>
              <Text style={styles.sectionContentTitle}>
                {contentHeader.title}
              </Text>

              {contentHeader.button && (
                <TouchableOpacity
                  style={[styles.topBarButton]}
                  onPress={contentHeader.button.onPress}
                >
                  <Icon
                    icon={contentHeader.button.icon}
                    color={
                      contentHeader.button.color || theme.palette.primary[600]
                    }
                    size={contentHeader.button.iconSize || 16}
                  />
                  <Text
                    style={{
                      ...styles.topBarText,
                      fontSize: contentHeader.button.fontSize,
                    }}
                  >
                    {contentHeader.button.title}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {contentData.map((item, index) => (
            <ContentItemComponent key={item?.id || index} {...item} />
          ))}
        </View>
      </View>
      {CustomModal && <CustomModal />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'baseline',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(4) + theme.getSpacing(2),
    marginBottom: theme.getSpacing(3),
  },
  sectionTitle: {
    ...theme.lumistryFonts.label.medium.semiBold,
    color: theme.palette.gray[900],
    paddingBottom: theme.getSpacing(2),
  },
  sectionContentTitle: {
    ...theme.lumistryFonts.label.small.bold,
    color: theme.palette.gray[900],
  },
  sectionSubtitle: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    paddingHorizontal: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topBarText: {
    color: theme.palette.primary[600],
    fontWeight: '400',
    fontSize: 14,
    marginLeft: getSpacing(0.5),
  },
}));

interface ContentHeaderButtonProps {
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  icon: FunctionComponent;
  color?: string;
  title?: string;
  iconSize?: number;
  fontSize?: number;
}

interface ContentHeaderProps {
  title: string;
  button?: ContentHeaderButtonProps;
  containerStyle?: StyleProp<ViewStyle>;
}
interface SettingsSectionProps {
  title: string;
  subtitle?: string;
  contentHeader?: ContentHeaderProps;
  customContentHeaderComponent?: FunctionComponent<PropsWithChildren<any>>;
  contentData: any[];
  contentItem: FunctionComponent<PropsWithChildren<any>>;
  customTypeModal?: FunctionComponent<PropsWithChildren<any>>;
}

export default SettingsSection;
