import { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Pressable, Text, View, useWindowDimensions } from 'react-native';
import { getText } from 'assets/localization/localization';
import { PhotoButton, PhotoInfo } from './PhotoButton';
import { ImageIcon } from 'assets/icons';
import FileStorageService from '../../api/FileStorageService';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { Modal } from 'assets/components/modal';
import { LoadingOverlay } from '../LoadingOverlay';
import { logError } from 'assets/logging/logger';

interface InsuranceCardPhotoModalProps {
  show: boolean;
  setShowModal: (arg: boolean) => void;
  title: string;
  fileId: string;
}

const InsuranceCardPhotoModal: FC<InsuranceCardPhotoModalProps> = ({
  show,
  setShowModal,
  title,
  fileId,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { pharmacyId } = useAppStateStore();

  const { height } = useWindowDimensions();
  const buttonHeight = height * 0.25;

  function onDismiss(): void {
    setShowModal(false);
  }

  const PatientInsuranceCardPhoto = ({ fileId }: { fileId: string }) => {
    const [photo, setPhoto] = useState<PhotoInfo>();
    const [loading, setLoading] = useState(false);

    const loadImages = useCallback(async () => {
      setLoading(true);
      try {
        const responseReadUrl =
          await FileStorageService.readUrlWithPharmacyCategory(
            PharmacyCategory.InsuranceCard,
            fileId,
            pharmacyId,
          );
        setPhoto({ uri: responseReadUrl.url });
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        logError(error as Error);
      } finally {
        setLoading(false);
      }
    }, []);

    useEffect(() => {
      void loadImages();
    }, []);

    const handlePhotoPress = useCallback(() => {
      window.open(photo?.uri);
    }, [photo]);

    return (
      <View style={{ flexDirection: 'column' }}>
        {loading && <LoadingOverlay />}
        <View style={styles.imageOptions}>
          <Text style={styles.textLabel}>{title}</Text>
        </View>
        <View
          style={{
            marginRight: theme.getSpacing(2),
          }}
        >
          <Pressable onPress={handlePhotoPress}>
            <PhotoButton uri={photo?.uri} width={276} height={buttonHeight}>
              <View
                style={{
                  padding: theme.getSpacing(1),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <ImageIcon size={32} color={theme.palette.gray[400]} />
                </View>
                <View style={styles.upload}>
                  <Text style={styles.uploadText}>{getText('no-photo')}</Text>
                </View>
              </View>
            </PhotoButton>
          </Pressable>
        </View>
      </View>
    );
  };

  return (
    <Modal
      title={getText('insurance')}
      dismissButtonProps={{
        onPress: onDismiss,
        logger: { id: 'confirmation-cancel-button' },
      }}
      okButtonProps={{
        onPress: onDismiss,
        logger: { id: 'confirmation-cancel-button' },
        text: getText('close'),
        hierarchy: 'secondary',
      }}
      show={show}
      size="sm"
      isScrollable={true}
    >
      <View>
        <PatientInsuranceCardPhoto fileId={fileId} />
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  textLabel: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(1),
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.gray[400],
    marginLeft: theme.getSpacing(1),
  },

  imageOptions: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}));

export default InsuranceCardPhotoModal;
