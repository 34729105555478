import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { getText } from 'assets/localization/localization';

export const ConversationsHeader: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <View style={styles.tabHeader}>
      <Text style={styles.headerText}>{getText('chat-messages')}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  tabHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  headerText: {
    ...theme.fonts.medium,
    fontSize: 26,
    marginRight: theme.getSpacing(4),
  },
}));
