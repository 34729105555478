import {
  CreateInfoCardDto,
  CreateLocationClosuresRequestDto,
  CreateNormalHoursDto,
  DepartmentTimeRangeDto,
  PharmacistLocationDto,
  PharmacyLocationDto,
  UpdateHolidaysDto,
  UpdateInfoCardDto,
  UpdatePharmacyLocationDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import PharmacyService from '../../../api/PharmacyService';
import PMSIntegrationService from '../../../api/PMSIntegrationService';
import { handleError } from '../../../common/errors/commonErrorHandler';
import { AsyncStatus } from '../../../store/types';
import { useUserState } from '../../../store/user-store';
import {
  CountryCode,
  useLocationSettingsState as locationState,
} from './location-settings-store';

export const setLocationId = (locationId?: string) =>
  locationState.setState({ locationId });

export const setCountry = (country: CountryCode) =>
  locationState.setState({ country });

export const setShowModalLocationGeneralInfo = (
  showModalLocationGeneralInfo: boolean,
) => locationState.setState({ showModalLocationGeneralInfo });

export const setShowModalLocationContactInfo = (
  showModalLocationContactInfo: boolean,
) => locationState.setState({ showModalLocationContactInfo });

export const setShowModalLocationTeams = (showModalLocationTeams: boolean) =>
  locationState.setState({ showModalLocationTeams });

export const setShowModalLocationPickup = (showModalLocationPickup: boolean) =>
  locationState.setState({ showModalLocationPickup });

export const setShowModalLocationNormalHours = (
  showModalLocationNormalHours: boolean,
) => locationState.setState({ showModalLocationNormalHours });

export const setShowModalLocationHolidayClosures = (
  showModalLocationHolidayClosures: boolean,
) => locationState.setState({ showModalLocationHolidayClosures });

export const setShowModalLocationAdditionalClosures = (
  showModalLocationAdditionalClosures: boolean,
) => locationState.setState({ showModalLocationAdditionalClosures });

export const setShowModalLocationInfoCards = (
  showModalLocationInfoCards: boolean,
) => locationState.setState({ showModalLocationInfoCards });

export const setShowModalLocation = (showModalLocation: boolean) =>
  locationState.setState({ showModalLocation });

export const setStatus = (status: AsyncStatus) =>
  locationState.setState({ status });

export const setSearch = (search: string) => locationState.setState({ search });

export const setPage = (page: number) => locationState.setState({ page });

export const getSettingsLocations = async (
  offset?: number,
  limit?: number,
  search?: string,
) => {
  try {
    const userId = useUserState.getState().data?.id;
    const paginatedResponse =
      await PharmacyService.pharmacistLocationsByPharmacyIdFind({
        userId: userId!,
        offset,
        limit,
        search,
      });
    locationState.setState({ allLocations: paginatedResponse });
  } catch (error: unknown) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-pharmacy-info'),
        error,
      }),
    });
  }
};

export const getLocationsByID = async (
  locationId: string,
): Promise<PharmacyLocationDto | undefined> => {
  try {
    const location = await PharmacyService.pharmacyLocationFindOne(locationId);
    return location;
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-pharmacy-info'),
        error,
      }),
    });
  }
};

export const setLocation = async (locationId: string) => {
  try {
    const location = await getLocationsByID(locationId);
    locationState.setState({ location });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('info-cards'),
        error,
      }),
    });
  }
};

export const updateLocation = async (
  locationId: string,
  updatedLocation: UpdatePharmacyLocationDto,
) => {
  try {
    const locationResponse = await PharmacyService.pharmacyLocationUpdate(
      locationId,
      updatedLocation,
    );
    const { allLocations } = locationState.getState();

    const updatedLocations: PharmacistLocationDto[] =
      allLocations.locations.map((location) => {
        return location.location_id === locationId
          ? {
              ...location,
              location_address1: locationResponse.address?.address1!,
              location_city: locationResponse.address?.city!,
              location_state: locationResponse.address?.state!,
              location_country: locationResponse.address?.country!,
              location_name: locationResponse.name,
              ncpdp: locationResponse.ncpdp,
              location_active: locationResponse.active!,
            }
          : location;
      });
    locationState.setState({
      allLocations: { ...allLocations, locations: updatedLocations },
    });
    await setLocation(locationId);
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-info'),
        error,
      }),
    });
  }
};

export const setLocationNormalHours = async (locationId: string) => {
  try {
    const locationNormalHours =
      await PharmacyService.pharmacyLocationHoursFindAll(locationId);
    locationState.setState({ locationNormalHours });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-normal-hours'),
        error,
      }),
    });
  }
};

export const updateLocationNormalHours = async (
  locationId: string,
  departmentId: string,
  createNormalHoursDto: CreateNormalHoursDto,
) => {
  const normalHours = await PharmacyService.pharmacyLocationHoursCreate(
    locationId,
    departmentId,
    createNormalHoursDto,
  );
  try {
    const locationNormalHours: DepartmentTimeRangeDto[] = locationState
      .getState()
      .locationNormalHours.map((hours) => {
        if (hours.department_id === departmentId) {
          locationState.setState({
            editingLocationNormalHours: { ...hours, ...normalHours },
          });
          return { ...hours, ...normalHours };
        } else {
          return hours;
        }
      });
    locationState.setState({ locationNormalHours });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-normal-hours'),
        error,
      }),
    });
  }
};

export const setEditingLocationNormalHours = (departmentId: string) => {
  const { locationNormalHours } = locationState.getState();
  const editingLocationNormalHours = locationNormalHours.find(
    (normalHours) => normalHours.department_id === departmentId,
  );
  locationState.setState({ editingLocationNormalHours });
};

export const setLocationHolidays = async (locationId: string) => {
  try {
    const locationHolidays =
      await PharmacyService.pharmacyLocationHoursGetHolidays(locationId);
    locationState.setState({ locationHolidays });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-holiday-closures'),
        error,
      }),
    });
  }
};

export const updateLocationHolidays = async (
  locationId: string,
  updateHolidaysDto: UpdateHolidaysDto,
) => {
  const locationHolidays =
    await PharmacyService.pharmacyLocationHoursToggleHoliday(
      locationId,
      updateHolidaysDto,
    );
  try {
    locationState.setState({ locationHolidays });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-holiday-closures'),
        error,
      }),
    });
  }
};

export const setLocationClosures = async (locationId: string) => {
  try {
    const locationClosures =
      await PharmacyService.pharmacyLocationHoursFindAllClosures(locationId);
    locationState.setState({ locationClosures: locationClosures });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-additional-closures'),
        error,
      }),
    });
  }
};

export const updateLocationClosures = async (
  locationId: string,
  updatedLocationClosures: CreateLocationClosuresRequestDto,
) => {
  try {
    const locationClosures =
      await PharmacyService.pharmacyLocationHoursCreateClosures(
        locationId,
        updatedLocationClosures,
      );
    locationState.setState({ locationClosures: locationClosures });
  } catch (error: unknown) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-additional-closures'),
        error,
      }),
    });
  }
};

export const findAllTimezones = async () => {
  try {
    const allTimezones = await PharmacyService.timezoneFindAll();
    locationState.setState({ allTimezones });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('locations-time-zone'),
        error,
      }),
    });
  }
};

export const infoCardsCreate = async (
  locationId: string,
  createInfoCardDto: CreateInfoCardDto,
) => {
  try {
    const infoCard = await PharmacyService.infoCardsCreate(
      locationId,
      createInfoCardDto,
    );
    const infoCards = locationState.getState().infoCards;
    infoCards.push(infoCard);
    locationState.setState({ infoCards });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('info-cards'),
        error,
      }),
    });
  }
};

export const infoCardsFindAll = async (locationId: string) => {
  try {
    const infoCards = await PharmacyService.infoCardsFindAll(locationId);
    locationState.setState({ infoCards });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('info-cards'),
        error,
      }),
    });
  }
};

export const infoCardsFindOne = (cardId: string) => {
  const { infoCards } = locationState.getState();
  const infoCard = infoCards.find((card) => card.id === cardId);
  locationState.setState({ infoCard });
};

export const infoCardsRemove = async (locationId: string, cardId: string) => {
  try {
    await Promise.all([
      PharmacyService.infoCardsRemove(locationId, cardId),
      infoCardsFindAll(locationId),
    ]);
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('info-cards'),
        error,
      }),
    });
  }
};

export const removeSelectedInfoCard = () => {
  locationState.setState({
    infoCard: undefined,
  });
};

export const infoCardsUpdate = async (
  locationId: string,
  cardId: string,
  updateInfoCard: UpdateInfoCardDto,
) => {
  try {
    const updatedInfoCard = await PharmacyService.infoCardsUpdate(
      locationId,
      cardId,
      updateInfoCard,
    );
    locationState.setState({
      infoCards: locationState.getState().infoCards.map((card) => {
        return card.id === cardId ? updatedInfoCard : card;
      }),
    });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('info-cards'),
        error,
      }),
    });
  }
};

export const getAllPMS = async () => {
  try {
    const allPMS = await PMSIntegrationService.pmsFindAll();
    locationState.setState({ allPMS });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-mgmt-system'),
        error,
      }),
    });
  }
};
