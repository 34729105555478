import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  BusinessType,
  LocationStatus,
  ObLocationResponseDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import { useForm } from 'react-hook-form';
import { updatePharmacyAccount } from '../../../../actions/onboarding-actions';
import PMSIntegrationService from '../../../../api/PMSIntegrationService';
import PharmacyService from '../../../../api/PharmacyService';
import { DropdownOptions } from '../../../../store/onboarding-store';
import { FORM_NOT_VALID, createOptions, findDropdownOption } from '../../utils';
import { InfoTooltip } from '../common/InfoTooltip';
import { OBFormHandler, OBFormProps } from '.';

export interface AccountFormType
  extends Omit<
    ObLocationResponseDto,
    | 'employees'
    | 'equipment'
    | 'location_billing_address'
    | 'location_holidays'
    | 'location_hours'
    | 'location_onsite_required'
    | 'location_shipping_address'
    | 'location_store_address'
  > {
  brand_name: string;
  corp_name: string;
}

export interface OBAccountFormProps extends OBFormProps {
  branName?: string;
  corpName?: string;
  isSingleLocation?: boolean;
}

export const OBAccountForm = forwardRef<OBFormHandler, OBAccountFormProps>(
  (
    {
      location,
      branName,
      corpName,
      isSingleLocation = true,
    }: OBAccountFormProps,
    ref: ForwardedRef<OBFormHandler>,
  ) => {
    const styles = useStyles();
    const accountData = location;
    const [timezoneOptions, setTimezoneOptions] = useState<DropdownOptions[]>(
      [],
    );
    const [pmsOptions, setPmsOptions] = useState<DropdownOptions[]>([]);
    const businessTypeOptions = createOptions(BusinessType);
    const statusOptions = createOptions(LocationStatus);

    const methods = useForm<AccountFormType>({
      defaultValues: {
        location_contact_name: accountData.location_contact_name,
        location_phone: accountData.location_phone,
        location_contact_email: accountData.location_contact_email,
        brand_name: branName,
        corp_name: corpName,
        location_name: accountData.location_name,
        location_dba: accountData.location_dba,
        location_legal_name: accountData.location_legal_name,
        location_jurisdiction_of_incorporation:
          accountData.location_jurisdiction_of_incorporation,
        location_email: accountData.location_email,
        location_ncpdp: accountData.location_ncpdp,
        location_npi: accountData.location_npi,
        location_duns: accountData.location_duns,
        location_tax_id: accountData.location_tax_id,
        location_status: accountData.location_status,
        location_mobile: accountData.location_mobile,
        location_fax: accountData.location_fax,
        location_pms: accountData.location_pms,
        location_business_type: accountData.location_business_type,
        location_timezone_id: accountData.location_timezone_id,
      },
    });

    useEffect(() => {
      const { location_timezone_id, location_pms } = location;
      const { setValue } = methods;

      const selectedTimezone = findDropdownOption(
        timezoneOptions,
        location_timezone_id,
      );
      const selectedPMS = findDropdownOption(pmsOptions, location_pms);

      if (selectedTimezone) {
        setValue('location_timezone_id', selectedTimezone.value);
      }

      if (selectedPMS) {
        setValue('location_pms', selectedPMS.value);
      }
    }, [timezoneOptions, pmsOptions, location]);

    useEffect(() => {
      getTimezone();
      getPMS();
    }, []);

    const getTimezone = () => {
      PharmacyService.timezoneFindAll()
        .then((data) =>
          setTimezoneOptions(data.map((d) => ({ label: d.name, value: d.id }))),
        )
        .catch((error: Error) => logError(error));
    };

    const getPMS = () => {
      PMSIntegrationService.pmsFindAll()
        .then((data) =>
          setPmsOptions(data.map(({ name }) => ({ label: name, value: name }))),
        )
        .catch((error: Error) => logError(error));
    };

    const handleOnSubmit = async () => {
      await methods.handleSubmit(
        async (values) => {
          updatePharmacyAccount(location.id, values);
        },
        () => {
          // throw error to prevent go next
          throw FORM_NOT_VALID;
        },
      )();
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: handleOnSubmit,
    }));

    return (
      <Form methods={methods}>
        {isSingleLocation && (
          <>
            <Form.Row>
              <Form.Column>
                <Text style={styles.title}>{getText('primary-contact')}</Text>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="location_contact_name"
                  type="text"
                  label={getText('name')}
                  rules={{
                    required: getText('name-is-required'),
                    validate: {
                      value: (value) => {
                        return validate.isName(value)
                          ? true
                          : getText('name-is-not-valid');
                      },
                    },
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="location_phone"
                  type="telephoneNumber2"
                  label={getText('phone-number')}
                  rules={{
                    required: getText('phone-is-required'),
                    validate: {
                      value: (value: string) => {
                        return validate.isPhoneNumber(value ? '+1' + value : '')
                          ? true
                          : getText('phone-is-not-valid');
                      },
                    },
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="location_contact_email"
                  type="emailAddress"
                  label={getText('email-address')}
                  disabled
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.title}>
                  {getText('business-information')}
                </Text>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="brand_name"
                  type="text"
                  label={getText('brand-name')}
                  rules={{
                    required: getText('brand-name-is-required'),
                    validate: {
                      value: (value) => {
                        return validate.isName(value)
                          ? true
                          : getText('brand-name-is-not-valid');
                      },
                    },
                  }}
                />
                <InfoTooltip
                  style={styles.tootlip}
                  tooltipId="brand-name"
                  text={getText('brand-name-description')}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="corp_name"
                  type="text"
                  label={getText('pharmacy-parent-company')}
                  rules={{
                    required: getText('parent-company-is-required'),
                    validate: {
                      value: (value) => {
                        return validate.isName(value)
                          ? true
                          : getText('parent-company-is-not-valid');
                      },
                    },
                  }}
                />
                <InfoTooltip
                  style={styles.tootlip}
                  tooltipId="parent-company"
                  text={getText('for-iternal-use')}
                />
              </Form.Column>
            </Form.Row>
          </>
        )}
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('pharmacy-information')}</Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_name"
              type="text"
              label={getText('pharmacy-name')}
              rules={{
                required: getText('pharmacy-name-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('pharmacy-name-is-not-valid');
                  },
                },
              }}
            />
            <InfoTooltip
              style={styles.tootlip}
              tooltipId="pharmacy-name"
              text={getText('pharmacy-name-tooltip')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_legal_name"
              type="text"
              label={getText('legal-entity-name')}
              rules={{
                required: getText('legal-entity-name-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('legal-entity-name-is-not-valid');
                  },
                },
              }}
            />
            <InfoTooltip
              style={styles.tootlip}
              tooltipId="legal-name"
              text={getText('legal-name-tooltip')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_jurisdiction_of_incorporation"
              type="text"
              label={getText('jurisdiction')}
              rules={{
                required: getText('jurisdiction-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('jurisdiction-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_dba"
              type="text"
              label={getText('dba')}
              rules={{
                required: getText('dba-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('dba-is-not-valid');
                  },
                },
              }}
            />
            <InfoTooltip
              style={styles.tootlip}
              tooltipId="dba"
              text={getText('dba-tooltip')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <DropdownSelectField
              name="location_status"
              labelInlined
              disabled
              options={statusOptions.filter(
                (x) => x.value !== LocationStatus.Unknown,
              )}
              label={getText('status')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_email"
              type="emailAddress"
              label={getText('location-email-address')}
              rules={{
                required: getText('pharmacy-email-address-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isEmail(value)
                      ? true
                      : getText('pharmacy-email-address-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_ncpdp"
              label={getText('pharmacy-ncpdp')}
              disabled
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_npi"
              type="numeric"
              label={getText('pharmacy-npi')}
              disabled
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_duns"
              type="duns"
              label={getText('duns-number')}
              rules={{
                required: getText('duns-number-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('duns-is-not-valid');
                  },
                },
              }}
            />
            <InfoTooltip
              style={styles.tootlip}
              tooltipId="duns"
              text={getText('duns-tooltip-before')}
            >
              {getText('duns-tooltip-after')}
            </InfoTooltip>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_tax_id"
              type="tax"
              label={getText('tax-id')}
              rules={{
                required: getText('tax-id-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('tax-id-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_mobile"
              type="telephoneNumber2"
              label={getText('phone-number')}
              rules={{
                required: getText('phone-is-required'),
                validate: {
                  value: (value: string) => {
                    return validate.isPhoneNumber(value ? '+1' + value : '')
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="location_fax"
              type="telephoneNumber2"
              label={getText('pharmacy-fax-number')}
              rules={{
                validate: {
                  value: (value: string) => {
                    return !value ||
                      validate.isPhoneNumber(value ? '+1' + value : '')
                      ? true
                      : getText('fax-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <DropdownSelectField
              name="location_pms"
              options={pmsOptions}
              labelInlined
              disabled
              label={getText('pharmacy-managment-system')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <DropdownSelectField
              name="location_business_type"
              options={businessTypeOptions}
              labelInlined
              disabled
              label={getText('business-type')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <DropdownSelectField
              name="location_timezone_id"
              labelInlined
              options={timezoneOptions}
              label={getText('locations-time-zone')}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary[500],
  },
  title: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  tootlip: { position: 'absolute', right: -35, top: 20 },
}));
