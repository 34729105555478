import {
  DrawerContentComponentProps,
  DrawerNavigationProp,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import { pharmacyDrawerWidth } from 'assets/theme/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import { View } from 'react-native';
import { useAppStateStore } from '../../../store/app-store';
import { AllLocationsSettings } from './AllLocationsSettings';
import { LocationSettings } from './LocationSettings';
import { getText } from 'assets/localization/localization';

const DrawerNavigator = createDrawerNavigator();

export const LocationsSettingsDrawer: FunctionComponent<
  PropsWithChildren
> = () => {
  const styles = useStyles();

  return (
    <View style={styles.menu}>
      <DrawerNavigator.Navigator
        screenOptions={{
          drawerStyle: {
            width: pharmacyDrawerWidth,
          },
        }}
        initialRouteName="location"
        drawerContent={(props) => <CustomDrawerContent {...props} />}
      >
        <DrawerNavigator.Screen
          name="locations"
          component={AllLocationsSettings}
          options={{
            title: `${getText('settings')} - ${getText('locations-all')}`,
            headerShown: false,
          }}
        />
        <DrawerNavigator.Screen
          name="location"
          component={LocationSettings}
          options={{ title: getText('edit-location'), headerShown: false }}
        />
      </DrawerNavigator.Navigator>
    </View>
  );
};

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const { locationId, isMultiLocation } = useAppStateStore((state) => ({
    locationId: state.locationId,
    isMultiLocation: state.userMostRecentLocations.length > 1,
  }));
  useFocusEffect(
    useCallback(() => {
      isMultiLocation
        ? props.navigation.navigate('locations')
        : props.navigation.navigate('location', {
            locationId: locationId,
          });
    }, []),
  );
  return <></>;
};

export type LocationsDrawerNavigationProp =
  DrawerNavigationProp<LocationsDrawerNavigationParamList>;

export type LocationsDrawerNavigationParamList = {
  locations: undefined;
  location: LocationsDetailParamsProp;
};

export type LocationsDetailParamsProp = {
  locationId: string;
};

export type LocationsDrawerRouteProp =
  RouteProp<LocationsDrawerNavigationParamList>;

const useStyles = makeStyles((theme) => ({
  menu: {
    height: '100%',
    marginTop: theme.getSpacing(2),
  },
}));
