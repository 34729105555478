import { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import {
  PatientAgreementRadioButtonProps,
  PatientAgreementRadioButtonValue,
} from '../../types';
import { PatientAgreementRadioButtonOption } from '../patient-agreement-radio-button-option';

export const PatientAgreementRadioButton: FunctionComponent<
  PatientAgreementRadioButtonProps
> = ({ values, selectedValue, onValueChange, label }) => {
  const styles = useStyles();

  const [currentValue, setCurrentValue] = useState<string | undefined>(
    selectedValue,
  );

  useEffect(() => {
    if (currentValue !== selectedValue) {
      setCurrentValue(selectedValue);
    }
  }, [selectedValue]);

  const handleOnValueChange = async (
    item: PatientAgreementRadioButtonValue,
  ) => {
    if (item.onPress) {
      const res = await item.onPress(item.value);
      if (!res) return;
    }

    setCurrentValue(item.value);
    onValueChange?.(item.value);
  };

  return (
    <View style={styles.root}>
      {label && <Text style={styles.label}>{label}</Text>}
      <View style={styles.container}>
        {values.map((x, index) => (
          <PatientAgreementRadioButtonOption
            key={`${index}`}
            {...x}
            selected={currentValue === x.value}
            onPress={() => handleOnValueChange(x)}
          />
        ))}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    gap: theme.getSpacing(2),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    gap: theme.getSpacing(2),
  },
  label: {
    ...theme.lumistryFonts.text.medium.bold,
    color: theme.palette.black,
  },
}));
