import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { setOpenedModal } from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { ChecklistModalProps } from '../../types';

export const WebsiteAccessCompletedModal: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const styles = useStyles();

  return (
    <Modal
      title={getText('website-access')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal(undefined)}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => console.log('back'),
          logger: { id: 'next-website-access-completed' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal(undefined),
          logger: { id: 'back-website-access-completed-modal' },
        },
      ]}
    >
      <Text style={styles.title}>
        {getText('website-completion-gratitude')}
      </Text>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
