import { FC } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { BulkStatus } from '@digitalpharmacist/unified-communications-service-client-axios';
import { IRendererProps } from '../types';
import { useBulkTableStyles } from '../commonStyles';
import {
  AlertTriangleIcon,
  ArrowUpRightIcon,
  CancelCircleIcon,
  CheckIcon,
  ClockIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { IconProps } from 'assets/icons/types';

export const StatusRenderer: FC<IRendererProps> = ({ data }) => {
  const bulkTableStyles = useBulkTableStyles();
  const styles = useStyles();

  let Icon: FC<IconProps>;
  let statusText;
  let style;
  switch (data.status) {
    case BulkStatus.Sent:
      Icon = CheckIcon;
      statusText = BulkStatus.Sent;
      style = styles.statusSent;
      break;

    case BulkStatus.InProgress:
      statusText = 'sending' as const;
      Icon = ArrowUpRightIcon;
      style = styles.inProgress;

      break;

    case BulkStatus.Scheduled:
      statusText = BulkStatus.Scheduled;
      Icon = ClockIcon;
      style = bulkTableStyles.text;

      break;

    case BulkStatus.Failed:
      statusText = BulkStatus.Failed;
      Icon = AlertTriangleIcon;
      style = styles.failed;

      break;

    case BulkStatus.Canceled:
      statusText = BulkStatus.Canceled;
      Icon = () => <CancelCircleIcon color={styles.canceled.color} size={16} />;
      style = styles.canceled;

      break;

    default:
      statusText = BulkStatus.InProgress;
      Icon = ClockIcon;
      style = bulkTableStyles.text;
  }

  return (
    <div style={bulkTableStyles.iconAndTextContainer}>
      <Icon size={20} {...style} />
      <div style={style}>{getText(statusText)}</div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  statusSent: {
    color: theme.palette.success[600],
  },
  failed: {
    color: theme.palette.error[600],
  },
  canceled: {
    color: theme.palette.gray[500],
  },
  inProgress: {
    color: theme.palette.orange[400],
  },
}));
