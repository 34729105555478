import { CheckboxInput } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import {
  setOpenedModal,
  setSelectedWebsiteOption,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';
import { PatientWebsiteOptions } from '../utils';

export const PatientWebsiteModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const { currentOpenedModalId, selectedWebsiteOption } = useChecklistStore();
  const styles = useStyles();

  return (
    <Modal
      title={getText('patient-website')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal(undefined)}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => console.log('next'),
          disabled: !selectedWebsiteOption,
          logger: { id: 'close-patient-website-setup' },
        },
        {
          text: getText('cancel'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal(undefined),
          logger: { id: 'close-media-settings-modal' },
        },
      ]}
    >
      <View style={styles.container}>
        <Text style={styles.title}>
          {getText('answer-following-questions')}
        </Text>
        <View style={styles.optionsContainer}>
          {PatientWebsiteOptions.map((option, index) => (
            <CheckboxInput
              key={option.type}
              checked={option.type === selectedWebsiteOption}
              roundIcon
              label={option.label}
              testID={'patient-website-option-' + index}
              onPress={() => setSelectedWebsiteOption(option.type)}
            />
          ))}
        </View>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(2),
  },
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
