import {
  AutoSaveObAddressDto,
  AutoSaveObWizardDto,
  FinalizeObAddressDto,
  FinalizeObLocationDto,
  FinalizeObTimeRangeDto,
  FinalizeObWizardDto,
  ObEmployeeResponseDto,
  WeekdaysEnum,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import axios from 'axios';
import PharmacyService from '../api/PharmacyService';
import {
  TimeRangeForm,
  convertTimeRangeToISOString,
  timesOverlap,
} from '../common/datetime-utils';
import { AgreementsFormType } from '../screens/onboarding/components/OBAgreements';
import { AccountFormType } from '../screens/onboarding/components/forms/OBAccountForm';
import { MultiLocationAccountFormType } from '../screens/onboarding/components/multi-location/OBMultiLocationAccount';
import { AddressesFormType } from '../screens/onboarding/components/single-locations/OBSingleLocationAddresses';
import { EquipmentType } from '../screens/onboarding/components/single-locations/OBSingleLocationEquipment';
import { HoursFormType } from '../screens/onboarding/components/single-locations/OBSingleLocationHours';
import {
  NOT_ALLOWED,
  closedDefaultData,
  mapOBError,
} from '../screens/onboarding/utils';
import {
  Employee,
  HourType,
  LocationHolidayType,
  LocationHourType,
  OB_EMPLOYEE_FIELDS_TO_CHECK,
  OB_EMPLOYEE_MIN_ROW_TO_SHOW,
  SetupStage,
  useOnboardingStore,
} from '../store/onboarding-store';
import { isUserItAdmin, useUserState } from '../store/user-store';

export const updatePharmacyLocationEmployees = (
  locationId: string | undefined,
  employees: Array<Employee>,
) => {
  const { data } = useOnboardingStore.getState();
  useOnboardingStore.setState({
    data: {
      ...data,
      locations: data.locations.map((location) => {
        if (location.id === locationId) {
          return {
            ...location,
            employees: employees as ObEmployeeResponseDto[],
          };
        }
        return location;
      }),
    },
  });
};

export const updatePharmacyLocationAddresses = (
  locationId: string | undefined,
  addresses: AddressesFormType,
) => {
  const { data } = useOnboardingStore.getState();
  useOnboardingStore.setState({
    data: {
      ...data,
      locations: data.locations.map((location) => {
        if (location.id === locationId) {
          return {
            ...location,
            location_billing_address: {
              ...addresses.billingAddress,
            },
            location_store_address: {
              ...addresses.storeAddress,
            },
            location_shipping_address: {
              ...addresses.shippingAddress,
            },
          };
        }
        return location;
      }),
    },
  });
};

export const updatePharmacyAgreements = (agreements: AgreementsFormType) => {
  const { data } = useOnboardingStore.getState();
  useOnboardingStore.setState({
    data: {
      ...data,
    },
  });
};

export const updatePharmacyAccount = (
  locationId: string | undefined,
  account: AccountFormType,
) => {
  const { data } = useOnboardingStore.getState();
  const { brand_name, corp_name, ...newAccount } = account;
  useOnboardingStore.setState({
    data: {
      ...data,
      brand_name: brand_name,
      corp_name: corp_name,
      locations: data.locations.map((location) => {
        if (location.id === locationId) {
          return {
            ...location,
            ...newAccount,
          };
        }
        return location;
      }),
    },
  });
};

export const updateLocationsEmployee = (
  account: MultiLocationAccountFormType,
) => {
  const { data } = useOnboardingStore.getState();
  const { brand_name, corp_name, ...contact } = account;
  const name = contact.contact_name.split(' ');

  useOnboardingStore.setState({
    data: {
      ...data,
      brand_name: brand_name,
      corp_name: corp_name,
      locations: data.locations.map((location) => {
        const employees = [...location.employees];
        employees[0] = {
          ...employees[0],
          phone: contact.contact_phone,
          first_name: name[0],
          last_name: name[1],
        };
        return {
          ...location,
          employees,
        };
      }),
    },
  });
};

export const updatePharmacyEquipment = (
  locationId: string | undefined,
  equipment: EquipmentType,
) => {
  const { data } = useOnboardingStore.getState();
  const { preferred_days_for_install_form, ...newEquipment } = equipment;
  const preferred_days_for_install = preferred_days_for_install_form
    ?.filter((x) => x.enabled)
    .map((x) => x.label);
  useOnboardingStore.setState({
    data: {
      ...data,
      locations: data.locations.map((location) => {
        if (location.id === locationId) {
          return {
            ...location,
            equipment: location.location_onsite_required
              ? {
                  ...newEquipment,
                  firewall_provider: newEquipment.firewall_provider,
                  technician_name: newEquipment.technician_name,
                  technician_email: newEquipment.technician_email,
                  technician_phone: newEquipment.technician_phone,
                  recipient_name: newEquipment.recipient_name || undefined,
                  preferred_days_for_install,
                }
              : undefined,
          };
        }
        return location;
      }),
    },
  });
};

export const updatePharmacyStoreHours = (
  locationId: string | undefined,
  hoursAndHolidays: HoursFormType,
  applyToAllLocations?: boolean,
) => {
  const { data } = useOnboardingStore.getState();
  const {
    hours,
    holidays,
    location_use_default_holidays,
    location_use_default_hours,
  } = hoursAndHolidays;
  const location_hours = hours.filter((h) => h.type !== HourType.CLOSED);
  useOnboardingStore.setState({
    data: {
      ...data,
      locations: data.locations.map((location) => {
        if (applyToAllLocations || location.id === locationId) {
          return {
            ...location,
            location_use_default_holidays,
            location_use_default_hours,
            location_hours: location_hours.map(({ time, day }) => ({
              start_hour: Number(new Date(time.start).getHours()),
              start_minute: Number(new Date(time.start).getMinutes()),
              end_hour: Number(new Date(time.end).getHours()),
              end_minute: Number(new Date(time.end).getMinutes()),
              day,
            })),
            location_holidays: holidays,
          };
        }
        return location;
      }),
    },
  });
};

export const sameAsPharmacyAddress = (
  eqAddress: AutoSaveObAddressDto | undefined,
  pharmacyAddress: FinalizeObAddressDto | undefined,
) => {
  if (!eqAddress || !pharmacyAddress) return false;

  return (
    eqAddress.address1 === pharmacyAddress.address1 &&
    eqAddress.address2 === pharmacyAddress.address2 &&
    eqAddress.city === pharmacyAddress.city &&
    eqAddress.postal_code === pharmacyAddress.postal_code &&
    eqAddress.country === pharmacyAddress.country &&
    eqAddress.state === pharmacyAddress.state
  );
};

export const mapToDaysForInstall = (days: WeekdaysEnum[] | undefined) => {
  return Object.values(WeekdaysEnum).map((day) => ({
    label: day,
    enabled: days?.includes(day) || false,
  }));
};

export const mapToShippingAddress = (
  address: AutoSaveObAddressDto | undefined,
) => {
  // TO DO- remove ID when it becomes optional
  return (
    address ?? { id: '7bdcfb63-8ccb-4d0d-afd9-366f3838975f', country: 'US' }
  );
};

const storeHoursRange = (currentHour: FinalizeObTimeRangeDto) => {
  return convertTimeRangeToISOString(currentHour);
};

const addStoreMissingHours = (hours: LocationHourType[]) => {
  const daysPresent = new Set(hours.map((h) => h.day));
  for (let day = 1; day <= 7; day++) {
    if (!daysPresent.has(day)) {
      hours.push({
        ...closedDefaultData,
        day,
      });
    }
  }
  return hours.sort((a, b) => {
    const adjustedDayA = a.day === 1 ? 8 : a.day;
    const adjustedDayB = b.day === 1 ? 8 : b.day;
    return adjustedDayA - adjustedDayB;
  });
};

const getTotalBreaks = (hours: LocationHourType[]) => {
  return hours.map((hour) => {
    if (hour.type === HourType.OPEN) {
      const totalBreaks = hours.filter(
        (h) => h.day === hour.day && h.type === HourType.BREAK,
      ).length;
      return { ...hour, totalBreaks };
    }
    return hour;
  });
};

export const mapToHoursFormType = (
  hours: FinalizeObTimeRangeDto[],
): LocationHourType[] => {
  hours.sort((a, b) => {
    const adjustedDayA = a.day === 1 ? 8 : a.day;
    const adjustedDayB = b.day === 1 ? 8 : b.day;
    return adjustedDayA - adjustedDayB;
  });

  const mappedHours = hours.map((currentHour, index, allHours) => {
    const timeRange = storeHoursRange(currentHour);
    const isBreak = allHours.some(
      (h, hIndex) =>
        h.day === currentHour.day && hIndex < index && h !== currentHour,
    );

    const curr: LocationHourType = {
      time: {
        start: timeRange.start,
        end: timeRange.end,
      },
      day: currentHour.day,
      type: isBreak ? HourType.BREAK : HourType.OPEN,
      text: isBreak
        ? getText('remove')
        : getText('locations-departments-add-hours'),
    };
    return curr;
  });

  const hoursWithTotalBreaks = getTotalBreaks(mappedHours);
  return addStoreMissingHours(hoursWithTotalBreaks);
};

export const fetchById = async (onboardingWizardId: string) => {
  try {
    useOnboardingStore.setState({
      status: 'loading',
    });

    const user = useUserState.getState().data;

    const userIsItAdmin = isUserItAdmin(user);
    if (!userIsItAdmin) throw Error(NOT_ALLOWED);

    const obModel =
      await PharmacyService.findOnboardingWizardById(onboardingWizardId);

    if (obModel.wizard_user_email !== user?.email) throw Error(NOT_ALLOWED);

    obModel.locations.forEach((location) => {
      if (location.employees.length < OB_EMPLOYEE_MIN_ROW_TO_SHOW) {
        location.employees = location.employees.concat(
          ...[
            ...Array(
              OB_EMPLOYEE_MIN_ROW_TO_SHOW - location.employees.length,
            ).keys(),
          ].map((x, index) => ({ id: 'new' + index }) as ObEmployeeResponseDto),
        );
      }
    });

    useOnboardingStore.setState({
      data: obModel,
      status: 'success',
    });
  } catch (e: any) {
    handleOnboardingErrors(e);
  }
};

export const save = async () => {
  try {
    useOnboardingStore.setState({
      status: 'loading',
    });
    const user = useUserState.getState().data;
    const obModel = useOnboardingStore.getState().data;
    if (!obModel.id) return;

    const userIsItAdmin = isUserItAdmin(user);
    if (!userIsItAdmin) throw Error(NOT_ALLOWED);

    const requestModel: AutoSaveObWizardDto = {
      ...obModel,
      locations: obModel.locations.map((loc) => ({
        ...loc,
        // clearing empty values
        employees: loc.employees.filter((emp: any) =>
          OB_EMPLOYEE_FIELDS_TO_CHECK.every((x) => emp[x]),
        ),
      })),
    };

    const newModel = await PharmacyService.updateOnboardingWizardById(
      obModel.id,
      requestModel,
    );

    newModel.locations.forEach((location) => {
      if (location.employees.length < OB_EMPLOYEE_MIN_ROW_TO_SHOW) {
        location.employees = location.employees.concat(
          ...[
            ...Array(
              OB_EMPLOYEE_MIN_ROW_TO_SHOW - location.employees.length,
            ).keys(),
          ].map(
            (x, index) =>
              ({ id: `new_${Date.now()}_${index}` }) as ObEmployeeResponseDto,
          ),
        );
      }
    });

    useOnboardingStore.setState({
      data: newModel,
      status: 'success',
    });
  } catch (e: any) {
    handleOnboardingErrors(e);
  }
};

export const submit = async () => {
  try {
    useOnboardingStore.setState({
      status: 'loading',
    });
    const user = useUserState.getState().data;

    const userIsItAdmin = isUserItAdmin(user);
    if (!userIsItAdmin) throw Error(NOT_ALLOWED);

    const obModel = useOnboardingStore.getState().data;
    if (obModel.id) {
      const requestModel: FinalizeObWizardDto = {
        ...obModel,
        id: obModel.id!,
        corp_name: obModel.corp_name!,
        corp_salesforce_id: obModel.corp_salesforce_id!,
        brand_name: obModel.brand_name!,
        brand_salesforce_id: obModel.brand_salesforce_id!,
        website_purchased: obModel.website_purchased!,
        locations: obModel.locations.map(
          (loc) =>
            ({
              ...loc,
              id: loc.id!,
              // clearing empty values
              employees: loc.employees.filter((emp: any) =>
                OB_EMPLOYEE_FIELDS_TO_CHECK.every((x) => emp[x]),
              ),
            }) as FinalizeObLocationDto,
        ),
      };
      const newModel = await PharmacyService.createOnboardingWizardById(
        obModel.id,
        requestModel,
      );
      useOnboardingStore.setState({
        data: newModel,
        status: 'success',
      });
    }
  } catch (e) {
    handleOnboardingErrors(e);
  }
};

export const setSetupStage = (setupStage: SetupStage) =>
  useOnboardingStore.setState({ setupStage });

export const checkHoursFormValidity = (form: HoursFormType): boolean => {
  if (form.location_use_default_hours) {
    return false;
  }

  let hasOverlaps = false;
  const dayMap: { [key: number]: TimeRangeForm[] } = {};
  for (const locationHour of form.hours) {
    if (!dayMap[locationHour.day]) {
      dayMap[locationHour.day] = [];
    }
    dayMap[locationHour.day].push({
      start: locationHour.time.start,
      end: locationHour.time.end,
    });
  }
  Array.from({ length: 7 }).map((_, index) => {
    const overlap = timesOverlap(dayMap[index + 1]);
    if (overlap) {
      hasOverlaps = true;
    }
  });

  return hasOverlaps;
};

export const chunkArray = (holidays: LocationHolidayType[]) => {
  const result = [];
  const length = holidays.length;

  for (let i = 0; i < Math.ceil(length / 2); i++) {
    const chunk = [];
    chunk.push(holidays[i]);
    if (i + Math.ceil(length / 2) < length) {
      chunk.push(holidays[i + Math.ceil(length / 2)]);
    }
    result.push(chunk);
  }

  return result;
};

const handleOnboardingErrors = (error: any) => {
  logError(error);
  if (error.message === NOT_ALLOWED) {
    useOnboardingStore.setState({
      status: 'NOT_ALLOWED',
    });
    return;
  }

  if (axios.isAxiosError(error)) {
    if (error.response?.status === 404) {
      useOnboardingStore.setState({
        status: 'error',
      });
    } else {
      useOnboardingStore.setState({
        status: 'HANDLED_ERROR',
      });
    }
    if (Array.isArray(error.response?.data?.message)) {
      throw Error(mapOBError(error.response.data?.message?.[0] ?? ''));
    } else if (typeof error.response?.data?.message === 'string') {
      throw Error(error.response.data?.message);
    }
  }
};
