import React, { useEffect } from 'react';
import PMSIntegrationPageLayout from './components/PMSIntegrationPageLayout';
import {
  getPMSConfig,
  updateShowPMSModalState,
} from './pms-integrations-settings-actions';
import { useAppStateStore } from '../../../store/app-store';
import PMSIntegrationEmpty from './components/PMSIntegrationEmpty';
import PMSIntegrationFormModal from './components/PMSIntegrationFormModal';
import { usePMSIntegrationsState } from './pms-integration-settings-store';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { PencilIcon } from 'assets/icons/PencilIcon';
import { PMSConfigDetailsItemRenderer } from './components/PMSConfigDetailsItemRenderer';
import { getText } from 'assets/localization/localization';
import { View } from 'react-native';

export default function PMSIntegrationSettings() {
  const { locationId } = useAppStateStore();
  const { pmsConfig, pmsConfigurationExists } = usePMSIntegrationsState();

  useEffect(() => {
    void getPMSConfig(locationId);
  }, [locationId]);

  return (
    <PMSIntegrationPageLayout title={getText('integration')}>
      {pmsConfigurationExists ? (
        <SettingsSection
          title={getText('integration')}
          contentHeader={{
            title: getText('details'),
            button: {
              title: getText('edit'),
              icon: PencilIcon,
              onPress: () => updateShowPMSModalState(true),
            },
          }}
          contentData={[pmsConfig]}
          contentItem={PMSConfigDetailsItemRenderer}
          customTypeModal={PMSIntegrationFormModal}
        />
      ) : (
        <View>
          <PMSIntegrationEmpty />
          <PMSIntegrationFormModal />
        </View>
      )}
    </PMSIntegrationPageLayout>
  );
}
