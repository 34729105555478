import { Modal } from 'assets/components/modal';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import { CreateNormalTimeRangeDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Alert } from 'assets/components/alert';
import { Button } from 'assets/components/button';
import { CheckboxInput, CheckboxInputMode } from 'assets/components/checkbox';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  TimeRangeForm,
  convertTimeRangeToISOString,
  extractHoursAndMinutesFromISOString,
  timesOverlap,
} from '../../../../common/datetime-utils';
import { canFactory } from '../../../../common/guards/permission-guard';
import { zIndexAuto } from '../../../../common/theme';
import { TimeRangeInput } from '../../../../schedule/time-range-input/TimeRangeInput';
import {
  createDepartmentHours,
  getDepartments,
  getDepartmentsHours,
  getEditingDepartmentHours,
  setEditingDepartmentId,
  setShowModalDepartmentHours,
} from '../pharmacy-settings-actions';
import {
  DaysOfWeekEnum,
  DepartmentHours,
  usePharmacySettingsState,
} from '../pharmacy-settings-store';

const emptyHour: TimeRangeForm = { start: '', end: '' };

const returnEmptyForm = (): DepartmentHoursFormProps => {
  const emptyForm: DepartmentHoursFormProps = {
    departmentId: '',
    departmentName: '',
  };
  for (let day = 1; day <= 7; day++) {
    emptyForm[day] = {
      disabled: true,
      dayHours: [],
    };
  }
  return emptyForm;
};

export const DepartmentHoursModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const {
    showModalDepartmentHours,
    editingDepartmentHours,
    editingDepartmentId,
  } = usePharmacySettingsState();
  const styles = useStyles();
  const canUpdate = canFactory('read')('settings');

  const methods = useForm<DepartmentHoursFormProps>({
    defaultValues: returnEmptyForm(),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { control } = methods;

  const dayFieldArrays = Array.from({ length: 7 }).map((_, index) =>
    useFieldArray({
      control,
      name: `${index + 1}.dayHours` as never,
    }),
  );

  useEffect(() => {
    if (editingDepartmentId) {
      const resetForm = (
        editingDepartmentHours: DepartmentHours,
      ): DepartmentHoursFormProps => {
        const form: DepartmentHoursFormProps = {
          ...returnEmptyForm(),
          departmentId: editingDepartmentHours.departmentId,
          departmentName: editingDepartmentHours.departmentName,
        };
        for (let day = 1; day <= 7; day++) {
          const dayDepartmentHours =
            editingDepartmentHours.departmentHours.filter(
              (item) => item.day === day,
            );
          form[day].disabled = dayDepartmentHours.length === 0;
          form[day].dayHours =
            dayDepartmentHours.length !== 0
              ? dayDepartmentHours.map((item) =>
                  convertTimeRangeToISOString(item),
                )
              : [];
        }
        return form;
      };

      const resetValues = editingDepartmentHours
        ? resetForm(editingDepartmentHours)
        : returnEmptyForm();
      editingDepartmentHours && methods.reset(resetValues);
    }
  }, [editingDepartmentId]);

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const createHours: CreateNormalTimeRangeDto[] = [];
    for (let day = 1; day <= 7; day++) {
      if (!formValue[day].disabled) {
        createHours.push(
          ...formValue[day].dayHours.map((item) => {
            const startHour = extractHoursAndMinutesFromISOString(item.start);
            const endHour = extractHoursAndMinutesFromISOString(item.end);
            return {
              day,
              start_hour: startHour.hours,
              start_minute: startHour.minutes,
              end_hour: endHour.hours,
              end_minute: endHour.minutes,
            };
          }),
        );
      }
    }
    await createDepartmentHours(createHours, formValue.departmentName)
      .then(() => getDepartments())
      .then(() => getDepartmentsHours())
      .then(() => closeModal());
  };

  const closeModal = () => {
    setEditingDepartmentId();
    getEditingDepartmentHours();
    methods.reset(returnEmptyForm());
    setShowModalDepartmentHours(false);
  };

  const RenderClosedRow: FunctionComponent<{
    dayOfWeek: string;
    disabled: boolean;
    index: number;
  }> = ({ dayOfWeek, disabled, index }) => (
    <Form.Row>
      <Form.Column style={{ ...styles.weekStyle, ...styles.textView }}>
        <CheckboxInput
          mode={CheckboxInputMode.FLAT}
          checked={disabled}
          onPress={(checked) => selectRow(index, !checked)}
        />
        <Text style={styles.textFormat}>{dayOfWeek}</Text>
      </Form.Column>
      <Form.Column style={styles.hoursStyle}>
        <Text style={styles.closedTextStyle}>{getText('closed')}</Text>
      </Form.Column>
    </Form.Row>
  );

  const RenderDateTimePickerRow: FunctionComponent<{
    index: number;
    nestedIndex: number;
  }> = ({ index, nestedIndex }) => (
    <Form.Row
      key={`[${index}].dayHours[${nestedIndex}].row`}
      style={{ zIndex: zIndexAuto }}
    >
      <Form.Column
        style={styles.hoursStyle}
        key={`[${index}].dayHours[${nestedIndex}].col`}
      >
        <TimeRangeInput
          name={`${index}.dayHours[${nestedIndex}]`}
          startTimeRules={{
            required: getText('start-time-required'),
          }}
          endTimeRules={{
            required: getText('end-time-required'),
          }}
        />
        <Button
          onPress={() => {
            nestedIndex > 0
              ? removeHours(index - 1, nestedIndex)
              : addNewHours(index - 1);
          }}
          hierarchy="tertiary"
          icon={nestedIndex > 0 ? MinusCircleIcon : PlusCircleIcon}
          logger={{
            id: `add-department-hours-[${
              editingDepartmentId || ''
            }]-[${index}]`,
          }}
        >
          {nestedIndex > 0
            ? getText('pharmacy-departments-remove-hours')
            : getText('pharmacy-departments-add-hours')}
        </Button>
      </Form.Column>
    </Form.Row>
  );

  const selectRow = (day: number, disabled: boolean) => {
    const formValue = methods.getValues();
    (formValue[day].disabled = disabled), methods.reset(formValue);
    disabled
      ? dayFieldArrays[day - 1].remove()
      : dayFieldArrays[day - 1].replace([emptyHour]);
  };

  const addNewHours = (day: number) => {
    dayFieldArrays[day].append(emptyHour);
  };

  const removeHours = (day: number, hourIndex: number) => {
    dayFieldArrays[day].remove(hourIndex);
  };

  const returnOverlapDay = () => {
    return Array.from({ length: 7 })
      .map((_, index) => timesOverlap(methods.getValues()[index + 1].dayHours))
      .includes(true);
  };

  return (
    <Modal
      title={
        editingDepartmentId
          ? getText('pharmacy-edit-default-department-hours')
          : getText('pharmacy-add-default-department-hours')
      }
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-contact-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-contact-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-contact-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
        disabled: returnOverlapDay(),
      }}
      show={showModalDepartmentHours}
      isScrollable={true}
      size="lg"
    >
      <Form methods={methods}>
        <Form.Row style={styles.bottomBorder}>
          <Form.Column style={{ ...styles.textWrapper, ...styles.textView }}>
            <Text style={styles.textFormat}>
              {getText('pharmacy-departments')}
            </Text>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="departmentName"
              type="text"
              rules={{
                required: getText('pharmacy-departments-name-required'),
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        {Array.from({ length: 7 }).map((_, index) => {
          const dayData = methods.getValues()[index + 1];
          const dayOfWeek = DaysOfWeekEnum[index + 1];
          const overlap = timesOverlap(methods.watch()[index + 1].dayHours);

          return dayData.disabled ? (
            <RenderClosedRow
              dayOfWeek={dayOfWeek}
              disabled={!dayData.disabled}
              index={index + 1}
            />
          ) : (
            <>
              <Form.Row>
                <Form.Column>
                  {overlap ? (
                    <Alert intent="error" title={getText('time-overlapped')} />
                  ) : (
                    <></>
                  )}
                </Form.Column>
              </Form.Row>
              <Form.Row
                key={`[${index + 1}].dayHours.row`}
                style={{ zIndex: zIndexAuto }}
              >
                <Form.Column
                  style={{ ...styles.weekStyle, ...styles.textView }}
                  key={`[${index + 1}].dayHours.colDay`}
                >
                  <CheckboxInput
                    mode={CheckboxInputMode.FLAT}
                    checked={!dayData.disabled}
                    onPress={(checked) => selectRow(index + 1, !checked)}
                  />
                  <Text style={styles.textFormat}>{dayOfWeek}</Text>
                </Form.Column>
                <Form.Column
                  style={styles.hoursGroupStyle}
                  key={`[${index + 1}].dayHours.colHours`}
                >
                  {dayData.dayHours.map((_, nestedIndex) => (
                    <RenderDateTimePickerRow
                      index={index + 1}
                      nestedIndex={nestedIndex}
                    />
                  ))}
                </Form.Column>
              </Form.Row>
            </>
          );
        })}
      </Form>
    </Modal>
  );
};

interface DayForm {
  disabled: boolean;
  dayHours: TimeRangeForm[];
}

interface DepartmentHoursFormProps {
  departmentId: string;
  departmentName: string;
  [key: number]: DayForm;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: 8,
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  weekStyle: {
    flexDirection: 'row',
    flex: 1,
    zIndex: zIndexAuto,
  },
  hoursGroupStyle: {
    flex: 4,
    zIndex: zIndexAuto,
  },
  hoursStyle: {
    flexDirection: 'row',
    flex: 4,
    zIndex: zIndexAuto,
    alignItems: 'baseline',
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    marginLeft: theme.getSpacing(1),
  },
  row: {
    alignContent: 'stretch',
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    paddingBottom: theme.getSpacing(3),
  },
  closedTextStyle: {
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.getSpacing(1),
  },
  separatorStyle: {
    color: theme.palette.gray[400],
    fontWeight: '600',
    alignItems: 'baseline',
  },
  separatorView: {
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
}));
