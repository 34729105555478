import { useUserState } from '../../store/user-store';
import { useTasksDataTableState } from '../tasks-data-table/tasks-data-table-store';
import { TaskFilters, useTasksFiltersState } from './tasks-filters-store';

const clearBaseFilterProps = {
  status: undefined,
  flagged: undefined,
  flaggedFirst: undefined,
  recurring: undefined,
  deleted_only: undefined,
  non_resolved_only: undefined,
  visibility: undefined,
};

interface TodayMultiFilterProps {
  min_due_date?: TaskFilters['min_due_date'];
  max_due_date?: TaskFilters['max_due_date'];
}

type TaskFiltersObjectEntries = [keyof TaskFilters, string][];

export const setBaseFilter = (filter: TaskFilters, name: string) => {
  const isCustomFilter = useTasksFiltersState.getState().isCustomFilter;

  const baseFiltersOverride = !isCustomFilter ? clearBaseFilterProps : {};

  useTasksDataTableState.setState({ status: 'loading' });
  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      ...baseFiltersOverride,
      ...filter,
    },
    activeTab: name,
  }));
};

export const setAdvancedFilter = (
  filter: TaskFilters,
  isCustomFilter = true,
) => {
  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      search_term: prevState.filters.search_term,
      ...clearBaseFilterProps,
      ...filter,
    },
    activeTab: 'all_tasks',
    showModal: false,
    isCustomFilter,
  }));
};

export const setCheckboxFilter = (filter: TaskFilters) => {
  const filters = useTasksFiltersState.getState().filters;

  const [[filterKey, filterValue]] = Object.entries(
    filter,
  ) as TaskFiltersObjectEntries;

  const isFilterSet = filters[filterKey] === filterValue;

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      [filterKey]: isFilterSet ? undefined : filterValue,
    },
  }));
};

export const setTodayCheckboxFilter = (props: TodayMultiFilterProps) => {
  const { min_due_date, max_due_date } = props;
  const filters = useTasksFiltersState.getState().filters;

  const isFilterSet =
    filters[`min_due_date`] === min_due_date &&
    filters[`max_due_date`] === max_due_date;

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      min_due_date: isFilterSet ? undefined : min_due_date,
      max_due_date: isFilterSet ? undefined : max_due_date,
    },
  }));
};

export const setOverdueCheckboxFilter = (filter: TaskFilters) => {
  const { due_date } = filter;
  const filters = useTasksFiltersState.getState().filters;

  const isFilterSet = filters.due_date === due_date;

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      min_due_date: undefined,
      max_due_date: undefined,
      due_date: isFilterSet ? undefined : due_date,
      non_resolved_only:
        isFilterSet || filters.status ? filters.non_resolved_only : true,
    },
  }));
};

export const setSearchTerm = (term: string) => {
  const filters = useTasksFiltersState.getState().filters;

  const hasOtherAdvancedFilters =
    filters &&
    Object.values(filters).filter((filter) => filter !== undefined).length > 2;

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    filters: {
      ...prevState.filters,
      search_term: term === '' ? undefined : term,
    },
    isCustomFilter: !hasOtherAdvancedFilters
      ? !!term
      : prevState.isCustomFilter,
  }));
};

export const setCreatedByMeCheckboxFilter = () => {
  const userId = useUserState.getState().data?.id;
  setCheckboxFilter({ created_by_user_id: userId });
};

export const disableFilters = () => {
  useTasksFiltersState.setState({ disabled: true });
};

export const enableFilters = () => {
  useTasksFiltersState.setState({ disabled: false });
};

export const setCounts = (count: {
  total: number;
  flagged: number;
  unresolved: number;
  recurring: number;
  personal: number;
  deleted: number;
  resolved: number;
}) => {
  useTasksFiltersState.setState({
    count: {
      total: count.total,
      flagged: count.flagged,
      unresolved: count.unresolved,
      recurring: count.recurring,
      personal: count.personal,
      deleted: count.deleted,
      resolved: count.resolved,
    },
  });
};

export const resetFilters = (page?: string) => {
  const isGrid = useTasksFiltersState.getState().isGrid;
  const fillerFilter = !isGrid ? { search_term: undefined } : {};

  useTasksFiltersState.setState((prevState) => ({
    filters: { non_resolved_only: true, ...fillerFilter },
    activeTab: 'all_tasks',
    isGrid: page ? page === 'grid' : prevState.isGrid,
    isCustomFilter: false,
  }));
};

export const updateFiltersShowModalState = (newState: boolean) => {
  useTasksFiltersState.setState({
    showModal: newState,
  });
};
