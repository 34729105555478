import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useAppStateStore } from '../store/app-store';
import { useUserState } from '../store/user-store';
import { loadUserInfoMap, persistUserInfo } from './user-actions';
import RoleService from '../api/RoleService';
import { Linking, Platform } from 'react-native';
import { ampli } from '../src/ampli';
import { useOnboardingStore } from '../store/onboarding-store';
import { logError } from 'assets/logging/logger';
import PharmacistLocationService from '../api/PharmacistLocationService';

export const getUsersMostRecentLocations = async () => {
  const { data: user } = useUserState.getState();
  if (!user?.id) return;

  const response =
    await PharmacistLocationService.pharmacistLocationsFindMostRecent(user.id);

  useAppStateStore.setState({
    userMostRecentLocations: response.locations,
    hasMoreLocations: response.total_count > 5,
  });

  if (response.locations.length === 1) {
    await changeLocation(response.locations[0]);
  } else {
    await loadLastSelectedLocation();
  }
};

export const setAmplitudeUserProperties = async (
  userId: string,
  pharmacyName: string,
  pharmacyId: string,
  locationId: string,
) => {
  ampli.identify(userId, {
    pharmacy_id: pharmacyId,
    pharmacy_name: pharmacyName,
    location_id: locationId,
  });
};

export const changeLocation = async (item: PharmacistLocationDto) => {
  const { userMostRecentLocations } = useAppStateStore.getState();
  const { data: user } = useUserState.getState();

  void (await persistUserInfo({
    ...user,
    selectedLocation: item,
  }));

  // faking the most recent locations, TODO: remove once the BE supports the most recent functionality
  if (
    !userMostRecentLocations
      .map((x) => x.location_id)
      .includes(item.location_id)
  ) {
    useAppStateStore.setState({
      userMostRecentLocations: [item, ...userMostRecentLocations.slice(1)],
    });
  }

  try {
    const res = await RoleService.userRoleGetRolesByUserIdAndLocation(
      user?.id!,
      item.location_id,
    );

    useAppStateStore.setState({
      availableRoles: res.available_roles,
    });
  } catch (e) {
    logError({
      cause: e,
      name: 'Could not fetch available roles',
      message: [
        'pharmacyId',
        item.pharmacy_id,
        'userId',
        user?.id,
        'locId',
        item.location_id,
      ].join(' - '),
    });
  }
};

export const resetLocationSelection = async () => {
  const { data: user } = useUserState.getState();

  void (await persistUserInfo({
    ...user,
    selectedLocation: undefined,
  }));

  await getUsersMostRecentLocations();
};

export const loadLastSelectedLocation = async () => {
  const { data: user } = useUserState.getState();
  if (!user?.id) return;

  const userInfoMap = await loadUserInfoMap();

  const userExtended = userInfoMap[user.id];
  if (userExtended.selectedLocation) {
    void changeLocation(userExtended.selectedLocation);
  }
};

/**
 *
 * @param url String that represent the url to be redirected
 * @param target Usi this only for web features. Use `_blank` value if you want to open in a new tab.
 */
export const openUrl = (url: string, target?: string) => {
  if (Platform.OS === 'web') {
    window.open(
      url,
      target, // <- This if we want to open on a new tab
    );
  } else {
    void Linking.openURL(url);
  }
};

export const loadLocationsAndSelectLastCreated = async () => {
  const { data: user } = useUserState.getState();
  if (!user?.id) return;
  const pharmacyId = useOnboardingStore.getState().data.id;
  if (!pharmacyId) return;

  const userMostRecentLocations =
    await PharmacistLocationService.pharmacistLocationsFindMostRecent(user.id);

  useAppStateStore.setState({
    userMostRecentLocations: userMostRecentLocations.locations,
    hasMoreLocations: userMostRecentLocations.total_count > 5,
  });

  if (userMostRecentLocations.total_count === 1) {
    await changeLocation(userMostRecentLocations.locations[0]);
  } else {
    const userLocationsAll =
      await PharmacistLocationService.pharmacistLocationsFindAll(user.id);

    const selectLocation = userLocationsAll.locations.find(
      (x) => x.pharmacy_id === pharmacyId,
    );
    if (selectLocation) await changeLocation(selectLocation);
  }
};
