/**
 * Edit and create user modals extend this
 */
import {
  BulkCreateRole,
  EntityType,
  Role,
  UserRoleDto,
} from '@digitalpharmacist/role-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { Modal } from 'assets/components/modal';
import RadioButton, {
  RadioButtonInputMode,
} from 'assets/components/radio-button-group/RadioButton';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { FormProvider } from 'assets/form';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { getSpacing, theme } from 'assets/theme';
import { FunctionComponent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { MenuDivider } from 'react-native-material-menu';
import RoleService from '../../../api/RoleService';
import UsersService from '../../../api/UsersService';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import PharmacyConfirmationModal from '../../../components/PharmacyConfirmationModal';
import { useAppStateStore } from '../../../store/app-store';
import {
  RoleOption,
  getEntityTypeFromRole,
  getHighestRole,
  roleOptions,
} from './UserHelpers';
import { UserLocationRolesModal } from './UserLocationRolesModal';
import { UserModalTestIDs } from './UserModalTestIDs';
import { UserForm } from './UserTypes';
import useViewSidebarStore from './view-user-sidebar-store';

export const ManageUserModalBase: FunctionComponent<ManageUserModalProps> = ({
  title,
  methods,
  newUserMode,
  refreshTable,
  onEditUser,
  onEditRole,
  show,
  onHide,
}) => {
  const [showUserRolesModal, setShowUserRolesModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { showUserEdit, setUserRoles, setShowSidebar } = useViewSidebarStore();
  const { availableRoles, pharmacyId, locationId } = useAppStateStore();
  const [selectedRoleObject, setSelectedRoleObject] = useState<RoleOption>();
  const [deleteRoleValue, setDeleteRoleValue] = useState(false);
  const [locationEmployeeValue, setLocationEmployeeValue] = useState(false);
  const [visible, setVisible] = useState(false);
  const highestAvailableRole = getHighestRole(availableRoles);
  const roleOptionList = roleOptions.getAssignableRoleSet(highestAvailableRole);

  useEffect(() => {
    if (!show && !showUserEdit) {
      return hide();
    }

    setVisible(true);
  }, [show, showUserEdit]);

  useEffect(() => {
    // Clears loading when location modal is closed
    if (showUserRolesModal) {
      setShowLoading(false);
    }
  }, [showUserRolesModal]);

  const hide = () => {
    setVisible(false);
    setShowLoading(false);
    onHide();
    methods.clearErrors();
  };

  const {
    firstName: firstNameIsPopulated,
    lastName: lastNameIsPopulated,
    email: emailIsPopulated,
    role: roleIsPopulated,
  } = methods.formState.dirtyFields;

  const roleListForEditUser = roleOptionList.filter(
    (x) =>
      x.value !== Role.NoAccess &&
      x.value !== Role.Manager &&
      x.value !== Role.Staff,
  );

  const onChange = (value: Role) => {
    methods.setValue('role', value, { shouldDirty: true });
    const roleObject = roleListForEditUser.find((x) => x.value === value);
    setSelectedRoleObject(roleObject);
    setDeleteRoleValue(false);
    setLocationEmployeeValue(false);
  };

  const onDeleteRoleSelection = () => {
    setDeleteRoleValue(true);
    setLocationEmployeeValue(false);
    setSelectedRoleObject(undefined);
    methods.setValue('role', undefined, { shouldDirty: true });
  };

  const onLocationEmployeeSelection = () => {
    setLocationEmployeeValue(true);
    setDeleteRoleValue(false);
    setSelectedRoleObject(undefined);
    methods.setValue('role', undefined, { shouldDirty: true });
  };

  const getRoledescription = (label: string) => {
    switch (label) {
      case Role.ItAdmin:
        return getText('it-admin-description');
      case Role.Corporate:
        return getText('corporate-description');
      case Role.Brand:
        return getText('brand-description');
      case Role.Manager:
        return getText('manager-description');
      default:
        return getText('full-access');
    }
  };

  const updateUserAndRole = async () => {
    const requiresUserEdit =
      firstNameIsPopulated || lastNameIsPopulated || emailIsPopulated;
    const requiresRoleEdit = roleIsPopulated;

    if (requiresUserEdit && onEditUser) {
      await onEditUser();
    }

    if (requiresRoleEdit) {
      await onEditRole(selectedRoleObject);
    }

    if (!requiresUserEdit && !requiresRoleEdit) {
      hide();
    }
    refreshTable();
  };

  /**
   * Create user or get and set userId
   */
  const upsertUser = async () => {
    const { firstName, lastName, email } = methods.getValues();
    if (!firstName || !lastName || !email) {
      throw 'firstName, lastName, or email not set';
    }
    const pharmacistUser = await UsersService.userPharmacistRegisterPost({
      firstName,
      lastName,
      email,
    }).catch((error) => {
      if (error.response.status === 409) {
        // Not a problem if conflict, just create the role
        const message = error.response.data.message;
        const existingUserId = message.split(' ')[1];
        return { pharmacistId: existingUserId };
      }

      logError(new Error(`Unknown error creating : ${error}`));
    });
    if (!pharmacistUser || !pharmacistUser.pharmacistId) {
      return logError(
        new Error('PharmacistUser.pharmacistId is unset after upsert.'),
      );
    }
    const userId = pharmacistUser.pharmacistId;
    methods.setValue('userId', userId);

    return;
  };

  const handleSubmit = async () => {
    setShowLoading(true);

    if (newUserMode) {
      await upsertUser();
    }

    const userId = methods.getValues('userId');

    if (!userId || !validate.isUUID(userId)) {
      return logError(new Error(`Pharmacist userId is not valid: ${userId}`));
    }

    if (locationEmployeeValue) {
      setShowUserRolesModal(true);
      return;
    }

    if (!newUserMode && deleteRoleValue) {
      setShowConfirmationModal(true);
      return;
    }

    await updateUserAndRole();
    hide();
  };

  const deleteUserRoles = async () => {
    await updateUserAndRole();
    setShowConfirmationModal(false);
    setShowSidebar(false);
    hide();
  };

  const updateUserRoles = async () => {
    const { userId, roles, firstName, lastName, email } = methods.getValues();
    setShowLoading(true);
    const user_roles: UserRoleDto[] = [];
    try {
      const requiresUserEdit = firstName || lastName || email;
      if (!userId) {
        throw 'UserId to be edited is not defined';
      }
      if (roles) {
        const locationRoles: BulkCreateRole[] = Object.entries(roles)
          .filter(([value]) => value && value !== Role.NoAccess)
          .map(([key, value]) => ({
            role: value,
            entity_id: key,
          }));
        const response = await RoleService.userRoleReplaceRoles(userId, {
          roles: locationRoles,
        });
        user_roles.push(...response.map((x) => ({ ...x, user_id: userId })));
      }

      setUserRoles(user_roles);
      if (requiresUserEdit && onEditUser) {
        await onEditUser();
      }
      hide();
      refreshTable();
    } catch (e) {
      logError(new Error(`Edit user failed with error: ${e}`));
    } finally {
      setShowLoading(false);
    }
  };

  /**
   * Returns true if user is not in the pharmacy,
   * returns error string if user does exist
   * @returns string | true
   */
  const validateUserNotInPharmacy = async (
    email: string,
  ): Promise<string | true> => {
    const entityType = getEntityTypeFromRole(highestAvailableRole);
    let matches;
    if (entityType === EntityType.Location) {
      matches = await RoleService.searchLocationUsers(locationId, email);
    } else {
      matches = await RoleService.searchPharmacyUsers(pharmacyId, email);
    }

    // This is a search but need an exact match
    // Check for exact match
    const userNotFound =
      matches.results.findIndex((user) => user.email === email) === -1;
    return userNotFound || getText('user-has-role-error');
  };

  /**
   * Validates email & not existing user in pharmacy
   * @param email
   * @returns
   */
  const validateEmail = async (email: string) => {
    const valid = true;
    if (!validate.isEmail(email)) {
      return getText('email-is-not-valid');
    }
    // To reduce request, only after email valid
    if (newUserMode === true) {
      return await validateUserNotInPharmacy(email);
    }
    return valid;
  };

  const onPressOk = () => {
    return methods.handleSubmit(handleSubmit)();
  };

  return (
    <>
      {showUserRolesModal && (
        <UserLocationRolesModal
          methods={methods}
          showModal={showUserRolesModal}
          updateUserRoles={updateUserRoles}
          setShowUserModal={() => {}}
          setShowModal={setShowUserRolesModal}
        />
      )}
      <Modal
        title={title}
        cancelButtonProps={{
          onPress: hide,
          logger: { id: 'user-edit-cancel-button' },
        }}
        okButtonProps={{
          onPress: onPressOk,
          logger: { id: 'user-edit-confirm-button' },
          hierarchy: 'primary',
          text: getText('ok'),
          disabled:
            showLoading ||
            (!methods.getValues().role &&
              !locationEmployeeValue &&
              !deleteRoleValue),
        }}
        show={visible}
        size="sm"
      >
        {showLoading && <LoadingOverlay />}
        <FormProvider {...methods}>
          <View style={styles.twoColumnRow}>
            <View style={styles.column}>
              <TextField
                label={getText('first-name')}
                name="firstName"
                rules={{
                  required: getText('first-name-is-required'),
                  validate: {
                    value: (value) => {
                      return (
                        validate.isName(value) ||
                        getText('first-name-is-not-valid')
                      );
                    },
                  },
                }}
                value={methods.getValues().firstName}
                disabled={status === 'loading'}
                testID={UserModalTestIDs.firstNameInput}
              />
            </View>
            <View style={styles.column}>
              <TextField
                label={getText('last-name')}
                name="lastName"
                value={methods.getValues().lastName}
                rules={{
                  required: getText('last-name-is-required'),
                  validate: {
                    value: (value) => {
                      return (
                        validate.isName(value) ||
                        getText('last-name-is-not-valid')
                      );
                    },
                  },
                }}
                disabled={status === 'loading'}
                testID={UserModalTestIDs.lastNameInput}
              />
            </View>
          </View>
          <View style={styles.row}>
            <TextField
              label={getText('email')}
              value={methods.getValues().email}
              name="email"
              rules={{
                required: getText('email-is-required'),
                validate: {
                  value: validateEmail,
                },
              }}
              disabled={status === 'loading' || !newUserMode}
              testID={UserModalTestIDs.emailInput}
            />
          </View>
          <View style={styles.rolesRow}>
            <Text style={[styles.boldText]}>{getText('role')}</Text>
            <Text style={styles.labelSmall}>
              {getText('role-permission-description')}
            </Text>
            <MenuDivider color={theme.palette.gray[500]} />
            <View style={styles.roleSelectionRow}>
              {roleListForEditUser.map((role) => {
                return (
                  <RadioButton
                    mode={RadioButtonInputMode.FLAT}
                    key={role.label}
                    text={role.label}
                    description={getRoledescription(role.value)}
                    selected={methods.getValues().role === role.value}
                    value={role.value}
                    onValueChange={(value) => onChange(value)}
                  />
                );
              })}
              <RadioButton
                mode={RadioButtonInputMode.FLAT}
                text={getText('location-employee')}
                description={getText('location-employee-description')}
                selected={locationEmployeeValue}
                value={locationEmployeeValue}
                onValueChange={() => onLocationEmployeeSelection()}
              />
              {!newUserMode && (
                <RadioButton
                  textColor={theme.palette.error[600]}
                  mode={RadioButtonInputMode.FLAT}
                  text={getText('remove-role')}
                  description={getText('remove-role-description')}
                  selected={deleteRoleValue}
                  value={deleteRoleValue}
                  onValueChange={onDeleteRoleSelection}
                />
              )}
            </View>
          </View>
        </FormProvider>
      </Modal>
      <PharmacyConfirmationModal
        show={showConfirmationModal}
        onAccepted={deleteUserRoles}
        onDismiss={() => setShowConfirmationModal(false)}
        message={getText('confirming-deactivate-user')}
      />
    </>
  );
};

const styles = StyleSheet.create({
  twoColumnRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  column: {
    flex: 50,
    margin: getSpacing(1),
  },
  row: {
    margin: getSpacing(1),
  },
  showLocations: {
    color: theme.palette.primary[600],
  },
  labelSmall: {
    ...theme.fonts.regular,
    size: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
    paddingBottom: getSpacing(2),
  },
  rolesRow: {
    marginTop: getSpacing(2),
    marginLeft: getSpacing(1),
  },
  boldText: {
    paddingBottom: getSpacing(0.5),
    fontSize: 16,
  },
  roleSelectionRow: {
    marginTop: getSpacing(2),
    overflow: 'scroll',
    maxHeight: 250,
  },
});

export interface ManageUserModalProps {
  title: string;
  newUserMode: boolean;
  onEditUser?: () => Promise<void> | void;
  onEditRole: (role?: RoleOption) => Promise<void>;
  methods: UseFormReturn<UserForm, any>;
  refreshTable: () => void;
  show: boolean;
  onHide: () => void;
}
