import {
  AllHolidaysDto,
  DepartmentTimeRangeDto,
  InfoCardDto,
  LocationClosuresResponseDto,
  PaginatedLocationResponseDto,
  PaginatedPharmacistLocationResponseDto,
  PharmacyLocationDto,
  ServiceDto,
  TimezoneDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import { AsyncStatus } from '../../../store/types';
import { PmsDto } from '@digitalpharmacist/pms-integration-service-client-axios';

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}

export enum DaysOfWeekEnum {
  Sunday = 1,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export interface LocationSettingsState {
  showModalLocation: boolean;
  showModalLocationGeneralInfo: boolean;
  showModalLocationContactInfo: boolean;
  showModalLocationTeams: boolean;
  showModalLocationService: boolean;
  showModalLocationPickup: boolean;
  showModalLocationNormalHours: boolean;
  showModalLocationHolidayClosures: boolean;
  showModalLocationAdditionalClosures: boolean;
  showModalLocationInfoCards: boolean;
  allLocations: PaginatedPharmacistLocationResponseDto;
  search: string;
  page: number;
  location?: PharmacyLocationDto;
  pharmacyId?: string;
  locationId?: string;
  status: AsyncStatus;
  country: CountryCode;
  locationNormalHours: DepartmentTimeRangeDto[];
  editingLocationNormalHours?: DepartmentTimeRangeDto;
  locationHolidays: AllHolidaysDto;
  locationClosures: LocationClosuresResponseDto;
  allServiceOptions: ServiceDto[];
  allTimezones: TimezoneDto[];
  infoCards: InfoCardDto[];
  infoCard?: InfoCardDto;
  allPMS: PmsDto[];
}

export const useLocationSettingsState = create<LocationSettingsState>(() => ({
  showModalLocation: false,
  showModalLocationGeneralInfo: false,
  showModalLocationContactInfo: false,
  showModalLocationTeams: false,
  showModalLocationService: false,
  showModalLocationPickup: false,
  showModalLocationNormalHours: false,
  showModalLocationHolidayClosures: false,
  showModalLocationAdditionalClosures: false,
  showModalLocationInfoCards: false,
  allLocations: { total_count: 0, locations: [] },
  search: '',
  page: 0,
  status: 'loading',
  country: CountryCode.US,
  locationNormalHours: [],
  locationHolidays: { use_default_holidays: true, holidays: [] },
  locationClosures: { location: [], pharmacy: [] },
  allServiceOptions: [],
  allTimezones: [],
  infoCards: [],
  allPMS: [],
}));
