import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { TouchableOpacity } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Icon } from 'assets/components/icon';

export const ActionBox: FunctionComponent<
  PropsWithChildren<ActionBoxProps>
> = ({ icon, title, onPress }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <TouchableOpacity style={styles.root} onPress={onPress}>
      <Icon icon={icon} color={theme.palette.primary[600]} size={24} />

      <Text style={styles.text}>{title}</Text>
    </TouchableOpacity>
  );
};

export interface ActionBoxProps {
  icon: React.FunctionComponent;
  title: string;
  onPress: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.getSpacing(1.5),
    paddingBottom: theme.getSpacing(1.5),
    height: 70,
    width: 60,
    gap: theme.getSpacing(1),
    backgroundColor: theme.palette.white,
    borderRadius: theme.roundness,
    borderWidth: 1,
    borderColor: theme.palette.primary[200],
    boxShadow:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);',
  },
  text: {
    color: theme.palette.primary[600],
    fontSize: 10,
    fontFamily: 'Lato_400Regular',
    lineHeight: 11,
  },
}));
