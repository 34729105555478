import React, { useEffect, useRef } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { Text } from 'assets/components/text';
import {
  CheckIcon,
  ChevronDownIcon,
  GlobeIcon,
  SearchIcon,
} from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { BaseOptionType, MenuWrapper } from '../pharmacy-header/MenuWrapper';
import {
  PharmacyStoreSelectorModal,
  PharmacyStoreSelectorModalHandler,
} from './PharmacyStoreSelectorModal';
import {
  changeLocation,
  getUsersMostRecentLocations,
} from '../../actions/app-actions';

export const PharmacyStoreSelector = () => {
  const styles = useStyles();

  const { data: user } = useUserState();
  const { userMostRecentLocations } = useAppStateStore();

  useEffect(() => {
    void (async () => {
      if (!user?.id) return;
      if (userMostRecentLocations.length === 0) {
        await getUsersMostRecentLocations();
      }
    })();
  }, [user?.id]);

  return userMostRecentLocations.length > 0 ? (
    <View style={styles.root}>
      {userMostRecentLocations.length === 1 ? <SingleStore /> : <MultiStore />}
    </View>
  ) : (
    <></>
  );
};

const SingleStore = () => {
  const styles = useStyles();

  const { locationId, userMostRecentLocations } = useAppStateStore();
  const currentLocation = userMostRecentLocations.find(
    (x) => x.location_id === locationId,
  );

  return (
    <View style={styles.singleStore}>
      <Text style={styles.singleStoreText}>
        {currentLocation?.location_full_display_name}
      </Text>
    </View>
  );
};

const MultiStore = () => {
  const theme = useTheme();
  const styles = useStyles();

  const pharmacyStoreSelectorModalRef =
    useRef<PharmacyStoreSelectorModalHandler>(null);

  const { locationId, userMostRecentLocations, hasMoreLocations } =
    useAppStateStore();

  // #region Temporary solution for testing, refactor this block
  const selectedLocation = userMostRecentLocations.find(
    (x) => x.location_id === locationId,
  );

  const findLocationOption: Partial<PharmacySelectorMenuType>[] =
    hasMoreLocations
      ? [
          {
            isFindLocation: true,
            location_full_display_name: 'Find a location ...',
          },
          {
            divider: true,
            isFindLocation: false,
          },
        ]
      : [];

  const items: Partial<PharmacySelectorMenuType>[] = [
    ...findLocationOption,
    ...userMostRecentLocations,
  ];
  // #endregion

  const handleOnMenuPress = (item: PharmacySelectorMenuType) => {
    if (item.isFindLocation) pharmacyStoreSelectorModalRef.current?.show();
    else void handleOnChangeLocation(item);
  };

  // TODO: this should be a centralized change location action
  const handleOnChangeLocation = (item: PharmacistLocationDto) => {
    void changeLocation(item);
  };

  return (
    <View style={styles.root}>
      <MenuWrapper
        anchor={
          <View style={styles.anchorContainer}>
            {selectedLocation?.location_is_hq && ( // TODO: refactor this line once we have the new definition from PharmacyLocationDTO
              <View style={styles.iconContainer}>
                <GlobeIcon size={20} color={theme.palette.white} />
              </View>
            )}

            <Text
              style={styles.anchorText}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {selectedLocation?.location_full_display_name}
            </Text>

            <View style={styles.iconContainer}>
              <ChevronDownIcon size={20} color={theme.palette.white} />
            </View>
          </View>
        }
        onPress={handleOnMenuPress}
        items={items as PharmacySelectorMenuType[]}
        optionTemplate={(item) => (
          <View style={styles.optionContainer}>
            {item.isFindLocation && (
              <View style={{ marginRight: theme.getSpacing(1) }}>
                <Icon
                  icon={SearchIcon}
                  color={theme.palette.gray[500]}
                  size={16}
                />
              </View>
            )}
            {item.location_is_hq && (
              <View style={{ marginRight: theme.getSpacing(1) }}>
                <Icon
                  icon={GlobeIcon}
                  color={theme.palette.gray[500]}
                  size={16}
                />
              </View>
            )}
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={{
                ...styles.optionText,
                color:
                  item.location_id == locationId
                    ? theme.palette.primary[600]
                    : theme.palette.gray[900],
              }}
            >
              {item.location_full_display_name}
            </Text>
            {item.location_id == locationId && (
              <View style={{ marginLeft: theme.getSpacing(1) }}>
                <Icon
                  icon={CheckIcon}
                  color={theme.palette.primary[600]}
                  size={16}
                />
              </View>
            )}
          </View>
        )}
        menuStyle={{ width: 250, marginTop: 45 }}
        menuItemStyle={{ height: 32 }}
      />

      <PharmacyStoreSelectorModal
        ref={pharmacyStoreSelectorModalRef}
        onChange={handleOnChangeLocation}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'center',
  },
  singleStore: { padding: theme.getSpacing(1) },
  singleStoreText: {
    color: theme.palette.white,
    opacity: 0.8,
    fontSize: 16,
    lineHeight: 24,
  },
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  dropdownElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  anchorContainer: {
    flexDirection: 'row',
    backgroundColor: theme.palette.primary[800],
    borderRadius: theme.roundness,
    paddingLeft: theme.getSpacing(3),
    paddingRight: theme.getSpacing(3),
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    gap: theme.getSpacing(2),
    width: 250,
    height: 44,
  },
  anchorText: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    flex: 1,
  },
  iconContainer: {
    justifyContent: 'center',
    alignContent: 'center',
  },
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  optionText: {
    flex: 1,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
}));

type PharmacySelectorMenuType = PharmacistLocationDto & {
  isFindLocation?: boolean;
} & BaseOptionType;
