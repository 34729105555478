import { Icon } from 'assets/components/icon';
import { InfoIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { Tooltip } from '../Tooltip/Tooltip';

export const SettingsTooltipsComponents: FunctionComponent<
  SettingsTooltipsComponentsProps
> = ({ tooltipId, tooltipText, color }) => {
  const styles = useStyles();

  return (
    <View style={styles.tooltip}>
      <PharmacyTooltipWrapper tooltipId={tooltipId}>
        <Icon icon={InfoIcon} size={16} color={color} />
      </PharmacyTooltipWrapper>
      {tooltipText && (
        <Tooltip
          anchorId={tooltipId}
          place="top"
          variant="dark"
          text={tooltipText}
        />
      )}
    </View>
  );
};
//

interface SettingsTooltipsComponentsProps {
  tooltipId: string;
  tooltipText?: string;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginLeft: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));
