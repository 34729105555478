export { Role } from '@digitalpharmacist/role-service-client-axios';

export type Resources =
  | 'tasks'
  | 'forms'
  | 'pharmacyUsers'
  | 'pharmacyUserRoles'
  | 'settings'
  | 'settingsLumistryUser'
  | 'settingsPmsIntegration'
  | 'settingsItAdmin'
  | 'appIcon';

export const allActions = [
  'read',
  'update',
  'delete',
  'create',
  'manage',
  'schedule',
  'makePublic',
  // manageLocations role is for pharmacy level users BRAND & CORPORATE
  // Intent is to allow actions that relate to managing locations
  // Its a concept at this point, is there a better way?
  'manageLocations',
  'push',
  'manage:role:NO_ACCESS',
  'manage:role:STAFF',
  'manage:role:MANAGER',
  'manage:role:BRAND',
  'manage:role:CORPORATE',
  'manage:role:IT_ADMIN',
  'manage:role:LUMISTRY_USER',
  'manage:role:LUMISTRY_ADMIN',
] as const;
export type Actions = (typeof allActions)[number]; // creates a union magically :)

export type Effect = 'EFFECT_ALLOW'; // Cerbos has EFFECT_DENY, but for now we will only use allow for simplicity.
