import {
  ChecklistOptionDto,
  RequiredOptional,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { getText, translations } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import {
  fetchChecklist,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import {
  ChecklistModalIds,
  useChecklistStore,
} from '../../../store/checklist-store';
import { ChecklistSetupGroupItem } from './ChecklistSetupGroupItem';
import { useToast } from '../../../common/hooks/useToast';
import { ChecklistModalProps } from '../types';
import { LoadingIndicator } from 'assets/components/loading-indicator';

export const ChecklistSetupModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const styles = useStyles();
  const { toast } = useToast();
  const { currentOpenedModalId, checklistOptions, status } =
    useChecklistStore();

  const sortedChecklist = useMemo(
    () => checklistOptions.slice().sort((a, b) => a.order - b.order),
    [checklistOptions],
  );

  const [requiredChecklist, optionalChecklist] = useMemo(
    () => [
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Required)
        .filter((x) => x.slug !== 'patient-website'), // temporary
      sortedChecklist.filter((x) => x.required === RequiredOptional.Optional),
    ],
    [sortedChecklist],
  );

  const [
    lastRequiredCompletedIndex,
    lastOptionalCompletedIndex,
    areRequiredItemsComplete,
    firstIncompletedOptionalIndex,
  ] = useMemo(
    () => [
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Required)
        .findLastIndex((x) => x.completed),
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Optional)
        .findLastIndex((x) => x.completed),
      sortedChecklist.findIndex(
        (x) => x.required === RequiredOptional.Required && !x.completed,
      ) < 0,
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Optional)
        .findIndex((x) => !x.completed),
    ],
    [sortedChecklist],
  );

  const showToast = (messageKey: translations) => {
    toast(getText(messageKey), { type: 'warning' });
  };

  const handleStep = (
    index: number,
    lastIndex: number,
    ch: ChecklistOptionDto,
  ) => {
    if (ch.completed) {
      showToast('checklist-item-completed');
    } else if (
      ch.required === RequiredOptional.Required &&
      index > lastIndex + 1
    ) {
      showToast('complete-checklist-items-in-order');
    } else if (
      !areRequiredItemsComplete &&
      ch.required === RequiredOptional.Optional
    ) {
      showToast('complete-checklist-items-in-order');
    } else {
      setOpenedModal(ch.slug as ChecklistModalIds);
    }
  };

  const handleCloseModal = () => setOpenedModal(undefined);

  useEffect(() => {
    if (currentOpenedModalId === id) fetchChecklist();
  }, [currentOpenedModalId]);

  return (
    <Modal
      title={getText('setup-checklist')}
      show={currentOpenedModalId === id}
      handleDismissModal={handleCloseModal}
      showDismissButton
      size="sm"
      buttons={[
        {
          text: getText('close'),
          onPress: handleCloseModal,
          hierarchy: 'primary',
          logger: { id: 'close-checklist-modal' },
        },
      ]}
      isScrollable
    >
      {status === 'loading' ? (
        <LoadingIndicator />
      ) : (
        <View style={styles.container}>
          <ChecklistSetupGroupItem
            title={getText('need-to-do-first')}
            subtitle={getText('complete-healthsite-patient')}
            items={requiredChecklist}
            lastCompletedIndex={lastRequiredCompletedIndex}
            areRequiredItemsComplete={areRequiredItemsComplete}
            firstIncompletedOptionalIndex={firstIncompletedOptionalIndex}
            handleStep={handleStep}
          />
          <ChecklistSetupGroupItem
            title={getText('other-settings')}
            subtitle={getText('checklist-items-in-settings')}
            items={optionalChecklist}
            lastCompletedIndex={lastOptionalCompletedIndex}
            areRequiredItemsComplete={areRequiredItemsComplete}
            firstIncompletedOptionalIndex={firstIncompletedOptionalIndex}
            handleStep={handleStep}
          />
        </View>
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
}));
