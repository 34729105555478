import {
  ChecklistOptionDto,
  AppSettingsDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { create } from './store-creator';
import { ApiError, AsyncStatus } from './types';

export type PatientAgreementType = {
  terms: BlobPart;
  privacy: BlobPart;
};

export type ChecklistModalIds =
  | 'checklist-setup'
  | 'patient-agreements'
  | 'patient-app'
  | 'media-settings'
  | 'color-picker'
  | 'patient-website'
  | 'website-access-setup'
  | 'website-access-completed'
  | 'website-access-permission'
  | 'patient-website-services'
  | 'patient-website-service-details';

export type PatientWebsiteOptionType =
  | 'build-new-website'
  | 'build-replace-website'
  | 'no-build-existing-website'
  | 'no-build-no-existing-website';

export interface ChecklistState {
  status: AsyncStatus;
  error?: ApiError;
  currentOpenedModalId?: ChecklistModalIds;
  brandColor?: string;
  checklistOptions: ChecklistOptionDto[];
  appSettings?: AppSettingsDto;
  dunsList?: Array<string>;
  selectedWebsiteOption?: PatientWebsiteOptionType;
  patientAgreementsDocs?: PatientAgreementType;
}

export const useChecklistStore = create<ChecklistState>(() => ({
  status: 'idle',
  checklistOptions: [],
}));
