import { Modal } from 'assets/components/modal';
import { ImageIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import theme, { makeStyles, useTheme } from 'assets/theme';
import { useState } from 'react';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Alert } from 'assets/components/alert';

type ImageViewerProps = {
  show: boolean;
  title: string;
  frontImageUrl: string | undefined;
  backImageUrl?: string | undefined;
  hasDouble?: boolean;
  okButtonPress: () => void;
};

const noImage = () => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <View>
        <ImageIcon size={32} color={theme.palette.gray[400]} />
      </View>
      <View style={styles.upload}>
        <Text style={styles.uploadText}>{getText('no-photo')}</Text>
      </View>
    </View>
  );
};

export const ImageViewer = ({
  show,
  title,
  frontImageUrl,
  backImageUrl,
  hasDouble = false,
  okButtonPress,
}: ImageViewerProps) => {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [errorWhileLoading, setErrorWhileLoading] = useState(false);

  const openImage = (image: string) => {
    window.open(image, '_blank');
  };

  const onErrorHandler = () => {
    setErrorWhileLoading(true);
    setIsLoading(false);
  };

  const ImageComponent = ({ imageUrl }: { imageUrl: string | undefined }) => {
    return imageUrl && !errorWhileLoading ? (
      <TouchableOpacity
        onPress={() => openImage(imageUrl)}
        style={styles.fullDimensions}
      >
        {isLoading && (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        )}
        <Image
          resizeMode={'contain'}
          onLoadEnd={() => setIsLoading(false)}
          onError={() => onErrorHandler()}
          style={{ height: 350 }}
          source={{
            uri: imageUrl,
          }}
        />
      </TouchableOpacity>
    ) : (
      noImage()
    );
  };

  return (
    <Modal
      title={title}
      size="lg"
      show={show}
      height={errorWhileLoading ? 450 : undefined}
      okButtonProps={{
        onPress: okButtonPress,
        logger: { id: 'confirmation-cancel-button' },
        text: getText('close'),
        hierarchy: 'secondary',
      }}
      showDismissButton
      handleDismissModal={okButtonPress}
    >
      {errorWhileLoading && (
        <Alert
          style={{ marginBottom: theme.getSpacing(2) }}
          intent="error"
          title={getText('photo-error')}
        />
      )}
      {hasDouble ? (
        <View style={styles.doubleContainer}>
          <View style={{ flex: 1 }}>
            <View style={styles.imageOptions}>
              <Text style={styles.textLabel}>{getText('front')}</Text>
            </View>
            <ImageComponent imageUrl={frontImageUrl} />
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.imageOptions}>
              <Text style={styles.textLabel}>{getText('back')}</Text>
            </View>
            <ImageComponent imageUrl={backImageUrl} />
          </View>
        </View>
      ) : (
        <ImageComponent imageUrl={frontImageUrl} />
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.gray[100],
    height: 350,
    borderRadius: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(3),
  },
  fullDimensions: {
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.gray[100],
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
  },
  doubleImageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageOptions: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  textLabel: {
    ...theme.lumistryFonts.label.small,
    marginBottom: theme.getSpacing(1),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
  },
  loading: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doubleContainer: {
    flexDirection: 'row',
    flex: 1,
    gap: 10,
  },
}));
