import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { SingleValueProps, OptionProps } from 'react-select';
import ReactQuill from 'react-quill';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import theme, { makeStyles } from 'assets/theme';
import { Modal } from 'assets/components/modal';
import { UploadIcon, UserIcon } from 'assets/icons';
import { PlaceholderProps } from 'react-select';
import { defaultTemplates } from '../../common/defaultTemplates';
import { useForm, useWatch } from 'react-hook-form';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { PlaceholderTemplate } from '../../common/components/PlaceholderTemplate';
import { AdvancedDropDownField } from '../../../../components/advanced-dropdown';
import { Form } from 'assets/layout';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { ITemplate } from 'assets/types/messageTypes';
import { useMessagingStyles } from '../../common/styles';
import { convertVariablesToPills } from '../../Quill/utils';
import '../../styles/messaging-text-editor-style.css';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';
import { BIG_MESSAGE_LIMIT, SMS_MESSAGE_LIMIT } from '../../data';
import {
  NewMessageBody,
  ToolBarOptions,
} from '../../components/NewMessageBody';
import {
  BulkMessageTime,
  EToALLFilter,
  ETo,
  BulkMessageForm,
  DynamicInputValue,
} from '../../common/types/MessageForm';
import { BulkMessageType } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  BulkFilterInput,
  BulkFilterInputRef,
  DynamicInputsOption,
  INPUT_MOCK,
} from './BulkFilterInput';
import { filteringOptions } from '../data';
import { validateFields } from '../utils';
import { useAppStateStore } from '../../../../store/app-store';
import unifiedCommsService from '../../../../api/UnifiedCommsService';
import patientService from '../../../../api/PatientService';
import { PatientLookupUserSelectField } from '../../../../components/patient-user-select/patient-lookup-user-select-field';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { formatDate } from '../../../../common/datetime-utils';
import { DateTimePickerField } from '../../../../components/DateTimePickerField';

export const NewBulkMessageModal: FunctionComponent<
  NewBulkMessageModalProps
> = ({ title, show, bulkData, defaultMessage = '', onCancel, onSubmit }) => {
  const locationId = useAppStateStore((state) => state.locationId);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const [errorFields, setErrorFields] = useState<Record<string, string>>({});
  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);
  const [quillRefEmail, setQuillRefEmail] = useState<ReactQuill | null>(null);
  const [quillRefSms, setQuillRefSms] = useState<ReactQuill | null>(null);
  const [filterInputs, setFilterInputs] = useState<{}[]>([INPUT_MOCK]);
  const [isNonSecureEnabled, setIsNonSecureEnabled] = useState<boolean>(true);
  const dynamicFiltersRef = useRef<BulkFilterInputRef>(null);
  const styles = useStyles();
  const messagingStyles = useMessagingStyles();

  const methods = useForm<BulkMessageForm>({
    defaultValues: bulkData ?? {
      message_type: BulkMessageType.Secure,
      template: null,
      template_email: null,
      subject: '',
      content: defaultMessage,
      content_email: defaultMessage,
      content_sms: defaultMessage,
      to: ETo.TO_ALL,
      to_all_filter: EToALLFilter.ALL,
      time: BulkMessageTime.IMMEDIATE,
      attachments: [],
    },
    mode: 'onChange',
  });

  const clearFormData = () => {
    methods.reset();
    setErrorFields({});
  };

  useEffect(() => {
    if (show && !bulkData) {
      clearFormData();
    }
  }, [show, bulkData]);

  useEffect(() => {
    void (async () => {
      const { marketing_phone, non_marketing_phone } =
        await unifiedCommsService.getDirectMessageSettings(
          pharmacyId,
          locationId,
        );

      if (!marketing_phone || !non_marketing_phone) {
        setIsNonSecureEnabled(false);
      }
    })();
  }, [pharmacyId, locationId]);

  const onSelectTemplate = (
    template: ITemplate,
    quillRef: ReactQuill | null,
  ) => {
    quillRef?.getEditor().deleteText(0, quillRef.getEditor().getLength());
    quillRef?.getEditor().insertText(0, template.body);

    const templateMessage = template.body ?? '';
    const templateSubject = template.subject ?? '';
    const contentWithPills =
      convertVariablesToPills(templateMessage, quillRef ?? null) ?? '';

    methods.setValue('content', contentWithPills);
    methods.setValue('content_email', contentWithPills);
    methods.setValue('content_sms', contentWithPills);
    methods.setValue('subject', templateSubject);
  };

  useEffect(() => {
    const template = methods.watch('template');
    if (template) {
      onSelectTemplate(template, quillRef);
    }
  }, [methods.watch('template')]);

  const content = useWatch({
    control: methods.control,
    name: 'content',
  });

  const subject = useWatch({
    control: methods.control,
    name: 'subject',
  });

  useEffect(() => {
    const template = methods.watch('template_email');
    if (template) {
      onSelectTemplate(template, quillRefEmail);
      onSelectTemplate(template, quillRefSms);
    }
  }, [methods.watch('template_email')]);

  useEffect(() => {
    // remove error from the subject field after changing its content
    if (subject && errorFields['subject']) {
      setErrorFields((prevErrorFields) => ({
        ...prevErrorFields,
        subject: '',
      }));
    }
  }, [subject]);

  useEffect(() => {
    // remove error from the message field after changing its content
    if (content && errorFields['content']) {
      setErrorFields((prevErrorFields) => ({
        ...prevErrorFields,
        content: '',
      }));
    }
  }, [content]);

  useEffect(() => {
    // remove error from the message field after changing its content
    if (methods.watch('content_email') && errorFields['content_email']) {
      setErrorFields({
        ...errorFields,
        content_email: '',
      });
    }
  }, [methods.watch('content_email')]);

  useEffect(() => {
    // remove error from the message field after changing its content
    if (methods.watch('content_sms') && errorFields['content_sms']) {
      setErrorFields({
        ...errorFields,
        content_sms: '',
      });
    }
  }, [methods.watch('content_sms')]);

  useEffect(() => {
    // remove error from the message field after changing its content
    if (
      methods.watch('specific_recipients') &&
      errorFields['specific_recipients']
    ) {
      setErrorFields({
        ...errorFields,
        specific_recipients: '',
      });
    }
  }, [methods.watch('specific_recipients')]);

  useEffect(() => {
    // remove error from the message field after changing its content
    if (methods.watch('scheduled_time') && errorFields['scheduled_time']) {
      setErrorFields({
        ...errorFields,
        scheduled_time: '',
      });
    }
  }, [methods.watch('scheduled_time')]);

  const onSelectSubmit = () => {
    const errorsCount = validateFields({
      data: methods.getValues(),
      methods: methods,
      filterInputs: filterInputs,
      setErrorFields: setErrorFields,
    });

    if (errorsCount === 0) {
      onSubmit(methods.getValues());
    }
  };

  const getFiltersWithoutInputSet = (
    filteringOptions: DynamicInputsOption[],
  ) => {
    return new Set(
      filteringOptions
        .filter((option) => option.isBooleanValue)
        .map((option) => option.value),
    );
  };

  const showErrorFor = (errorField: string) => {
    if (errorField in errorFields) {
      return <Text style={styles.errorMessage}>{errorFields[errorField]}</Text>;
    }
    return <></>;
  };

  const handleQuillRef = (quillRef: ReactQuill) => {
    setQuillRef(quillRef);
  };

  const handleQuillRefEmail = (quillRef: ReactQuill) => {
    setQuillRefEmail(quillRef);
  };

  const handleQuillRefSms = (quillRef: ReactQuill) => {
    setQuillRefSms(quillRef);
  };

  const asyncOptions = async (
    search: string,
  ): Promise<LocationPatientRecordDto[]> => {
    const data = await patientService.findAllLocationsRecordsById(locationId, {
      search: search,
      limit: 10,
      offset: 0,
    });

    return data.items;
  };

  function getValueField(filter: DynamicInputValue): {
    valueInput: React.JSX.Element;
    isReturningValueInput: boolean;
    marginBottom: number;
  } {
    const filtersWithoutInputSet = getFiltersWithoutInputSet(filteringOptions);
    const highRows = new Set([
      DynamicInputValue.ZIP_CODE_IS_ONE_OF,
      DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF,
    ]);
    const isReturningValueInput = !filtersWithoutInputSet.has(filter);
    const isHighRow = highRows.has(filter);

    const valueFieldMap: Record<DynamicInputValue, React.JSX.Element> = {
      [DynamicInputValue.AGE_IS_OLDER_THAN]: (
        <View style={styles.ageFilterWrapper}>
          <TextField
            hideRequiredAsterisk
            style={styles.filterInput}
            label={getText('enter-age')}
            name={DynamicInputValue.AGE_IS_OLDER_THAN}
            placeholderColor={theme.palette.white}
            textColor={theme.palette.black}
            activeBorderColor={theme.palette.gray[400]}
            inactiveBorderColor={theme.palette.gray[400]}
            labelColor={theme.palette.gray[400]}
            testID={DynamicInputValue.AGE_IS_OLDER_THAN}
            value={methods.watch(DynamicInputValue.AGE_IS_OLDER_THAN)}
            rules={{
              required: getText('age-is-required-field'),
            }}
          />
        </View>
      ),
      [DynamicInputValue.AGE_IS_YOUNGER_THAN]: (
        <View style={styles.ageFilterWrapper}>
          <TextField
            hideRequiredAsterisk
            style={styles.filterInput}
            label={getText('enter-age')}
            name={DynamicInputValue.AGE_IS_YOUNGER_THAN}
            placeholderColor={theme.palette.white}
            textColor={theme.palette.black}
            activeBorderColor={theme.palette.gray[400]}
            inactiveBorderColor={theme.palette.gray[400]}
            labelColor={theme.palette.gray[400]}
            testID={DynamicInputValue.AGE_IS_YOUNGER_THAN}
            value={methods.watch(DynamicInputValue.AGE_IS_YOUNGER_THAN)}
            rules={{
              required: getText('age-is-required-field'),
            }}
          />
        </View>
      ),
      [DynamicInputValue.AGE_IS]: (
        <View style={styles.ageFilterWrapper}>
          <TextField
            hideRequiredAsterisk
            style={styles.filterInput}
            label={getText('enter-age')}
            name={DynamicInputValue.AGE_IS}
            placeholderColor={theme.palette.white}
            textColor={theme.palette.black}
            activeBorderColor={theme.palette.gray[400]}
            inactiveBorderColor={theme.palette.gray[400]}
            labelColor={theme.palette.gray[400]}
            testID={DynamicInputValue.AGE_IS}
            value={methods.watch(DynamicInputValue.AGE_IS)}
            rules={{
              required: getText('age-is-required-field'),
            }}
          />
        </View>
      ),
      [DynamicInputValue.AGE_IS_NOT]: (
        <View style={styles.ageFilterWrapper}>
          <TextField
            hideRequiredAsterisk
            style={styles.filterInput}
            label={getText('enter-age')}
            name={DynamicInputValue.AGE_IS_NOT}
            placeholderColor={theme.palette.white}
            textColor={theme.palette.black}
            activeBorderColor={theme.palette.gray[400]}
            inactiveBorderColor={theme.palette.gray[400]}
            labelColor={theme.palette.gray[400]}
            testID={DynamicInputValue.AGE_IS_NOT}
            value={methods.watch(DynamicInputValue.AGE_IS_NOT)}
            rules={{
              required: getText('age-is-required-field'),
            }}
          />
        </View>
      ),
      [DynamicInputValue.GENDER_IS_MALE]: <></>,
      [DynamicInputValue.GENDER_IS_FEMALE]: <></>,
      [DynamicInputValue.ZIP_CODE_IS_ONE_OF]: (
        <View style={styles.zipCodesFilterWrapper}>
          <TextField
            hideRequiredAsterisk
            style={styles.filterInput}
            label={getText('enter-zip-codes')}
            name={DynamicInputValue.ZIP_CODE_IS_ONE_OF}
            placeholderColor={theme.palette.white}
            textColor={theme.palette.black}
            activeBorderColor={theme.palette.gray[400]}
            inactiveBorderColor={theme.palette.gray[400]}
            labelColor={theme.palette.gray[400]}
            testID={DynamicInputValue.ZIP_CODE_IS_ONE_OF}
            value={methods.watch(DynamicInputValue.ZIP_CODE_IS_ONE_OF)}
            rules={{
              required: getText('zip-code-is-required-field'),
            }}
          />
          <Text style={styles.zipCodesFilterTip}>
            {getText('use-commas-for-multiple-values')}
          </Text>
        </View>
      ),
      [DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF]: (
        <View style={styles.zipCodesFilterWrapper}>
          <TextField
            hideRequiredAsterisk
            style={styles.filterInput}
            label={getText('enter-zip-codes')}
            name={DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF}
            placeholderColor={theme.palette.white}
            textColor={theme.palette.black}
            activeBorderColor={theme.palette.gray[400]}
            inactiveBorderColor={theme.palette.gray[400]}
            labelColor={theme.palette.gray[400]}
            testID={DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF}
            value={methods.watch(DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF)}
            rules={{
              required: getText('zip-code-is-required-field'),
            }}
          />
          <Text style={styles.zipCodesFilterTip}>
            {getText('use-commas-for-multiple-values')}
          </Text>
        </View>
      ),
    };

    return {
      valueInput: valueFieldMap[filter],
      isReturningValueInput,
      marginBottom: isHighRow ? 30 : 10,
    };
  }

  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      cancelButtonProps={{
        onPress: onCancel,
        text: getText('cancel'),
        logger: { id: 'cancel-button-modal-new-bulk-chat' },
        hierarchy: 'tertiary-gray',
      }}
      okButtonProps={{
        onPress: onSelectSubmit,
        logger: { id: 'ok-button-modal-new-bulk-chat' },
        text: getText('review'),
        hierarchy: 'primary',
        disabled: false,
      }}
      isScrollable
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              isHorizontal
              name="message_type"
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('secure'),
                  value: BulkMessageType.Secure,
                  description: getText('in-app-chat'),
                },
                {
                  text: getText('non-secure'),
                  value: BulkMessageType.NotSecure,
                  description: getText('sms-email-1-way-communication'),
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={[styles.halfColumn]}>
              <AdvancedDropDownField
                isClearable
                isMulti={false}
                noOptionsMessage={() => null}
                options={defaultTemplates}
                name="template"
                getOptionValue={(option: ITemplate) => option.name}
                optionTemplate={(props: OptionProps<ITemplate>) => {
                  return (
                    <View style={messagingStyles.dropdownOptionContainer}>
                      <Text style={[messagingStyles.dropdownFullName]}>
                        {props.data.name}
                      </Text>
                    </View>
                  );
                }}
                placeholderTemplate={(props: PlaceholderProps<ITemplate>) => (
                  <PlaceholderTemplate {...props} text={getText('template')} />
                )}
                singleValueTemplate={(props: SingleValueProps<ITemplate>) => {
                  return (
                    <View style={messagingStyles.dropdownOptionContainer}>
                      <Text style={[messagingStyles.dropdownFullName]}>
                        {props.data.name}
                      </Text>
                    </View>
                  );
                }}
                styles={{
                  control: () => styles.input,
                }}
              />
            </View>
          </Form.Column>
        </Form.Row>

        {methods.watch('message_type') === BulkMessageType.NotSecure ? (
          <>
            <Form.Row>
              <Form.Column style={styles.columnLabel}>
                <Text style={styles.columnLabelText}>
                  {getText('messages')}
                </Text>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.columnSubLabel}>{getText('SMS')}</Text>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <View>
                  <NewMessageBody<BulkMessageForm>
                    methods={methods}
                    setErrorFields={setErrorFields}
                    errorFields={errorFields}
                    height={108}
                    handleQuillRef={handleQuillRefSms}
                    toolbarId={'toolbar-sms'}
                    content="content_sms"
                    placeholder={getText('sms-message')}
                    toolbarOptions={[ToolBarOptions.Variables]}
                  />
                  {showErrorFor('content_sms')}
                  <Text style={styles.charactersCounter}>
                    {getText('character-count', {
                      max: SMS_MESSAGE_LIMIT,
                      count:
                        stripRichTextEditorElements(
                          methods.watch('content_sms'),
                        ).length ?? 0,
                    })}
                  </Text>
                </View>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.columnSubLabel}>
                  {getText('email-short')}
                </Text>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <View style={[styles.halfColumn]}>
                  <TextField
                    label={`${getText('email-subject')}`}
                    name="subject"
                  />
                  {showErrorFor('subject')}
                </View>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <NewMessageBody
                  methods={methods}
                  setErrorFields={setErrorFields}
                  errorFields={errorFields}
                  handleQuillRef={handleQuillRefEmail}
                  toolbarId={'toolbar-email'}
                  content="content_email"
                  placeholder={getText('email-message')}
                  height={108}
                  toolbarOptions={[
                    ToolBarOptions.Variables,
                    ToolBarOptions.Links,
                  ]}
                />
                {showErrorFor('content_email')}
                <Text style={styles.charactersCounter}>
                  {getText('character-count', {
                    max: BIG_MESSAGE_LIMIT,
                    count:
                      stripRichTextEditorElements(
                        methods.watch('content_email'),
                      ).length ?? 0,
                  })}
                </Text>
              </Form.Column>
            </Form.Row>
          </>
        ) : (
          <>
            <Form.Row>
              <Form.Column>
                <View style={[styles.halfColumn]}>
                  <TextField label={`${getText('subject')}`} name="subject" />
                  {showErrorFor('subject')}
                </View>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <NewMessageBody
                  methods={methods}
                  setErrorFields={setErrorFields}
                  errorFields={errorFields}
                  handleQuillRef={handleQuillRef}
                  toolbarId={'toolbar-main'}
                  content="content"
                  height={108}
                  placeholder={getText('chat-message')}
                  toolbarOptions={[
                    ToolBarOptions.Variables,
                    ToolBarOptions.Attachments,
                    ToolBarOptions.Emojis,
                    ToolBarOptions.Links,
                  ]}
                />
                {showErrorFor('content')}
                <Text style={styles.charactersCounter}>
                  {getText('character-count', {
                    max: BIG_MESSAGE_LIMIT,
                    count:
                      stripRichTextEditorElements(methods.watch('content'))
                        .length ?? 0,
                  })}
                </Text>
              </Form.Column>
            </Form.Row>
          </>
        )}
        <Form.Row style={{ marginBottom: 0, marginTop: 40 }}>
          <Form.Column style={styles.columnLabel}>
            <Text style={styles.columnLabelText}>{getText('recipients')}</Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              name="to"
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('send-to-all-patients'),
                  value: ETo.TO_ALL,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              name="to"
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('send-to-filtered-list'),
                  value: ETo.TO_FILTERED_LIST,
                },
              ]}
            />
            <Text style={styles.meetCriteria}>
              {getText('must-meet-all-criteria-below')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <View style={{ width: '100%' }}>
            <BulkFilterInput<BulkMessageForm>
              options={filteringOptions}
              inputs={filterInputs}
              setInputs={setFilterInputs}
              methods={methods}
              getValueField={getValueField}
              isDisabled={methods.watch('to') !== ETo.TO_FILTERED_LIST}
              ref={dynamicFiltersRef}
            />
          </View>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              name="to"
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('send-to-specific-people'),
                  value: ETo.TO_SPECIFIC,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={[styles.typeahead]}>
              <PatientLookupUserSelectField
                name="specific_recipients"
                asyncOptions={asyncOptions}
                noOptionsMessage={() => null}
                isClearable={true}
                label={getText('add-recipient')}
                isDisabled={methods.watch('to') !== ETo.TO_SPECIFIC}
                getOptionValue={(option: LocationPatientRecordDto) => {
                  return `${option.first_name} ${option.last_name} ${
                    option.id
                  } ${option.patient_record_id} (${formatDate(
                    option.date_of_birth,
                  )})`;
                }}
                isMulti
                onChange={(value) => {
                  methods.setValue(
                    'specific_recipients',
                    value as unknown as LocationPatientRecordDto[], // ignores null value, not great
                  );
                }}
              />
              {errorFields['specific_recipients'] && (
                <Text style={styles.errorMessage}>
                  {errorFields['specific_recipients']}
                </Text>
              )}
            </View>
            <View style={[styles.uploadCSVWrapper, styles.disabledCSV]}>
              <UploadIcon size={20} />
              <Text style={[styles.uploadCSV]}>{getText('upload-csv')}</Text>
            </View>
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.columnLabel}>
            <Text style={styles.columnLabelText}>
              {getText('date-and-time')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            {/** TODO: `View` will be removed when the option BulkMessageTime.SCHEDULED will be available. BulkMessageTime.IMMEDIATE and BulkMessageTime.SCHEDULED will be under 1 RadioButtonGroupField after this as well */}
            <View style={{ height: 34 }}>
              <RadioButtonGroupField
                name="time"
                mode={RadioButtonInputMode.FLAT}
                values={[
                  {
                    text: getText('immediate'),
                    value: BulkMessageTime.IMMEDIATE,
                  },
                ]}
              />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={styles.schedule_container}>
              {/** TODO: `View` will be removed after Ermal's fix of RadioButtonGroupField */}
              <View style={styles.timeRadioButton}>
                <RadioButtonGroupField
                  name="time"
                  mode={RadioButtonInputMode.FLAT}
                  values={[
                    {
                      text: getText('scheduled'),
                      value: BulkMessageTime.SCHEDULED,
                    },
                  ]}
                />
              </View>
              <View style={{ flex: 1 }}>
                <DateTimePickerField
                  name="scheduled_time"
                  placeholder={getText('date')}
                  rules={{ required: getText('date-is-required') }}
                  minDate={new Date().toISOString()}
                  disabled={methods.watch('time') !== BulkMessageTime.SCHEDULED}
                />
              </View>
            </View>
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const getInputStyles = (theme: any) => {
  return {
    height: theme.getSpacing(4) + theme.getSpacing(3),
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 16,
  };
};

const useStyles = makeStyles((theme) => ({
  template: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontSize: 13,
    marginTop: theme.getSpacing(1),
  },
  input: {
    ...getInputStyles(theme),
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 12,
  },
  charactersCounter: {
    alignSelf: 'flex-start',
    marginTop: theme.getSpacing(1),
    fontSize: 12,
    color: theme.palette.gray[500],
  },
  halfColumn: {
    width: 274,
  },
  uploadCSVWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
  },
  uploadCSV: {
    ...theme.fonts.regular,
    fontSize: 16,
    color: theme.palette.primary[600],
    marginLeft: theme.getSpacing(1),
  },
  disabledCSV: {
    opacity: 0.5,
  },
  sendTimeText: {
    fontSize: 16,
  },
  inputToAllFilter: {
    ...getInputStyles(theme),
    height: theme.getSpacing(4) + theme.getSpacing(1.5),
  },
  message: {
    height: 196,
  },
  channelTags: {
    flexDirection: 'row',
  },
  smsButton: {
    marginRight: theme.getSpacing(1),
  },
  emailButton: {
    marginRight: theme.getSpacing(3),
  },
  columnLabel: {
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[700],
    borderBottomWidth: 1,
  },
  columnLabelText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 16,
  },
  columnSubLabel: {
    fontFamily: 'Lato_700Bold',
    fontSize: 14,
  },
  filterInput: {
    height: 44,
  },
  ageFilterWrapper: {
    width: '20%',
    marginLeft: 16,
  },
  zipCodesFilterWrapper: {
    width: '26%',
    marginLeft: 16,
  },
  zipCodesFilterTip: {
    position: 'absolute',
    bottom: -20,
    color: theme.palette.gray[500],
  },
  meetCriteria: {
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(1),
    color: theme.palette.gray[500],
  },
  typeahead: {
    textAlign: 'left',
    marginBottom: theme.getSpacing(2),
    fontSize: 16,
  },
  schedule_container: {
    flexDirection: 'row',
  },
  timeRadioButton: {
    marginTop: 10,
  },
}));

interface NewBulkMessageModalProps {
  title: string;
  show: boolean;
  defaultMessage?: string;
  bulkData?: BulkMessageForm;
  onSubmit: (data: BulkMessageForm) => void;
  onCancel: () => void;
}

type TAddRecipientPlaceholderTemplate<T> = PlaceholderProps<T> & {
  text: string;
};

export function AddRecipientPlaceholderTemplate<T>(
  props: TAddRecipientPlaceholderTemplate<T>,
) {
  const styles = useAddRecipientStyles();

  return (
    <View style={styles.container}>
      <UserIcon size={15} />
      <Text style={styles.dropdownPlaceholderText}>{props.text}</Text>
    </View>
  );
}

const useAddRecipientStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.gray[100],
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(0.5),
    margin: 0,
  },
  dropdownPlaceholderText: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: 'auto',
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[600],
  },
  input: {
    height: theme.getSpacing(4) + theme.getSpacing(3),
    backgroundColor: theme.palette.gray[100],
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 16,
  },
}));
