import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import { RowItemRender } from '../../../components/Settings/RowItemRender';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useUserState } from '../../../store/user-store';
import { InformationItemRenderer } from './components/InformationItemRenderer';
import { RoleItemRenderer } from './components/RoleItemRenderer';
import { getRolesByUserId } from './profile-settings-action';
import { useProfileSettingsState } from './profile-settings-store';

export const ProfileSettings: FunctionComponent = () => {
  const data = useUserState((x) => x.data);
  const { userRoles } = useProfileSettingsState();

  useEffect(() => {
    if (data?.id) {
      getRolesByUserId(data.id);
    }
  }, [data]);

  return (
    <SettingsPageLayout title={getText('profile-settings')}>
      <SettingsSection
        title={getText('information')}
        contentHeader={{ title: getText('details') }}
        contentData={[data]}
        contentItem={InformationItemRenderer}
      />
      <SettingsSection
        title={getText('roles')}
        customContentHeaderComponent={RoleHeader}
        contentData={userRoles}
        contentItem={RoleItemRenderer}
      />
    </SettingsPageLayout>
  );
};

const RoleHeader: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <RowItemRender title={getText('location')} isHeader={true}>
      <View>{<Text style={styles.title}>{getText('role')}</Text>}</View>
    </RowItemRender>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.label.small.bold,
    color: theme.palette.gray[900],
  },
}));
