import { logError } from 'assets/logging/logger';
import PharmacyService from '../api/PharmacyService';
import { useAppStateStore } from '../store/app-store';
import {
  ChecklistModalIds,
  PatientWebsiteOptionType,
  useChecklistStore,
} from '../store/checklist-store';
import {
  ChecklistOptionDto,
  CreateAppSettingsDto,
  CreateMediaDto,
  CreatePatientAgreementDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { PhotoInfo } from '../components/InsuranceDetailsModal/PhotoButton';
import { getText } from 'assets/localization/localization';
import DocumentService from '../api/DocumentService';
import { PHARMACY_API_BASE_URL } from '../common/constants';

export const setOpenedModal = (id?: ChecklistModalIds) =>
  useChecklistStore.setState({ currentOpenedModalId: id });

export const setBrandColor = (brandColor?: string) =>
  useChecklistStore.setState({ brandColor });

export const setSelectedWebsiteOption = (
  selectedWebsiteOption?: PatientWebsiteOptionType,
) => useChecklistStore.setState({ selectedWebsiteOption });

export const checkAppLogoRequirement = (image: PhotoInfo): string | null => {
  const tenMB = 10 * 1024 * 1024;
  const base64String = image.uri?.split(',')[1] || '';
  const fileSizeInBytes =
    (base64String.length * 3) / 4 -
    (base64String.endsWith('==') ? 2 : base64String.endsWith('=') ? 1 : 0);

  if (fileSizeInBytes > tenMB) {
    return getText('specs-first-item');
  }
  const validImage = image.uri?.substring(
    'data:image/'.length,
    image.uri.indexOf(';base64'),
  );

  return validImage !== 'png' && validImage !== 'svg'
    ? getText('specs-second-item')
    : null;
};

export const fetchChecklist = async () => {
  try {
    useChecklistStore.setState({ status: 'loading' });
    const locationId = useAppStateStore.getState().locationId;
    const data = await PharmacyService.findChecklistById(locationId);
    useChecklistStore.setState({ checklistOptions: data });
    useChecklistStore.setState({ status: 'success' });
  } catch (e: any) {
    logError(e);
    useChecklistStore.setState({ status: 'error', error: { message: e } });
  }
};

export const fetchMediaColors = async () => {
  try {
    const data = await PharmacyService.fetchMediaColors();
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const fetchMedia = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.fetchMedia(pharmacyId);
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const createMedia = async (media: CreateMediaDto) => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.createMedia(pharmacyId, media);
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const fetchAppSettings = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.getAppSettings(pharmacyId);
    useChecklistStore.setState({ appSettings: data });
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const createAppSettings = async (appSettings: CreateAppSettingsDto) => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.createAppSettings(
      pharmacyId,
      appSettings,
    );
    return data;
  } catch (e: any) {
    logError(e);
    throw e;
  }
};

export const fetchDunsList = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.getAppSettingsDunsList(pharmacyId);
    useChecklistStore.setState({ dunsList: data });
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const createPatientAgreement = async (
  patientAgreement: CreatePatientAgreementDto,
) => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.createPatientAgreement(
      pharmacyId,
      patientAgreement,
    );
    return data;
  } catch (e: any) {
    logError(e);
    throw e;
  }
};

export const fetchPatientAgreementDefaultDocs = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;

    const baseUrl = `${PHARMACY_API_BASE_URL}/pharmacies/${pharmacyId}/patient_agreements/`;
    // https://api.dev.lumistry.com/pharmacy-service/pharmacies/dcc2ba94-a0e9-4aa3-91a0-590a12778674/patient_agreements/privacy

    const termsResponse = await DocumentService.getBlobParts(baseUrl + 'terms');

    const privacyResponse = await DocumentService.getBlobParts(
      baseUrl + 'privacy',
    );

    useChecklistStore.setState({
      patientAgreementsDocs: { terms: termsResponse, privacy: privacyResponse },
    });
  } catch (e: any) {
    logError(e);
    throw e;
  }
};
