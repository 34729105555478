import { LinkIcon, SmileIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useMemo, useState } from 'react';
import {
  UseFormReturn,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { View } from 'react-native';
import { useOutsideClick } from '../hooks/useOutsideClick';
import ReactQuill from 'react-quill';
import { getText } from 'assets/localization/localization';
import EmojiModal from 'react-native-emoji-modal';
import { MessageFields } from '../common/types/MessageFields';
import { EMOJI_CHARS_LENGTH, BIG_MESSAGE_LIMIT } from '../data';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';

export const AutoConfigMessageBodyEditor = <T extends FieldValues>({
  methods,
  setErrorFields,
  errorFields,
  handleQuillRef,
  defaultMessage,
  onContentChange,
}: AutoConfigMessageBodyProps<T>) => {
  const theme = useTheme();
  const styles = useStyles();
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState(defaultMessage);

  let quillRef: ReactQuill | null;

  const onSmileClick = () => {
    setShowEmojis(!showEmojis);
  };

  const outSideClickRef = useOutsideClick(() => {
    setShowEmojis(false);
  });

  const onEmojiSelect = (emoji: any) => {
    if (
      !defaultMessage ||
      defaultMessage.length <= BIG_MESSAGE_LIMIT - EMOJI_CHARS_LENGTH
    ) {
      const index = quillRef?.getEditor().getSelection()?.index ?? 0;
      quillRef?.getEditor().insertText(index, emoji);

      if (quillRef?.editor?.root.innerHTML) {
        setEditorContent(quillRef.editor.root.innerHTML);
        methods.setValue(
          'content' as Path<T>,
          quillRef.editor.root.innerHTML as UnpackNestedValue<
            PathValue<T, Path<T>>
          >,
        );
        onContentChange(
          quillRef.editor.root.innerHTML,
          quillRef.editor.root.innerHTML.length,
        );
      }
    }
  };

  const onVariableSelect = (variable: string) => {
    if (
      !defaultMessage ||
      defaultMessage.length <= BIG_MESSAGE_LIMIT - EMOJI_CHARS_LENGTH
    ) {
      if (quillRef === null) {
        throw new Error('quillRef is not initialize');
      }

      const index = quillRef.getEditor().getSelection()?.index;

      if (index !== undefined) {
        quillRef.getEditor().insertText(index, ' ');
        quillRef
          .getEditor()
          .insertEmbed(
            index,
            'pill',
            variable
              .replaceAll('${', '')
              .replaceAll('}', '')
              .replaceAll('_', ' '),
          );

        if (quillRef.editor?.root.innerHTML) {
          setEditorContent(quillRef.editor.root.innerHTML);
          methods.setValue(
            'content' as Path<T>,
            quillRef.editor.root.innerHTML as UnpackNestedValue<
              PathValue<T, Path<T>>
            >,
          );
        }
      }
    }
  };

  const handleChange = (value: UnpackNestedValue<PathValue<T, Path<T>>>) => {
    setEditorContent(value);
    methods.setValue('content' as Path<T>, value);
    onContentChange(value, stripRichTextEditorElements(value).length);
  };

  useEffect(() => {
    if (quillRef) {
      const editor = quillRef.getEditor();
      const plainDefaultMessage = editor.getText() || '';
      const stripRichTextForDefaultMessage =
        stripRichTextEditorElements(plainDefaultMessage);
      onContentChange(
        stripRichTextForDefaultMessage,
        stripRichTextForDefaultMessage.length,
      );
    }
  }, [defaultMessage]);

  return (
    <>
      <Tooltip text={getText('insert-emoji')} anchorId={'insert-emoji'} />
      <Tooltip
        text={getText('highlight-text-to-insert-link')}
        anchorId={'insert-link'}
      />
      <div id="toolbar">
        <select className="ql-variables" style={styles.variables}>
          <option value="add_variable">{getText('add-variable')}</option>
          <option value="${patient_first_name}" style={styles.variableOption}>
            {getText('patient-first-name-messaging')}
          </option>
          <option value="${patient_last_name}" style={styles.variableOption}>
            {getText('patient-last-name-messaging')}
          </option>
          <option value="${pharmacy_name}">{getText('pharmacy-name')}</option>
          <option
            value="${pharmacy_phone_number}"
            style={styles.variableOption}
          >
            {getText('pharmacy-phone-number-messaging')}
          </option>
        </select>
        <button className="ql-emoji" onClick={onSmileClick} id="insert-emoji">
          <SmileIcon size={20} color={theme.palette.gray[500]} />
        </button>
        <button className="ql-link" id="insert-link">
          <LinkIcon size={20} color={theme.palette.gray[500]} />
        </button>
      </div>
      <ReactQuill
        ref={(el) => {
          if (el) {
            quillRef = el;
            handleQuillRef(quillRef);
          }
        }}
        theme="snow"
        id="quill-message-box"
        placeholder={getText('message')}
        value={editorContent}
        style={styles.message}
        bounds="#quill-message-box"
        modules={useMemo(() => {
          return {
            toolbar: {
              container: '#toolbar',
              handlers: {
                variables: onVariableSelect,
              },
            },
            clipboard: {
              matchVisual: false,
            },
          };
        }, [])}
        onChange={(value, delta, source) => {
          if (source === 'user') {
            handleChange(value as UnpackNestedValue<PathValue<T, Path<T>>>);
          }
          if (MessageFields.message in errorFields) {
            const errorFieldsCopy = { ...errorFields };
            delete errorFieldsCopy[MessageFields.message];
            setErrorFields(errorFieldsCopy);
          }
        }}
      />
      {showEmojis && (
        <View style={styles.emojiWrapper} ref={outSideClickRef as any}>
          <EmojiModal onEmojiSelected={(emoji) => onEmojiSelect(emoji)} />
        </View>
      )}
    </>
  );
};

interface AutoConfigMessageBodyProps<T extends FieldValues> {
  methods: UseFormReturn<T, any>;
  setErrorFields: (errorFields: Record<string, string>) => void;
  errorFields: Record<string, string>;
  handleQuillRef: (quillRef: ReactQuill) => void;
  defaultMessage?: string;
  onContentChange: (content: string, length: number) => void;
}

const useStyles = makeStyles((theme) => ({
  message: {
    height: 196,
    marginBottom: theme.getSpacing(1),
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 32,
    zIndex: 10,
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  attachments: {
    marginBottom: theme.getSpacing(1),
    marginRight: theme.getSpacing(2),
  },
  variables: {
    fontFamily: 'Lato_700Bold',
    textAlign: 'left',
    width: 105,
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
  variableOption: {
    width: 32,
    height: 32,
    fontFamily: 'Lato_400Regular',
    size: 14,
  },
}));
