import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
  DrawerItem,
} from '@react-navigation/drawer';
import { Icon } from 'assets/components/icon';
import {
  ClipboardIcon,
  ClockIcon,
  CalendarIcon,
  UserXIcon,
  CalendarAltIcon,
  CornerUpLeftIcon,
} from 'assets/icons';
import NewAppointmentModal from '../schedule/new-appointment-modal/NewAppointmentModal';
import { Availability } from '../screens/schedule/availability/Availability';
import { Services } from '../screens/schedule/services/Services';
import { AvailabilitiesList } from '../screens/schedule/availability/AvailabilitiesList';
import { Service } from '../screens/schedule/service/Service';
import { Appointment } from '../screens/schedule/appointment/Appointment';
import { Appointments } from '../screens/schedule/appointments/Appointments';
import { CancelledAppointments } from '../screens/schedule/appointments/CancelledAppointments';
import { useTheme } from 'assets/theme';
import { Divider } from 'react-native-paper';
import { getText } from 'assets/localization/localization';
import { AppointmentsCalendar } from '../screens/schedule/appointments/AppointmentsCalendar';
import { navigationDrawerStyle } from '../common/theme';
import { PastAppointments } from '../screens/schedule/appointments/PastAppointments';
import { AppointmentFilters } from '../schedule/appointment-filters/AppointmentFilters';
import { defaultDrawerTheme } from 'assets/theme/theme';
import { Switch, Text, View } from 'react-native';
import { create } from '../store/store-creator';
import AppointmentService from '../api/AppointmentService';
import { BookingStatesCountDto } from '@digitalpharmacist/appointment-service-client-axios';
import ChecklistCard from '../components/checklist/checklist-card/checklist-card';
import { setOpenedModal } from '../actions/checklist-actions';

const DrawerNavigator =
  createDrawerNavigator<ScheduleDrawerNavigationParamList>();

export const ScheduleDrawer: FunctionComponent<
  PropsWithChildren<ScheduleDrawerProps>
> = (props) => {
  const theme = useTheme();

  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
        drawerStyle: defaultDrawerTheme,
      }}
      initialRouteName="calendar"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      backBehavior="history"
    >
      <DrawerNavigator.Screen
        name="calendar"
        options={{
          title: getText('appointments-calendar'),
          drawerItemStyle: { display: 'none' },
        }}
        component={AppointmentsCalendar}
      />
      <DrawerNavigator.Screen
        name="appointments"
        options={{
          title: getText('upcoming-appointments'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Appointments}
      />
      <DrawerNavigator.Screen
        name="appointments-cancelations"
        options={{
          title: getText('canceled-appointments'),
          drawerItemStyle: { display: 'none' },
        }}
        component={CancelledAppointments}
      />
      <DrawerNavigator.Screen
        name="past-appointments"
        options={{
          title: getText('past-appointments'),
          drawerItemStyle: { display: 'none' },
        }}
        component={PastAppointments}
      />
      <DrawerNavigator.Screen
        name="availabilities"
        options={{
          title: getText('availability'),
          ...navigationDrawerStyle,
          drawerLabel: getText('availability'),
          drawerIcon: ({ color, size }) => (
            <Icon icon={ClockIcon} color={color} size={size} />
          ),
        }}
        component={AvailabilitiesList}
      />

      <DrawerNavigator.Screen
        name="services"
        options={{
          title: getText('services'),
          ...navigationDrawerStyle,
          drawerLabel: getText('services'),
          drawerIcon: ({ color, size }) => (
            <Icon icon={ClipboardIcon} color={color} size={size} />
          ),
        }}
        component={Services}
      />

      <DrawerNavigator.Screen
        name="new-availability"
        options={{
          title: getText('new-availability-tab'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Availability}
      />
      <DrawerNavigator.Screen
        name="edit-availability"
        options={{
          title: getText('edit-availability-record-tooltip'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Availability}
      />
      <DrawerNavigator.Screen
        name="new-service"
        options={{
          title: getText('new-service'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Service}
      />
      <DrawerNavigator.Screen
        name="edit-service"
        options={{
          title: getText('edit-service-tab'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Service}
      />
      <DrawerNavigator.Screen
        name="new-appointment"
        options={{
          title: getText('new-appointment'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Appointment}
      />
      <DrawerNavigator.Screen
        name="edit-appointment"
        options={{
          title: getText('edit-appointment'),
          drawerItemStyle: { display: 'none' },
        }}
        component={Appointment}
      />
    </DrawerNavigator.Navigator>
  );
};

interface ScheduleDrawerProps {}

export type ScheduleDrawerNavigationParamList = {
  availabilities: undefined;
  'appointments-cancelations': undefined;
  appointments: AppointmentsScreenParamsProps | undefined;
  calendar: undefined;
  'new-availability': undefined;
  services: undefined;
  'edit-availability': {
    availabilityId: string;
  };
  'new-service': undefined;
  'edit-service': {
    serviceId: string;
  };
  'new-appointment': {
    isPrefilled?: boolean;
  };
  'edit-appointment': {
    appointmentId: string;
  };
  'past-appointments': undefined;
};

export type AppointmentsScreenParamsProps = {
  id: string;
};

export type ScheduleDrawerNavigationProp =
  DrawerNavigationProp<ScheduleDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  const theme = useTheme();
  const { refreshData } = useScheduleDrawer();

  const [appointmentCounts, setAppointmentCounts] =
    useState<BookingStatesCountDto>();

  useEffect(() => {
    void loadAppointmentCounts();
  }, []);

  useEffect(() => {
    void loadAppointmentCounts();
    setRefreshDataFalse();
  }, [refreshData]);

  const loadAppointmentCounts = async () => {
    setAppointmentCounts(await AppointmentService.getAppointmentCounts());
  };

  return (
    <>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ paddingTop: 0 }}
      >
        <NewAppointmentModal />
        <DrawerItem
          label={getText('calendar')}
          icon={({ color }) => (
            <Icon icon={CalendarAltIcon} color={color} size={20} />
          )}
          focused={props?.state.index === 0}
          onPress={() => props?.navigation?.navigate('calendar')}
          activeBackgroundColor={
            navigationDrawerStyle.drawerActiveBackgroundColor
          }
          activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
          style={navigationDrawerStyle.drawerItemStyle}
          labelStyle={navigationDrawerStyle.drawerLabelStyle}
        />
        <DrawerItem
          label={getText('upcoming')}
          icon={({ color }) => (
            <Icon icon={CalendarIcon} color={color} size={20} />
          )}
          focused={props?.state.index === 1}
          onPress={() => props?.navigation?.navigate('appointments')}
          activeBackgroundColor={
            navigationDrawerStyle.drawerActiveBackgroundColor
          }
          activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
          style={navigationDrawerStyle.drawerItemStyle}
          labelStyle={navigationDrawerStyle.drawerLabelStyle}
        />
        {!!appointmentCounts?.total_cancelled && (
          <DrawerItem
            label={getText('cancelations')}
            icon={({ color }) => (
              <Icon icon={UserXIcon} color={color} size={20} />
            )}
            focused={props?.state.index === 2}
            onPress={() =>
              props?.navigation?.navigate('appointments-cancelations')
            }
            activeBackgroundColor={
              navigationDrawerStyle.drawerActiveBackgroundColor
            }
            activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
            style={navigationDrawerStyle.drawerItemStyle}
            labelStyle={navigationDrawerStyle.drawerLabelStyle}
          />
        )}
        {!!appointmentCounts?.total_past && (
          <DrawerItem
            label={getText('past')}
            icon={({ color }) => (
              <Icon icon={CornerUpLeftIcon} color={color} size={20} />
            )}
            focused={props?.state.index === 3}
            onPress={() => props?.navigation?.navigate('past-appointments')}
            activeBackgroundColor={
              navigationDrawerStyle.drawerActiveBackgroundColor
            }
            activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
            style={navigationDrawerStyle.drawerItemStyle}
            labelStyle={navigationDrawerStyle.drawerLabelStyle}
          />
        )}
        <Divider
          style={{
            width: '95%',
            marginHorizontal: theme.getSpacing(1),
            marginVertical: theme.getSpacing(1),
          }}
        />
        <DrawerItemList {...props} />
        <AppointmentFilters />
        <ChecklistCard
          hierarchy="secondary"
          onPress={() => setOpenedModal('checklist-setup')}
        />
      </DrawerContentScrollView>
    </>
  );
}

export const useScheduleDrawer = create<ScheduleDrawerStore>(() => ({
  refreshData: false,
}));

interface ScheduleDrawerStore {
  refreshData: boolean;
}

export const setRefreshDataTrue = () => {
  useScheduleDrawer.setState(() => {
    return {
      refreshData: true,
    };
  });
};

export const setRefreshDataFalse = () => {
  useScheduleDrawer.setState(() => {
    return {
      refreshData: false,
    };
  });
};
