import ReactQuill from 'react-quill';
const Quill = ReactQuill.Quill;
const Embed = Quill.import('blots/embed');
const icons = ReactQuill.Quill.import('ui/icons');
icons['link'] = null;
const Link = ReactQuill.Quill.import('formats/link');
Link.sanitize = function (url: string) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

class Pill extends Embed {
  static create(value: any) {
    const node = super.create();
    node.innerHTML = value;
    node.setAttribute('class', 'pill');
    node.setAttribute('value', value);
    node.setAttribute('contenteditable', false);
    return node;
  }

  static value(node: any) {
    return node.getAttribute('value');
  }
}
Pill.blotName = 'pill';
Pill.tagName = 'span';

Quill.register(Pill, true);

export function convertVariablesToPills(
  text: string,
  quillRef: ReactQuill | null,
): string | undefined {
  // This is a hardcoded list for now. Might change to something dynamic in the future.
  const variableList = [
    '${first_name}',
    '${patient_first_name}',
    '${patient_last_name}',
    '${pharmacy_phone_number}',
    '${pharmacy_name}',
  ];
  const variableIndices: string[] = [];

  for (const variable of variableList) {
    const indices = [...text.matchAll(new RegExp(`\\${variable}`, 'g'))].map(
      (a) => a.index,
    );

    for (const index of indices) {
      if (index !== undefined) {
        variableIndices[index] = variable;
      }
    }
  }

  for (let i = variableIndices.length - 1; i >= 0; i--) {
    if (variableIndices[i]) {
      quillRef?.getEditor().deleteText(i, variableIndices[i].length);
      quillRef
        ?.getEditor()
        .insertEmbed(
          i,
          'pill',
          variableIndices[i]
            .replaceAll('${', '')
            .replaceAll('}', '')
            .replaceAll('_', ' '),
        );
    }
  }

  return quillRef?.editor?.root.innerHTML;
}
