import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import theme, { getSpacing } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { PencilIcon, TrashWithStripesIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { deleteTask, updateShowModalState } from '../task-settings-actions';
import PharmacyConfirmationModal from '../../../../components/PharmacyConfirmationModal';
import { getText } from 'assets/localization/localization';
import { PharmacyTooltipWrapper } from '../../../../common/PharmacyTooltipWrapper';

const TaskTypeItemRenderer: FunctionComponent<
  PropsWithChildren<TaskTypeItemRendererProps>
> = ({ id, title, description, color, in_use }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const handleDelete = async () => {
    deleteTask(id);
    setShowCloseConfirmation(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        {color && (
          <View style={styles.colorContainer}>
            <View
              style={{
                backgroundColor: color,
                borderRadius: 100,
                height: '100%',
              }}
            />
          </View>
        )}
        <Text style={styles.title}>{title}</Text>
      </View>

      {description && (
        <View style={{ flex: 4 }}>
          <Text style={styles.description}>{description}</Text>
        </View>
      )}

      <View style={styles.actionButtonsContainer}>
        <IconButton
          icon={PencilIcon}
          onPress={() => updateShowModalState(true, id)}
          size={16}
          color={theme.palette.gray[500]}
          logger={{ id: 'task-type-renderer-action-button-edit' }}
        />

        <PharmacyTooltipWrapper
          tooltipId={in_use ? `delete-action-button-tooltip-${id}` : undefined}
        >
          <IconButton
            icon={TrashWithStripesIcon}
            onPress={() => setShowCloseConfirmation(true)}
            size={16}
            color={theme.palette.gray[500]}
            logger={{ id: 'task-type-renderer-action-button-delete' }}
            disabled={in_use}
          />
        </PharmacyTooltipWrapper>
      </View>

      <Tooltip
        id={`delete-action-button-tooltip-${id}`}
        place="bottom"
        text={getText('cannot-delete-task-type')}
      />
      <Tooltip
        id={`edit-action-button-tooltip-${id}`}
        place="bottom"
        text={getText('cannot-edit-task-type')}
      />
      <PharmacyConfirmationModal
        show={showCloseConfirmation}
        onAccepted={handleDelete}
        onDismiss={() => setShowCloseConfirmation(false)}
        message={getText('delete-task-type-confirm')}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  colorContainer: {
    backgroundColor: theme.palette.white,
    width: getSpacing(2),
    height: getSpacing(2),
    padding: 2,
    marginRight: 6,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: theme.palette.gray[400],
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  description: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  actionButtonsContainer: {
    width: 60,
    flexDirection: 'row',
  },
});

interface TaskTypeItemRendererProps {
  id: string;
  title: string;
  is_default: boolean;
  color?: string;
  description?: string;
  in_use?: boolean;
}

export default TaskTypeItemRenderer;
