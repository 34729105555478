import { getText } from 'assets/localization/localization';
import { useServiceFormState } from '../service-form/service-form-store';
import { useServicesListState } from '../services-list/services-list-store';
import { useAppointmentFiltersState } from './appointment-filters-store';

export const allowedScreenNames = [
  'calendar',
  'appointments-cancelations',
  'appointments',
  'past-appointments',
];

export interface InitialAppointmentFilterValueProps {
  title: string;
  id: string;
}

export const initialAppointmentsFiltersFormValue: Record<
  string,
  InitialAppointmentFilterValueProps | undefined
> = {
  appointment_type_id: { title: getText('all-services'), id: 'default' },
  appointment_group_id: { title: getText('all-service-types'), id: 'default' },
  appointment_group_category_id: {
    title: getText('all-service-categories'),
    id: 'default',
  },
};

const extractFilterAppointmentType = (appointmentTypeId: string) => {
  const appointmentTypeData = useServicesListState
    .getState()
    .services?.find((service) => service.id === appointmentTypeId);

  if (appointmentTypeData) {
    return `"${appointmentTypeData.title}"`;
  }
};

const extractFilterAppointmentGroup = (appointmentGroupId: string) => {
  const appointmentGroupData = useServiceFormState
    .getState()
    .appointmentGroups?.find((service) => service.id === appointmentGroupId);

  if (appointmentGroupData) {
    return `"${appointmentGroupData.title}"`;
  }
};

const filtersMessageMapper: Record<string, any> = {
  appointment_type_id: {
    message: getText('service-name-info-box'),
    extractor: extractFilterAppointmentType,
  },
  appointment_group_id: {
    message: getText('service-category-info-box'),
    extractor: extractFilterAppointmentGroup,
  },
};

export const composeAppointmentFiltersMessage = () => {
  const filters = useAppointmentFiltersState.getState().filters;

  const definedFilters = Object.entries(filters).filter(
    ([key, value]) => value !== undefined,
  );

  return definedFilters
    .map(([key, value]) => {
      const { message, extractor } = filtersMessageMapper[key] ?? {};
      if (message && extractor) {
        return message + extractor(value);
      }
      return '';
    })
    .filter((message) => !!message)
    .join(',  ');
};
