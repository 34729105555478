import {
  UpdateLocationServiceOptionDto,
  UpdatePickupMethodDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { logError } from 'assets/logging/logger';
import PharmacyService from '../../../api/PharmacyService';
import { useRefillsSettingsState as refillsState } from './refills-settings-store';
import PrescriptionService from '../../../api/PrescriptionService';
import {
  RefillRemoveBehavior,
  RxStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { getText } from 'assets/localization/localization';

export const setShowModalTransferMedications = (
  showModalTransferMedications: boolean,
) => refillsState.setState({ showModalTransferMedications });

export const setServiceOptions = (locationId: string) => {
  void PharmacyService.serviceOptionsFindAll(locationId)
    .then((allServiceOptions) => refillsState.setState({ allServiceOptions }))
    .catch((error: Error) => logError(error));
};

export const setRefillErrors = (locationId: string) => {
  void PrescriptionService.getLocationConfig(locationId)
    .then((config) =>
      refillsState.setState({ refillErrors: config.refill_configs }),
    )
    .catch((error: Error) => logError(error));
};

export const setRefillDueReminders = (locationId: string) => {
  void PrescriptionService.getLocationConfig(locationId)
    .then((config) => {
      refillsState.setState({
        refillDueReminders: [
          {
            key: 'refill_due_reminder_non_controller',
            modalLabel: getText(
              'refill-due-reminders-non-controlled-long-text',
            ),
            label: getText('refill-due-reminders-non-controlled'),
            value: config.refill_due_reminder_non_controller,
          },
          {
            key: 'refill_due_reminder_controller',
            label: getText('refill-due-reminders-controlled'),
            modalLabel: getText('refill-due-reminders-controlled-long-text'),
            value: config.refill_due_reminder_controller,
          },
          {
            key: 'refill_past_due_reminder',
            label: getText('refill-past-due-reminders'),
            modalLabel: getText('refill-past-due-reminders-long-text'),
            value: config.refill_past_due_reminder,
          },
        ],
      });
      refillsState.setState({ integrationType: config.integration_type });
    })
    .catch((error: Error) => logError(error));
};

export const updateServiceOptions = (
  locationId: string,
  updateLocationServiceOptionDto: UpdateLocationServiceOptionDto[],
) => {
  void PharmacyService.updateServiceOptions(
    locationId,
    updateLocationServiceOptionDto,
  )
    .then((allServiceOptions) => refillsState.setState({ allServiceOptions }))
    .catch((error: Error) => logError(error));
};

export const setShowModalPickup = (showModalPickup: boolean) =>
  refillsState.setState({ showModalPickup });

export const setShowRefillErrorModal = (showRefillErrorModal: boolean) =>
  refillsState.setState({ showRefillErrorModal });

export const setPickupMethods = (locationId: string) => {
  void PharmacyService.pickupMethodsFindAll(locationId)
    .then((allPickupMethods) => refillsState.setState({ allPickupMethods }))
    .catch((error: Error) => logError(error));
};

export const updatePickupMethod = (
  locationId: string,
  pickupMethodId: string,
  updatedPickupMethod: UpdatePickupMethodDto,
) => {
  void PharmacyService.pickupMethodToggle(
    locationId,
    pickupMethodId,
    updatedPickupMethod,
  )
    .then((pickupMethod) => {
      const allPickupMethods = refillsState
        .getState()
        .allPickupMethods.map((pickup) => {
          return pickup.id === pickupMethodId
            ? { ...pickup, ...pickupMethod }
            : pickup;
        });
      refillsState.setState({ allPickupMethods });
    })
    .catch((error: Error) => logError(error));
};

export const updateRefillErrors = (
  locationId: string,
  rx_status: RxStatus,
  remove_behavior: RefillRemoveBehavior,
  message_patient: string,
) => {
  void PrescriptionService.locationConfigCreate(locationId, {
    refill_configs: [
      {
        rx_status: rx_status,
        remove_behavior: remove_behavior,
        message_patient: message_patient,
      },
    ],
  })
    .then((config) =>
      refillsState.setState({ refillErrors: config.refill_configs }),
    )
    .catch((error: Error) => logError(error));
};

export const updateRefillDueReminders = (
  locationId: string,
  refill_due_reminder_controller: number,
  refill_due_reminder_non_controller: number,
  refill_past_due_reminder: number,
) => {
  void PrescriptionService.locationConfigCreate(locationId, {
    refill_due_reminder_controller: refill_due_reminder_controller,
    refill_due_reminder_non_controller: refill_due_reminder_non_controller,
    refill_past_due_reminder: refill_past_due_reminder,
  })
    .then((config) => {
      refillsState.setState({
        refillDueReminders: [
          {
            key: 'refill_due_reminder_non_controller',
            modalLabel: getText(
              'refill-due-reminders-non-controlled-long-text',
            ),
            label: getText('refill-due-reminders-non-controlled'),
            value: config.refill_due_reminder_non_controller,
          },
          {
            key: 'refill_due_reminder_controller',
            label: getText('refill-due-reminders-controlled'),
            modalLabel: getText('refill-due-reminders-controlled-long-text'),
            value: config.refill_due_reminder_controller,
          },
          {
            key: 'refill_past_due_reminder',
            label: getText('refill-past-due-reminders'),
            modalLabel: getText('refill-past-due-reminders-long-text'),
            value: config.refill_past_due_reminder,
          },
        ],
      });
      refillsState.setState({ integrationType: config.integration_type });
    })
    .catch((error: Error) => logError(error));
};

export const setEditingPickupMethod = (pickupId: string) => {
  const { allPickupMethods } = refillsState.getState();
  const editingPickupMethod = allPickupMethods.find(
    (pickupMethod) => pickupMethod.id === pickupId,
  );
  refillsState.setState({ editingPickupMethod });
};

export const setRefillErrorsMethod = (rx_status: string) => {
  const { refillErrors } = refillsState.getState();
  const editingRefillErrors = refillErrors.find(
    (refillError) => refillError.rx_status === rx_status,
  );
  refillsState.setState({ editingRefillErrors });
};
