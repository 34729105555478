import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const MapIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M5.333 12 .667 14.667V4l4.666-2.667m0 10.667 5.334 2.667M5.333 12V1.334m5.334 13.333L15.333 12V1.334L10.667 4m0 10.667V4m0 0L5.333 1.333"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
