import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { chunk } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { setOpenedModal } from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { InfoTooltip } from '../../onboarding/components/common/InfoTooltip';
import { ChecklistModalProps } from '../types';

export interface WebsiteService {
  id: string;
  order: number;
  name: string;
  enabled: boolean;
}

// to do
const initialServices: WebsiteService[] = [
  {
    id: '1',
    order: 0,
    name: 'About us',
    enabled: true,
  },
  {
    id: '2',
    order: 1,
    name: 'Medication adherence',
    enabled: false,
  },
  {
    id: '3',
    order: 4,
    name: 'Immunizations',
    enabled: false,
  },
  {
    id: '4',
    order: 2,
    name: 'Pharmacy services',
    enabled: false,
  },
  {
    id: '5',
    order: 3,
    name: 'Compliance packaging',
    enabled: true,
  },
];

export const PatientWebsiteServices: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const { currentOpenedModalId } = useChecklistStore();
  const styles = useStyles();
  const services = initialServices.sort((a, b) => a.order - b.order);

  const methods = useForm<{ services: WebsiteService[] }>({
    //to do
    defaultValues: {
      services,
    },
  });
  const serviceFields = methods.watch('services');

  return (
    <Modal
      title={getText('website-featured-services')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal(undefined)}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => console.log('back'),
          logger: { id: 'next-website-services' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal(undefined),
          logger: { id: 'back-website-services' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('select-6-services') + '*'}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="select-6-services"
                text={getText('selected-services-tooltip-text')}
              />
            </Text>
          </Form.Column>
        </Form.Row>
        {chunk(serviceFields, 2).map((group, index) => (
          <Form.Row key={index}>
            {group.map((service, i) => (
              <Form.Column key={service.id}>
                <CheckboxField
                  name={`services.${index * 2 + i}.enabled`}
                  label={service.name}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
            ))}
          </Form.Row>
        ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  tootlip: {
    left: theme.getSpacing(1),
    top: theme.getSpacing(0.5),
  },
}));
