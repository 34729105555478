import { Text } from 'assets/components/text';
import { ImageIcon } from 'assets/icons';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import * as ImagePicker from 'expo-image-picker';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ImageBackground,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import { InfoTooltip } from '../../screens/onboarding/components/common/InfoTooltip';
import { ErrorMessage } from './ErrorMessage';
import { logError } from 'assets/logging/logger';

export const UploadImage: FunctionComponent<UploadImageProps> = ({
  title,
  width = 275,
  height = useWindowDimensions().height * 0.2,
  isDark = false,
  required,
  uri,
  tooltipText,
  choosePhoto,
  validateRules,
}) => {
  const [errorUpload, setErrorUpload] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const styles = useStyles();
  const theme = useTheme();

  useEffect(() => {
    setErrorUpload(false);
  }, [uri]);

  useEffect(() => {
    setErrorMessage(required ? getText('image-is-required') : null);
  }, [required]);

  const handlePhotoUpload = async () => {
    try {
      const photo = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
      });

      if (photo.canceled) {
        return;
      }

      const error = validateRules?.(photo) ?? null;
      setErrorMessage(error);

      if (!error) {
        choosePhoto(photo.assets[0]);
      }
    } catch (error: any) {
      logError(error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.errorContainer}>
        <View style={styles.tootlipContainer}>
          <Text style={styles.uploadTitle}>{title}</Text>
          {tooltipText && (
            <InfoTooltip
              style={styles.tooltip}
              size={16}
              tooltipId={title}
              text={tooltipText}
            />
          )}
        </View>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </View>
      <TouchableOpacity onPress={handlePhotoUpload}>
        <View
          style={[
            styles.emptyStateCard,
            {
              backgroundColor: theme.palette.gray[isDark ? 600 : 100],
              width,
              height,
            },
          ]}
        >
          {uri && !errorUpload ? (
            <ImageBackground
              source={{ uri }}
              resizeMode="contain"
              style={{ width, height }}
              onError={() => {
                setErrorUpload(true);
              }}
            />
          ) : (
            <View style={styles.emptyStateInsideCard}>
              <View>
                <ImageIcon
                  size={32}
                  color={isDark ? theme.palette.white : theme.palette.gray[400]}
                />
              </View>
              <View style={styles.upload}>
                <FileUploadIcon
                  color={isDark ? theme.palette.white : theme.palette.gray[700]}
                  size={16}
                />
                <Text
                  style={[
                    styles.uploadText,
                    {
                      color: isDark
                        ? theme.palette.white
                        : theme.palette.gray[500],
                    },
                  ]}
                >
                  {getText('upload')}
                </Text>
              </View>
            </View>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

interface UploadImageProps {
  title: string;
  uri: string;
  isDark?: boolean;
  width?: number;
  height?: number;
  required?: boolean;
  tooltipText?: string;
  choosePhoto: (photo: ImagePicker.ImagePickerAsset) => void;
  validateRules?: (
    photo: ImagePicker.ImagePickerSuccessResult,
  ) => string | null;
}

const useStyles = makeStyles((theme) => ({
  emptyStateCard: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    padding: theme.getSpacing(4),
  },
  emptyStateInsideCard: {
    padding: theme.getSpacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  upload: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    ...theme.lumistryFonts.label.xSmall.regular,
  },
  uploadTitle: {
    ...theme.lumistryFonts.label.small.regular,
    color: theme.palette.gray[700],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(1),
  },
  tootlipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: { paddingTop: 5 },
}));
