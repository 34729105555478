import { UpdatePharmacyDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { TypeaheadFormControl } from 'assets/components/typeahead';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { View } from 'react-native';
import { validateOptionalURL } from '../../../../../utils';
import PharmacyService from '../../../../api/PharmacyService';
import { canFactory } from '../../../../common/guards/permission-guard';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import { AdvancedDropDownField } from '../../../../components/advanced-dropdown';
import {
  createParentCompany,
  findParentCompanyNyName,
  getAllPMS,
  getPharmacy,
  setPharmacy,
  setShowModalGeneralInfo,
} from '../pharmacy-settings-actions';
import { usePharmacySettingsState } from '../pharmacy-settings-store';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';

export const GeneralInfoModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalGeneralInfo, pharmacy, allPMS, allParentCompanies } =
    usePharmacySettingsState();
  const styles = useStyles();

  useEffect(() => {
    methods.reset({
      ...pharmacy,
      parent_company: [
        {
          text: pharmacy?.parent_company?.name || '',
          value: pharmacy?.parent_company?.id.toString() || '',
        },
      ],
      pms: { label: pharmacy?.pms || '', value: pharmacy?.pms || '' },
    });
  }, [pharmacy]);

  useEffect(() => {
    getAllPMS();
  }, []);

  const methods = useForm<GeneralInfoFormProps>({
    defaultValues: {
      ...pharmacy,
      parent_company: [
        {
          text: pharmacy?.parent_company?.name || '',
          value: pharmacy?.parent_company?.id.toString() || '',
        },
      ],
      pms: { label: pharmacy?.pms || '', value: pharmacy?.pms || '' },
    },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    const formValue = methods.getValues();

    const parentCompanyName = formValue.parent_company
      ? formValue.parent_company[0].text
      : '';

    const existingParentCompany = findParentCompanyNyName(
      allParentCompanies,
      parentCompanyName,
    );

    const parentCompanyId: number | undefined =
      !existingParentCompany && parentCompanyName
        ? (await createParentCompany({ name: parentCompanyName })).id
        : existingParentCompany?.id;

    const updatedPharmacy: UpdatePharmacyDto = {
      name: formValue.name,
      full_name: formValue.full_name,
      parent_company_id: parentCompanyId,
      ncpdp_id: formValue.ncpdp_id,
      npi: formValue.npi,
      facebook_url: formValue.facebook_url,
      instagram_handle: formValue.instagram_handle,
      twitter_handle: formValue.twitter_handle,
      pms: formValue.pms?.value,
    };
    await setPharmacy(updatedPharmacy);
    await getPharmacy();

    setShowModalGeneralInfo(false);
  };

  const closeModal = (updatePharmacy?: UpdatePharmacyDto) => {
    setShowModalGeneralInfo(false);
    const updateRecord = updatePharmacy || pharmacy;
    methods.reset({
      ...updateRecord,
      parent_company: [
        {
          text: pharmacy?.parent_company?.name,
          value: pharmacy?.parent_company?.id.toString(),
        },
      ],
      pms: { label: updateRecord?.pms, value: updateRecord?.pms },
    });
  };

  const optionsPMS: SelectionParent[] = [
    ...allPMS.map((pms) => ({
      label: pms.name,
      value: pms.name,
    })),
  ];

  return (
    <Modal
      title={getText('pharmacy-edit-business-info')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-general-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalGeneralInfo}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-brand-full-name')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="full_name" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-company-name')}
              </Text>
              <SettingsTooltipsComponents tooltipId="pharmacy-general-info-limited-brand-name-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="name"
              type="text"
              rules={{
                maxLength: {
                  value: 16,
                  message: getText('pharmacy-brand-name-length'),
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-brand-admin')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="admin" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-parent-company')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TypeaheadFormControl
              name="parent_company"
              typeaheadProps={{
                multiple: false,
                getOptionText: (o: any) => o.text,
                getOptionValue: (o: any) => o.value,
                asyncOptions: async (searchTerm) => {
                  const data =
                    await PharmacyService.parentCompanySearch(searchTerm);
                  return data.map((x) => ({
                    text: x.name,
                    value: x.id.toString(),
                  }));
                },
                disabled: !canFactory('update')('settingsLumistryUser'),
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pharmacy-ncpdp')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="ncpdp_id" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pharmacy-npi')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="npi"
              type="text"
              rules={{
                maxLength: {
                  value: 10,
                  message: getText('pharmacy-npi-length'),
                },
                minLength: {
                  value: 10,
                  message: getText('pharmacy-npi-length'),
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pharmacy-pms')}</Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <AdvancedDropDownField
              options={optionsPMS}
              name="pms"
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              isSearchable={true}
              rules={{ required: getText('service-required') }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-facebook')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="facebook_url"
              type="text"
              rules={{ validate: validateOptionalURL }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-instagram')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="instagram_handle"
              type="text"
              rules={{ validate: validateOptionalURL }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-twitter')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="twitter_handle"
              type="text"
              rules={{ validate: validateOptionalURL }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
      <Tooltip
        anchorId="pharmacy-general-info-limited-brand-name-modal"
        text={getText('pharmacy-general-info-limited-brand-name')}
      />
    </Modal>
  );
};

export interface SelectionParent {
  value: string;
  label: string;
}

interface GeneralInfoFormProps {
  name: string;
  full_name?: string;
  admin?: string;
  parent_company?: TypeaheadBaseItem[];
  ncpdp_id?: string;
  npi?: string;
  pms?: SelectionParent;
  facebook_url?: string;
  instagram_handle?: string;
  twitter_handle?: string;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.getSpacing(1),
    height: 44,
    flexDirection: 'row',
    alignContent: 'center',
  },
  textFormat: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));
