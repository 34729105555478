import { ImageIcon } from 'assets/icons';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import theme, { getSpacing, makeStyles } from 'assets/theme';
import * as ImagePicker from 'expo-image-picker';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Image, Text, useWindowDimensions, View } from 'react-native';
import { PUBLIC_FILE_STORAGE_SERVICE_BASE_URL } from '../../../../common/constants';
import { ResourceGuard } from '../../../../common/guards/permission-guard';
import { PhotoButton } from '../../../../components/InsuranceDetailsModal/PhotoButton';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import { useAppStateStore } from '../../../../store/app-store';
import {
  checkAppIconRequirements,
  checkAppLogoRequirement,
  checkImageUrl,
  uploadFile,
} from '../app-settings-actions';
import { BrandingColorSquare } from './BrandingColorSquare';

export const BrandingInfoItemRenderer: FunctionComponent<
  PropsWithChildren<BrandingInfoItemRendererProps>
> = ({ button_primary_color, branded_text_color }) => {
  const styles = useStyles();
  const { height } = useWindowDimensions();
  const buttonWidth = theme.webMaxWidth - theme.getSpacing(4);
  const buttonHeight = height * 0.5;
  const { pharmacyId } = useAppStateStore.getState();
  const [imageCache, setImageCache] = useState(Date.now());

  const [logoLight, setLogoLight] = useState<string | undefined>();
  const [logoDark, setLogoDark] = useState<string | undefined>();
  const [icon_android, setIconAndroid] = useState<string | undefined>();
  const [icon_ios, setIconIos] = useState<string | undefined>();

  useEffect(() => {
    setLogoLight(undefined);
    setLogoDark(undefined);
    setIconAndroid(undefined);
    setIconIos(undefined);

    checkImageUrl(
      `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/logo_light?${imageCache}`,
    )
      .then((result) =>
        setLogoLight(
          result
            ? `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/logo_light?${imageCache}`
            : undefined,
        ),
      )
      .catch((error: Error) => logError(error));

    checkImageUrl(
      `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/logo_dark?${imageCache}`,
    )
      .then((result) =>
        setLogoDark(
          result
            ? `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/logo_dark?${imageCache}`
            : undefined,
        ),
      )
      .catch((error: Error) => logError(error));

    checkImageUrl(
      `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/icon_android.png?${imageCache}`,
    )
      .then((result) =>
        setIconAndroid(
          result
            ? `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/icon_android.png?${imageCache}`
            : undefined,
        ),
      )
      .catch((error: Error) => logError(error));

    checkImageUrl(
      `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/icon_ios.png?${imageCache}`,
    )
      .then((result) =>
        setIconIos(
          result
            ? `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/icon_ios.png?${imageCache}`
            : undefined,
        ),
      )
      .catch((error: Error) => logError(error));
  }, [pharmacyId]);

  const choosePhoto = async (type: LogoType): Promise<void> => {
    const photo = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
    });
    if (photo.canceled) {
      return;
    }
    switch (type) {
      case LogoType.logoLight:
        if (!checkAppLogoRequirement(photo.assets[0])) {
          return;
        }
        setLogoLight(photo.assets[0].uri);
        setImageCache(Date.now());
        await uploadFile(
          'branding',
          'logo_light',
          photo.assets[0].uri,
          pharmacyId,
        );
        break;
      case LogoType.logoDark:
        if (!checkAppLogoRequirement(photo.assets[0])) {
          return;
        }
        setLogoDark(photo.assets[0].uri);
        setImageCache(Date.now());
        await uploadFile(
          'branding',
          'logo_dark',
          photo.assets[0].uri,
          pharmacyId,
        );
        break;
      case LogoType.iconAndroid:
        if (!checkAppIconRequirements(photo.assets[0])) {
          return;
        }
        setIconAndroid(photo.assets[0].uri);
        setImageCache(Date.now());
        await uploadFile(
          'branding',
          'icon_android.png',
          photo.assets[0].uri,
          pharmacyId,
        );
        break;
      case LogoType.iconIos:
        if (!checkAppIconRequirements(photo.assets[0])) {
          return;
        }
        setIconIos(photo.assets[0].uri);
        setImageCache(Date.now());
        await uploadFile(
          'branding',
          'icon_ios.png',
          photo.assets[0].uri,
          pharmacyId,
        );
        break;
      default:
        break;
    }
  };
  return (
    <>
      <RowItemRender title={getText('primary-color')}>
        <View style={styles.brandingInfo}>
          <BrandingColorSquare color={button_primary_color} />
          <Text style={styles.brandingValue}>{button_primary_color}</Text>
        </View>
      </RowItemRender>
      <RowItemRender title={getText('text-color')}>
        <View style={styles.brandingInfo}>
          <BrandingColorSquare color={branded_text_color} />
          <Text style={styles.brandingValue}>{branded_text_color}</Text>
        </View>
      </RowItemRender>
      <View style={{ marginLeft: getSpacing(0.5) }}>
        <RowItemRender title={getText('logo-light')}>
          <ResourceGuard resource="settingsLumistryUser" action="update">
            <View style={styles.inline}>
              <PhotoButton
                resize="contain"
                uri={logoLight}
                backgroundColor={theme.palette.grayNeutral[50]}
                width={buttonWidth}
                height={buttonHeight}
                onPress={() => choosePhoto(LogoType.logoLight)}
                testID="upload-logo-light"
              >
                <View style={styles.placeholderWrapper}>
                  <View>
                    <ImageIcon size={32} color={theme.palette.gray[300]} />
                  </View>
                  <View style={styles.upload}>
                    <FileUploadIcon color={theme.palette.gray[500]} size={24} />
                    <Text style={styles.uploadText}>{getText('upload')}</Text>
                  </View>
                </View>
              </PhotoButton>
              <Text
                onPress={() => choosePhoto(LogoType.logoLight)}
                style={{ color: theme.palette.primary[600] }}
              >
                {getText('replace')}
              </Text>
            </View>

            <View style={styles.inline}>
              <View style={styles.placeholderWrapper}>
                <View>
                  <Image
                    source={{ uri: logoLight }}
                    style={{
                      width: buttonWidth,
                      height: buttonHeight,
                      resizeMode: 'contain',
                      backgroundColor: theme.palette.grayNeutral[50],
                    }}
                  />
                </View>
              </View>
            </View>
          </ResourceGuard>
        </RowItemRender>

        <RowItemRender title={getText('logo-dark')}>
          <ResourceGuard resource="settingsLumistryUser" action="update">
            <View style={styles.inline}>
              <PhotoButton
                resize="contain"
                uri={logoDark}
                backgroundColor={theme.palette.gray[500]}
                width={buttonWidth}
                height={buttonHeight}
                onPress={() => choosePhoto(LogoType.logoDark)}
                testID="upload-logo-dark"
              >
                <View style={styles.placeholderWrapper}>
                  <View>
                    <ImageIcon size={32} color={theme.palette.gray[300]} />
                  </View>
                  <View style={styles.upload}>
                    <FileUploadIcon color={theme.palette.gray[500]} size={24} />
                    <Text style={styles.uploadText}>{getText('upload')}</Text>
                  </View>
                </View>
              </PhotoButton>
              <Text
                onPress={() => choosePhoto(LogoType.logoDark)}
                style={{ color: theme.palette.primary[600] }}
              >
                {getText('replace')}
              </Text>
            </View>

            <View style={styles.inline}>
              <View style={styles.placeholderWrapper}>
                <View>
                  <Image
                    source={{ uri: logoDark }}
                    style={{
                      width: buttonWidth,
                      height: buttonHeight,
                      resizeMode: 'contain',
                      backgroundColor: theme.palette.gray[500],
                    }}
                  />
                </View>
              </View>
            </View>
          </ResourceGuard>
        </RowItemRender>

        <RowItemRender
          title={getText('icon-android')}
          icon={
            <SettingsTooltipsComponents
              tooltipId="icon-android-tooltip-id"
              tooltipText={getText('requires-rebuilding-app')}
            />
          }
        >
          <ResourceGuard resource="appIcon" action="update">
            <View style={styles.inline}>
              <PhotoButton
                resize="contain"
                uri={icon_android}
                backgroundColor={theme.palette.grayNeutral[50]}
                width={100}
                height={100}
                imageHeight={64}
                onPress={() => choosePhoto(LogoType.iconAndroid)}
                testID="upload-icon-android"
              >
                <View style={styles.placeholderWrapper}>
                  <View>
                    <ImageIcon size={24} color={theme.palette.gray[300]} />
                  </View>
                  <View style={styles.upload}>
                    <FileUploadIcon color={theme.palette.gray[500]} size={18} />
                    <Text style={[styles.uploadText, { fontSize: 12 }]}>
                      {getText('upload')}
                    </Text>
                  </View>
                </View>
              </PhotoButton>
              <Text
                onPress={() => choosePhoto(LogoType.iconAndroid)}
                style={{ color: theme.palette.primary[600] }}
              >
                {getText('replace')}
              </Text>
            </View>

            <View style={styles.inline}>
              <View style={styles.placeholderWrapper}>
                <View>
                  <Image
                    source={{ uri: icon_android }}
                    style={styles.imageStyle}
                  />
                </View>
              </View>
            </View>
          </ResourceGuard>
        </RowItemRender>

        <RowItemRender
          title={getText('icon-ios')}
          icon={
            <SettingsTooltipsComponents
              tooltipId="icon-ios-tooltip-id"
              tooltipText={getText('requires-rebuilding-app')}
            />
          }
        >
          <ResourceGuard resource="appIcon" action="update">
            <View style={styles.inline}>
              <PhotoButton
                resize="contain"
                uri={icon_ios}
                backgroundColor={theme.palette.grayNeutral[50]}
                width={100}
                height={100}
                imageHeight={64}
                onPress={() => choosePhoto(LogoType.iconIos)}
                testID="upload-icon-ios"
              >
                <View style={styles.placeholderWrapper}>
                  <View>
                    <ImageIcon size={24} color={theme.palette.gray[300]} />
                  </View>
                  <View style={styles.upload}>
                    <FileUploadIcon color={theme.palette.gray[500]} size={18} />
                    <Text style={[styles.uploadText, { fontSize: 12 }]}>
                      {getText('upload')}
                    </Text>
                  </View>
                </View>
              </PhotoButton>
              <Text
                onPress={() => choosePhoto(LogoType.iconIos)}
                style={{ color: theme.palette.primary[600] }}
              >
                {getText('replace')}
              </Text>
            </View>

            <View style={styles.inline}>
              <View style={styles.placeholderWrapper}>
                <View>
                  <Image source={{ uri: icon_ios }} style={styles.imageStyle} />
                </View>
              </View>
            </View>
          </ResourceGuard>
        </RowItemRender>
      </View>
    </>
  );
};

interface BrandingInfoItemRendererProps {
  button_primary_color: string;
  branded_text_color: string;
  logo_light: string;
  logo_dark: string;
  icon_android: string;
  icon_ios: string;
}

enum LogoType {
  logoLight = 'logo_light',
  logoDark = 'logo_dark',
  iconAndroid = 'icon_android',
  iconIos = 'icon_ios',
}

const useStyles = makeStyles((theme) => ({
  brandingInfo: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  brandingValue: {
    color: theme.palette.gray[700],
    fontSize: 14,
    fontWeight: '400',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  placeholderWrapper: {
    padding: theme.getSpacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  inline: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  imageStyle: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
    backgroundColor: theme.palette.grayNeutral[50],
  },
}));
