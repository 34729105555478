import { useWindowDimensions, View } from 'react-native';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import { DrawerScreenProps } from '@react-navigation/drawer';
import {
  FormStatus,
  ListFormDto,
  FormWidget,
  FormFullDataDto,
  FormAccessType,
} from '@digitalpharmacist/forms-service-client-axios';

import { FormsDrawerNavigationProp } from '../../layout/FormsDrawer';
import { TrashWithStripesIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { ChevronLeftIcon } from 'assets/icons/ChevronLeftIcon';
import { IconButton } from 'assets/components/icon-button';
import {
  changeFormsStatus,
  clearFormState,
  getForm,
  deleteForm,
  updateFormQuestions,
  generateFormQuestions,
  getFormPreview,
  changeFormsAccessType,
} from './edit-form-actions';
import { useEditFormStore } from './edit-form-store';
import { CopyToClipboard } from 'assets/components/copy-to-clipboard';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import FormBuilder from '../../forms/forms-builder/FormBuilder';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { useIsFocused } from '@react-navigation/native';
import { Text } from 'assets/components/text';
import { EditFormNameModal } from '../../forms/edit-form-name-modal/EditFormNameModal';
import { useToast } from '../../common/hooks/useToast';
import { EditThankYouMessageModal } from '../../forms/edit-thank-you-message-modal/EditThankYouMessageModal';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { FormBuilderPlaceholder } from '../../forms/forms-builder/FormBuilderPlaceholder';
import { zIndexAuto } from '../../common/theme';
import { FormPreviewModal } from './FormPreviewModal';
import { getText } from 'assets/localization/localization';
import { CheckboxBase } from 'assets/components/checkbox/CheckboxBase';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { FormTestIDs } from './FormTestIDs';

const getAccessTypeText = (status: FormAccessType) =>
  status == FormAccessType.Public ? 'Public' : 'Private';

const getStatusText = (status: FormStatus | undefined) =>
  status == FormStatus.Enabled ? 'Active' : 'Inactive';

const invertFormStatus = (status: FormStatus): FormStatus =>
  status == FormStatus.Enabled ? FormStatus.Disabled : FormStatus.Enabled;

const invertFormAccessType = (status: FormAccessType): FormAccessType =>
  status == FormAccessType.Private
    ? FormAccessType.Public
    : FormAccessType.Private;

export const EditForm: FunctionComponent<PropsWithChildren<EditFormProps>> = ({
  route,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const formId = route.params?.formId;
  const [formToBeDeleted, setFormToBeDeleted] = useState<ListFormDto | null>(
    null,
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showStatusChangeConfirmation, setShowStatusChangeConfirmation] =
    useState(false);

  const [
    showAccessTypeChangeConfirmation,
    setShowAccessTypeChangeConfirmation,
  ] = useState(false);
  const { toast } = useToast();

  const { height: viewportHeight } = useWindowDimensions();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] =
    useState(false);

  // isFocused ensures that we fetch the form after the user navigates back to this page
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getForm(formId);
    }

    return () => {
      // Runs on component unmount
      clearFormState();
    };
  }, [formId, isFocused]);

  const { status, form, editedFormBuilder, saveButtonEnabled } =
    useEditFormStore();

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const goBack = () => {
    navigation.navigate('forms-list');
  };

  const saveFormChanges = () => {
    if (editedFormBuilder) {
      updateFormQuestions(
        formId,
        form?.questions as FormWidget[],
        editedFormBuilder.task_data,
      );
    }
  };

  const showDeleteModal = (form: ListFormDto) => {
    setFormToBeDeleted(form);
    setShowDeleteConfirmation(true);
  };

  const showPreview = (form: FormFullDataDto) => {
    if (editedFormBuilder) {
      getFormPreview(
        form.id,
        generateFormQuestions(form.questions, editedFormBuilder.task_data),
      );
      setShowPreviewModal(true);
    }
  };

  const deleteFormCloseModal = () => {
    if (formToBeDeleted) {
      deleteForm(formToBeDeleted.id, navigation);
    }
    setShowDeleteConfirmation(false);
  };

  return form ? (
    <View style={styles.container}>
      {status == 'loading' ? <LoadingOverlay /> : null}
      <PharmacyConfirmationModal
        show={showDeleteConfirmation}
        onAccepted={() => deleteFormCloseModal()}
        onDismiss={() => setShowDeleteConfirmation(false)}
        message={getText('confirm-to-delete-name', {
          name: formToBeDeleted?.title,
        })}
      />

      <PharmacyConfirmationModal
        show={showSaveConfirmationModal}
        onAccepted={() => {
          saveFormChanges();
          setShowSaveConfirmationModal(false);
        }}
        onDismiss={() => setShowSaveConfirmationModal(false)}
        message={getText('form-save-modal-message')}
        title={getText('form-save-confirm')}
        okButtonText={getText('form-save-save-button')}
      />

      <FormPreviewModal
        showPreviewModal={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
      />

      <View
        style={[
          styles.rowCenter,
          styles.verticalAlign,
          styles.headerContainerOutside,
        ]}
      >
        <IconButton
          size={25}
          logger={{ id: 'go-back-forms-button' }}
          icon={ChevronLeftIcon}
          onPress={goBack}
        />
        <View style={styles.headerContainer}>
          <View style={[styles.rowCenter, styles.verticalAlign, styles.header]}>
            <View style={styles.headerTitleContainer}>
              <Text
                style={styles.title}
                selectable
                testID={FormTestIDs.editFormName}
              >
                {form.title}
              </Text>
              <EditFormNameModal />
            </View>

            <View
              style={[
                styles.rowCenter,
                styles.verticalAlign,
                { zIndex: zIndexAuto },
              ]}
            >
              <PharmacyTooltipWrapper tooltipId="status-tooltip">
                <View
                  style={[styles.rowCenter, styles.verticalAlign]}
                  data-tooltip-id="status-tooltip"
                >
                  <View
                    style={styles.checkboxContainer}
                    testID={FormTestIDs.formStatusCheckboxContainer}
                  >
                    <CheckboxBase
                      onPress={() => {
                        setShowStatusChangeConfirmation(true);
                      }}
                      isChecked={form.status == FormStatus.Enabled}
                      testID={`change-status-form--${form.id}`}
                    />
                    <Text style={styles.checkboxLabel}>
                      {getText('active')}
                    </Text>
                  </View>
                </View>
              </PharmacyTooltipWrapper>

              <Tooltip
                id="status-tooltip"
                text={`${getText('status')}: ${getText(
                  'pharmacy-additional-closures-active',
                )}/${getText('pharmacy-additional-closures-inactive')}`}
              />

              <Tooltip
                id="access-tooltip"
                text={getText('access-private-public')}
              />
            </View>

            <Button
              hierarchy="secondary-gray"
              mode="contained"
              disabled={form.submissionCount > 0}
              style={{
                borderColor: 'transparent',
                marginLeft: theme.getSpacing(2),
              }}
              size="medium"
              logger={{ id: 'delete-form-button' }}
              icon={() => (
                <TrashWithStripesIcon
                  size={20}
                  color={
                    form.submissionCount > 0
                      ? theme.palette.gray[300]
                      : theme.palette.gray[700]
                  }
                />
              )}
              onPress={() => showDeleteModal(form)}
            >
              {getText('delete')}
            </Button>

            <Button
              hierarchy="secondary-gray"
              size="medium"
              logger={{ id: 'save-form-button' }}
              onPress={() => showPreview(form)}
              style={{
                marginLeft: theme.getSpacing(2),
              }}
            >
              {getText('preview')}
            </Button>

            <Button
              hierarchy="primary"
              size="medium"
              logger={{ id: 'save-form-button' }}
              disabled={!saveButtonEnabled}
              onPress={() =>
                form.submissionCount
                  ? setShowSaveConfirmationModal(true)
                  : saveFormChanges()
              }
            >
              {getText('save')}
            </Button>
          </View>

          <View
            style={[styles.rowCenter, styles.verticalAlign, styles.subheader]}
          >
            <PharmacyConfirmationModal
              show={showStatusChangeConfirmation}
              onAccepted={() => {
                changeFormsStatus(form.id, invertFormStatus(form.status!));
                setShowStatusChangeConfirmation(false);
              }}
              onDismiss={() => setShowStatusChangeConfirmation(false)}
              message={`${getText('confirm-to-make')} ${getStatusText(
                invertFormStatus(form.status!),
              ).toLowerCase()}?`}
            />

            <PharmacyConfirmationModal
              show={showAccessTypeChangeConfirmation}
              onAccepted={() => {
                changeFormsAccessType(
                  form.id,
                  invertFormAccessType(form.formAccessType!),
                );
                setShowAccessTypeChangeConfirmation(false);
              }}
              onDismiss={() => setShowAccessTypeChangeConfirmation(false)}
              message={`${getText('confirm-to-make')} ${getAccessTypeText(
                invertFormAccessType(form.formAccessType!),
              ).toLowerCase()}?`}
            />
          </View>
        </View>
      </View>

      <View style={styles.formContainer}>
        <View style={[styles.rowCenter, styles.formBuilderTopBar]}>
          <View style={[styles.rowCenter, styles.verticalAlign]}>
            <Text selectable={true}>{form.url}</Text>
            <CopyToClipboard
              logger={{ id: `copy-form-url--${form.id}` }}
              stringToCopy={form.url}
              fetchFromClipboard={() => toast('Copied to clipboard')}
            ></CopyToClipboard>
          </View>

          <View style={[styles.rowCenter, styles.verticalAlign]}>
            <EditThankYouMessageModal />
          </View>
        </View>
        {form.editable ? (
          <>{status !== 'loading' && <FormBuilder />}</>
        ) : (
          <FormBuilderPlaceholder
            title={getText('form-not-editable')}
            text={
              form.imported
                ? getText('form-imported-text')
                : getText('form-not-imported-text')
            }
          />
        )}
      </View>
    </View>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginHorizontal: theme.getSpacing(4),
    marginBottom: theme.getSpacing(4),
    height: '100%',
    flex: 1,
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
  },
  verticalAlign: {
    alignItems: 'center',
  },
  leftMargin: {
    marginLeft: theme.getSpacing(4),
  },
  headerContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: theme.getSpacing(2),
    zIndex: zIndexAuto,
  },
  headerContainerOutside: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    zIndex: zIndexAuto,
  },
  header: {
    marginBottom: 0,
    gap: theme.getSpacing(1),
    zIndex: zIndexAuto,
  },
  subheader: {
    justifyContent: 'space-between',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'baseline',
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(1),
  },
  accessTypeText: {
    fontWeight: 'bold',
    marginRight: theme.getSpacing(1),
  },
  active: {
    color: theme.palette.success['700'],
    fontWeight: 'bold',
    marginRight: theme.getSpacing(1),
  },
  notActive: {
    color: theme.palette.error['700'],
    fontWeight: 'bold',
    marginRight: theme.getSpacing(1),
  },
  greyedOut: {
    color: theme.palette.gray['500'],
  },
  formContainer: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
    flex: 1,
  },
  formBuilderTopBar: {
    justifyContent: 'space-between',
    paddingTop: theme.getSpacing(2),
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: 16,
    color: theme.palette.gray[700],
    marginLeft: theme.getSpacing(1),
    flex: 1,
  },
}));

type EditFormProps = DrawerScreenProps<any, any>;
{
}
