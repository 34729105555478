import { PharmacyUserDto } from '@digitalpharmacist/users-service-client-axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { logEvent } from 'assets/logging/logger';
import { StorageKeys } from '../../enums/storage-keys';
import UsersService from '../api/UsersService';
import { useAppStateStore } from '../store/app-store';
import { PharmacyUserExtended, useUserState } from '../store/user-store';
import { setAmplitudeUserProperties } from './app-actions';
import RoleService from '../api/RoleService';

export const tryGetUser = async (): Promise<PharmacyUserDto | undefined> => {
  try {
    const userId = await AsyncStorage.getItem(StorageKeys.UserId);

    if (userId) {
      const user = await UsersService.getPharmacistUser(userId);
      return user;
    }
  } catch (e: any) {
    logEvent('loadUserInfo', e);
  }
};
/**
 * This action will persist `PharmacyUserExtended` and load user state
 * @param user
 */
export const persistUserInfo = async (user: PharmacyUserExtended) => {
  if (!user.id) {
    throw Error('The user supplied has no [id] information!');
  }

  const pharmacyUsersMap = await loadUserInfoMap();

  const userInfoExists = Object.keys(pharmacyUsersMap).includes(user.id);
  if (!userInfoExists) {
    pharmacyUsersMap[user.id] = user;
  } else {
    pharmacyUsersMap[user.id] = {
      ...pharmacyUsersMap[user.id],
      ...user,
    };
  }

  // save on storage
  await AsyncStorage.setItem(
    StorageKeys.UserInfo,
    JSON.stringify(pharmacyUsersMap),
  );
  await AsyncStorage.setItem(StorageKeys.UserId, user.id);

  // NOTE: this may not be what you want, you probably only want available roles for the specific location MLW
  // Leaving as is, but may want to review. I am adding the available roles here and it may be the preferred set,
  // But with the caveat that it is only available after the user has selected a location
  // getting roles for the user
  const roles = await RoleService.userRoleGetRolesByUserId(user.id);

  // save on state
  useUserState.setState({ data: { ...user, roles: roles } });
  useAppStateStore.setState({
    locationId: pharmacyUsersMap[user.id].selectedLocation?.location_id || '',
    pharmacyId: pharmacyUsersMap[user.id].selectedLocation?.pharmacy_id || '',
    pharmacySlug:
      pharmacyUsersMap[user.id].selectedLocation?.pharmacy_slug || '',
    pharmacyName:
      pharmacyUsersMap[user.id].selectedLocation?.pharmacy_name || '',
  });
  void setAmplitudeUserProperties(
    user.id,
    pharmacyUsersMap[user.id].selectedLocation?.pharmacy_id || '',
    pharmacyUsersMap[user.id].selectedLocation?.pharmacy_id || '',
    pharmacyUsersMap[user.id].selectedLocation?.location_id || '',
  );
};

export const setAvailableRoles = async (userId: string) => {
  if (!userId) {
    throw Error(
      'The user supplied has no [id] information when setting available roles',
    );
  }
  const pharmacyUsersMap = await loadUserInfoMap();
  const currentUser = pharmacyUsersMap[userId];
  const { selectedLocation } = currentUser;
  const locationId = selectedLocation?.location_id;
  if (!locationId) {
    return console.error(
      'No location id selected while setting available roles, continuing without UI guards',
    );
  }

  try {
    const availableRolesReq =
      await RoleService.userRoleGetRolesByUserIdAndLocation(userId, locationId);

    useAppStateStore.setState({
      availableRoles: availableRolesReq.available_roles,
    });
  } catch (e) {
    console.error(
      'Could not get users available roles. Continuing to display UI without guards.',
    );
  }
};

export const loadUserInfoMap = async (): Promise<
  Record<string, PharmacyUserExtended>
> => {
  const pharmacyUsersMapStringified = await AsyncStorage.getItem(
    StorageKeys.UserInfo,
  );

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const pharmacyUsersMap: Record<string, PharmacyUserExtended> = (
    pharmacyUsersMapStringified && pharmacyUsersMapStringified.length > 0
      ? JSON.parse(pharmacyUsersMapStringified)
      : {}
  ) as Record<string, PharmacyUserExtended>;

  return pharmacyUsersMap;
};
