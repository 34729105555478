import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import {
  createPatientAgreement,
  fetchPatientAgreementDefaultDocs,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { useToast } from '../../../common/hooks/useToast';
import { ChecklistModalProps } from '../types';
import * as DocumentPicker from 'expo-document-picker';
import {
  PatientAgreementRadioButtonValue,
  PatientAgreementTemplateModalType,
} from './types';
import { PatientAgreementRadioButtonField } from './components/patient-agreement-radio-button/PatientAgreementRadioButtonField';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import {
  CreatePatientAgreementDto,
  PatientAgreementEnum,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { uploadPublicPharmacyFile } from '../../../actions/file-storage-actions';
import { PatientAgreementTemplateModal } from './components/patient-agreement-template-modal/PatientAgreementTemplateModal';

type PatientAgreementDocUriType = {
  [key in keyof CreatePatientAgreementDto]: string;
};

export const PatientAgreementModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const styles = useStyles();
  const { toast } = useToast();

  const currentOpenedModalId = useChecklistStore((x) => x.currentOpenedModalId);

  const [modalType, setModalTypes] =
    useState<PatientAgreementTemplateModalType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUploadTemplateModal, setShowUploadTemplateModal] =
    useState<boolean>(false);
  const resolveRef = useRef<() => void>();
  const rejectRef = useRef<() => void>();
  const [uploadedDocs, setUploadedDocs] = useState<
    | {
        [key in keyof CreatePatientAgreementDto]?: DocumentPicker.DocumentPickerAsset;
      }
    | undefined
  >();
  const methods = useForm<CreatePatientAgreementDto>();

  const [formFieldConfig, setFormFieldConfig] = useState<{
    [key in keyof CreatePatientAgreementDto]: PatientAgreementRadioButtonValue[];
  }>({
    privacy_policy: [
      {
        title: getText('patient-agreements-use-template'),
        value: PatientAgreementEnum.Template,
        onPress: () => {
          return new Promise((resolve, reject) => {
            setModalTypes('privacy');
            setShowUploadTemplateModal(true);

            resolveRef.current = () => {
              handleDocsUpload('privacy_policy', undefined);

              return resolve(PatientAgreementEnum.Template);
            };
            rejectRef.current = reject;
          });
        },
      },
      {
        title: getText('upload-a-pdf'),
        value: PatientAgreementEnum.Upload,
        onPress: (value) =>
          new Promise((res) => {
            void onDocumentSelect('privacy_policy').then(
              (filename: string | undefined) => {
                if (filename) res(value);
              },
            );
          }),
      },
    ],
    terms_of_use: [
      {
        title: getText('patient-agreements-use-template'),
        value: PatientAgreementEnum.Template,
        onPress: () => {
          return new Promise((resolve, reject) => {
            setModalTypes('terms');
            setShowUploadTemplateModal(true);

            resolveRef.current = () => {
              handleDocsUpload('terms_of_use', undefined);

              return resolve(PatientAgreementEnum.Template);
            };
            rejectRef.current = reject;
          });
        },
      },
      {
        title: getText('upload-a-pdf'),
        value: PatientAgreementEnum.Upload,
        onPress: (value) =>
          new Promise((res) => {
            void onDocumentSelect('terms_of_use').then(
              (filename: string | undefined) => {
                if (filename) res(value);
              },
            );
          }),
      },
    ],
  });

  const handleDocsUpload = (
    field: keyof CreatePatientAgreementDto,
    doc: DocumentPicker.DocumentPickerAsset | undefined,
  ) => {
    setUploadedDocs((prev) => ({
      ...prev,
      [field]: doc,
    }));

    setFormFieldConfig((prev) => ({
      ...prev,
      [field]: prev[field].map((x) => {
        if (x.value === PatientAgreementEnum.Upload)
          x.description = doc ? doc.name : undefined;
        return x;
      }),
    }));
  };

  const onDocumentSelect = async (
    field: keyof CreatePatientAgreementDto,
  ): Promise<string | undefined> => {
    try {
      const docsResult: DocumentPicker.DocumentPickerResult =
        await DocumentPicker.getDocumentAsync({
          multiple: false,
          type: 'application/pdf',
        });

      if (docsResult.canceled) return;

      handleDocsUpload(field, docsResult.assets[0]);

      return docsResult.assets[0].name;
    } catch (error) {
      //TODO: review console.error
      console.error('Documents picking error: ', error);
      return;
    }
  };

  const uploadDocuments = async () => {
    if (uploadedDocs) {
      const hasDocsToUpload = Object.values(uploadedDocs).some(
        (x) => x !== undefined,
      );
      if (!hasDocsToUpload) return;

      const upload = (
        fileName: keyof PatientAgreementDocUriType,
        file: File,
      ): Promise<Partial<PatientAgreementDocUriType>> => {
        return new Promise((resolve, reject) => {
          if (!file) return reject('There is no file');

          uploadPublicPharmacyFile(fileName, file)
            .then((url) => resolve({ [fileName]: url }))
            .catch(reject);
        });
      };

      const uploadEntries = Object.entries(uploadedDocs).filter(
        ([key, value]) => value,
      );

      if (!uploadEntries.length) return;

      const uploadPromises = uploadEntries.map(async ([key, value]) =>
        upload(key as keyof PatientAgreementDocUriType, value.file!),
      );

      const allFilesPromises =
        await Promise.allSettled<Partial<PatientAgreementDocUriType>>(
          uploadPromises,
        );

      if (allFilesPromises.some((x) => x.status === 'rejected'))
        throw Error('An error happened during file upload!');
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const patientAgreementCreate = methods.getValues();

      await uploadDocuments();

      await createPatientAgreement(patientAgreementCreate);

      setIsLoading(false);
      handleCloseModal();
    } catch (e: any) {
      toast(e.message ?? getText('something-went-wrong-implicit'), {
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenedModal('checklist-setup');
  };

  useEffect(() => {
    void fetchPatientAgreementDefaultDocs();
  }, []);

  return (
    <Modal
      title={getText('patient-agreements')}
      show={currentOpenedModalId === id}
      handleDismissModal={handleCloseModal}
      size="sm"
      buttons={[
        {
          text: getText('ok'),
          onPress: methods.handleSubmit(handleSubmit),
          hierarchy: 'primary',
          logger: { id: 'ok-checklist-patient-agreement-modal' },
          loading: isLoading,
          disabled: isLoading,
        },
        {
          text: getText('cancel'),
          onPress: handleCloseModal,
          hierarchy: 'tertiary-gray',
          logger: { id: 'cancel-checklist-patient-agreement-modal' },
        },
      ]}
      isScrollable
      showDismissButton
    >
      <Form methods={methods}>
        <View style={styles.container}>
          <Text style={styles.description}>
            {getText('patient-agreements-description')}
          </Text>
          <PatientAgreementRadioButtonField
            values={formFieldConfig.terms_of_use}
            label={getText('patient-agreements-terms-option')}
            name="terms_of_use"
            rules={{ required: getText('required-field') }}
          />
          <PatientAgreementRadioButtonField
            values={formFieldConfig.privacy_policy}
            label={getText('patient-agreements-privacy-option')}
            name="privacy_policy"
            rules={{ required: getText('required-field') }}
          />
        </View>
      </Form>
      <PatientAgreementTemplateModal
        show={showUploadTemplateModal}
        type={modalType}
        onClose={function (): void {
          resolveRef.current?.();
          setShowUploadTemplateModal(false);
        }}
        onDismiss={function (): void {
          rejectRef.current?.();
          setShowUploadTemplateModal(false);
        }}
      />
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(2),
  },
  description: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.black,
  },
}));
