import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useAppStateStore } from '../../../../../store/app-store';
import { useRefillsSettingsState } from '../../refills-settings-store';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'react-hook-form';
import { Form } from 'assets/layout';
import { updateRefillDueReminders } from '../../refills-settings-actions';
import { isNumberAndBiggerOrLessThan } from '../../../../../../utils';

export const RefillDueModal: FunctionComponent<PropsWithChildren<any>> = () => {
  const { showRefillDueModal, refillDueReminders } = useRefillsSettingsState();
  const { locationId } = useAppStateStore();
  const styles = useStyles();
  const [showError, setShowError] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (!refillDueReminders || refillDueReminders.length < 3) return;
    methods.setValue(
      'refill_due_reminder_non_controller',
      refillDueReminders[0].value.toString(),
    );
    methods.setValue(
      'refill_due_reminder_controller',
      refillDueReminders[1].value.toString(),
    );
    methods.setValue(
      'refill_past_due_reminder',
      refillDueReminders[2].value.toString(),
    );
  }, [refillDueReminders, showRefillDueModal]);

  const methods = useForm({
    defaultValues: {
      refill_due_reminder_non_controller: '',
      refill_due_reminder_controller: '',
      refill_past_due_reminder: '',
    },
  });

  const handleSubmit = async () => {
    const values = methods.getValues();
    const newErrors: { [key: string]: boolean } = {};

    if (!isNumberAndBiggerOrLessThan(values.refill_due_reminder_controller)) {
      newErrors.refill_due_reminder_controller = true;
    }
    if (
      !isNumberAndBiggerOrLessThan(values.refill_due_reminder_non_controller)
    ) {
      newErrors.refill_due_reminder_non_controller = true;
    }
    if (!isNumberAndBiggerOrLessThan(values.refill_past_due_reminder, 1, 14)) {
      newErrors.refill_past_due_reminder = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowError(true);
    } else {
      setErrors({});
      setShowError(false);
      updateRefillDueReminders(
        locationId,
        parseInt(values.refill_due_reminder_controller, 10),
        parseInt(values.refill_due_reminder_non_controller, 10),
        parseInt(values.refill_past_due_reminder, 10),
      );
      closeModal();
    }
  };

  const closeModal = () => {
    useRefillsSettingsState.setState({ showRefillDueModal: false });
    setShowError(false);
    methods.reset();
  };

  return (
    <Modal
      title={getText('edit-refill-due-reminders')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'refill-due-reminders-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'refill-due-reminders-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'refill-due-reminders-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showRefillDueModal}
      isScrollable={false}
    >
      <Form methods={methods}>
        <Form.Alert
          title={getText('form-error-occurred')}
          intent="error"
          visible={showError}
        />
        {refillDueReminders.length > 0 &&
          refillDueReminders.map((item, index) => (
            <Form.Row key={index} style={styles.rowView}>
              <View>
                <Form.Column>
                  <Text style={styles.title}>{item.modalLabel}</Text>
                </Form.Column>
              </View>
              <View style={styles.rightColumnContent}>
                <Form.Column style={styles.rightColumnContent}>
                  <TextField
                    name={item.key}
                    value={item?.value?.toString()}
                    style={
                      errors[item.key]
                        ? styles.inputFieldError
                        : styles.inputField
                    }
                    maxLength={2}
                    type="numeric"
                  />
                </Form.Column>
              </View>
            </Form.Row>
          ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium,
    flex: 1,
  },
  rightColumnContent: {
    width: 50,
    flex: -1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },
  inputField: {
    width: 50,
    textAlign: 'center',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
  },
  inputFieldError: {
    width: 50,
    textAlign: 'center',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    borderColor: theme.palette.error[500],
  },
  rowView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  errorMessage: {
    color: theme.palette.error[500],
    textAlign: 'right',
  },
}));
