import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { MessageCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { notImplementedAlert } from 'assets/utils/alert';
import { makeStyles, useTheme } from 'assets/theme';
import { useMessagingStyles } from '../common/styles';

export const PharmacyWithoutPatientsView: FunctionComponent = () => {
  const theme = useTheme();
  const messagingStyles = useMessagingStyles();
  const styles = useStyles();

  return (
    <View style={messagingStyles.noPatientsWrapper}>
      <MessageCircleIcon size={45} />
      <Text style={[styles.noPatientsSecondaryText, styles.bold]}>
        {getText('add-patients-start-messaging')}
      </Text>
      <Button
        hierarchy="secondary"
        size="small"
        logger={{ id: 'add-patient-button' }}
        style={{ marginTop: theme.getSpacing(1.5) }}
        onPress={notImplementedAlert}
      >
        {getText('add-patient')}
      </Button>
      <Button
        hierarchy="primary"
        size="small"
        logger={{ id: 'add-upload-patients-csv-button' }}
        style={{ marginTop: theme.getSpacing(1.5) }}
        onPress={notImplementedAlert}
      >
        {getText('upload-patients-csv')}
      </Button>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  noPatientsSecondaryText: {
    marginTop: theme.getSpacing(1.5),
  },
  bold: {
    fontWeight: '600',
  },
}));
