import React, { FunctionComponent, useCallback } from 'react';
import { View } from 'react-native';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import {
  useRoute,
  getFocusedRouteNameFromRoute,
  useFocusEffect,
} from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { useTheme } from 'assets/theme';
import Chat from '../screens/messages/Chat';
import Bulk from '../screens/messages/Bulk/components/Bulk';
import Auto from '../screens/messages/Auto';
import { Tabs } from '../components/tabs/Tabs';
import { MessagesFilters } from '../screens/messages/components/messages-filters/MessagesFilters';
import { AutoIcon, ChatIcon, BulkIcon } from 'assets/icons';
import { openSidebarNewChatModal } from '../screens/messages/stores/chat-store/chat-actions';
import { BulkSideBar } from '../screens/messages/components/bulkSideBar/BulkSideBar';
import { setIsNewBulkChatModalOpen } from '../screens/messages/stores/bulk-messages-store/bulk-messages-actions';
import { defaultDrawerTheme } from 'assets/theme/theme';
import { useMessagingState } from '../screens/messages/stores/messaging-store/messaging-store';
import ChecklistCard from '../components/checklist/checklist-card/checklist-card';
import { setOpenedModal } from '../actions/checklist-actions';

const DrawerNavigator =
  createDrawerNavigator<MessagesDrawerNavigationParamList>();

export const MessagesDrawer: FunctionComponent<MessagesDrawerProps> = (
  props,
) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
        drawerStyle: defaultDrawerTheme,
      }}
      initialRouteName={ETabs.CHAT}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen
        name={ETabs.CHAT}
        component={Chat}
        options={{ title: getText('chat-messages') }}
      />
      <DrawerNavigator.Screen
        name={ETabs.BULK}
        component={Bulk}
        options={{ title: getText('bulk-messages') }}
      />
      <DrawerNavigator.Screen
        name={ETabs.AUTO}
        component={Auto}
        options={{ title: getText('automated-messages') }}
      />
    </DrawerNavigator.Navigator>
  );
};

interface MessagesDrawerProps {}

export type MessagesDrawerNavigationParamList = {
  [ETabs.CHAT]: ChatScreenParamsProps | undefined;
  [ETabs.BULK]: undefined;
  [ETabs.AUTO]: undefined;
};

export type MessagesDrawerNavigationProp =
  DrawerNavigationProp<MessagesDrawerNavigationParamList>;

export type ChatScreenProps = StackScreenProps<
  MessagesDrawerNavigationParamList,
  ETabs.CHAT
>;

export type ChatScreenParamsProps = {
  newMessageModal: 'open';
};

enum ETabs {
  CHAT = 'chat',
  BULK = 'bulk',
  AUTO = 'auto',
}

function getMessagesTabs(
  theme: ReactNativePaper.Theme,
  disableBulkAuto: boolean,
) {
  const onNewChat = () => {
    openSidebarNewChatModal();
  };

  const onNewBulkModalOpen = () => {
    setIsNewBulkChatModalOpen(true);
  };

  return [
    {
      title: getText('chat'),
      id: ETabs.CHAT,
      children: (
        <>
          <View>
            <Button
              hierarchy="primary"
              size="large"
              logger={{ id: 'new-chat-button' }}
              style={{ margin: theme.getSpacing(2) }}
              onPress={onNewChat}
            >
              {getText('new-chat')}
            </Button>
            <MessagesFilters />
          </View>
        </>
      ),
      icon: ChatIcon,
      disable: false,
    },
    {
      title: getText('bulk'),
      id: ETabs.BULK,
      children: (
        <>
          <View>
            <Button
              hierarchy="primary"
              size="large"
              logger={{ id: 'new-bulk-message' }}
              style={{ margin: theme.getSpacing(2) }}
              onPress={onNewBulkModalOpen}
            >
              {getText('new-bulk-message')}
            </Button>
            <BulkSideBar />
          </View>
        </>
      ),
      icon: BulkIcon,
      disable: disableBulkAuto,
    },
    {
      title: getText('auto'),
      id: ETabs.AUTO,

      icon: AutoIcon,
      disable: disableBulkAuto,
    },
  ];
}

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const theme = useTheme();
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);

  const handleTabChange = (activeTab: string) => {
    props.navigation.navigate(
      activeTab as keyof MessagesDrawerNavigationParamList,
    );
  };

  useFocusEffect(
    useCallback(() => {
      handleTabChange(ETabs.CHAT);
    }, []),
  );

  const pharmacyLocationHasPatientRecords = useMessagingState(
    (state) => state.pharmacyLocationHasPatientRecords,
  );

  return (
    <>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ paddingTop: 0 }}
      >
        <Tabs
          tabs={getMessagesTabs(theme, !pharmacyLocationHasPatientRecords)}
          onTabChange={handleTabChange}
          defaultTab={activeRoute ?? ETabs.CHAT}
        />
        <ChecklistCard
          hierarchy="secondary"
          onPress={() => setOpenedModal('checklist-setup')}
        />
      </DrawerContentScrollView>
    </>
  );
};
