import { PublicCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import {
  BrandingDto,
  CreatePharmacyAppDto,
  UpdateBrandingDto,
  UpdatePharmacyAppDto,
  UpdatePharmacyWebsiteDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import axios from 'axios';
import FileStorageService from '../../../api/FileStorageService';
import PharmacyServiceInstance from '../../../api/PharmacyService';
import { useToast } from '../../../common/hooks/useToast';
import { PhotoInfo } from '../../../components/InsuranceDetailsModal/PhotoButton';
import { usePharmacySettingsState as pharmacyState } from '../pharmacy/pharmacy-settings-store';
import { useAppSettingsState as brandingState } from './app-settings-store';

const { toast } = useToast();
export const setShowModalBrandingInfo = (showModalBrandingInfo: boolean) =>
  brandingState.setState({ showModalBrandingInfo });

export const setShowModalWebsite = (showModalWebsite: boolean) =>
  brandingState.setState({ showModalWebsite });

export const setShowModalApp = (showModalApp: boolean) =>
  brandingState.setState({ showModalApp });

export const updateBrandingColors = async (
  branded_colors: UpdateBrandingDto,
) => {
  const { pharmacyId } = pharmacyState.getState();

  if (pharmacyId) {
    await PharmacyServiceInstance.updatePharmacyBranding(
      pharmacyId,
      branded_colors,
    ).then(() =>
      brandingState.setState((prevState) => ({
        ...prevState,
        ...branded_colors,
      })),
    );
  }
};

export const getBranding = async () => {
  const { pharmacyId } = pharmacyState.getState();
  if (!pharmacyId) {
    brandingState.setState({ branded_colors: {} as BrandingDto });
    return;
  }
  await PharmacyServiceInstance.brandingGetByPharmacy(pharmacyId).then(
    (branding) => brandingState.setState(() => ({ branded_colors: branding })),
  );
};

const isPNG = (uri: string) => {
  const pngImage = uri.substring('data:image/'.length, uri.indexOf(';base64'));
  return pngImage === 'png';
};

export const isValidImage = (uri: string) => {
  const validImage = uri.substring(
    'data:image/'.length,
    uri.indexOf(';base64'),
  );

  return (
    validImage === 'jpeg' ||
    validImage === 'jpg' ||
    validImage === 'png' ||
    validImage === 'svg'
  );
};

export const checkImageUrl = async (url: string): Promise<boolean> => {
  try {
    const response = await axios.get(url);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

export const checkAppIconRequirements = (image: PhotoInfo) => {
  if (!isPNG(image.uri!)) {
    toast('Error', {
      type: 'error',
      content: getText('png-only'),
    });
    return false;
  } else if (image.width !== 1024 && image.height !== 1024) {
    toast('Error', {
      type: 'error',
      content: getText('valid-icon-dimensions'),
    });
    return false;
  } else {
    return true;
  }
};

export const checkAppLogoRequirement = (image: PhotoInfo) => {
  if (!isValidImage(image.uri!)) {
    toast('Error', {
      type: 'error',
      content: getText('valid-image-type'),
    });
    return false;
  } else {
    return true;
  }
};

export async function uploadFile(
  category: PublicCategory,
  filename: string,
  uri: string,
  pharmacyId: string,
): Promise<string> {
  const responseWriteUrl = await FileStorageService.publicBrandingWriteUrl(
    category,
    filename,
    pharmacyId,
  );

  try {
    const blob = await fetch(uri).then((r) => r.blob());
    await axios.put(responseWriteUrl.url, blob, {
      headers: { 'content-type': 'application/octet-stream' },
    });
  } catch (error) {
    logError(error as Error);
  }

  return filename;
}

export const setPharmacyWebsite = (pharmacyId: string) => {
  void PharmacyServiceInstance.pharmacyWebsiteFind(pharmacyId)
    .then((pharmacyWebsite) => {
      brandingState.setState({ pharmacyWebsite });
    })
    .catch((error: Error) => {
      logError(error);
      brandingState.setState({ pharmacyWebsite: undefined });
    });
};

export const setPharmacyApp = (pharmacyId: string) => {
  void PharmacyServiceInstance.pharmacyAppFind(pharmacyId)
    .then((pharmacyApp) => {
      brandingState.setState({ pharmacyApp });
    })
    .catch((error: Error) => {
      logError(error);
      brandingState.setState({ pharmacyApp: undefined });
    });
};

export const updatePharmacyWebsite = (
  pharmacyId: string,
  updatePharmacyWebsite: UpdatePharmacyWebsiteDto,
) => {
  void PharmacyServiceInstance.pharmacyWebsiteUpdate(
    pharmacyId,
    updatePharmacyWebsite,
  )
    .then((pharmacyWebsite) => {
      brandingState.setState({ pharmacyWebsite });
    })
    .catch((error: Error) => logError(error));
};

export const updatePharmacyApp = (
  pharmacyId: string,
  updatePharmacyApp: UpdatePharmacyAppDto,
) => {
  void PharmacyServiceInstance.pharmacyAppUpdate(pharmacyId, updatePharmacyApp)
    .then((pharmacyApp) => {
      brandingState.setState({ pharmacyApp });
    })
    .catch((error: Error) => logError(error));
};

export const createPharmacyApp = (
  pharmacyId: string,
  createPharmacyApp: CreatePharmacyAppDto,
) => {
  void PharmacyServiceInstance.pharmacyAppCreate(pharmacyId, createPharmacyApp)
    .then((pharmacyApp) => {
      brandingState.setState({ pharmacyApp });
    })
    .catch((error: Error) => logError(error));
};
